import React from 'react'
import styled from '@emotion/styled'
import theme, { SpacingInterval } from './theme'
import { Div } from './Div'

interface TextProps {
  size?: keyof theme['font']['size']
  color?: keyof theme['font']['color']
  weight?: keyof theme['font']['weight']
  lineHeight?: number | string
  block?: boolean
}

export const Text = styled.span<TextProps>(({ size, color, weight, lineHeight, block }) => ({
  fontSize: size && theme.font.size[size],
  color: color && theme.font.color[color],
  fontWeight: weight && theme.font.weight[weight],
  lineHeight,

  ...(block ? { display: 'block' } : {}),
}))

export const TitleText: React.FC<TextProps> = (props) => <Text size="large" color="dark" weight="heavy" {...props} />

interface ParagraphText {
  marginTop?: SpacingInterval
  marginBottom?: SpacingInterval
}

export const ParagraphText: React.FC<TextProps & ParagraphText> = ({ marginTop = 1, marginBottom = 1, ...props }) => (
  <Div marginTop={marginTop} marginBottom={marginBottom}>
    <Text {...props} />
  </Div>
)
