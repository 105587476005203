/* eslint-disable @typescript-eslint/no-explicit-any */
import type { KeysOfType } from '@mm/backend/helpers'
import firebase from 'firebase/app'

import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

if (!firebase.apps.length) {
  // Guard firebase initializing again if already done during hot refreshing.
  firebase.initializeApp({
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
    measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
  })
}

export * from './unsafeFirestoreConverter'
export const auth = firebase.auth()
export const storage = firebase.storage()
export const firestore = firebase.firestore()

if (process.env.NEXT_PUBLIC_FIREBASE_USE_EMULATORS === 'true') {
  firestore.useEmulator('localhost', 8080)
}

export default firestore

export const getRefFormatByIds = async <T>(
  ref: firebase.firestore.Query<firebase.firestore.DocumentData>,
  datesToTransform?: Array<keyof T>,
) => {
  const snapshot = await ref.get()
  const groups: Record<string, T> = {}

  snapshot.forEach((doc) => {
    const item = doc.data() as T

    datesToTransform &&
      datesToTransform.map((key) => {
        if (item[key]) {
          item[key] = (item[key] as any).toDate ? (item[key] as any).toDate() : new Date(item[key] as any)
        }
      })

    groups[doc.id] = item
  })

  return groups
}

export const getDocumentRef = async <T>(
  ref: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>,
  datesToTransform?: Array<keyof T>,
) => {
  const snapshot = await ref.get()
  const item = snapshot.data() as T

  datesToTransform &&
    datesToTransform.map((key) => {
      if (item[key]) {
        item[key] = (item[key] as any).toDate ? (item[key] as any).toDate() : new Date(item[key] as any)
      }
    })

  return item
}

export const listenForNewUpdates = <T>(
  ref: firebase.firestore.Query,
  cb: (change: firebase.firestore.DocumentChange, item: T) => void,
  datesToTransform?: Array<keyof T>,
) => {
  return ref.where('updatedAt', '>', new Date()).onSnapshot((querySnapshot) => {
    querySnapshot.docChanges().forEach((change) => {
      const docData = change.doc.data() as T

      datesToTransform &&
        datesToTransform.map((key) => {
          if (docData[key]) {
            docData[key] = (docData[key] as any).toDate ? (docData[key] as any).toDate() : new Date(docData[key] as any)
          }
        })

      const item = docData as T

      cb(change, item)
    })
  })
}

export const listenDocumentForNewUpdates = <T>(
  ref: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>,
  cb: (item: T) => void,
  datesToTransform?: Array<keyof T>,
) => {
  return ref.onSnapshot((doc) => {
    const docData = doc.data() as T

    datesToTransform &&
      datesToTransform.map((key) => {
        if (docData && docData[key]) {
          docData[key] = (docData[key] as any).toDate ? (docData[key] as any).toDate() : new Date(docData[key] as any)
        }
      })

    cb(docData)
  })
}

export const inflateDates = <T>(data: T, datesToTransform: KeysOfType<T, Date>[]) => {
  datesToTransform &&
    datesToTransform.map((key) => {
      if (data && data[key]) {
        data[key] = (data[key] as any).toDate ? (data[key] as any).toDate() : new Date(data[key] as any)
      }
    })

  return data as T
}

export type ToClientModel<T> = T extends Uint8Array
  ? firebase.firestore.Blob
  : {
      [K in keyof T]: ToClientModel<T[K]>
    }
