import { ExclamationCircleTwoTone } from '@ant-design/icons'
import { AccountIntegrationTypes } from '@mm/backend/integrations/model'
import { Meeting } from '@mm/backend/meetings/model'
import { Group } from '@mm/backend/groups/model'
import { MeetingParticipantRole, roleMap } from '@mm/common'
import { Button, Checkbox, Form, Input, Modal, notification, Popconfirm, Radio, Typography, Spin } from 'antd'
import { addMinutes, differenceInMinutes } from 'date-fns'
import Router from 'next/router'
import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useDispatch, useSelector } from 'react-redux'
import { usePrevious } from 'react-use'
import { selectors as accountSelectors } from '../../../accounts'
import { selectors as companySelectors } from '../../../companies'

import { CompanySelector } from '../../../companies/components/CompanySelector'
import { actions as groupsActions, selectors as groupsSelectors } from '../../../groups'
import { selectors as integrationsSelectors } from '../../../integrations'
import { selectors as meetingsSelectors } from '../../../meetings'
import { actions } from '../../store'

import { MeetingParticipantsInput } from './MeetingParticipantsInput'

interface Props {
  visible: boolean
  onComplete?: () => void
  onCancel?: () => void
  meeting?: Meeting
  updateView?: boolean
  initialValues?: Partial<Fields>
}

interface Participant {
  id: string
  role: MeetingParticipantRole
}

export interface Fields {
  groupId?: Group['id'] | undefined
  title: string
  startAt: Date
  company: string
  duration: number
  recurring: 'weekly' | 'monthly' | 'bi-weekly' | 'daily' | 'none'
  conferenceUrl: string
  participants: Participant[]
  sync: boolean
}

export const CreateEditMeetingModalOld: React.FC<Props> = ({
  visible,
  initialValues,
  onComplete,
  onCancel,
  meeting,
  updateView,
}) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const creating = useSelector(meetingsSelectors.getCreating())
  const wasCreating = usePrevious<boolean>(creating)
  const activeAccount = useSelector(accountSelectors.getActiveAccount())
  const activeCompanyId = useSelector(companySelectors.getActiveCompanyId())
  const [meetingParticipants, setMeetingParticipants] = useState<Participant[]>([])
  const [changingParticipants, setChangingParticipants] = useState(false)
  const accounts = useSelector(accountSelectors.getAccounts())
  const group = useSelector(groupsSelectors.getGroupById(meeting?.groupId || ''))
  const companies = useSelector(companySelectors.getCompanies())
  const companiesSize = Object.keys(companies).length
  const googleCalConnected =
    useSelector(integrationsSelectors.getStatus(AccountIntegrationTypes.GOOGLE_CALENDAR)) === 'CONNECTED'
  const calendarNotAllowed = typeof window !== 'undefined' && location.protocol !== 'https:'
  const defaultParticipants = activeAccount
    ? [
        {
          id: activeAccount.id,
          role: roleMap.owner,
        },
      ]
    : []

  const formInitialValues =
    updateView && meeting
      ? {
          title: meeting.title,
          startAt: new Date(meeting.startAt),
          duration: differenceInMinutes(new Date(meeting.endAt), new Date(meeting.startAt)),
          recurring: meeting.recurring ? meeting.recurring : 'none',
          conferenceUrl: meeting.conferenceUrl ?? '',
          participants: meetingParticipants,
          ...initialValues,
        }
      : {
          startAt: new Date(),
          duration: 50,
          recurring: 'none',
          sync: !calendarNotAllowed,
          company: activeCompanyId,
          participants: defaultParticipants,
          ...initialValues,
        }

  useEffect(() => {
    if (wasCreating && !creating) {
      onComplete?.()
    }
  }, [creating])

  useEffect(() => {
    const updatedMeetingParticipants = meeting?.participantIds
      ?.filter((accountId: string) => Boolean(accounts[accountId]))
      .map((accountId: string) => {
        const account = accounts[accountId]
        if (meeting.participants && meeting.participants[account.id]) {
          return {
            id: account.id,
            role: meeting.participants[account.id].role as MeetingParticipantRole,
          }
        }

        return {
          id: account.id,
          role: roleMap.participant,
        }
      })

    setMeetingParticipants(updatedMeetingParticipants || [])
  }, [meeting])

  const handleOk = async () => {
    try {
      const values = (await form.validateFields()) as Fields
      setChangingParticipants(true)
      const updateParticipants = values.participants.reduce(
        (obj, mp) =>
          Object.assign(obj, {
            [mp.id]: {
              role: mp.role,
            },
          }),
        {},
      )

      updateView && meeting
        ? dispatch(
            actions.updateRequest({
              meeting: {
                ...meeting,
                participants: updateParticipants,
                title: values.title,
                startAt: values.startAt,
                endAt: addMinutes(values.startAt, values.duration),
                recurring: values.recurring === 'none' ? false : values.recurring,
                conferenceUrl: values.conferenceUrl,
              },
            }),
          )
        : dispatch(
            actions.createRequest({
              groupId: initialValues?.groupId || '',
              participants: values.participants,
              companyId: values.company,
              title: values.title,
              startAt: values.startAt,
              endAt: addMinutes(values.startAt, values.duration),
              recurring: values.recurring === 'none' ? false : values.recurring,
              conferenceUrl: values.conferenceUrl,
              syncWithGCal: values.sync,
            }),
          )

      if (updateView) {
        onComplete?.()
      }
    } catch (error) {
      console.log(error, 'Error creating here')
      notification.error({
        message: `There was an error when trying to ${updateView ? 'Update' : 'Create'} the meeting`,
      })
    } finally {
      setChangingParticipants(false)
    }
  }

  const onChange = (participants: Participant[]) => {
    if (group) {
      for (const participant of participants) {
        if (!group.participantIds.includes(participant.id)) {
          dispatch(
            groupsActions.addAccount({
              groupId: group.id,
              accountId: participant.id,
              role: participant.role,
            }),
          )
        }
      }

      for (const participantId of group.participantIds) {
        if (!participants.some((participant) => participantId === participant.id)) {
          dispatch(
            groupsActions.removeAccount({
              groupId: group.id,
              accountId: participantId,
            }),
          )
        }
      }
    }
  }

  const handleCancel = () => {
    form.resetFields()
    onCancel?.()
  }

  const handleDelete = () => {
    if (meeting) {
      dispatch(actions.remove({ meeting }))
      dispatch(actions.delete({ id: meeting.id }))
      Router.push('/dashboard')
    }
  }

  const handleRegenerateUpdates = () => {
    if (meeting) {
      dispatch(actions.regenerateUpdates({ meetingId: meeting.id }))
    }
  }

  return (
    <Modal
      title={updateView ? 'Edit Meeting' : 'Add Meeting'}
      visible={visible}
      okText={updateView ? 'Save' : 'Create'}
      onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={{
        disabled: creating,
        loading: creating,
      }}
      wrapProps={{
        'data-cy': 'create-edit-meeting-modal',
      }}
    >
      {activeAccount && activeCompanyId ? (
        <>
          <Form form={form} name="basic" layout="vertical" initialValues={formInitialValues}>
            <Form.Item
              label="Meeting Title"
              name="title"
              rules={[{ required: true, message: 'Meeting title is required' }]}
            >
              <Input disabled={creating} />
            </Form.Item>

            <Input.Group compact={true}>
              <Form.Item
                label="Start"
                name="startAt"
                rules={[{ required: true, message: 'Start time is required' }]}
                valuePropName="selected"
                style={{ marginRight: 12 }}
              >
                <DatePicker showTimeSelect={true} dateFormat="Pp" onChange={() => null} disabled={creating} />
              </Form.Item>

              <Form.Item
                label="Duration (min)"
                name="duration"
                rules={[{ required: true, message: 'Duration is required' }]}
              >
                <Input type="number" disabled={creating} />
              </Form.Item>
            </Input.Group>

            <Form.Item
              label="Video Conference Link"
              name="conferenceUrl"
              rules={[{ type: 'url', message: 'Must be a valid URL' }]}
            >
              <Input placeholder="https://us02web.zoom.us/..." disabled={creating} />
            </Form.Item>

            <Form.Item label="Recurring" name="recurring">
              <Radio.Group disabled={meeting?.complete || creating}>
                <Radio value="daily">Daily</Radio>
                <Radio value="weekly">Weekly</Radio>
                <Radio value="bi-weekly">Bi-weekly</Radio>
                <Radio value="monthly">Monthly</Radio>
                <Radio value="none">None</Radio>
              </Radio.Group>
            </Form.Item>

            {!updateView && (
              <Form.Item
                label="Company"
                name="company"
                rules={[{ required: true, message: 'Company selection is required' }]}
                style={{
                  display: companiesSize <= 1 ? 'none' : undefined,
                }}
              >
                <CompanySelector
                  onChange={() =>
                    form.setFieldsValue({
                      participants: defaultParticipants,
                    })
                  }
                  width={270}
                  disabled={creating}
                />
              </Form.Item>
            )}

            <Form.Item
              label="Participants"
              name="participants"
              rules={[
                {
                  required: true,
                  validator: (_, participants) => {
                    if (participants.length || meetingParticipants.length) {
                      return Promise.resolve(updateView ? meetingParticipants : participants)
                    }

                    return Promise.reject(new Error('Participants are required'))
                  },
                },
              ]}
            >
              <MeetingParticipantsInput
                onChange={(participants) => onChange(participants)}
                disabled={creating || changingParticipants}
              />
            </Form.Item>

            {!updateView && googleCalConnected ? (
              <Form.Item name="sync" valuePropName="checked">
                <Checkbox disabled={calendarNotAllowed || creating}>
                  Create Google Calendar Event {calendarNotAllowed ? '(only on https)' : ''}
                </Checkbox>
              </Form.Item>
            ) : null}
          </Form>
          {updateView && meeting && (
            <>
              <Popconfirm
                title={
                  <>
                    <Typography.Text type="secondary">
                      Are you sure you want to regenerate updates for all participants?
                    </Typography.Text>
                  </>
                }
                icon={<ExclamationCircleTwoTone />}
                onConfirm={handleRegenerateUpdates}
                okText="Yes, Regenerate"
                cancelText="Cancel"
                okType="danger"
              >
                <Button type="text" danger>
                  Regenerate Update Forms
                </Button>
              </Popconfirm>
              <Popconfirm
                title={
                  <>
                    <Typography.Title level={5}>Are you sure you want to delete this meeting?</Typography.Title>
                    <Typography.Text type="secondary">You will be redirected to the Meetings page.</Typography.Text>
                  </>
                }
                icon={<ExclamationCircleTwoTone />}
                onConfirm={handleDelete}
                okText="Yes, Delete"
                cancelText="Cancel"
                okType="danger"
              >
                <Button type="text" danger>
                  Delete Meeting
                </Button>
              </Popconfirm>
            </>
          )}
        </>
      ) : (
        <Spin />
      )}
    </Modal>
  )
}
