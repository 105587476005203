import { createStore, applyMiddleware, combineReducers, Middleware } from 'redux'

import { middleware as accountsMiddleware, reducer as accountsReducer, selector as accountsSelector } from '../accounts'
import { middleware as actionsMiddleware, reducer as actionsReducer, selector as actionsSelector } from '../actions'
import { middleware as authMiddleware, reducer as authReducer, selector as authSelector } from '../auth'
import { middleware as commentsMiddleware, reducer as commentsReducer, selector as commentsSelector } from '../comments'
import { middleware as datadogMiddleware } from '../datadog'
import { middleware as feedbackMiddleware, reducer as feedbackReducer, selector as feedbackSelector } from '../feedback'
import { middleware as goalsMiddleware, reducer as goalsReducer, selector as goalsSelector } from '../goals'
import {
  middleware as goodThingsMiddleware,
  reducer as goodThingsReducer,
  selector as goodThingsSelector,
} from '../goodThings'
import { middleware as groupsMiddleware, reducer as groupsReducer, selector as groupsSelector } from '../groups'
import { middleware as issuesMiddleware, reducer as issuesReducer, selector as issuesSelector } from '../issues'
import { middleware as meetingsMiddleware, reducer as meetingReducer, selector as meetingSelector } from '../meetings'
import { middleware as mixpanelMiddleware } from '../mixpanel'
import { middleware as sentryMiddleware } from '../sentry'
import { middleware as topicsMiddleware, reducer as topicsReducer, selector as topicsSelector } from '../topics'
import {
  middleware as integrationsMiddleware,
  reducer as integrationsReducer,
  selector as integrationsSelector,
} from '../integrations'
import { middleware as adminMiddleware, reducer as adminReducer, selector as adminSelector } from '../admin'
import {
  middleware as companiesMiddleware,
  reducer as companiesReducer,
  selector as companiesSelector,
} from '../companies'
import { middleware as updatesMiddleware, reducer as updatesReducer, selector as updatesSelector } from '../updates'

// TODO: Hookup redux devtools enhancer https://github.com/zalmoxisus/redux-devtools-extension#usage

/**
 * Imports every feature's middleware and reducers and creates a redux store that can be consumed by the UI and
 * devtools.
 */
export default function useStore(injectedMiddlewares?: Array<Middleware>) {
  let middleware = injectedMiddlewares || [
    accountsMiddleware,
    actionsMiddleware,
    authMiddleware,
    commentsMiddleware,
    datadogMiddleware,
    feedbackMiddleware,
    goalsMiddleware,
    goodThingsMiddleware,
    groupsMiddleware,
    issuesMiddleware,
    meetingsMiddleware,
    topicsMiddleware,
    integrationsMiddleware,
    adminMiddleware,
    companiesMiddleware,
    sentryMiddleware,
    mixpanelMiddleware,
    updatesMiddleware,
  ]

  if (process.env.NODE_ENV !== 'production') {
    const { createLogger } = require('redux-logger') // eslint-disable-line
    const loggerMiddleware = createLogger({
      level: 'info',
      collapsed: true,
    })
    middleware = [...middleware, loggerMiddleware]
  }

  const reducer = combineReducers({
    [accountsSelector]: accountsReducer,
    [actionsSelector]: actionsReducer,
    [authSelector]: authReducer,
    [commentsSelector]: commentsReducer,
    [feedbackSelector]: feedbackReducer,
    [goalsSelector]: goalsReducer,
    [goodThingsSelector]: goodThingsReducer,
    [groupsSelector]: groupsReducer,
    [issuesSelector]: issuesReducer,
    [meetingSelector]: meetingReducer,
    [topicsSelector]: topicsReducer,
    [integrationsSelector]: integrationsReducer,
    [adminSelector]: adminReducer,
    [companiesSelector]: companiesReducer,
    [updatesSelector]: updatesReducer,
  })

  const store = createStore(reducer, {}, applyMiddleware(...middleware))
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  store.dispatch({ type: '@@INIT' } as any)
  return store
}
