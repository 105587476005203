import styled from '@emotion/styled'
import { color, spacing } from '../../interface/theme'

export const DropdownMenu = styled.div<{ visible: boolean }>`
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 2px;

  display: ${(props) => (props.visible ? 'flex' : 'none')};
  flex-direction: column;
  align-items: stretch;

  width: max-content;
  padding: 2px 0;
  background: ${color('backgroundWhite')};

  // copied from antd
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
`

export const DropdownMenuButton = styled.button`
  display: flex;
  align-items: start;

  border: 0;
  background: ${color('backgroundWhite')};
  padding: ${spacing(0.5)}px ${spacing(1)}px;
  cursor: pointer;

  transition: background-color 200ms;

  &:hover {
    background: ${color('grayBackground')};
  }

  &:active {
    background: ${color('blueBackground')};
  }

  &:disabled {
    background: ${color('lightGray')};
  }
`
