import React from 'react'
import _ from 'lodash'
import { Input } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { actions, selectors } from '../store'
import { useEffect } from 'react'
import { selectors as companySelectors } from '../../companies/store'
import { Meeting } from '@mm/backend/meetings'
import { AccountNames } from '../../accounts'
import { addHours } from 'date-fns'
import { useState } from 'react'
import { Div, FlexRow, Table, Text } from '../../interface'
import Fuse from 'fuse.js'
import { LoadingOutlined } from '@ant-design/icons'

interface Props {}

const Meetings: React.FC<Props> = () => {
  const now = addHours(new Date(), -2)

  const dispatch = useDispatch()

  const { meetings, loading } = useSelector(selectors.getMeetingsState())
  const companies = useSelector(companySelectors.getCompanies())
  const [search, setSearch] = useState<string>('')
  useEffect(() => {
    dispatch(actions.fetchMeetings())
  }, [])

  const getSortedItems = (): Array<Meeting> => {
    return _(meetings)
      .filter(({ id, startAt }) => {
        if (id && startAt < now) return true
        else return false
      })
      .orderBy('startAt', 'desc')
      .value()
  }

  const sortedItems = getSortedItems()

  const columnSchema = [
    {
      title: 'Title',
      key: 'title',
      component: 'link',
      linkKey: 'meetingLink',
      filter: false,
      sort: true,
    },
    {
      title: 'Start Time',
      key: 'startTime',
      component: 'date',
      filter: false,
      sort: true,
    },
    {
      title: 'Participants',
      key: 'participants',
      component: 'text',
      filter: false,
      sort: false,
    },
    {
      title: 'Company',
      key: 'company',
      component: 'text',
      filter: false,
      sort: true,
    },
    {
      title: 'Complete',
      key: 'complete',
      component: 'text',
      filter: false,
      sort: true,
    },
  ]

  const getMeetingCompanyName = (companyId: string): string => {
    if (companyId && companies[companyId]) {
      return companies[companyId].name
    }

    return ''
  }

  const tableItems = sortedItems.map(({ id, title, recurring, startAt, participantIds, companyId, complete }) => {
    return {
      key: id,
      meetingLink: `/meetings/${id}`,
      title: title + (recurring ? ` (${recurring})` : ''),
      startTime: startAt,
      participants: (
        <AccountNames
          showRole
          profileLink
          ids={participantIds}
          meeting={meetings.find((meeting) => meeting.id === id)}
          omitActiveAccount={false}
          emptyText=""
        />
      ),
      company: getMeetingCompanyName(companyId),
      complete: complete ? 'Complete' : '',
    }
  })

  const fuse = new Fuse(tableItems, {
    keys: ['title', 'participants', 'company'],
    shouldSort: false,
    ignoreLocation: true,
    threshold: 0.5,
  })

  const results = search ? fuse.search(search) : tableItems

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 32,
        }}
      >
        <LoadingOutlined spin style={{ fontSize: 32 }} />
      </div>
    )
  }

  return (
    <Div>
      <FlexRow justifyContent="flex-end" marginTop={2}>
        <Input style={{ width: 200, marginRight: 24 }} placeholder="Search..." value={search} onChange={onSearch} />
      </FlexRow>
      {results.length ? (
        <Div>
          <Table columnSchema={columnSchema} tableData={results} />
        </Div>
      ) : (
        <FlexRow style={{ height: 160, border: '1px solid #eee', borderRadius: 4 }} justifyContent="center">
          <Text color="light">No meetings.</Text>
        </FlexRow>
      )}
    </Div>
  )
}

export default Meetings
