import React from 'react'
import styled from '@emotion/styled'
import { SpacingInterval, spacing } from './theme'
import { Div, Props as DivProps } from './Div'

interface Props extends DivProps {
  direction?: React.CSSProperties['flexDirection']
  alignItems?: React.CSSProperties['alignItems']
  justifyContent?: React.CSSProperties['justifyContent']
  onClick?: () => void
  onMouseDown?: (event: React.MouseEvent<HTMLDivElement>) => void
  gap?: SpacingInterval
  style?: React.CSSProperties
}

export const Flex = styled(Div)<Props>(({ direction = 'row', alignItems = 'center', justifyContent, gap }) => ({
  display: 'flex',
  flexDirection: direction,
  alignItems,
  justifyContent,

  '& > *:not(:last-child)': {
    marginRight: direction === 'row' ? spacing(gap) : undefined,
    marginBottom: direction === 'column' ? spacing(gap) : undefined,
  },
}))

export const FlexRow: React.FC<Props> = (props) => <Flex direction="row" {...props} />

export const FlexColumn: React.FC<Props> = (props) => <Flex direction="column" {...props} />
