import React from 'react'
import { Modal, Form, Input, Select, Button, Divider, Radio, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { actions, selectors } from '../../integrations/store'
import { CompanyIntegrationTypes } from '@mm/backend/integrations/model'
import {
  ReqBody as MondayGetBoardsReqBody,
  ResBody as MondayGetBoardsResBody,
} from '../../../pages/api/integrations/monday/getBoards'
import {
  ReqBody as MondayGetGroupsReqBody,
  ResBody as MondayGetGroupsResBody,
} from '../../../pages/api/integrations/monday/getGroups'
import { Board, Group } from '@mm/backend/integrations/monday'
import axios from 'axios'

interface Props {
  visible: boolean
  onOk: () => void
  onCancel: () => void
}

const Label: React.FC = ({ children }) => (
  <Typography.Title level={5} style={{ marginBottom: 0 }}>
    {children}
  </Typography.Title>
)

export const MondayModal: React.FC<Props> = ({ visible, onOk, onCancel }) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [error, setError] = React.useState('')

  // API KEY
  const [apiKey, setApiKey] = React.useState('')
  const [loadApiCheck, setLoadApiCheck] = React.useState(false)
  const [isApiCorrect, setIsApiCorrect] = React.useState(false)
  const onApiCheck = async () => {
    setLoadApiCheck(true)
    setError('')
    if (!apiKey) {
      setLoadApiCheck(false)
      return
    }
    try {
      const res = await axios.post<MondayGetBoardsResBody, MondayGetBoardsReqBody>(
        `api/integrations/monday/getBoards`,
        {
          apiKey,
        },
      )
      const { errorMessage, boards } = res.data

      if (errorMessage) {
        setError(errorMessage)
        setBoards([])
        setBoardId('')
        setGroups([])
        setGroupId('')
      } else if (boards && boards.length) {
        setIsApiCorrect(true)
        setBoards(boards)
        onSelectBoard(boards[0].id)
      }
      return setLoadApiCheck(false)
    } catch (error) {
      if (!(error instanceof Error)) return
      setError(error.message)
    }
  }

  // BOARDS
  const [boardCreateType, setBoardCreateType] = React.useState<'old' | 'new'>('new')
  const [boardName, setBoardName] = React.useState('Mochary Method')
  const [boards, setBoards] = React.useState<Board[]>([])
  const [boardId, setBoardId] = React.useState('')
  const boardOptions: Array<JSX.Element> = boards.map((board) => (
    <Select.Option key={board.id} value={board.id}>
      {board.name}
    </Select.Option>
  ))
  const onSelectBoard = async (id: string) => {
    setBoardId(id)
    try {
      if (!id) return
      const res = await axios.post<MondayGetGroupsResBody, MondayGetGroupsReqBody>(
        `api/integrations/monday/getGroups`,
        {
          apiKey,
          boardId: id,
        },
      )
      const { errorMessage, groups } = res.data
      if (errorMessage) {
        return setError(errorMessage)
      } else if (groups && groups.length) {
        setGroups(groups)
        setGroupId(groups[0].id)
        onSelectGroup(groups[0].id)
      }
    } catch (error) {
      if (!(error instanceof Error)) return
      setError(error.message)
    }
  }

  // GROUPS
  const [groupCreateType, setGroupCreateType] = React.useState<'old' | 'new'>('new')
  const [groupName, setGroupName] = React.useState('Team Actions')
  const [groups, setGroups] = React.useState<Group[]>([])
  const [groupId, setGroupId] = React.useState('')
  const groupOptions: Array<JSX.Element> = groups.map((group) => (
    <Select.Option key={group.id} value={group.id}>
      {group.title}
    </Select.Option>
  ))
  const onSelectGroup = async (id: string) => {
    setGroupId(id)
    try {
      if (!id) return
    } catch (error) {
      if (!(error instanceof Error)) return
      setError(error.message)
    }
  }

  // SUBMIT FORM
  const [submitted, setSubmitted] = React.useState(false)
  const submitError = useSelector(selectors.getErrorMessage(CompanyIntegrationTypes.MONDAY))
  const isLoading = useSelector(selectors.isLoading(CompanyIntegrationTypes.MONDAY))
  const onSubmit = async () => {
    if (!isApiCorrect) return setError('Check the API before submitting.')
    else if (!apiKey) return setError('API Key is required.')
    else if (boardCreateType === 'new' && !boardName) return setError('Board name is required.')
    else if (boardCreateType === 'old' && !boardId) return setError('Select a board first.')
    else if (groupCreateType === 'new' && !groupName) return setError('Group name is required.')
    else if (groupCreateType === 'old' && !groupId) return setError('Select a group first.')
    else {
      setError('')
      dispatch(
        actions.connectMonday({
          apiKey,
          boardId,
          boardName,
          boardCreateType,
          groupId,
          groupName,
          groupCreateType,
        }),
      )
      return setSubmitted(true)
    }
  }

  // CLOSE MODAL
  React.useEffect(() => {
    if (submitted && !isLoading && !submitError && !error) {
      setError('')
      setIsApiCorrect(false)
      setLoadApiCheck(false)
      onOk()
    }
  }, [submitted, isLoading, submitError, error])

  return (
    <Modal
      title="Connect Monday.com"
      okText="Connect"
      visible={visible}
      onOk={onSubmit}
      onCancel={onCancel}
      confirmLoading={isLoading}
    >
      <p>
        The connection through Monday is only available to <strong>admin</strong> users. Once connected, the integration
        will work for the entire company.
      </p>
      <Divider />
      <Form form={form} name="basic" layout="vertical">
        <Form.Item
          label={<Label>Monday.com API</Label>}
          name="apiKey"
          rules={[{ required: true, message: 'API key is required' }]}
        >
          <ol style={{ listStyleType: 'decimal', marginLeft: 16 }}>
            <li>
              On your{' '}
              <a href="https://monday.com" target="_blank" rel="noopener noreferrer">
                Monday.com
              </a>{' '}
              account, go to: Avatar → Admin → API
            </li>
            <li>Click &quot;Generate API V2 Token&quot;. Copy and paste it below.</li>
            <li>Click the &quot;Check API&quot; button. </li>
          </ol>
          <Input value={apiKey} onChange={(event) => setApiKey(event.target.value)} placeholder="Monday.com API key" />
        </Form.Item>
        {isApiCorrect ? (
          <Typography.Text type="success">The API is correct!</Typography.Text>
        ) : (
          <Button onClick={onApiCheck} style={{ marginTop: 8 }} type="primary" loading={loadApiCheck}>
            Check API
          </Button>
        )}

        <Divider />
        <Form.Item
          label={<Label>Select Board</Label>}
          name="boardId"
          rules={[{ required: true, message: 'Board is required' }]}
        >
          <p>Select an existing board or create a new one. Meeting actions will be sent to this board.</p>
          <Radio.Group
            value={boardCreateType}
            onChange={(e) => {
              if (e.target.value === 'new') setGroupCreateType('new')
              return setBoardCreateType(e.target.value)
            }}
            style={{ marginBottom: 8 }}
          >
            <Radio value="new" defaultChecked>
              Create new board
            </Radio>
            <Radio value="old">Select existing board</Radio>
          </Radio.Group>
          {boardCreateType === 'new' ? (
            <Input value={boardName} onChange={(e) => setBoardName(e.target.value)} placeholder="Board Name" />
          ) : (
            <Select
              value={boardId}
              style={{ width: 200 }}
              onChange={onSelectBoard}
              showSearch
              optionFilterProp="children"
            >
              {boardOptions}
            </Select>
          )}
        </Form.Item>
        <Divider />
        <Form.Item
          label={<Label>Select Group</Label>}
          name="groupId"
          rules={[{ required: true, message: 'Group is required' }]}
        >
          <p>Select an existing group or create a new one. </p>
          <Radio.Group
            value={groupCreateType}
            onChange={(e) => setGroupCreateType(e.target.value)}
            style={{ marginBottom: 8 }}
          >
            <Radio value="new" defaultChecked>
              Create new group
            </Radio>
            <Radio value="old" disabled={boardCreateType === 'new'}>
              Select existing group
            </Radio>
          </Radio.Group>
          {groupCreateType === 'new' ? (
            <Input value={groupName} onChange={(e) => setGroupName(e.target.value)} placeholder="Group Name" />
          ) : (
            <Select
              value={groupId}
              style={{ width: 200 }}
              onChange={onSelectGroup}
              showSearch
              optionFilterProp="children"
            >
              {groupOptions}
            </Select>
          )}
        </Form.Item>
      </Form>
      {error && <Typography.Text type="danger">{error}</Typography.Text>}
      {submitError && <Typography.Text type="danger">{submitError}</Typography.Text>}
    </Modal>
  )
}

export default MondayModal
