import React from 'react'
import { useDispatch } from 'react-redux'
import { actions as authActions } from '../../auth'
import { Dropdown, Menu } from 'antd'
import Link from 'next/link'
import { useFeatureFlags } from '../../featureFlags'
import { Div } from '../../interface'
import styled from '@emotion/styled'
import { css, useTheme } from '@emotion/react'
import { Account } from '@mm/backend/accounts/model'

interface Props {
  activeAccount: Account
}

export const PageHeaderProfileDropdown: React.FC<Props> = ({ activeAccount }) => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const Text = styled.div`
    color: ${theme.font.color.light};
    :hover {
      color: ${theme.font.color.blue};
    }
  `

  const menu = (
    <Menu>
      <Menu.Item>
        <Link href="/profile/_/info">Profile</Link>
      </Menu.Item>
      <Menu.Item>
        <Link href="/integrations">Integrations</Link>
      </Menu.Item>
      <Menu.Item>
        <Link href="/settings/company">Company Settings</Link>
      </Menu.Item>
      <Menu.Item onClick={() => dispatch(authActions.logOut())} id="logout">
        Log out
      </Menu.Item>
    </Menu>
  )

  return activeAccount ? (
    <Dropdown overlay={menu}>
      <Div
        padding={1}
        marginRight={-1}
        css={css`
          cursor: pointer;
          color: #333;

          &:hover {
            background: rgba(0, 0, 0, 0.02);
          }
        `}
      >
        <Text>{activeAccount.name}</Text>
      </Div>
    </Dropdown>
  ) : null
}

export default PageHeaderProfileDropdown
