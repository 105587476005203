import React from 'react'
import { Avatar as AntAvatar } from 'antd'
import { Account } from '@mm/backend/accounts/model'

interface Props {
  account: Account
  index?: number
  size?: number | 'small' | 'large'
  style?: React.CSSProperties
}

const stringToIntHash = (str = '', upperbound = 100, lowerbound = 0) => {
  let result = 0
  for (let i = 0; i < str.length; i++) {
    result = result + str.charCodeAt(i)
  }

  return (result % (upperbound - lowerbound)) + lowerbound
}

const randomColor = (id: string) => Math.floor((stringToIntHash(id) / 100) * 16777215).toString(16)

export const Avatar: React.FC<Props> = ({ account, index, size, style }) => {
  const name = account.name

  return (
    <AntAvatar
      size={size}
      // src={face.avatar}
      style={{
        boxShadow: '0 0 0 2px #fff',
        zIndex: index,
        backgroundColor: `#${randomColor(name + account.id)}`,
        ...style,
      }}
    >
      {name ? name[0].toUpperCase() : ''}
    </AntAvatar>
  )
}

export default Avatar
