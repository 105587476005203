import { ApolloClient, ApolloLink, ApolloProvider, concat, HttpLink, InMemoryCache, split } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { ErrorLink } from '@apollo/client/link/error'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import fetch from 'cross-fetch'
import _ from 'lodash'
import React, { useMemo } from 'react'
import introspectionResult from '../../gen/graphql/introspection-result.json'
import { auth } from '../firebase'
import { typePolicies } from './typePolicies'

export type ApolloProviderProps = {
  children?: React.ReactNode
}

export function ConfiguredApolloProvider({ children }: ApolloProviderProps): React.ReactElement {
  const apollo = useMemo(() => {
    const url = new URL(process.env.NEXT_PUBLIC_GRAPHQL_URL || 'http://localhost:3001/graphql')
    const wsUrl = new URL(url.href)
    wsUrl.protocol = url.protocol === 'http:' ? 'ws:' : 'wss:'

    const httpLink = concat(
      setContext(async (_operation, { headers, ...rest }) => ({
        ...rest,
        headers: _.pickBy({
          ...headers,
          authorization: await auth.currentUser?.getIdToken(),
        }),
      })),
      new HttpLink({
        uri: url.href,
        fetch,
      }),
    )
    const wsLink = process.browser
      ? new WebSocketLink({
          uri: wsUrl.href,
          options: {
            lazy: true,
            reconnect: true,
            connectionParams: async () => ({
              authToken: await auth.currentUser?.getIdToken(),
            }),
          },
        })
      : ApolloLink.empty()
    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query)
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
      },
      wsLink,
      httpLink,
    )
    const errorLink = new ErrorLink(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
        )
      }
      if (networkError) {
        console.error(`[Network error]: ${networkError}`)
      }
    })

    return new ApolloClient({
      link: errorLink.concat(splitLink),
      cache: new InMemoryCache({
        possibleTypes: introspectionResult.possibleTypes,
        typePolicies,
      }),
    })
  }, [])

  return <ApolloProvider client={apollo}>{children}</ApolloProvider>
}
