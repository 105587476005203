import { createContext, useContext } from 'react'

interface BlockIndentContext {
  popoverVisible: boolean
  setPopoverVisible: (param: boolean) => void
}

export const BlockIndentContext = createContext<BlockIndentContext | null>(null)

export function useBlockIndentContext(): BlockIndentContext {
  const context = useContext(BlockIndentContext)
  if (context == null) {
    throw new Error('Components which use useBlockIndentContext must be nested inside BlockIndentContextProvider')
  }
  return context
}
