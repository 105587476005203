import { Select } from 'antd'
import React from 'react'
import { Frequency, RRule, rrulestr } from 'rrule'

export type RecurrenceInputProps = {
  value?: string
  onChange?: (value: string | undefined) => void
  disabled?: boolean
}

const options = [
  {
    rrule: '',
    label: 'Does not repeat',
  },
  {
    rrule: new RRule({
      freq: Frequency.DAILY,
    }).toString(),
    label: 'Daily',
  },
  {
    rrule: new RRule({
      freq: Frequency.WEEKLY,
    }).toString(),
    label: 'Weekly',
  },
  {
    rrule: new RRule({
      freq: Frequency.WEEKLY,
      interval: 2,
    }).toString(),
    label: 'Bi-weekly',
  },
  {
    rrule: new RRule({
      freq: Frequency.MONTHLY,
    }).toString(),
    label: 'Monthly',
  },
] as const

export function RecurrenceInput({ value = '', onChange }: RecurrenceInputProps) {
  const customOptions = []
  if (value && options.every((opt) => opt.rrule !== value)) {
    try {
      customOptions.push({
        rrule: value,
        label: 'Custom: ' + rrulestr(value).toText(),
      })
    } catch (e) {
      console.warn('Unsupported recurrence format', e)
      customOptions.push({
        rrule: value,
        label: 'Custom: Unsupported recurrence',
      })
    }
  }

  return (
    <Select onChange={onChange} value={value}>
      {[...options, ...customOptions].map(({ rrule, label }) => (
        <Select.Option value={rrule} key={rrule}>
          {label}
        </Select.Option>
      ))}
    </Select>
  )
}
