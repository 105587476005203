import React from 'react'
import { Modal, Form, Input, Select, Button, Divider, Radio, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { actions, selectors } from '../../integrations/store'
import { selectors as AccountSeletors } from '../../accounts/store'
import { AccountIntegrationTypes, IntegrationStatus } from '@mm/backend/integrations/model'
import {
  ReqBody as AsanaGetWorkspacesReqBody,
  ResBody as AsanaGetWorkspacesResBody,
} from '../../../pages/api/integrations/asana/getWorkspaces'
import {
  ReqBody as AsanaGetProjectsReqBody,
  ResBody as AsanaGetProjectsResBody,
} from '../../../pages/api/integrations/asana/getProjects'
import { Workspace } from '@mm/backend/integrations/asana/methods/getWorkspaces'
import { Project } from '@mm/backend/integrations/asana/methods/getProjects'
import axios from 'axios'

interface Props {
  visible: boolean
  onOk: () => void
  onCancel: () => void
}

const Label: React.FC = ({ children }) => (
  <Typography.Title level={5} style={{ marginBottom: 0 }}>
    {children}
  </Typography.Title>
)

export const AsanaModal: React.FC<Props> = ({ visible, onOk, onCancel }) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [error, setError] = React.useState('')

  const user = useSelector(AccountSeletors.getActiveAccount())
  const asanaStatus = useSelector(selectors.getStatus(AccountIntegrationTypes.ASANA))
  const isAsanaLoading = useSelector(selectors.isLoading(AccountIntegrationTypes.ASANA))
  const isAsanaConnected =
    asanaStatus === IntegrationStatus.CONNECTED || asanaStatus === IntegrationStatus.SETUP_REQUIRED
  const asanaError = useSelector(selectors.getErrorMessage(AccountIntegrationTypes.ASANA)) // Error from redux

  // Get workspaces on init
  React.useEffect(() => {
    if (user && isAsanaConnected) getWorkspaces()
  }, [user, isAsanaConnected])

  // WORKSPACE
  const [workspaces, setWorkspaces] = React.useState<Workspace[]>([])
  const [workspaceId, setWorkspaceId] = React.useState('')
  const workspaceOptions: Array<JSX.Element> = workspaces.map((workspace) => (
    <Select.Option key={workspace.gid} value={workspace.gid}>
      {workspace.name}
    </Select.Option>
  ))
  const getWorkspaces = async () => {
    try {
      const res = await axios.post<AsanaGetWorkspacesResBody, AsanaGetWorkspacesReqBody>(
        `api/integrations/asana/getWorkspaces`,
        {
          userId: user.id,
        },
      )
      const { errorMessage, workspaces } = res.data

      if (errorMessage) {
        setError(errorMessage)
        setWorkspaces([])
        setWorkspaceId('')
        setProjects([])
        setProjectId('')
      } else if (workspaces && workspaces.length) {
        setWorkspaces(workspaces)
        onSelectWorkspace(workspaces[0].gid)
      }
    } catch (error) {
      if (!(error instanceof Error)) return
      setError(error.message)
    }
  }
  const onSelectWorkspace = async (id: string) => {
    if (!id || !isAsanaConnected) return
    setWorkspaceId(id)
    try {
      const res = await axios.post<AsanaGetProjectsResBody, AsanaGetProjectsReqBody>(
        `api/integrations/asana/getProjects`,
        {
          userId: user.id,
          workspaceId: id,
        },
      )
      const { errorMessage, projects } = res.data

      if (errorMessage) return setError(errorMessage)
      else if (projects && projects.length) {
        setProjects(projects)
        setProjectId(projects[0].gid)
        onSelectProject(projects[0].gid)
      }
    } catch (error) {
      if (!(error instanceof Error)) return
      setError(error.message)
    }
  }

  // PROJECT
  const [projectCreateType, setProjectCreateType] = React.useState<'old' | 'new'>('new')
  const [projectName, setProjectName] = React.useState('Mochary Method Actions')
  const [projects, setProjects] = React.useState<Project[]>([])
  const [projectId, setProjectId] = React.useState('')
  const projectOptions: Array<JSX.Element> = projects.map((project) => (
    <Select.Option key={project.gid} value={project.gid}>
      {project.name}
    </Select.Option>
  ))
  const onSelectProject = async (id: string) => {
    if (!id) return
    setProjectId(id)
  }

  // SUBMIT FORM
  const [submitted, setSubmitted] = React.useState(false)
  const onSubmit = async () => {
    if (user && asanaStatus !== IntegrationStatus.SETUP_REQUIRED) return setError('Connect to Asana before submitting.')
    else if (!workspaceId) return setError('Select a workspace first.')
    else if (projectCreateType === 'new' && !projectName) return setError('Project name is required.')
    else if (projectCreateType === 'old' && !projectId) return setError('Select a project first.')
    else {
      setError('')
      dispatch(actions.setupAsana({ userId: user.id, workspaceId, projectCreateType, projectId, projectName }))
      return setSubmitted(true)
    }
  }

  // CLOSE MODAL
  React.useEffect(() => {
    if (submitted && !isAsanaLoading && !asanaError && !error) {
      setError('')
      onOk()
    }
  }, [submitted, isAsanaLoading, asanaError, error])

  return (
    <Modal
      title="Connect Asana"
      okText="Connect"
      visible={visible}
      onOk={onSubmit}
      onCancel={onCancel}
      confirmLoading={isAsanaLoading}
    >
      <Form form={form} name="basic" layout="vertical">
        {/* Authenticate Asana */}
        <Label>Authenticate Asana</Label>
        <p>Give access to Mochary Method to create new Tasks into your Asana account. </p>

        <Form.Item>
          <Button
            type={isAsanaConnected ? 'dashed' : 'primary'}
            loading={isAsanaLoading}
            onClick={() => {
              if (isAsanaConnected) {
                return dispatch(actions.disconnect({ name: AccountIntegrationTypes.ASANA }))
              } else {
                return dispatch(actions.connectAsana())
              }
            }}
          >
            {isAsanaConnected ? 'Disconnect' : 'Connect Asana'}
          </Button>
        </Form.Item>

        <Divider />
        {/* Select Workspace */}
        <Label>Select Workspace</Label>
        <p>Choose the Asana workspace.</p>
        <Form.Item>
          <Select
            value={workspaceId}
            style={{ width: 200 }}
            onChange={onSelectWorkspace}
            showSearch
            optionFilterProp="children"
          >
            {workspaceOptions}
          </Select>
        </Form.Item>
        <Divider />
        {/* Select Project */}
        <Label>Select Project</Label>
        <p>Select an existing project or create a new one. Meeting actions will be sent to this project. </p>
        <Form.Item>
          <Radio.Group
            value={projectCreateType}
            onChange={(e) => setProjectCreateType(e.target.value)}
            style={{ marginBottom: 8 }}
          >
            <Radio value="new" defaultChecked>
              Create new project
            </Radio>
            <Radio value="old">Select existing project</Radio>
          </Radio.Group>
          {projectCreateType === 'new' ? (
            <Input
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              placeholder="Mochary Method Actions"
            />
          ) : (
            <Select
              value={projectId}
              style={{ width: 200 }}
              onChange={onSelectProject}
              showSearch
              optionFilterProp="children"
            >
              {projectOptions}
            </Select>
          )}
        </Form.Item>
        {error && <Typography.Text type="danger">{error}</Typography.Text>}
        {asanaError && <Typography.Text type="danger">{asanaError}</Typography.Text>}
      </Form>
    </Modal>
  )
}

export default AsanaModal
