import { Account } from '@mm/backend/accounts/model'
import { Topic } from '@mm/backend/topics/model'
import { Update } from '@mm/backend/update/model'
import { Reducer } from 'redux'
import { actions as authActions, actionTypes as authActionTypes } from '../auth'
import {
  clear,
  optimisticChange,
  OptimisticChange,
  optimisticCommit,
  OptimisticCommit,
  optimisticRollback,
  OptimisticRollback,
  remove,
  removeById,
  set,
  State,
  upsert,
} from '../optimistic-redux'

export const selector = 'topics'

export const actionTypes = {
  CREATE_REQUEST: 'topics/CREATE_REQUEST',
  CREATE_SUCCESS: 'topics/CREATE_SUCCESS',
  CREATE_FAILURE: 'topics/CREATE_FAILURE',
  EDIT: 'topics/EDIT',
  DELETE: 'topics/DELETE',
  CLEAR: 'topics/CLEAR',
  SET: 'topics/SET',
  ADD: 'topics/ADD',
  CHANGE: 'topics/CHANGE',
  REMOVE: 'topics/REMOVE',
  LIKE: 'topics/LIKE',
  UNLIKE: 'topics/UNLIKE',
  VIEW: 'topics/VIEW',

  OPTIMISTIC_CHANGE: 'topics/OPTIMISTIC_CHANGE',
  OPTIMISTIC_ROLLBACK: 'topics/OPTIMISTIC_ROLLBACK',
  OPTIMISTIC_COMMIT: 'topics/OPTIMISTIC_COMMIT',
} as const

type CreateRequestAction = Pick<Topic, 'description' | 'meetingId' | 'attachments'> & {
  updateId?: Update['id']
}

interface CreateSuccessAction {
  topic: Topic
}

interface CreateFailureAction {
  error: Error
}

type EditAction = Partial<
  Pick<Topic, 'accountId' | 'description' | 'discussed' | 'attachments' | 'likeAccountIds' | 'recurring' | 'updateId'>
> &
  Pick<Topic, 'id'> & {
    optimistic?: boolean
  }

type DeleteAction = Pick<Topic, 'id' | 'updateId'>

interface SetAction {
  topics: Record<string, Topic>
}

interface AddAction {
  topic: Topic
}

interface ChangeAction {
  topic: Topic
}

interface RemoveAction {
  topic: Topic
}

interface LikeAction {
  topic: Topic
  accountId: Account['id']
}

interface UnlikeAction {
  topic: Topic
  accountId: Account['id']
}

interface ViewAction {
  topicsIds: Topic['id'][]
}

export const actions = {
  createRequest: ({ description, meetingId, attachments, updateId }: CreateRequestAction) => ({
    type: actionTypes.CREATE_REQUEST,
    description,
    meetingId,
    attachments,
    updateId,
  }),
  createSuccess: ({ topic }: CreateSuccessAction) => ({
    type: actionTypes.CREATE_SUCCESS,
    topic,
  }),
  createFailure: ({ error }: CreateFailureAction) => ({
    type: actionTypes.CREATE_FAILURE,
    error,
  }),
  edit: ({
    id,
    accountId,
    description,
    discussed,
    attachments,
    likeAccountIds,
    optimistic,
    recurring,
    updateId,
  }: EditAction) => ({
    type: actionTypes.EDIT,
    id,
    accountId,
    description,
    discussed,
    attachments,
    likeAccountIds,
    optimistic,
    recurring,
    updateId,
  }),
  delete: ({ id, updateId }: DeleteAction) => ({
    type: actionTypes.DELETE,
    id,
    updateId,
  }),
  clear: () => ({
    type: actionTypes.CLEAR,
  }),
  set: ({ topics }: SetAction) => ({
    type: actionTypes.SET,
    topics,
  }),
  add: ({ topic }: AddAction) => ({
    type: actionTypes.ADD,
    topic,
  }),
  change: ({ topic }: ChangeAction) => ({
    type: actionTypes.CHANGE,
    topic,
  }),
  remove: ({ topic }: RemoveAction) => ({
    type: actionTypes.REMOVE,
    topic,
  }),
  like: ({ topic, accountId }: LikeAction) => ({
    type: actionTypes.LIKE,
    topic,
    accountId,
  }),
  unlike: ({ topic, accountId }: UnlikeAction) => ({
    type: actionTypes.UNLIKE,
    topic,
    accountId,
  }),
  view: ({ topicsIds }: ViewAction) => ({
    type: actionTypes.VIEW,
    topicsIds,
  }),
  optimisticChange: (action: OptimisticChange<Topic>) => ({
    ...action,
    type: actionTypes.OPTIMISTIC_CHANGE,
  }),
  optimisticRollback: (action: OptimisticRollback) => ({
    ...action,
    type: actionTypes.OPTIMISTIC_ROLLBACK,
  }),
  optimisticCommit: (action: OptimisticCommit<Topic>) => ({
    ...action,
    type: actionTypes.OPTIMISTIC_COMMIT,
  }),
}

export type actions = ReturnType<typeof actions[keyof typeof actions]>

interface TopicsState extends State<Topic> {
  form: {
    loading: boolean
    error?: Error
  }
}

const initialState: TopicsState = {
  byId: {},
  optimisticStorage: {},
  form: {
    loading: false,
  },
}

export const reducer: Reducer<TopicsState, actions | authActions> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET: {
      return set(state, action.topics)
    }
    case actionTypes.ADD:
    case actionTypes.CHANGE: {
      return upsert(state, action.topic)
    }
    case actionTypes.REMOVE: {
      return remove(state, action.topic)
    }
    case actionTypes.DELETE: {
      return removeById(state, action.id)
    }
    case actionTypes.CLEAR: {
      return clear(state)
    }
    case actionTypes.CREATE_REQUEST: {
      return {
        ...state,
        form: {
          loading: true,
        },
      }
    }
    case actionTypes.CREATE_SUCCESS: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.topic.id]: action.topic,
        },
        form: {
          loading: false,
        },
      }
    }
    case actionTypes.CREATE_FAILURE: {
      return {
        ...state,
        form: {
          loading: false,
          error: action.error,
        },
      }
    }
    case actionTypes.OPTIMISTIC_CHANGE: {
      return optimisticChange(state, action)
    }
    case actionTypes.OPTIMISTIC_ROLLBACK: {
      return optimisticRollback(state, action)
    }
    case actionTypes.OPTIMISTIC_COMMIT: {
      return optimisticCommit(state, action)
    }
    case authActionTypes.LOG_OUT:
      return initialState
    default:
      return state
  }
}

export const selectors = {
  get: () => (state: Record<typeof selector, TopicsState>) => state[selector].byId,
  getForm: () => (state: Record<typeof selector, TopicsState>) => state[selector].form,
  getById: (id: string) => (state: Record<typeof selector, TopicsState>) => state[selector].byId[id],
}
