import { Account } from '../accounts'
import { KeysOfType } from '../helpers'
import { Meeting } from '../meetings'
import { Update } from '../update'
import Likeable from '../interfaces/Likeable'
import { HasAttachments } from '../shared'

export interface Topic extends HasAttachments, Likeable {
  id: string
  description: string
  discussed: boolean
  recurring: boolean
  meetingId: Meeting['id']
  meetingIds?: Array<Meeting['id']>
  accountId: Account['id']
  createdAt: Date
  updatedAt: Date
  updateId: Update['id']
}

export const dateKeys: KeysOfType<Topic, Date>[] = ['createdAt', 'updatedAt']
