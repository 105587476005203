import React, { useMemo } from 'react'
import { Popover } from 'antd'
import { BlockIndentContext } from '../context/BlockIndentContext'
import DotsVerticalIcon from '@icons/material/DotsVerticalIcon'
import SwapVerticalIcon from '@icons/material/SwapVerticalIcon'
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import { FlexRow } from '../../interface'
import { LoadingOutlined } from '@ant-design/icons'

interface Props {
  menu?: React.ReactElement
  menuLoading?: boolean
  controls?: React.ReactElement
  alignMenu?: string

  dragHandleProps?: DraggableProvidedDragHandleProps
}

export const BlockIndent: React.FC<Props> = ({ children, controls, menu, menuLoading, alignMenu, dragHandleProps }) => {
  const [popoverVisible, setPopoverVisible] = React.useState(false)

  const memoizedContextVal = useMemo(
    () => ({
      popoverVisible,
      setPopoverVisible,
    }),
    [popoverVisible, setPopoverVisible],
  )

  return (
    <BlockIndentContext.Provider value={memoizedContextVal}>
      <div style={{ display: 'flex' }}>
        <div style={{ width: menu && dragHandleProps ? 50 : 30, display: 'flex', alignItems: alignMenu }}>
          <FlexRow>
            {dragHandleProps ? (
              <div {...dragHandleProps}>
                {menu ? (
                  <SwapVerticalIcon
                    style={{ marginTop: 5, marginBottom: 5, color: '#999', display: 'block', cursor: 'grab' }}
                  />
                ) : null}
              </div>
            ) : null}

            {menuLoading && <LoadingOutlined style={{ fontSize: 24, marginRight: 5 }} spin />}

            {menu && !menuLoading && (
              <Popover
                placement="rightTop"
                content={<div>{React.cloneElement(menu, { setPopoverVisible })}</div>}
                trigger="click"
                onVisibleChange={setPopoverVisible}
                visible={popoverVisible && !menuLoading}
                overlayClassName="block-indent"
              >
                <DotsVerticalIcon
                  data-cy="block-menu"
                  style={{ marginTop: 5, marginBottom: 5, color: '#999', display: 'block', cursor: 'pointer' }}
                />
              </Popover>
            )}
          </FlexRow>
        </div>
        {controls && <div style={{ width: 30, margin: '5px 0px' }}>{controls}</div>}
        <div style={{ flex: 1, minWidth: 0 }}>{children}</div>
        <div style={{ width: 30 }} />
      </div>
    </BlockIndentContext.Provider>
  )
}

export default BlockIndent
