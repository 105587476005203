import { FlexRow } from '../../ui'
import { Button, Popconfirm, Table } from 'antd'
import { Account } from '@mm/backend/accounts'
import Link from 'next/link'
import PencilIcon from '@icons/material/PencilIcon'
import DeleteIcon from '@icons/material/DeleteIcon'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectors } from '../../companies'
import { actions as accountsActions, CreateEditAccountModal, selectors as accountsSelectors } from '../../accounts'
import _ from 'lodash'
import { ExclamationCircleTwoTone } from '@ant-design/icons'

export const CompanySettingsMembersTab = () => {
  const dispatch = useDispatch()
  const activeCompanyId = useSelector(selectors.getActiveCompanyId())
  const companies = useSelector(selectors.getCompanies())
  const accounts = useSelector(accountsSelectors.getAccounts())
  const activeCompany = companies[activeCompanyId] || {}
  const [modalVisibility, setModalVisibility] = React.useState(false)
  const [modalAccount, setModalAccount] = React.useState<Account | undefined>()
  const orderedAccounts = _(activeCompany.members)
    .map((id) => accounts[id])
    .filter((account) => !!account)
    .orderBy('name')
    .value()

  const handleCreateAccount = () => {
    setModalAccount(undefined)
    setModalVisibility(true)
  }

  const handleEditAccount = (id: string) => () => {
    setModalAccount(accounts[id])
    setModalVisibility(true)
  }

  const handleCancel = () => {
    setModalVisibility(false)
    setModalAccount(undefined)
  }

  const handleDelete = (id: string) => () => {
    dispatch(
      accountsActions.removeCompanyRequest({
        id,
        companyId: activeCompanyId,
      }),
    )
  }

  return (
    <div>
      {modalVisibility ? (
        <CreateEditAccountModal
          companyId={activeCompany.id}
          visible={modalVisibility}
          account={modalAccount}
          onCancel={handleCancel}
          onComplete={handleCancel}
        />
      ) : null}

      <FlexRow style={{ justifyContent: 'space-between' }}>
        <h2 style={{ marginBottom: 0 }}>{activeCompany.members?.length || 0} Members</h2>
        <Button type="primary" style={{ marginRight: 0 }} onClick={handleCreateAccount}>
          Add Member
        </Button>
      </FlexRow>

      <Table
        dataSource={orderedAccounts}
        rowKey="id"
        pagination={false}
        showHeader={false}
        style={{ marginTop: 18, borderTop: '1px solid #eee' }}
      >
        <Table.Column
          dataIndex="name"
          key="name"
          render={(name, account: Account) => (
            <Link href={`/profile/${account?.id}`}>
              <a data-cy="user-name-link" style={{ color: '#333' }}>
                {name}
              </a>
            </Link>
          )}
        />
        <Table.Column dataIndex="email" key="email" />
        <Table.Column
          dataIndex="id"
          key="edit"
          width={24}
          render={(id) => (
            <div onClick={handleEditAccount(id)}>
              <PencilIcon />
            </div>
          )}
          className="icon-column"
        />
        <Table.Column
          dataIndex="id"
          key="delete"
          width={24}
          render={(id, account: Account) => (
            <Popconfirm
              key="delete"
              title={`Are you sure you want to delete ${account?.name}?`}
              icon={<ExclamationCircleTwoTone />}
              onConfirm={handleDelete(id)}
              okText="Delete"
              cancelText="Cancel"
              okType="danger"
            >
              <DeleteIcon data-cy="delete-icon" />
            </Popconfirm>
          )}
          className="icon-column"
        />
      </Table>
    </div>
  )
}
