import React from 'react'
import { useSelector } from 'react-redux'
import { CreateEditMeetingModalOld, selectors as meetingSelectors } from '../../../meetings'

export type EditMeetingModalProps = {
  meetingId: string
  visible: boolean

  onComplete?: () => void
  onCancel?: () => void
}

export function EditMeetingModal(props: EditMeetingModalProps): React.ReactElement {
  // TODO rewrite with GraphQL
  const meeting = useSelector(meetingSelectors.getMeetingById(props.meetingId))
  return <CreateEditMeetingModalOld {...props} meeting={meeting} updateView />
}
