import { ReactMocharyMethodFlagSet } from './flagSet'
import React from 'react'
import { useFeatureFlags } from '@mm/web/modules/featureFlags/useFeatureFlags'

export function flaggedComponent<P, K extends keyof ReactMocharyMethodFlagSet>(
  flagKey: K,
  pickFn: (value?: ReactMocharyMethodFlagSet[K]) => React.ComponentType<P>,
): React.ComponentType<P> {
  const FlaggedComponent = (props: P) => {
    const { [flagKey]: value } = useFeatureFlags()
    const Component = pickFn(value)
    return <Component {...props} />
  }
  FlaggedComponent.displayName = `FlaggedComponent(${flagKey})`
  return FlaggedComponent
}
