import React, { useState } from 'react'
import BlockResolver from './BlockResolver'
import { useDispatch } from 'react-redux'
import { Account } from '@mm/backend/accounts/model'
import { Meeting } from '@mm/backend/meetings/model'
import { GoodThing } from '@mm/backend/goodThings/model'
import { Block as ActionsBlockItemType } from './ActionsBlockItem'
import { Block as CommentsBlockItemType } from './CommentsBlockItem'
import { actions as goodThingsActions } from '../../goodThings'
import { actions as actionsActions } from '../../actions'
import { actions as commentsActions } from '../../comments'
import Editable from './Editable'
import BlockIndent from './BlockIndent'
import BlockAccountLink from './BlockAccountLink'
import BlockMenu, {
  DeleteMenuItem,
  MenuItemDivider,
  AddActionMenuItem,
  AddCommentMenuItem,
  ChangeParticipantsMenuItem,
} from './BlockMenu'
import { format, differenceInSeconds } from 'date-fns'
import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import CommentPlusOutlineIcon from '@icons/material/CommentPlusOutlineIcon'
import PlaylistPlusIcon from '@icons/material/PlaylistPlusIcon'
import VideoOutlineIcon from '@icons/material/VideoOutlineIcon'
import { FlexRow, RecordVideoButton } from '../../interface'
import useScopedCreateActionLoadingState from '../../actions/hooks/useScopedCreateActionLoadingState'
import useScopedCreateCommentLoadingState from '../../comments/hooks/useScopedCreateCommentLoadingState'
import TooltipIconButton from './TooltipIconButton'
import { getActionDueDate } from '../utils/meetingInfoHelpers'

export const TYPE = 'goodThing'

let Quill: any // eslint-disable-line
const NOOP = () => {} // eslint-disable-line

interface Participant {
  name: string
  id: string
}

export interface Block {
  type: typeof TYPE
  id: string
  activeAccount: Account
  participants: Array<Participant>
  firstName: string
  goodThing: GoodThing
  meeting: Meeting | null
  reviewView?: boolean
  blocks: Array<ActionsBlockItemType | CommentsBlockItemType>
}

export const GoodThingsBlockItem = ({
  firstName,
  activeAccount,
  goodThing,
  participants,
  meeting,
  reviewView = false,
  blocks = [],
}: Block) => {
  const dispatch = useDispatch()
  const [quill, setQuill] = useState<typeof Quill>()
  const [actionLoading, setActionLoading] = useScopedCreateActionLoadingState()
  const [commentLoading, setCommentLoading] = useScopedCreateCommentLoadingState()
  const updateView = goodThing.updateId && !goodThing.meetingId
  const editUpdateId = updateView ? goodThing.updateId : ''
  const isAuthor = goodThing.accountId === activeAccount.id

  const handleDescriptionChange = (description: string) => {
    dispatch(goodThingsActions.edit({ id: goodThing.id, description, updateId: editUpdateId }))
  }

  const handleChangeAccount = (accountId?: string) => {
    accountId &&
      dispatch(
        goodThingsActions.edit({
          id: goodThing.id,
          accountId,
          optimistic: true,
          updateId: editUpdateId,
        }),
      )
  }

  const handleDelete = () => {
    if (blocks.length) {
      Modal.confirm({
        title: 'Are you sure delete this good thing?',
        icon: <ExclamationCircleOutlined />,
        content: 'It may delete any comments or actions attached to it.',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: () => {
          dispatch(goodThingsActions.delete({ id: goodThing.id, updateId: goodThing.updateId }))
        },
      })
    } else {
      dispatch(goodThingsActions.delete({ id: goodThing.id, updateId: goodThing.updateId }))
    }
  }

  const handleAddAction = (description = '') => {
    if (meeting) {
      setActionLoading(true)
      dispatch(
        actionsActions.createRequest({
          description,
          accountId: activeAccount.id,
          assignedTo: activeAccount.id,
          featureId: goodThing.id,
          featureType: 'goodThing',
          featurePreviewText: description || goodThing.description,
          meetingIds: [meeting.id],
          groupIds: [meeting.groupId],
          participantIds: meeting.participantIds,
          dueAt: getActionDueDate(meeting),
        }),
      )
    }
  }

  const handleAddComment = () => {
    if (meeting) {
      setCommentLoading(true)
      dispatch(
        commentsActions.createRequest({
          description: '',
          meetingId: meeting.id,
          parentId: goodThing.id,
        }),
      )
    }
  }

  const handleSaveVideo = (links: { embedUrl: string; shareUrl: string }) => {
    const quillLength = quill?.getLength()
    const delta = {
      ops: [{ retain: quillLength }, { insert: 'Video', attributes: { link: links.shareUrl } }],
    }
    quill?.updateContents(delta, 'user')
  }

  return (
    <>
      <BlockIndent
        menuLoading={actionLoading || commentLoading}
        menu={
          <BlockMenu>
            {!reviewView && (
              <>
                <ChangeParticipantsMenuItem
                  label="Change author"
                  participants={participants}
                  onClick={handleChangeAccount}
                />
                <DeleteMenuItem onClick={handleDelete} />
              </>
            )}
            <MenuItemDivider />
            <AddActionMenuItem onClick={() => handleAddAction()} />
            <AddCommentMenuItem onClick={handleAddComment} />
          </BlockMenu>
        }
      >
        <FlexRow gap={0.5}>
          <Editable
            setParentQuill={setQuill}
            prefix={<BlockAccountLink id={goodThing.accountId}>{firstName}</BlockAccountLink>}
            editable={!reviewView}
            html={goodThing.description}
            focusOnNew={isAuthor && differenceInSeconds(new Date(), goodThing.createdAt) < 15}
            onChange={handleDescriptionChange}
            onAddComment={handleAddComment}
            onAddAction={(description) => handleAddAction(description)}
            onDelete={handleDelete}
            placeholder={updateView ? `${goodThing.type} good thing...` : ''}
          />
          <RecordVideoButton onComplete={handleSaveVideo}>
            <TooltipIconButton title="Add Video" loading={actionLoading} Icon={VideoOutlineIcon} onClick={NOOP} />
          </RecordVideoButton>
          <TooltipIconButton
            title="Add Action"
            loading={actionLoading}
            Icon={PlaylistPlusIcon}
            onClick={() => handleAddAction()}
          />
          <TooltipIconButton
            title="Add Comment"
            loading={commentLoading}
            Icon={CommentPlusOutlineIcon}
            onClick={() => handleAddComment()}
          />
          <div style={{ color: '#999' }}>
            <span style={{ paddingRight: 10 }}>{goodThing.type}</span>
            <span>{format(goodThing.createdAt, 'M/d')}</span>
          </div>
        </FlexRow>
      </BlockIndent>
      <BlockIndent>
        {blocks.map((block) => (
          <BlockResolver key={block.id} block={block} />
        ))}
      </BlockIndent>
    </>
  )
}

export default GoodThingsBlockItem
