import { useQuery } from '@apollo/client'
import { Select } from 'antd'
import React from 'react'
import { CalendarSelectorDocument } from '../../../../gen/graphql/documents'

export type CalendarSelectorProps = {
  value?: string
  disabled?: boolean
  onChange?: (calendarId: string | undefined) => void
}

export function CalendarSelector({ value, disabled, onChange }: CalendarSelectorProps): React.ReactElement {
  const { data, loading } = useQuery(CalendarSelectorDocument)

  return (
    <Select
      showSearch
      allowClear
      placeholder="Select a calendar"
      loading={loading}
      disabled={disabled || loading}
      value={value}
      onChange={onChange}
      optionFilterProp="children"
    >
      {data?.me?.calendars?.map(({ id, email }) => (
        <Select.Option key={id} value={id}>
          {email}
        </Select.Option>
      ))}
    </Select>
  )
}
