import { datadogRum } from '@datadog/browser-rum'

const environment =
  process.env.NEXT_PUBLIC_PREVIEW === 'true'
    ? 'preview'
    : process.env.NODE_ENV === 'production'
    ? 'production'
    : 'development'

datadogRum.init({
  applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID as string,
  clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN as string,
  site: 'datadoghq.com',
  service: 'mochary-method',
  version: process.env.NEXT_PUBLIC_VERSION as string,
  env: environment,
  sampleRate: 100,
  trackInteractions: true,
})

export { default as middleware } from './middleware'
