// this file is a copy of BubbleMenu from @tiptap/react package with minor tweaks
// to support toolbar buttons which should not prevent toolbar from hiding
import { Plugin, PluginKey } from 'prosemirror-state'
import { BubbleMenuPluginProps, BubbleMenuView, BubbleMenuViewProps } from '@tiptap/extension-bubble-menu'
import React, { useEffect, useRef } from 'react'
import { useLatestCallback } from '../hooks'

const BubbleMenuPluginKey = new PluginKey('menuBubble')

type ExtraBubbleMenuViewProps = {
  onHide?: () => void
}

export type BubbleMenuProps = Omit<BubbleMenuPluginProps, 'element'> &
  ExtraBubbleMenuViewProps & {
    className?: string
  }

class CustomBubbleMenuView extends BubbleMenuView {
  private readonly onHide?: () => void

  constructor(props: BubbleMenuViewProps & ExtraBubbleMenuViewProps) {
    super(props)
    this.onHide = props.onHide
  }

  hide() {
    super.hide()
    this.onHide?.()
  }
}

export const BubbleMenu: React.FC<BubbleMenuProps> = ({ editor, tippyOptions, className, children, onHide }) => {
  const element = useRef<HTMLDivElement>(null)
  const viewRef = useRef<BubbleMenuView>()
  const handleHide = useLatestCallback(onHide)

  useEffect(() => {
    editor.registerPlugin(
      new Plugin({
        key: BubbleMenuPluginKey,
        view: (view) => {
          viewRef.current = new CustomBubbleMenuView({
            view,
            editor,
            element: element.current as HTMLElement,
            tippyOptions,
            onHide: handleHide,
          })
          return viewRef.current
        },
      }),
    )

    return () => {
      editor.unregisterPlugin(BubbleMenuPluginKey)
    }
  }, [])

  return (
    <div
      ref={element}
      onClick={() => {
        if (!editor.isFocused) {
          viewRef.current?.hide()
        }
      }}
      className={className}
      style={{ visibility: 'hidden' }}
    >
      {children}
    </div>
  )
}
