import React from 'react'
import NextErrorComponent, { ErrorProps } from 'next/error'
import { NextPage, NextPageContext } from 'next'
import * as Sentry from '@sentry/node'

const ErrorComponent: NextPage<ErrorProps> = ({ statusCode, ...props }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const error = (props as any).err
  if (error) {
    // getInitialProps is not called in case of
    // https://github.com/vercel/next.js/issues/8592. As a workaround, we pass
    // err via _app.js so it can be captured
    Sentry.captureException(error)
  }

  return <NextErrorComponent statusCode={statusCode} />
}

ErrorComponent.getInitialProps = async ({ res, err, asPath }: NextPageContext) => {
  const errorInitialProps = await NextErrorComponent.getInitialProps({
    res,
    err,
  } as NextPageContext)

  if (err) {
    Sentry.captureException(err)
    await Sentry.flush(2000)
    return errorInitialProps
  }

  // If this point is reached, getInitialProps was called without any
  // information about what the error might be. This is unexpected and may
  // indicate a bug introduced in Next.js, so record it in Sentry
  Sentry.captureException(new Error(`_error.js getInitialProps missing data at path: ${asPath}`))
  await Sentry.flush(2000)

  return errorInitialProps
}

export default ErrorComponent
