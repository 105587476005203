// Converts firebase timestamp object into JS Dates
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const timestampToDate = (timestamp: any): Date => {
  // Default == now
  if (!timestamp) return new Date()
  // some dates are stored as strings in the DB
  else if (typeof timestamp == 'string') {
    return new Date(timestamp)
  } else if (timestamp instanceof Date) {
    return timestamp
  } else {
    if (timestamp && '_seconds' in timestamp) return new Date(timestamp._seconds * 1000)
    else if (timestamp && 'seconds' in timestamp) return new Date(timestamp.seconds * 1000)
    else throw new Error('Invalid date format')
  }
}
