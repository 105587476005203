import React from 'react'
import PlusIcon from '@icons/material/PlusIcon'
import BlockIndent from './BlockIndent'
import styled from '@emotion/styled'
import { LoadingOutlined } from '@ant-design/icons'

const BlockWrap = styled.div(({ color }: { color?: string }) => ({
  margin: 5,
  color,
  cursor: 'pointer',
  display: 'inline-flex',
  alignItems: 'center',
  transition: 'opacity 400ms',

  '&:active': {
    opacity: 0.7,
  },
}))

interface Props {
  label: string
  color?: string
  indent?: boolean
  disabled?: boolean
  loading?: boolean
  onClick?: () => void
}

export const BlockAdd: React.FC<Props> = ({
  label,
  color = '#999',
  indent = true,
  disabled,
  loading = false,
  onClick,
}) => {
  const components = (
    <BlockWrap data-cy="block-add" color={color} onClick={() => !disabled && onClick?.()}>
      {loading ? (
        <LoadingOutlined style={{ fontSize: 24, marginRight: 5 }} spin />
      ) : (
        <PlusIcon style={{ marginRight: 5 }} />
      )}

      {label}
    </BlockWrap>
  )
  return indent ? <BlockIndent>{components}</BlockIndent> : components
}

export default BlockAdd
