import React from 'react'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import BlockResolver from './BlockResolver'
import { Account } from '@mm/backend/accounts/model'
import { Meeting } from '@mm/backend/meetings/model'
import { Issue } from '@mm/backend/issues/model'
import { Block as ActionsBlockItemType } from './ActionsBlockItem'
import { Block as CommentsBlockItemType } from './CommentsBlockItem'
import { AccountName } from '../../accounts'
import { actions as issuesActions } from '../../issues'
import { actions as actionsActions } from '../../actions'
import { actions as commentsActions } from '../../comments'
import { actions as meetingActions } from '../../meetings'
import Editable from './Editable'
import BlockIndent from './BlockIndent'
import BlockAccountLink from './BlockAccountLink'
import BlockMenu, {
  DeleteMenuItem,
  MenuItemDivider,
  AddActionMenuItem,
  AddCommentMenuItem,
  ChangeParticipantsMenuItem,
} from './BlockMenu'
import { Modal, Spin, Popover, notification } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { differenceInSeconds } from 'date-fns'
import CommentPlusOutlineIcon from '@icons/material/CommentPlusOutlineIcon'
import PlaylistPlusIcon from '@icons/material/PlaylistPlusIcon'
import useScopedCreateActionLoadingState from '../../actions/hooks/useScopedCreateActionLoadingState'
import useScopedCreateCommentLoadingState from '../../actions/hooks/useScopedCreateActionLoadingState'
import useRemoveIssueFromMeetingMutation from '../../issues/hooks/useRemoveIssueFromMeetingMutation'
import TooltipIconButton from './TooltipIconButton'
import { Card, FlexRow, Div, TitleText, Text, Button, Flex } from '../../interface'
import { SingleIssue } from '../../issues'
import DotsVerticalIcon from '@icons/material/DotsVerticalIcon'
import styled from '@emotion/styled'
import { getActionDueDate } from '../utils/meetingInfoHelpers'

export const TYPE = 'issue'

interface Participant {
  name: string
  id: string
}

const Row = styled(Flex)({
  boxShadow: '0 0 0 2px rgb(153, 153, 153, 0)',
  borderRadius: 6,
  transition: '200ms box-shadow',

  '&:hover': {
    boxShadow: '0 0 0 2px rgb(153, 153, 153, 1)',
    zIndex: 2,
    position: 'relative',
  },

  '&:active': {
    boxShadow: '0 0 0 2px rgb(255, 112, 67)',
    zIndex: 2,
    position: 'relative',
  },
})

export interface Block {
  type: typeof TYPE
  id: string
  activeAccount: Account
  participants: Array<Participant>
  firstName: string
  issue: Issue
  meeting: Meeting | null
  blocks: Array<ActionsBlockItemType | CommentsBlockItemType>
}

export const IssuesBlockItem = ({ firstName, issue, activeAccount, participants, meeting, blocks = [] }: Block) => {
  const dispatch = useDispatch()
  const [actionLoading, setActionLoading] = useScopedCreateActionLoadingState()
  const [commentLoading, setCommentLoading] = useScopedCreateCommentLoadingState()
  const [viewModalVisible, setViewModalVisible] = React.useState(false)
  const handleDescriptionChange = (
    key: 'description' | 'whatWasDoneText' | 'proposedSolutionText' | 'finalSolutionText',
  ) => (value: string) => {
    dispatch(issuesActions.edit({ id: issue.id, [key]: value }))
  }

  const handleChangeAccount = (accountId?: string) => {
    accountId && dispatch(issuesActions.edit({ id: issue.id, accountId }))
  }

  const handleDelete = () => {
    if (blocks.length) {
      Modal.confirm({
        title: 'Are you sure delete this issue?',
        icon: <ExclamationCircleOutlined />,
        content: 'It may delete any comments or actions attached to it.',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: () => {
          dispatch(issuesActions.delete({ id: issue.id }))
        },
      })
    } else {
      dispatch(issuesActions.delete({ id: issue.id }))
    }
  }

  const handleAddAction = (description = '') => {
    if (meeting) {
      setActionLoading(true)
      dispatch(
        actionsActions.createRequest({
          description,
          accountId: activeAccount.id,
          assignedTo: activeAccount.id,
          featureId: issue.id,
          featureType: 'issue',
          featurePreviewText: description || issue.title || '',
          meetingIds: [meeting.id],
          groupIds: [meeting.groupId],
          participantIds: meeting.participantIds,
          dueAt: getActionDueDate(meeting),
        }),
      )
    }
  }

  const handleAddComment = () => {
    if (meeting) {
      setCommentLoading(true)
      dispatch(
        commentsActions.createRequest({
          description: '',
          meetingId: meeting.id,
          parentId: issue.id,
        }),
      )
    }
  }

  const removeIssueFromMeeting = useRemoveIssueFromMeetingMutation({
    onError: () => {
      notification.error({
        message: 'There was an error removing this issue',
      })
    },
    onSuccess: () => {
      if (meeting) {
        dispatch(
          meetingActions.view({
            id: meeting.id,
          }),
        )
      }
      notification.success({
        message: 'Issue removed',
      })
    },
  })

  const handleRemove = () => {
    meeting &&
      removeIssueFromMeeting.mutate({
        meetingId: meeting.id,
        issueId: issue.id,
      })
  }

  return (
    <>
      {issue.description || issue.whatWasDoneText || issue.proposedSolutionText || issue.finalSolutionText ? (
        <BlockIndent
          menuLoading={commentLoading || actionLoading}
          menu={
            <BlockMenu>
              <ChangeParticipantsMenuItem
                label="Change author"
                participants={participants}
                onClick={handleChangeAccount}
              />
              <DeleteMenuItem onClick={handleDelete} />
              <MenuItemDivider />
              <AddActionMenuItem onClick={() => handleAddAction()} />
              <AddCommentMenuItem onClick={handleAddComment} />
            </BlockMenu>
          }
        >
          <FlexRow paddingVertical={0.5} gap={0.5}>
            <Div style={{ flex: 1 }}>
              <BlockAccountLink id={issue.accountId}>{firstName}</BlockAccountLink>
            </Div>

            <TooltipIconButton
              title="Add Action"
              loading={actionLoading}
              Icon={PlaylistPlusIcon}
              onClick={() => handleAddAction()}
            />
            <TooltipIconButton
              title="Add Comment"
              loading={commentLoading}
              Icon={CommentPlusOutlineIcon}
              onClick={() => handleAddComment()}
            />
          </FlexRow>
          <div style={{ borderLeft: '3px solid #ddd', padding: '7px 10px', background: '#fafafa' }}>
            <Editable
              prefix={<strong>What is the issue</strong>}
              editable={issue.accountId === activeAccount.id}
              focusOnNew={issue.accountId === activeAccount.id && differenceInSeconds(new Date(), issue.createdAt) < 15}
              html={issue.description}
              onChange={handleDescriptionChange('description')}
              onAddComment={handleAddComment}
              onAddAction={(description) => handleAddAction(description)}
              onDelete={handleDelete}
            />
            <Editable
              prefix={<strong>What I did to create this issue</strong>}
              editable={issue.accountId === activeAccount.id}
              html={issue.whatWasDoneText}
              onChange={handleDescriptionChange('whatWasDoneText')}
              onAddComment={handleAddComment}
              onAddAction={(description) => handleAddAction(description)}
            />
            <Editable
              prefix={<strong>Proposed solution</strong>}
              editable={issue.accountId === activeAccount.id}
              html={issue.proposedSolutionText}
              onChange={handleDescriptionChange('proposedSolutionText')}
              onAddComment={handleAddComment}
              onAddAction={(description) => handleAddAction(description)}
            />
            <Editable
              prefix={<strong>Decision</strong>}
              editable={issue.accountId === activeAccount.id}
              html={issue.finalSolutionText}
              onChange={handleDescriptionChange('finalSolutionText')}
              onAddComment={handleAddComment}
              onAddAction={(description) => handleAddAction(description)}
            />
          </div>
        </BlockIndent>
      ) : (
        <Div style={{ borderTop: '1px solid #eee' }}>
          <Modal
            title="View Issue"
            className="revert-font-size"
            width="80vw"
            visible={viewModalVisible}
            onCancel={() => setViewModalVisible(false)}
            footer={null}
          >
            {meeting ? (
              <SingleIssue inline={true} issue={issue} meetingId={meeting.id} groupId={meeting.groupId} />
            ) : (
              <Spin />
            )}
          </Modal>
          <Row gap={1} alignItems="flex-start">
            <Flex
              gap={1}
              padding={1}
              style={{ flex: 1, cursor: 'pointer' }}
              alignItems="flex-start"
              onClick={() => setViewModalVisible(true)}
            >
              <Div style={{ flex: 5 }}>
                <Text color="dark" weight="heavy">
                  {issue.title}
                </Text>
              </Div>
              <Div style={{ flex: 2 }}>
                <Text color="light">
                  <AccountName id={issue.accountId} skipYou={true} />
                </Text>
              </Div>
              <Div style={{ flex: 2 }}>
                <Text color="light">
                  {issue.decisionMaker ? <AccountName id={issue.decisionMaker} skipYou={true} /> : null}
                </Text>
              </Div>
              <Div style={{ flex: 2 }}>
                <Text color="light">{_.startCase(issue.status.toLowerCase())}</Text>
              </Div>
            </Flex>

            <Div style={{ width: 24, cursor: 'pointer' }} margin={1} data-cy="issue-menu-block">
              <Popover
                placement="leftTop"
                overlayInnerStyle={{ border: '1px solid #eee', width: 180 }}
                content={
                  <a data-cy="remove-from-meeting" style={{ display: 'block', color: '#333' }} onClick={handleRemove}>
                    {removeIssueFromMeeting.isLoading ? 'Removing...' : 'Remove from Meeting'}
                  </a>
                }
                trigger="click"
              >
                <DotsVerticalIcon style={{ display: 'block', color: '#999' }} />
              </Popover>
            </Div>
          </Row>
        </Div>
      )}
      {blocks.map((block) => (
        <BlockResolver key={block.id} block={block} />
      ))}
    </>
  )
}

export default IssuesBlockItem
