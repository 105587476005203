import React from 'react'
import { useSelector } from 'react-redux'
import { selectors as commentssSelectors } from '../store'

export const useScopedCreateCommentLoadingState = () => {
  const [loading, setLoading] = React.useState(false)
  const form = useSelector(commentssSelectors.getForm())

  React.useEffect(() => {
    if (loading && !form.loading) {
      setLoading(false)
    }
  }, [form.loading])

  return [loading, setLoading] as const
}

export default useScopedCreateCommentLoadingState
