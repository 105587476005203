import { Account } from '@mm/backend/accounts/model'
import { Radio } from 'antd'
import React, { useState } from 'react'
import { UserGoalsWithActions } from '../../goals'
import { FlexRow } from '../../ui'

export type AccountSettingsProfileTabProps = {
  account: Account
}

export const AccountSettingsGoalsTab = ({ account }: AccountSettingsProfileTabProps) => {
  const [showPastGoalsAndActions, setShowPastGoalsAndActions] = useState(false)
  return (
    <div>
      <FlexRow style={{ justifyContent: 'space-between', marginBottom: 17 }}>
        <h2 style={{ marginBottom: 0 }}>Goals</h2>

        <FlexRow>
          <Radio.Group
            buttonStyle="outline"
            value={showPastGoalsAndActions}
            onChange={(e) => setShowPastGoalsAndActions(e.target.value)}
          >
            <Radio.Button value={false}>In progress</Radio.Button>
            <Radio.Button value={true}>All</Radio.Button>
          </Radio.Group>
        </FlexRow>
      </FlexRow>
      <UserGoalsWithActions accountId={account.id} showPastGoalsAndActions={showPastGoalsAndActions} meeting={null} />
    </div>
  )
}
