import React from 'react'
import { Comment } from '@mm/backend/comments/model'
import { useDispatch, useSelector } from 'react-redux'
import firebase from '../../firebase'
import { actions, selectors } from '../store'

const useRealtimeComments = (parentId: string) => {
  const dispatch = useDispatch()

  React.useEffect(() => {
    const commentsRef = firebase.collection('comments').where('parentId', '==', parentId)

    const unsubscribe = commentsRef.onSnapshot((querySnapshot) => {
      for (const change of querySnapshot.docChanges()) {
        const docData = change.doc.data() as Comment
        const comment = {
          ...docData,
          createdAt: (docData.createdAt as any).toDate
            ? (docData.createdAt as any).toDate()
            : new Date(docData.createdAt),
        }

        switch (change.type) {
          case 'added':
            dispatch(actions.add({ comment }))
            break
          case 'modified':
            dispatch(actions.change({ comment }))
            break
          case 'removed':
            dispatch(actions.remove({ comment }))
            break
        }
      }
    })

    return unsubscribe
  }, [parentId])

  return useSelector(selectors.getByParentId(parentId))
}

export default useRealtimeComments
