import React from 'react'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { Issue } from '@mm/backend/issues/model'
import styled from '@emotion/styled'
import { Div, Text, Flex } from '../../interface'
import useIssues from '../hooks/useIssues'
import useAddIssueToMeetingMutation from '../hooks/useAddIssueToMeetingMutation'
import { AccountName } from '../../accounts'
import { Spin, Popconfirm, notification } from 'antd'
import PlusBoxOutlineIcon from '@icons/material/PlusBoxOutlineIcon'
import { actions as meetingActions } from '../../meetings'

interface Props {
  companyId: string
  meetingId: string
  alreadyImported?: Array<string>
  warnIfParticipantsDontMatch?: Array<string>
}

const AddIconWrap = styled(Div)({
  width: 24,
  height: 24,
  color: '#999',
  cursor: 'pointer',
  transition: 'color 200ms',

  '&:hover': {
    color: '#42a5f5',
  },
})

export const ImportIssuesList: React.FC<Props> = ({
  companyId,
  meetingId,
  alreadyImported = [],
  warnIfParticipantsDontMatch = [],
}) => {
  const { data, status } = useIssues(companyId)
  const issues = data?.pages.flatMap((page) => page.edges.map(({ node }) => node))

  return (
    <Div
      style={{
        backgroundColor: '#fff',
        boxShadow: '0 2px 4px rgba(0,0,0,0.05), 0 4px 8px rgba(0,0,0,0.05)',
        border: '1px solid #eee',
        borderRadius: 6,
        width: 500,
        height: 300,
        overflowY: 'scroll',
      }}
    >
      {status === 'loading' ? (
        <Flex style={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
          <Spin />
        </Flex>
      ) : issues ? (
        <>
          <Div
            paddingVertical={0.5}
            paddingHorizontal={1}
            style={{ background: '#fafafa', borderBottom: '1px solid #eee', textTransform: 'uppercase' }}
          >
            <Text color="light">Import Issues</Text>
          </Div>
          {issues.length ? (
            issues.map((issue) => {
              const participantsHaveAccess =
                _.difference(warnIfParticipantsDontMatch, issue.participantIds || []).length === 0
              return !alreadyImported.includes(issue.id) ? (
                <ImportIssuesListItem
                  key={issue.id}
                  issue={issue}
                  warnOnImport={!participantsHaveAccess}
                  meetingId={meetingId}
                />
              ) : null
            })
          ) : (
            <Div
              style={{ display: 'flex', height: '90%', justifyContent: 'center', alignItems: 'center', color: '#999' }}
            >
              No Issues Yet
            </Div>
          )}
        </>
      ) : null}
    </Div>
  )
}

interface ItemProps {
  issue: Issue
  warnOnImport: boolean
  meetingId: string
}

const ImportIssuesListItem: React.FC<ItemProps> = ({ issue, warnOnImport, meetingId }) => {
  const dispatch = useDispatch()
  const addIssueToMeeting = useAddIssueToMeetingMutation({
    onError: () => {
      notification.error({
        message: 'There was an error importing this issue',
      })
    },
    onSuccess: () => {
      if (meetingId) {
        dispatch(
          meetingActions.view({
            id: meetingId,
          }),
        )
      }

      notification.success({
        message: 'Issue imported',
      })
    },
  })
  const handleImport = () => {
    addIssueToMeeting.mutate({
      meetingId,
      issueId: issue.id,
    })
  }

  return (
    <Flex padding={1} style={{ borderBottom: '1px solid #eee' }} gap={1} data-cy="import-issue-list-item">
      <Div style={{ flex: 1, cursor: 'pointer' }} onClick={handleImport}>
        <Div>
          <Text size="medium">{issue.title}</Text>
        </Div>
        <Flex gap={1}>
          <Text color="light">
            Author: <AccountName id={issue.accountId} skipYou={true} />
          </Text>
          <Text color="light">Status: {_.startCase((issue.status || 'CREATED').toLowerCase())}</Text>
        </Flex>
      </Div>
      {warnOnImport ? (
        <Popconfirm
          title="Not all participants in this meeting have access to this issue. Importing this issue will give them access."
          onConfirm={handleImport}
          okText="Import and grant access"
          cancelText="Cancel"
          overlayStyle={{ maxWidth: 300 }}
        >
          <AddIconWrap>{addIssueToMeeting.isLoading ? <Spin size="small" /> : <PlusBoxOutlineIcon />}</AddIconWrap>
        </Popconfirm>
      ) : (
        <AddIconWrap onClick={handleImport}>
          {addIssueToMeeting.isLoading ? <Spin size="small" /> : <PlusBoxOutlineIcon />}
        </AddIconWrap>
      )}
    </Flex>
  )
}

export default ImportIssuesList
