import React from 'react'
import { useSelector } from 'react-redux'
import { selectors } from '../store'
import { useRouter } from 'next/router'

export const ProtectRoutes: React.FC = ({ children }) => {
  const router = useRouter()
  const initialized = useSelector(selectors.getInitialized())
  const user = useSelector(selectors.getUser())

  React.useEffect(() => {
    if (initialized && !user && !router.pathname.includes('/login')) {
      router.push(`/login?redirect=${encodeURI(window.location.pathname)}`)
    }
  }, [initialized, user])

  return <>{initialized && user ? children : null}</>
}

export default ProtectRoutes
