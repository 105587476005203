import { Account } from '../accounts'
import { KeysOfType } from '../helpers'
import { Meeting } from '../meetings'
import { Update } from '../update'
import { Comment } from '../comments'
import Likeable from '../interfaces/Likeable'
import { HasAttachments } from '../shared'

export interface GoodThing extends HasAttachments, Likeable {
  id: string
  description: string
  commentIds: Array<Comment['id']>
  type: 'work' | 'personal'
  meetingId: Meeting['id']
  accountId: Account['id']
  createdAt: Date
  updatedAt: Date
  updateId: Update['id']
}

export const dateKeys: KeysOfType<GoodThing, Date>[] = ['updatedAt', 'createdAt']
