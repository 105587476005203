import { useMutation } from '@apollo/client'
import { notification } from 'antd'
import Router from 'next/router'
import { useSelector } from 'react-redux'
import { useAsync } from 'react-use'
import { selectors } from '../../auth'
import { HandleGoogleCalendarCallbackDocument } from '../../../gen/graphql/documents'

export function useHandleGoogleCalendarCallback(authCode: unknown, state: unknown): void {
  const initialized = useSelector(selectors.getInitialized())
  const user = useSelector(selectors.getUser())

  const [handleGoogleCalendarCallback] = useMutation(HandleGoogleCalendarCallbackDocument)

  useAsync(async () => {
    if (initialized) {
      console.log('handling google auth callback', { state, authCode })

      let uid
      try {
        if (typeof state !== 'string') {
          throw new Error('state is malformed')
        }
        uid = JSON.parse(state).uid
      } catch (e) {
        console.error('Failed to parse state', e)
      }

      try {
        if (!uid || typeof uid !== 'string' || !authCode || typeof authCode !== 'string') {
          throw new Error('Callback request parameters are malformed')
        }

        if (user?.uid !== uid) {
          throw new Error('Triying to connect google calendar for a different user')
        }

        const { data } = await handleGoogleCalendarCallback({
          variables: {
            authCode,
            accountId: uid,
          },
        })

        if (data?.linkGoogleCalendar.__typename !== 'Calendar') {
          throw new Error(data?.linkGoogleCalendar.message ?? 'something went wrong')
        }

        notification.success({
          message: 'Success!',
          description: 'The calendar was successfully added to your account',
        })
      } catch (e) {
        console.error('Failed to connect google calendar to an account', e)
        notification.error({
          message: 'Error',
          description: 'Something went wrong on our side, please try again later',
        })
      } finally {
        Router.push('/integrations')
      }
    }
  }, [authCode, initialized, state])
}
