import { array } from 'fp-ts'
import { pipe } from 'fp-ts/function'
import _ from 'lodash'

export type State<T extends { id: string }> = {
  byId: {
    [id: string]: T
  }
  optimisticStorage: OptimisticStorage<T>
}

export type OptimisticStorage<T extends { id: string }> = {
  [id: string]: {
    state: T
    changes: Array<{
      changeId: string
      patch: Partial<Omit<T, 'id'>>
    }>
  }
}

export function buildOptimisticState<T extends { id: string }>(storage: OptimisticStorage<T>[string]): T {
  return storage.changes.reduce((state, { patch }) => ({ ...state, ...patch }), storage.state)
}

export function set<T extends { id: string }, S extends State<T>>(state: S, items: Record<string, T>): S {
  const optimisticStorage: OptimisticStorage<T> = pipe(
    Object.entries(items),
    array.map(([id, item]) => [
      id,
      {
        ...(state.optimisticStorage[id] ?? { changes: [] }),
        state: item,
      },
    ]),
    Object.fromEntries,
  )
  const byId: State<T>['byId'] = pipe(
    Object.entries(optimisticStorage),
    array.map(([id, storage]) => [id, buildOptimisticState(storage)]),
    Object.fromEntries,
  )
  return {
    ...state,
    optimisticStorage: {
      ...state.optimisticStorage,
      ...optimisticStorage,
    },
    byId: {
      ...state.byId,
      ...byId,
    },
  }
}

export function upsert<T extends { id: string }, S extends State<T>>(state: S, item: T): S {
  const itemStorage = {
    ...(state.optimisticStorage[item.id] ?? { changes: [] }),
    state: item,
  }
  return {
    ...state,
    optimisticStorage: {
      ...state.optimisticStorage,
      [item.id]: itemStorage,
    },
    byId: {
      ...state.byId,
      [item.id]: buildOptimisticState(itemStorage),
    },
  }
}

export function remove<T extends { id: string }, S extends State<T>>(state: S, item: T): S {
  return removeById(state, item.id)
}

export function removeById<T extends { id: string }, S extends State<T>>(state: S, id: string): S {
  const newThingsById = { ...state.byId }
  const newOptimisticStorage = { ...state.optimisticStorage }

  delete newThingsById[id]
  delete newOptimisticStorage[id]

  return {
    ...state,
    byId: newThingsById,
    optimisticStorage: newOptimisticStorage,
  }
}

export function clear<T extends { id: string }, S extends State<T>>(state: S): S {
  return {
    ...state,
    byId: {},
    optimisticStorage: {},
  }
}

export type OptimisticChange<T extends { id: string }> = {
  itemId: string
  changeId: string
  patch: Partial<Omit<T, 'id'>>
}

export function optimisticChange<T extends { id: string }, S extends State<T>>(
  state: S,
  change: OptimisticChange<T>,
): S {
  const itemStorage = state.optimisticStorage[change.itemId]
  if (itemStorage == null) {
    console.error('Cannot apply optimistic change to a non existing topic, id: ', change.itemId)
    return state
  }
  const newItemStorage = {
    ...itemStorage,
    changes: [
      ...itemStorage.changes,
      {
        changeId: change.changeId,
        patch: _.pickBy(change.patch, _.negate(_.isUndefined)) as Partial<T>,
      },
    ],
  }
  return {
    ...state,
    byId: {
      ...state.byId,
      [change.itemId]: buildOptimisticState(newItemStorage),
    },
    optimisticStorage: {
      ...state.optimisticStorage,
      [change.itemId]: newItemStorage,
    },
  }
}

export type OptimisticRollback = {
  itemId: string
  changeId: string
}

export function optimisticRollback<T extends { id: string }, S extends State<T>>(
  state: S,
  rollback: OptimisticRollback,
): S {
  const change = state.optimisticStorage[rollback.itemId]?.changes.find(
    (change) => change.changeId === rollback.changeId,
  )
  if (!change) {
    console.warn('Cannot rollback: optimistic change', rollback.changeId, 'not found for item', rollback.itemId)
    return state
  }
  const itemStorage = {
    ...state.optimisticStorage[rollback.itemId],
    changes: state.optimisticStorage[rollback.itemId].changes.filter((change) => change.changeId !== rollback.changeId),
  }
  return {
    ...state,
    byId: {
      ...state.byId,
      [rollback.itemId]: buildOptimisticState(itemStorage),
    },
    optimisticStorage: {
      ...state.optimisticStorage,
      [rollback.itemId]: itemStorage,
    },
  }
}

export type OptimisticCommit<T extends { id: string }> = {
  changeId: string
  item: T
}

export function optimisticCommit<T extends { id: string }, S extends State<T>>(
  state: S,
  commit: OptimisticCommit<T>,
): S {
  const itemStorage = {
    state: commit.item,
    changes:
      state.optimisticStorage[commit.item.id]?.changes.filter((change) => change.changeId !== commit.changeId) ?? [],
  }
  return {
    ...state,
    byId: {
      ...state.byId,
      [commit.item.id]: buildOptimisticState(itemStorage),
    },
    optimisticStorage: {
      ...state.optimisticStorage,
      [commit.item.id]: itemStorage,
    },
  }
}
