import React from 'react'
import { Empty } from 'antd'
import BlockIndent from './BlockIndent'

interface Props {
  label: string
}

export const BlockEmpty: React.FC<Props> = ({ label }) => (
  <BlockIndent>
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={label} />
  </BlockIndent>
)

export default BlockEmpty
