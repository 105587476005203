import { CloseCircleOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import styled from '@emotion/styled'
import { Button, Spin } from 'antd'
import React from 'react'
import { FlexColumn, FlexRow } from '../../interface'
import { color } from '../../interface/theme'
import { CalendarConnection } from './CalendarConnection'
import { CalendarsListDocument } from '../../../gen/graphql/documents'

export type CalendarsListProps = {
  renderEmptyList?: () => React.ReactElement
}

export function CalendarsList({ renderEmptyList }: CalendarsListProps): React.ReactElement {
  const { data, loading, refetch } = useQuery(CalendarsListDocument, {
    notifyOnNetworkStatusChange: true,
  })

  if (loading) {
    return (
      <FlexRow justifyContent="center">
        <Spin />
      </FlexRow>
    )
  }

  if (data?.me?.calendars == null) {
    return (
      <ConnectionErrorBlock gap={1} alignItems="top" marginTop={2}>
        <ConnectionErrorIcon />
        <FlexColumn gap={1} alignItems="start">
          <ConnectionErrorDescription>
            <strong>Connection error.</strong>
            <br />
            For some reason, the calendars failed to load. It&apos;s not your fault. Wait a moment, and try again.
          </ConnectionErrorDescription>
          <Button size="small" danger onClick={() => refetch()}>
            Retry
          </Button>
        </FlexColumn>
      </ConnectionErrorBlock>
    )
  }

  if (data.me.calendars.length === 0) {
    return renderEmptyList?.() ?? <></>
  }

  return (
    <FlexColumn gap={1} alignItems="stretch" marginTop={2}>
      {data.me.calendars.map(({ id }) => (
        <CalendarConnection id={id} key={id} />
      ))}
    </FlexColumn>
  )
}

const ConnectionErrorBlock = styled(FlexRow)`
  color: ${color('red')};
  width: 380px;
`

const ConnectionErrorIcon = styled(CloseCircleOutlined)`
  font-size: 24px;
`

const ConnectionErrorDescription = styled.div`
  font-size: 14px;
`
