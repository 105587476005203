/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from 'react'
import _ from 'lodash'
import { css, useTheme } from '@emotion/react'
import { Action } from '@mm/backend/actions/model'
import { Meeting } from '@mm/backend/meetings/model'
import useScopedCreateActionLoadingState from '../../actions/hooks/useScopedCreateActionLoadingState'
import useScopedCreateCommentLoadingState from '../../comments/hooks/useScopedCreateCommentLoadingState'
import BlockResolver from './BlockResolver'
import { useDispatch, useSelector } from 'react-redux'
import { actions as actionsActions } from '../../actions'
import { actions as commentsActions } from '../../comments'
import ChevronDownIcon from '@icons/material/ChevronDownIcon'
import CheckBoxOutlineIcon from '@icons/material/CheckBoxOutlineIcon'
import CheckboxBlankOutlineIcon from '@icons/material/CheckboxBlankOutlineIcon'
import MinusBoxOutlineIcon from '@icons/material/MinusBoxOutlineIcon'
import VideoOutlineIcon from '@icons/material/VideoOutlineIcon'
import { Account } from '@mm/backend/accounts/model'
import { format, isToday, differenceInSeconds, isPast } from 'date-fns'
import Editable from './Editable'
import BlockIndent from './BlockIndent'
import BlockAccountLink from './BlockAccountLink'
import { Block as CommentsBlockItemType } from './CommentsBlockItem'
import { Dropdown, Button, Modal, Radio, Input } from 'antd'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import BlockMenu, {
  DeleteMenuItem,
  MenuItemDivider,
  AddActionMenuItem,
  AddCommentMenuItem,
  ChangeParticipantsMenuItem,
} from './BlockMenu'
import { RichText } from '../../ui'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { ParagraphText, RecordVideoButton } from '../../interface'
import { selectors as companySelectors } from '../../companies'
import { selectors } from '../store'
import { getActionDueDate } from '../utils/meetingInfoHelpers'

export const TYPE = 'action'

interface Participant {
  name: string
  id: string
}

export interface Block {
  type: typeof TYPE
  id: string
  firstName: string
  activeAccount: Account
  showContext?: boolean
  showNotDone?: boolean
  participants: Array<Participant>
  action: Action
  meeting: Meeting | null
  blocks: Array<Block | CommentsBlockItemType>
  allowAddComments?: boolean
  allowAddActions?: boolean
  showIfEmpty?: boolean
  reviewView?: boolean
  children?: Array<Block>
  background?: 'white' | 'off-white'
}

let Quill: any // eslint-disable-line

export const ActionsBlockItem = ({
  firstName,
  action,
  showContext = false,
  showNotDone = false,
  participants,
  activeAccount,
  meeting,
  reviewView = false,
  blocks = [],
  allowAddComments = true,
  allowAddActions = true,
  background = 'off-white',
}: Block) => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const getSelectedMeetingId = () => {
    if (meeting) {
      return meeting.id
    }

    if (action.meetingIds && action.meetingIds.length) {
      return action.meetingIds[0]
    }

    return ''
  }

  const selectedMeeting = useSelector(selectors.getMeetingById(getSelectedMeetingId()))
  const company = useSelector(companySelectors.getCompanyById(selectedMeeting?.companyId))
  const [creatingAction, setCreatingAction] = useScopedCreateActionLoadingState()
  const [creatingComment, setCreatingComment] = useScopedCreateCommentLoadingState()
  const checkboxStatus = action.status
  const [quill, setQuill] = useState<typeof Quill>()

  const handleChangeStatus = (status: Action['status'], reason?: string) => () => {
    if (!reviewView) {
      dispatch(
        actionsActions.edit({
          id: action.id,
          status,
          statusChangedInMeetingId: meeting ? meeting.id : 'not-in-meeting',
          reason,
          optimistic: true,
        }),
      )

      if (status === 'NOT_DONE_REGRETTABLE') {
        handleAddAction(action.description)
      }
    }
  }
  const handleChangeDue = (dueAt: Date) => dispatch(actionsActions.edit({ id: action.id, dueAt, optimistic: true }))

  const handleDescriptionChange = (description: string) => {
    if (!reviewView) {
      dispatch(actionsActions.edit({ id: action.id, description }))
    }
  }

  const handleChangeParticipant = (assignedTo?: string) => {
    assignedTo && dispatch(actionsActions.edit({ id: action.id, assignedTo, optimistic: true }))
  }

  const handleDelete = () => {
    if (blocks.length) {
      Modal.confirm({
        title: 'Are you sure delete this action?',
        icon: <ExclamationCircleOutlined />,
        content: 'It may delete any comments or actions attached to it.',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: () => {
          dispatch(actionsActions.delete({ id: action.id }))
        },
      })
    } else {
      dispatch(actionsActions.delete({ id: action.id }))
    }
  }

  const handleAddAction = (description = '') => {
    setCreatingAction(true)
    dispatch(
      actionsActions.createRequest({
        description,
        accountId: activeAccount.id,
        assignedTo: activeAccount.id,
        featureId: action.id,
        featureType: 'action',
        featurePreviewText: action.featurePreviewText,
        meetingIds: meeting ? [meeting.id] : [],
        groupIds: meeting ? [meeting.groupId] : [],
        participantIds: meeting ? meeting.participantIds : [],
        dueAt: getActionDueDate(meeting),
      }),
    )
  }

  const handleAddComment = () => {
    setCreatingComment(true)
    dispatch(
      commentsActions.createRequest({
        description: '',
        meetingId: meeting ? meeting.id : '',
        parentId: action.id,
      }),
    )
  }

  const due =
    action.status === 'CREATED' &&
    action.dueAt &&
    (isToday(action.dueAt) || action.dueAt.getTime() < new Date().getTime())

  const checkboxStyleMap = {
    CREATED: {
      cursor: 'pointer',
      color: theme.color.gray,
    },
    DONE: {
      cursor: 'pointer',
      color: theme.color.blue,
    },
    NOT_DONE: {
      cursor: 'pointer',
      color: theme.color.red,
    },
  }

  const checkboxComponent = {
    CREATED: <CheckboxBlankOutlineIcon style={checkboxStyleMap.CREATED} onClick={handleChangeStatus('DONE')} />,
    DONE: <CheckBoxOutlineIcon style={checkboxStyleMap.DONE} onClick={handleChangeStatus('CREATED')} />,
    NOT_DONE: <MinusBoxOutlineIcon style={checkboxStyleMap.NOT_DONE} onClick={handleChangeStatus('CREATED')} />,
    NOT_DONE_REGRETTABLE: (
      <MinusBoxOutlineIcon style={checkboxStyleMap.NOT_DONE} onClick={handleChangeStatus('CREATED')} />
    ),
    NOT_DONE_NOT_REGRETTABLE: (
      <MinusBoxOutlineIcon style={checkboxStyleMap.NOT_DONE} onClick={handleChangeStatus('CREATED')} />
    ),
  }[checkboxStatus]

  const [showEditDue, setShowEditDue] = React.useState(false)
  const editDueMenu = <DatePicker onChange={handleChangeDue} selected={action.dueAt} inline={true} />

  const [showNotDoneModal, setShowNotDoneModal] = React.useState(false)
  const [reasonValue, setReasonValue] = React.useState(action.reason)
  const [notDoneValue, setNotDoneValue] = React.useState(action.status)
  const [viewContext, setViewContext] = React.useState(false)
  const handleOk = () => {
    handleChangeStatus(notDoneValue, reasonValue)()
    setShowNotDoneModal(false)
  }
  const handleCancel = () => {
    setReasonValue(action.reason)
    setNotDoneValue(action.status)
    setShowNotDoneModal(false)
  }
  const handleToggleViewContext = () => setViewContext(!viewContext)

  const handleSaveVideo = (links: { embedUrl: string; shareUrl: string }) => {
    const quillLength = quill?.getLength()
    const delta = {
      ops: [{ retain: quillLength }, { insert: 'Video', attributes: { link: links.shareUrl } }],
    }
    quill?.updateContents(delta, 'user')
  }

  return (
    <>
      <Modal
        title={<RichText htmlString={action.description} />}
        okText="Mark as Not Done"
        okButtonProps={{
          disabled:
            !(notDoneValue === 'NOT_DONE_REGRETTABLE' || notDoneValue === 'NOT_DONE_NOT_REGRETTABLE') || !reasonValue,
        }}
        onOk={handleOk}
        onCancel={handleCancel}
        visible={showNotDoneModal}
      >
        <ParagraphText marginTop={0}>What blocked you from getting this action done?</ParagraphText>
        <Input.TextArea
          data-cy="not-done-input"
          rows={4}
          placeholder="State why the action wasn’t completed."
          onChange={(e) => setReasonValue(e.target.value)}
          value={reasonValue}
        />
        <ParagraphText marginTop={2}>Do you still want to do this Action?</ParagraphText>
        <Radio.Group onChange={(e) => setNotDoneValue(e.target.value)} value={notDoneValue}>
          <Radio value="NOT_DONE_REGRETTABLE">Yes</Radio>
          <Radio value="NOT_DONE_NOT_REGRETTABLE">No</Radio>
        </Radio.Group>
      </Modal>
      <div style={{ marginTop: 5, marginBottom: 5 }}>
        <BlockIndent
          menuLoading={creatingAction || creatingComment}
          menu={
            <BlockMenu>
              {!reviewView && (
                <>
                  <ChangeParticipantsMenuItem participants={participants} onClick={handleChangeParticipant} />
                  <DeleteMenuItem onClick={handleDelete} />
                </>
              )}
              <MenuItemDivider />
              {allowAddActions ? <AddActionMenuItem onClick={() => handleAddAction()} /> : <></>}
              {allowAddComments ? <AddCommentMenuItem onClick={handleAddComment} /> : <></>}
            </BlockMenu>
          }
          alignMenu="center"
        >
          <div
            style={{
              border: `1px solid ${theme.color.lightGray}`,
              borderRadius: 3,
              backgroundColor: background === 'off-white' ? theme.color.offWhite : theme.color.backgroundWhite,
              boxShadow: '0 2px 2px rgba(0,0,0,0.05)',
            }}
          >
            {action.featurePreviewText && showContext ? (
              <div
                style={{
                  marginBottom: -8,
                  marginRight: 140,
                  fontSize: 14,
                  padding: 5,
                  paddingRight: 12,
                  color: theme.color.gray,
                  cursor: 'pointer',
                  display: 'flex',
                }}
              >
                <div style={{ width: '36px' }} />
                <div
                  style={
                    viewContext
                      ? {}
                      : {
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: '350px',
                        }
                  }
                  onClick={handleToggleViewContext}
                >
                  <strong>{_.startCase(action.featureType.toLowerCase())}</strong> {action.featurePreviewText}
                </div>
                {company ? <div style={{ marginLeft: 8 }}>{company.name}</div> : null}

                {action.featureType === 'issue' ? (
                  <a
                    target="_blank"
                    href={`/issues/${action.featureId}`}
                    style={{
                      textDecoration: 'underline',
                      marginLeft: 8,
                      whiteSpace: 'nowrap',
                      color: theme.color.silver,
                    }}
                  >
                    [issue]
                  </a>
                ) : action.meetingIds?.includes(selectedMeeting?.id) ? (
                  <a
                    target="_blank"
                    href={`/meetings/${selectedMeeting.id}`}
                    style={{
                      textDecoration: 'underline',
                      marginLeft: 8,
                      whiteSpace: 'nowrap',
                      color: theme.color.silver,
                    }}
                  >
                    [{selectedMeeting?.title || 'meeting'}]
                  </a>
                ) : null}
                <div style={{ marginLeft: 8 }}>{format(action.createdAt, 'M/d')}</div>
              </div>
            ) : null}
            <div
              style={{
                display: 'flex',
              }}
            >
              <div
                data-cy="action-checkbox"
                style={{
                  padding: '0 4px',
                  marginLeft: 4,
                  alignSelf: 'stretch',
                  display: 'flex',
                  alignItems: 'center',
                  width: '32px',
                }}
              >
                {checkboxComponent}
              </div>
              <div style={{ flex: 1, minWidth: 0 }}>
                <div style={{ display: 'flex' }}>
                  <div style={{ flex: 1 }}>
                    <Editable
                      setParentQuill={setQuill}
                      prefix={<BlockAccountLink id={action.assignedTo}>{firstName}</BlockAccountLink>}
                      html={action.description}
                      editable={!reviewView}
                      focusOnNew={
                        action.accountId === activeAccount.id &&
                        action.assignedTo === activeAccount.id &&
                        differenceInSeconds(new Date(), action.createdAt) < 15
                      }
                      onChange={handleDescriptionChange}
                      onAddComment={allowAddComments ? handleAddComment : undefined}
                      onAddAction={allowAddActions ? (description) => handleAddAction(description) : undefined}
                      padding={8}
                      style={
                        action.status !== 'CREATED'
                          ? { textDecoration: 'line-through', color: theme.color.darkGray }
                          : {}
                      }
                      onDelete={handleDelete}
                    />
                  </div>
                  {action.accountId === activeAccount.id ? (
                    <RecordVideoButton onComplete={handleSaveVideo}>
                      <VideoOutlineIcon style={{ cursor: 'pointer', marginTop: 8 }} />
                    </RecordVideoButton>
                  ) : null}
                  {action.status === 'CREATED' ? (
                    <div style={{ padding: '7px 9px', display: 'flex', alignItems: 'center', color: theme.color.coal }}>
                      <Button
                        data-cy="not-done-button"
                        onClick={() => setShowNotDoneModal(!reviewView)}
                        size="small"
                        style={{ backgroundColor: 'transparent', color: theme.color.darkGray }}
                      >
                        Not Done
                      </Button>
                    </div>
                  ) : null}

                  <Dropdown
                    overlay={reviewView ? <></> : editDueMenu}
                    trigger={['click']}
                    visible={showEditDue}
                    onVisibleChange={(visible) => setShowEditDue(visible)}
                  >
                    <div
                      data-cy="action-calendar-dropdown"
                      css={(theme: any) => css`
                        padding: 7px 9px;
                        display: flex;
                        align-items: center;
                        color: ${isPast(action.dueAt) ? theme.color.red : theme.color.gray};
                        cursor: pointer;

                        &:hover {
                          background: rgba(0, 0, 0, 0.02);
                        }
                      `}
                    >
                      {format(action.dueAt || new Date(), 'M/d')} {!isPast(action.dueAt) && <ChevronDownIcon />}
                    </div>
                  </Dropdown>
                </div>

                {action.reason ? (
                  <div
                    style={{
                      color: theme.color.gray,
                      marginTop: -11,
                      marginRight: 140,
                      fontSize: 14,
                      padding: 5,
                    }}
                  >
                    <strong>Reason</strong> {action.reason}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </BlockIndent>
      </div>
      {blocks.length ? (
        <BlockIndent>
          {blocks.map((block) => (
            <BlockResolver key={block.id} block={block} />
          ))}
        </BlockIndent>
      ) : null}
    </>
  )
}

export default ActionsBlockItem
