import { Middleware } from 'redux'
import mixpanel from 'mixpanel-browser'

import { actions as accountsActions, actionTypes as accountsActionTypes } from '../accounts'
import { actions as companiesActions, actionTypes as companiesActionTypes } from '../companies'

const middleware: Middleware = () => {
  return (next) => async (action: accountsActions | companiesActions) => {
    next(action)

    mixpanel.track(action.type, action)

    switch (action.type) {
      case accountsActionTypes.SET_ACTIVE: {
        mixpanel.identify(action.account.id)
        mixpanel.people.set({
          $email: action.account.email,
          id: action.account.id,
        })
        break
      }
      case companiesActionTypes.SET_ACTIVE: {
        mixpanel.add_group('companyId', action.companyId)
        break
      }
      default:
        break
    }
  }
}

export default middleware
