import React, { useState } from 'react'
import { notification } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import BlockResolver from './BlockResolver'
import { Account } from '@mm/backend/accounts/model'
import { Meeting } from '@mm/backend/meetings/model'
import { Feedback } from '@mm/backend/feedback/model'
import { Block as ActionsBlockItemType } from './ActionsBlockItem'
import { Block as CommentsBlockItemType } from './CommentsBlockItem'
import { actions as feedbackActions, selectors as feedbackSelectors } from '../../feedback'
import { actions as actionsActions } from '../../actions'
import { actions as commentsActions } from '../../comments'
import { selectors as accountsSelectors } from '../../accounts/store'
import { selectors as updateSelectors } from '../../updates/store'
import Editable from './Editable'
import BlockIndent from './BlockIndent'
import BlockAccountLink from './BlockAccountLink'
import BlockMenu, {
  DeleteMenuItem,
  MenuItemDivider,
  AddActionMenuItem,
  AddCommentMenuItem,
  SwapParticipantsMenuItem,
} from './BlockMenu'
import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { differenceInSeconds } from 'date-fns'
import CommentPlusOutlineIcon from '@icons/material/CommentPlusOutlineIcon'
import PlaylistPlusIcon from '@icons/material/PlaylistPlusIcon'
import VideoOutlineIcon from '@icons/material/VideoOutlineIcon'
import { FlexRow, Div, RecordVideoButton } from '../../interface'
import useScopedCreateActionLoadingState from '../../actions/hooks/useScopedCreateActionLoadingState'
import useScopedCreateCommentLoadingState from '../../comments/hooks/useScopedCreateCommentLoadingState'
import TooltipIconButton from './TooltipIconButton'
import { AccountsSelector } from '../../accounts'
import { getActionDueDate } from '../utils/meetingInfoHelpers'

export const TYPE = 'feedbackItem'
let Quill: any // eslint-disable-line

export interface Block {
  type: typeof TYPE
  id: string
  firstName: string
  recipientFirstName: string
  activeAccount: Account
  feedback: Feedback
  meeting: Meeting | null
  reviewView?: boolean
  blocks: Array<ActionsBlockItemType | CommentsBlockItemType>
}

interface CheckboxProps {
  checked: boolean
  label: string
  onChange: (change: boolean) => void
}

const ControlledCheckbox: React.FC<CheckboxProps> = ({ checked, onChange, label }) => {
  const handleChange = () => {
    onChange(!checked)
  }

  return (
    <label>
      <input
        data-cy="display-to-input"
        style={{ marginRight: 8 }}
        type="checkbox"
        checked={checked}
        onChange={handleChange}
      />
      {label}
    </label>
  )
}

export const FeedbackBlockItem = ({
  firstName,
  recipientFirstName,
  feedback,
  activeAccount,
  meeting,
  reviewView = false,
  blocks = [],
}: Block) => {
  const dispatch = useDispatch()
  const accounts = Object.values(useSelector(accountsSelectors.getAccounts()))
  const [actionLoading, setActionLoading] = useScopedCreateActionLoadingState()
  const [commentLoading, setCommentLoading] = useScopedCreateCommentLoadingState()
  const feedbackStore = useSelector(feedbackSelectors.get())
  const updateView = feedback.updateId && !feedback.meetingId
  const editUpdateId = updateView ? feedback.updateId : ''
  const activeUpdate = useSelector(updateSelectors.getUpdateById(feedback.updateId || ''))
  const [likeQuill, setLikeQuill] = useState<typeof Quill>()
  const [wishQuill, setWishQuill] = useState<typeof Quill>()

  const handleDescriptionChange = (key: 'likeText' | 'wishThatText') => (value: string) => {
    if (!reviewView) {
      dispatch(feedbackActions.edit({ id: feedback.id, [key]: value }))
    }
  }

  const handleDelete = () => {
    if (blocks.length) {
      Modal.confirm({
        title: 'Are you sure delete this feedback?',
        icon: <ExclamationCircleOutlined />,
        content: 'It may delete any comments or actions attached to it.',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: () => {
          dispatch(feedbackActions.delete({ id: feedback.id, updateId: feedback.updateId || '' }))
        },
      })
    } else {
      dispatch(feedbackActions.delete({ id: feedback.id, updateId: feedback.updateId || '' }))
    }
  }

  const handleToggleVisibility = (visibleToRecipient: boolean) => {
    dispatch(feedbackActions.edit({ id: feedback.id, visibleToRecipient, optimistic: true }))
  }

  const handleSwap = () => {
    dispatch(
      feedbackActions.edit({
        id: feedback.id,
        accountId: feedback.recipientId,
        recipientId: feedback.accountId,
        optimistic: true,
      }),
    )
  }

  const handleRecipientChange = async (recipientId: string) => {
    if (activeUpdate) {
      for (const feedbackId of activeUpdate.feedbackIds) {
        const feedback = feedbackStore[feedbackId]
        if (feedback.recipientId === recipientId) {
          notification.error({
            message: 'Can not create multiple feebacks for the same recipient',
          })
          return
        }
      }
    }

    dispatch(feedbackActions.edit({ id: feedback.id, recipientId, optimistic: true, updateId: editUpdateId }))
  }

  const handleAddAction = (description = '') => {
    if (meeting) {
      setActionLoading(true)
      dispatch(
        actionsActions.createRequest({
          description,
          accountId: activeAccount.id,
          assignedTo: activeAccount.id,
          featureId: feedback.id,
          featureType: 'feedback',
          featurePreviewText: description || `Like: ${feedback.likeText}.\nWish that: ${feedback.wishThatText}.`,
          meetingIds: [meeting.id],
          groupIds: [meeting.groupId],
          participantIds: meeting.participantIds,
          dueAt: getActionDueDate(meeting),
        }),
      )
    }
  }

  const handleAddComment = () => {
    if (meeting) {
      setCommentLoading(true)
      dispatch(
        commentsActions.createRequest({
          description: '',
          meetingId: meeting.id,
          parentId: feedback.id,
        }),
      )
    }
  }

  const isRecepient = activeAccount.id === feedback.recipientId
  const isAuthor = activeAccount.id === feedback.accountId

  const handleSaveVideo = (quill: typeof Quill, links: { embedUrl: string; shareUrl: string }) => {
    const quillLength = quill?.getLength()
    const delta = {
      ops: [{ retain: quillLength }, { insert: 'Video', attributes: { link: links.shareUrl } }],
    }
    quill?.updateContents(delta, 'user')
  }

  return (
    <>
      <BlockIndent
        menuLoading={actionLoading || commentLoading}
        menu={
          <BlockMenu>
            {!reviewView && (
              <>
                <DeleteMenuItem onClick={handleDelete} />
                <SwapParticipantsMenuItem onClick={handleSwap} />
              </>
            )}
            <MenuItemDivider />
            <AddActionMenuItem onClick={() => handleAddAction()} />
            <AddCommentMenuItem onClick={handleAddComment} />
          </BlockMenu>
        }
      >
        <FlexRow paddingVertical={0.5} gap={0.5}>
          <Div style={{ flex: 1 }}>
            <BlockAccountLink id={feedback.accountId}>{firstName}</BlockAccountLink> for{' '}
            {feedback.recipientId ? (
              <BlockAccountLink id={feedback.recipientId}>{recipientFirstName}</BlockAccountLink>
            ) : (
              ''
            )}
          </Div>

          <TooltipIconButton
            title="Add Action"
            loading={actionLoading}
            Icon={PlaylistPlusIcon}
            onClick={() => handleAddAction()}
          />
          <TooltipIconButton
            title="Add Comment"
            loading={commentLoading}
            Icon={CommentPlusOutlineIcon}
            onClick={() => handleAddComment()}
          />
        </FlexRow>

        <div style={{ borderLeft: '3px solid #ddd', padding: '7px 10px', background: '#fafafa' }}>
          {updateView && (
            <Div marginTop={1} marginBottom={1}>
              <AccountsSelector
                width={150}
                disabled={reviewView}
                onChange={(id: string) => handleRecipientChange(id || '')}
                allowClear={false}
                firstNameOnly
                value={feedback.recipientId}
                excludeIds={accounts
                  .filter((account) => !meeting?.participantIds.includes(account.id))
                  .map((account) => account.id)}
              />
            </Div>
          )}
          <Div style={{ display: 'flex' }}>
            <Div style={{ flex: 1 }}>
              <Editable
                setParentQuill={setLikeQuill}
                prefix={<strong>Like</strong>}
                focusOnNew={
                  feedback.accountId === activeAccount.id && differenceInSeconds(new Date(), feedback.createdAt) < 15
                }
                html={isRecepient && feedback.visibleToRecipient === false ? '[hidden]' : feedback.likeText}
                onChange={handleDescriptionChange('likeText')}
                onAddComment={handleAddComment}
                onAddAction={(description) => handleAddAction(description)}
                onDelete={handleDelete}
              />
            </Div>
            {isAuthor ? (
              <RecordVideoButton onComplete={(links) => handleSaveVideo(likeQuill, links)}>
                <VideoOutlineIcon style={{ cursor: 'pointer' }} />
              </RecordVideoButton>
            ) : null}
          </Div>
          <Div style={{ display: 'flex' }}>
            <Div style={{ flex: 1 }}>
              <Editable
                setParentQuill={setWishQuill}
                prefix={<strong>Wish that</strong>}
                html={isRecepient && feedback.visibleToRecipient === false ? '[hidden]' : feedback.wishThatText}
                onChange={handleDescriptionChange('wishThatText')}
                onAddComment={handleAddComment}
                onAddAction={(description) => handleAddAction(description)}
              />
            </Div>
            {isAuthor ? (
              <RecordVideoButton onComplete={(links) => handleSaveVideo(wishQuill, links)}>
                <VideoOutlineIcon style={{ cursor: 'pointer' }} />
              </RecordVideoButton>
            ) : null}
          </Div>
          {!isRecepient && isAuthor && feedback.recipientId && recipientFirstName ? (
            <ControlledCheckbox
              checked={feedback.visibleToRecipient !== false}
              label={`Display to ${recipientFirstName}`}
              onChange={handleToggleVisibility}
            />
          ) : null}
        </div>
      </BlockIndent>
      <BlockIndent>
        {blocks.map((block) => (
          <BlockResolver key={block.id} block={block} />
        ))}
      </BlockIndent>
    </>
  )
}

export default FeedbackBlockItem
