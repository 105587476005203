import { Middleware } from 'redux'
import _ from 'lodash'
import firebase, { getRefFormatByIds, listenForNewUpdates, inflateDates } from '../firebase'
import { actionTypes, actions } from './store'
import { actions as groupsActions } from '../groups'
import { actions as issueActions, actionTypes as issuesActionTypes } from '../issues'
import {
  actionTypes as meetingActionTypes,
  actions as meetingActions,
  selectors as meetingsSelectors,
} from '../meetings'
import { actionTypes as topicsActionTypes, actions as topicsActions } from '../topics'
import { Action, dateKeys } from '@mm/backend/actions/model'
import { ReqBody as CreateReqBody, ResBody as CreateResBody } from '../../pages/api/actions/create'
import { ReqBody as UpdateReqBody, ResBody as UpdateResBody } from '../../pages/api/actions/update'
import { ReqBody as DeleteReqBody, ResBody as DeleteResBody } from '../../pages/api/actions/delete'
import { addDays } from 'date-fns'
import { actions as accountActions, actionTypes as accountActionTypes } from '../accounts'
import { captureException } from '../sentry'
import axios from 'axios'
import { notification } from 'antd'
import { v4 as uuid } from 'uuid'

// Key listeners based off meeting.id so that expanding other meetings
// will not remove listeners from the main meeting that is loaded.
const listeners: Record<string, Array<() => void>> = {}

const middleware: Middleware = ({ dispatch, getState }) => {
  return (next) => async (
    action: actions | groupsActions | accountActions | meetingActions | issueActions | topicsActions,
  ) => {
    next(action)

    switch (action.type) {
      case actionTypes.CREATE_REQUEST: {
        try {
          const res = await axios.post<CreateResBody, CreateReqBody>('/api/actions/create', {
            description: action.description,
            accountId: action.accountId,
            assignedTo: action.assignedTo,
            featureId: action.featureId,
            featureType: action.featureType,
            featurePreviewText: action.featurePreviewText,
            meetingIds: action.meetingIds,
            groupIds: action.groupIds,
            participantIds: action.participantIds,
            dueAt: action.dueAt,
          })
          const newAction = inflateDates(res.data, ['createdAt', 'dueAt'])
          dispatch(actions.createSuccess({ action: newAction }))
        } catch (error) {
          if (!(error instanceof Error)) return
          console.error(error)
          dispatch(actions.createFailure({ error }))
          notification.error({
            message: 'There was an error creating a new action',
          })
        }
        break
      }

      case actionTypes.EDIT: {
        const itemId = action.id
        const changeId = uuid()
        try {
          const patch = {
            description: action.description,
            assignedTo: action.assignedTo,
            status: action.status,
            statusChangedInMeetingId: action.statusChangedInMeetingId,
            reason: action.reason,
            dueAt: action.dueAt,
          }
          if (action.optimistic) {
            dispatch(
              actions.optimisticChange({
                changeId,
                itemId,
                patch,
              }),
            )
          }
          const res = await axios.post<UpdateResBody, UpdateReqBody>('/api/actions/update', {
            id: action.id,
            ...patch,
          })
          dispatch(
            actions.optimisticCommit({
              changeId,
              item: inflateDates(res.data, dateKeys),
            }),
          )
        } catch (error) {
          console.log(error)
          dispatch(
            actions.optimisticRollback({
              itemId,
              changeId,
            }),
          )
          notification.error({
            message: 'There was an error editing an action',
          })
        }
        break
      }

      case actionTypes.DELETE: {
        try {
          await axios.post<DeleteResBody, DeleteReqBody>('/api/actions/delete', {
            id: action.id,
          })
        } catch (error) {
          console.log(error)
          notification.error({
            message: 'There was an error deleting an action',
          })
        }
        break
      }

      case issuesActionTypes.VIEW: {
        const chunks = _.chunk(action.issuesIds, 10)

        await Promise.all(
          chunks.map(async (issuesIds) => {
            const actionsRef = firebase.collection('actions').where('featureId', 'in', issuesIds)
            const topicsActions = await getRefFormatByIds<Action>(actionsRef, ['createdAt', 'dueAt'])
            dispatch(
              actions.set({
                actions: topicsActions,
              }),
            )
          }),
        )
        break
      }

      case topicsActionTypes.VIEW: {
        const chunks = _.chunk(action.topicsIds, 10)

        await Promise.all(
          chunks.map(async (topicsIds) => {
            const actionsRef = firebase.collection('actions').where('featureId', 'in', topicsIds)
            const topicsActions = await getRefFormatByIds<Action>(actionsRef, ['createdAt', 'dueAt'])
            dispatch(
              actions.set({
                actions: topicsActions,
              }),
            )
          }),
        )
        break
      }

      case meetingActionTypes.VIEW: {
        try {
          const state = getState()
          const meeting = meetingsSelectors.getMeetingById(action.id)(state)
          let meetingListeners = listeners[action.id] || []

          // This action gets fired when hot reloading on the meeting page.
          // We need to clear old listeners otherwise they will stack up.
          if (meetingListeners.length) {
            meetingListeners.forEach((detach) => detach())
            meetingListeners = []
          }

          // Create listeners for firebase based off chunks of 10 participants.
          const chunks = _.chunk(meeting.participantIds, 10)
          await Promise.all(
            chunks.map(async (participantsChunk) => {
              const actionsRef = firebase.collection('actions').where('assignedTo', 'in', participantsChunk)

              // Fetch all the documents once on view and set them.
              const actionItems = await getRefFormatByIds<Action>(actionsRef, ['createdAt', 'dueAt'])
              dispatch(actions.set({ actions: actionItems }))

              // Listen for updates from Firebase on same documents.
              const listener = listenForNewUpdates<Action>(
                actionsRef,
                (change, action) => {
                  if (change.type === 'added') {
                    dispatch(actions.add({ action }))
                  }
                  if (change.type === 'modified') {
                    dispatch(actions.change({ action }))
                  }
                  if (change.type === 'removed') {
                    dispatch(actions.remove({ action }))
                  }
                },
                ['createdAt', 'dueAt'],
              )
              meetingListeners.push(listener)
            }),
          )
          listeners[action.id] = meetingListeners
        } catch (error) {
          // When Firebase has a permissions error, catch the exception and
          // send it to Sentry.
          console.warn(error)
          captureException(error)
        }
        break
      }

      case accountActionTypes.VIEW_PROFILE: {
        try {
          let accountListener = listeners['profile'] || []

          // This action gets fired when hot reloading on the meeting page.
          // We need to clear old listeners otherwise they will stack up.
          if (accountListener.length) {
            accountListener.forEach((detach) => detach())
            accountListener = []
          }

          const actionsRef = firebase.collection('actions').where('assignedTo', '==', action.accountId)

          // Fetch all the documents once on view and set them.
          const actionItems = await getRefFormatByIds<Action>(actionsRef, ['createdAt', 'dueAt'])
          dispatch(actions.set({ actions: actionItems }))

          // Listen for updates from Firebase on same documents.
          const listener = listenForNewUpdates<Action>(
            actionsRef,
            (change, action) => {
              if (change.type === 'added') {
                dispatch(actions.add({ action }))
              }
              if (change.type === 'modified') {
                dispatch(actions.change({ action }))
              }
              if (change.type === 'removed') {
                dispatch(actions.remove({ action }))
              }
            },
            ['createdAt', 'dueAt'],
          )

          listeners['account'] = [listener]
        } catch (error) {
          // When Firebase has a permissions error, catch the exception and
          // send it to Sentry.
          console.warn(error)
          captureException(error)
        }
        break
      }
      default:
        break
    }
  }
}

export default middleware
