import _ from 'lodash'
import { Account } from '@mm/backend/accounts'
import { Issue } from '@mm/backend/issues'
import { Feedback } from '@mm/backend/feedback'
import { Meeting } from '@mm/backend/meetings'
import { Group } from '@mm/backend/groups'
import { Action } from '@mm/backend/actions'
import { addDays, addBusinessDays, addWeeks } from 'date-fns'
import { recurringMeetingMap } from '@mm/common'

interface AccountStore {
  [id: string]: Account
}

interface IssuesStore {
  [id: string]: Issue
}

interface FeedbackStore {
  [id: string]: Feedback
}

export const getAccountFirstName = (accountsStore: AccountStore, id: string) => {
  const account = accountsStore[id]
  const [firstName] = account && 'name' in account ? account.name.split(' ') : ['']
  return firstName
}

export const getIssuesIds = (issuesStore: IssuesStore, updateView: boolean, idToMatch: string) => {
  return _(issuesStore)
    .filter(({ updateId, meetingIds }) => {
      return updateView ? updateId === idToMatch : meetingIds.includes(idToMatch)
    })
    .orderBy(({ createdAt }) => createdAt)
    .map(({ id }) => id)
    .value()
}

export const getOrderedIssueIds = (ids: Array<string>, issuesStore: IssuesStore) => {
  const issues: Array<string> = []

  ids.map((id) => {
    if (issuesStore[id]) {
      issues.push(id)
    }
  })

  return issues
}

export const getFeedbackIds = (feedbackStore: FeedbackStore, updateView: boolean, idToMatch: string) => {
  return _(feedbackStore)
    .filter(({ updateId, meetingId }) => {
      return updateView ? updateId === idToMatch : meetingId === idToMatch
    })
    .orderBy(({ createdAt }) => createdAt)
    .map(({ id }) => id)
    .value()
}

export const getParticipantIdsOrdered = (group: Group, meeting: Meeting | null) => {
  return (group.participantIds || []).reduce((acc, participantId) => {
    if (participantId === meeting?.ownerAccountId) {
      return [...acc, participantId]
    }
    return [participantId, ...acc]
  }, [] as Array<string>)
}

export const getParticipants = (group: Group, accountsStore: AccountStore) => {
  return (group.participantIds || []).map((id) => ({
    name: getAccountFirstName(accountsStore, id),
    id,
  }))
}

export const getActionsPriorForParticipantId = (
  actionsArray: Array<Action>,
  meeting: Meeting | null,
  participantId: string,
  closestPreviousMeeting?: Meeting,
) => {
  return actionsArray.filter((action) => {
    return (
      action.groupIds?.includes(meeting?.groupId || '') &&
      !action.meetingIds?.includes(meeting?.id || '') &&
      action.assignedTo === participantId &&
      action.featureType !== 'action' &&
      (action.status === 'CREATED' ||
        (!!action.statusChangedInMeetingId && action.statusChangedInMeetingId === meeting?.id) ||
        (!!closestPreviousMeeting &&
          (action.createdAt > closestPreviousMeeting.startAt || action.dueAt > closestPreviousMeeting.startAt)))
    )
  })
}

export const getActionsNextForGoalId = (actionsArray: Array<Action>, goalId: string, meeting: Meeting | null) => {
  return actionsArray.filter((action) => {
    return (
      action.featureId === goalId &&
      (action.status === 'CREATED' ||
        (!!action.statusChangedInMeetingId && action.statusChangedInMeetingId === meeting?.id))
    )
  })
}

export const getActionDueDate = (meeting: Meeting | null) => {
  if (meeting && meeting.recurring) {
    if (meeting.recurring === 'daily') {
      return addBusinessDays(meeting.startAt, recurringMeetingMap[meeting.recurring])
    }

    return addWeeks(meeting.startAt, recurringMeetingMap[meeting.recurring])
  }

  return addDays(new Date(), 14)
}
