import { LoadingOutlined } from '@ant-design/icons'
import CalendarBlankOutlineIcon from '@icons/material/CalendarBlankOutlineIcon'
import {
  sharedActionsNextConfig,
  sharedActionsPriorConfig,
  sharedFeedbackConfig,
  sharedGoodThingConfig,
  sharedIssuesConfig,
  sharedTopicsConfig,
} from '@mm/common'
import { Meeting } from '@mm/backend/meetings/model'
import { Button, Spin } from 'antd'
import { format } from 'date-fns'
import _ from 'lodash'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AccountNames, selectors as accountsSelectors } from '../../accounts'
import { selectors as actionsSelectors } from '../../actions'
import { selectors as commentsSelectors } from '../../comments'
import { selectors as companiesSelectors } from '../../companies'
import { selectors as feedbackSelectors } from '../../feedback'
import { UserGoalsWithActions } from '../../goals'
import { selectors as goodThingsSelectors } from '../../goodThings'
import { selectors as groupsSelectors } from '../../groups'
import { Card, Div, FlexRow } from '../../interface'
import { actions as issuesActions, selectors as issuesSelectors } from '../../issues'
import { EditMeetingModal } from '../../meetings'
import { actions as topicsActions, selectors as topicsSelectors } from '../../topics'
import { actions, selectors } from '../store'
import {
  getAccountFirstName,
  getActionsPriorForParticipantId,
  getFeedbackIds,
  getIssuesIds,
  getOrderedIssueIds,
  getParticipantIdsOrdered,
  getParticipants,
} from '../utils/meetingInfoHelpers'
import { Block as ActionsBlockItem } from './ActionsBlockItem'
import { Block as ActionsNextBlock } from './ActionsNextBlock'
import { Block as ActionsPriorBlock } from './ActionsPriorBlock'

import BlockResolver from './BlockResolver'

import BlockTitle from './BlockTitle'
import { Block as CommentsBlock } from './CommentsBlockItem'
import { Block as FeedbackBlock } from './FeedbackBlock'
import { Block as GoodThingsBlock } from './GoodThingsBlock'
import { Block as IssuesBlock } from './IssuesBlock'
import { Block as LibraryBlock } from './LibraryBlock'
import MeetingOutline from './MeetingOutline'
import { Block as MeetingPrepBlock } from './MeetingPrepBlock'
import { Block as MeetingSummaryBlock } from './MeetingSummaryBlock'
import { Block as ParticipantBlock } from './ParticipantsBlockItem'
import { Block as TopicsBlock } from './TopicsBlock'

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

interface Props {
  id: string
  showPreviousLink?: boolean
}

type ParentBlocks =
  | LibraryBlock
  | GoodThingsBlock
  | ActionsPriorBlock
  | IssuesBlock
  | MeetingSummaryBlock
  | MeetingPrepBlock
  | TopicsBlock
  | ActionsNextBlock
  | FeedbackBlock

export const MeetingInfo: React.FC<Props> = ({ id, showPreviousLink }) => {
  const dispatch = useDispatch()
  const router = useRouter()
  const meeting = useSelector(selectors.getMeetingById(id)) || {}
  const initialize = useSelector(selectors.getInitialize())
  const group = useSelector(groupsSelectors.getGroupById(meeting.groupId)) || {}
  const activeAccount = useSelector(accountsSelectors.getActiveAccount())
  const company = useSelector(companiesSelectors.getCompanyById(group.companyId))
  const meetingsStore = useSelector(selectors.getMeetings())
  const meetingsArray = Object.values(meetingsStore)

  const closestPreviousMeeting = React.useMemo(() => {
    let innerClosestPreviousMeeting: Meeting | undefined
    meetingsArray.map((previousMeeting) => {
      if (meeting && meeting.groupId === previousMeeting.groupId && previousMeeting.startAt < meeting.startAt) {
        if (!innerClosestPreviousMeeting || previousMeeting.startAt > innerClosestPreviousMeeting.startAt) {
          innerClosestPreviousMeeting = previousMeeting
        }
      }
    })
    return innerClosestPreviousMeeting
  }, [meetingsStore, meeting])

  const accountsStore = useSelector(accountsSelectors.getAccounts())
  const actionsStore = useSelector(actionsSelectors.get())
  const actionsArray = Object.values(actionsStore)
  const actionsByFeatureId = _.groupBy(actionsArray, 'featureId')
  const commentsStore = useSelector(commentsSelectors.get())
  const commentsArray = Object.values(commentsStore)
  const commentsByParentId = _.groupBy(commentsArray, 'parentId')

  const participantIdsOrdered = getParticipantIdsOrdered(group, meeting)
  const participants = getParticipants(group, accountsStore)

  const commentsBlocksForFeatureId = (id: string, replyTo?: string) =>
    _(commentsByParentId[id] ?? [])
      .filter((comment) => comment.pathToRoot?.[0] === replyTo)
      .orderBy('createdAt')
      .map(
        (comment): CommentsBlock => ({
          type: 'comment',
          id: comment.id,
          activeAccount,
          participants,
          firstName: getAccountFirstName(accountsStore, comment.accountId),
          comment,
          meeting,
          blocks: [...actionsBlocksForFeatureId(comment.id), ...commentsBlocksForFeatureId(id, comment.id)],
        }),
      )
      .value()

  const actionsBlocksForFeatureId = (id: string, showNotDone = false, background?: 'white' | 'off-white') =>
    (actionsByFeatureId[id] ?? []).map(
      (action): ActionsBlockItem => ({
        type: 'action',
        id: action.id,
        firstName: getAccountFirstName(accountsStore, action.assignedTo),
        showNotDone,
        participants,
        activeAccount,
        meeting,
        action,
        blocks: [
          ...actionsBlocksForFeatureId(action.id, showNotDone, background),
          ...commentsBlocksForFeatureId(action.id),
        ],
        background,
      }),
    )

  const actionsFromMeeting = actionsArray.filter((action) => {
    return action.meetingIds?.includes(meeting.id)
  })

  const goodThingsStore = useSelector(goodThingsSelectors.get())
  // This will eventually come from meeting.goodThingsIds
  const goodThingsIds = _(goodThingsStore)
    .filter(({ meetingId }) => meetingId === meeting.id)
    .orderBy(({ createdAt }) => createdAt)
    .map(({ id }) => id)
    .value()

  const issuesStore = useSelector(issuesSelectors.get())
  const issuesIds = meeting.orderedIssueIds
    ? getOrderedIssueIds(meeting.orderedIssueIds, issuesStore)
    : getIssuesIds(issuesStore, false, meeting.id)

  const topicsStore = useSelector(topicsSelectors.get())
  // This will eventually come from meeting.topicsIds
  const topicsIds = meeting.orderedTopicIds
    ? meeting.orderedTopicIds.filter((id) => topicsStore[id])
    : _(topicsStore)
        .filter(({ meetingId }) => meetingId === meeting.id)
        .orderBy(({ createdAt }) => createdAt)
        .map(({ id }) => id)
        .value()

  const feedbackStore = useSelector(feedbackSelectors.get())
  const feedbackIds = getFeedbackIds(feedbackStore, false, meeting.id)

  React.useEffect(() => {
    // only fire view action once meeting has been loaded
    if (meeting.id) {
      dispatch(actions.view({ id }))
      // const canRender = meeting.id && activeAccount.id && Object.values(accountsStore).length > 1
    } else if (!meeting.id && initialize) {
      // If meetings initialized and no meeting id was found, the user does not have access to it
      // Redirect back to dashboard
      router.push('/dashboard')
    }
  }, [meeting, initialize])

  React.useEffect(() => {
    if (issuesIds.length) {
      dispatch(issuesActions.view({ issuesIds }))
    }
  }, [JSON.stringify(issuesIds)])

  React.useEffect(() => {
    if (topicsIds.length) {
      dispatch(topicsActions.view({ topicsIds }))
    }
  }, [JSON.stringify(topicsIds)])

  const meetingFlow: Array<ParentBlocks> = []

  meetingFlow.push({
    type: 'meetingPrep',
    id: 'meetingPrep',
    title: _.startCase(meeting.title),
    subtitle: format(meeting.startAt ? new Date(meeting.startAt) : new Date(), 'LLL dd yyyy, h:mm aa'),
    activeAccount,
    meeting,
    group,
    color: '#333',
  })

  meetingFlow.push({
    type: 'meetingLibrary',
    id: 'meetingLibrary',
    title: 'Library',
    subtitle: 'Store any written information that is used in every meeting.',
    activeAccount,
    meeting,
    group,
    color: '#009688',
    blocks: actionsBlocksForFeatureId(meeting.id),
  })

  meetingFlow.push({
    ...sharedGoodThingConfig,
    activeAccount,
    meetingId: meeting.id,
    blocks: goodThingsIds.map((id) => {
      const goodThing = goodThingsStore[id]

      return {
        type: 'goodThing' as 'goodThing',
        id: goodThing.id,
        activeAccount,
        participants,
        firstName: getAccountFirstName(accountsStore, goodThing.accountId),
        goodThing,
        meeting,
        blocks: [...actionsBlocksForFeatureId(goodThing.id), ...commentsBlocksForFeatureId(goodThing.id)],
      }
    }),
  })

  meetingFlow.push({
    ...sharedActionsPriorConfig,
    meetingId: meeting.id,
    blocks: participantIdsOrdered.map(
      (participantId): ParticipantBlock => ({
        type: 'participant',
        id: participantId,
        firstName: getAccountFirstName(accountsStore, participantId),
        showIfEmpty: true,
        collapsed: true,
        children: getActionsPriorForParticipantId(actionsArray, meeting, participantId, closestPreviousMeeting).map(
          (action) => (
            <BlockResolver
              key={action.id}
              block={{
                type: 'action',
                id: action.id,
                activeAccount,
                showContext: true,
                showNotDone: true,
                firstName: getAccountFirstName(accountsStore, action.assignedTo),
                participants,
                action,
                meeting,
                blocks: [...actionsBlocksForFeatureId(action.id, true), ...commentsBlocksForFeatureId(action.id)],
              }}
            />
          ),
        ),
      }),
    ),
  })

  meetingFlow.push({
    ...sharedActionsNextConfig,
    meetingId: meeting.id,
    blocks: participantIdsOrdered.map(
      (participantId): ParticipantBlock => {
        return {
          type: 'participant',
          id: participantId,
          firstName: getAccountFirstName(accountsStore, participantId),
          collapsed: true,
          children: <UserGoalsWithActions accountId={participantId} meeting={meeting} />,
        }
      },
    ),
  })

  meetingFlow.push({
    ...sharedIssuesConfig,
    meeting,
    company,
    blocks: issuesIds.map((id) => {
      const issue = issuesStore[id]

      return {
        type: 'issue',
        id: issue.id,
        activeAccount,
        participants,
        firstName: getAccountFirstName(accountsStore, issue.accountId),
        issue,
        meeting,
        blocks: [...actionsBlocksForFeatureId(issue.id)],
      }
    }),
  })

  meetingFlow.push({
    ...sharedTopicsConfig,
    meeting,
    blocks: topicsIds.map((id) => {
      const topic = topicsStore[id]

      return {
        type: 'topic',
        id: topic.id,
        activeAccount,
        participants,
        firstName: getAccountFirstName(accountsStore, topic.accountId),
        topic,
        meeting,
        blocks: [...actionsBlocksForFeatureId(topic.id), ...commentsBlocksForFeatureId(topic.id)],
      }
    }),
  })

  meetingFlow.push({
    ...sharedFeedbackConfig,
    meetingId: meeting.id,
    activeAccount,
    participants: participantIdsOrdered.map((id) => accountsStore[id]),
    blocks: feedbackIds.map((id) => {
      const feedback = feedbackStore[id]

      return {
        type: 'feedbackItem',
        id: feedback.id,
        activeAccount,
        firstName: getAccountFirstName(accountsStore, feedback.accountId),
        recipientFirstName: getAccountFirstName(accountsStore, feedback.recipientId),
        feedback,
        meeting,
        blocks: [...actionsBlocksForFeatureId(feedback.id), ...commentsBlocksForFeatureId(feedback.id)],
      }
    }),
  })

  meetingFlow.push({
    type: 'meetingSummary',
    id: 'meetingSummary',
    title: 'Summary',
    subtitle: 'Review the actions that were created during this meeting.',
    meeting,
    participants: participantIdsOrdered.map((id) => accountsStore[id]),
    actions: actionsFromMeeting,
    actionsBlocks: actionsFromMeeting.map((action) => ({
      type: 'action',
      id: action.id,
      activeAccount,
      firstName: getAccountFirstName(accountsStore, action.assignedTo),
      showContext: true,
      participants,
      action,
      meeting,
      blocks: [],
      allowAddComments: false,
      allowAddActions: false,
    })),
    color: '#26a69a',
  })

  const [visible, setVisible] = React.useState(false)

  const canRender = meeting.id && activeAccount.id && Object.values(accountsStore).length > 1
  const dateString = format(meeting.startAt ? new Date(meeting.startAt) : new Date(), 'LLL dd yyyy, h:mm aa')
  const participantsList = meeting.participantIds || []

  return canRender && Object.values(accountsStore).length ? (
    <>
      <Head>
        <title>
          {meeting.title} — {dateString} — Mochary Method
        </title>
      </Head>

      <FlexRow alignItems="top">
        <Div style={{ minWidth: 180 }}>
          <MeetingOutline items={meetingFlow} width={180} postfix={meeting.id} showPreviousLink={showPreviousLink} />
        </Div>

        <Card marginBottom={2} padding={1} id="meeting-container" style={{ minWidth: 0 }}>
          <BlockTitle
            id={`meetingTitle-${meeting.id}`}
            title={`${meeting.title}${meeting.recurring ? ` (${meeting.recurring})` : ''}`}
            subtitle={
              <>
                {dateString}
                <br />
                {'Participants: '}
                <AccountNames
                  showRole
                  profileLink
                  ids={participantsList}
                  meeting={meeting}
                  omitActiveAccount={false}
                  emptyText=""
                />
                <br />
                {meeting.conferenceUrl && (
                  <>
                    {'Video Conference Link: '}
                    <a href={meeting.conferenceUrl} target="_blank" rel="noopener noreferrer">
                      {meeting.conferenceUrl}
                    </a>
                  </>
                )}
              </>
            }
            color={'#333'}
            icon={<CalendarBlankOutlineIcon />}
            content={
              <>
                {meetingFlow.map((block) => (
                  <div key={block.id} style={{ marginBottom: 24, paddingBottom: 24, borderBottom: '1px solid #ddd' }}>
                    <BlockResolver block={block} />
                  </div>
                ))}
              </>
            }
            right={
              <Button
                data-cy="edit-meeting-button"
                style={{ background: 'none', marginRight: -20, marginTop: 10 }}
                type="dashed"
                onClick={() => setVisible(true)}
              >
                Edit Meeting
              </Button>
            }
          />
        </Card>
        <EditMeetingModal
          visible={visible}
          onComplete={() => setVisible(false)}
          onCancel={() => setVisible(false)}
          meetingId={meeting.id}
        />
      </FlexRow>
    </>
  ) : (
    <div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Spin indicator={antIcon} />
    </div>
  )
}

export default MeetingInfo
