import _ from 'lodash'
import React from 'react'
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import { ActionsBlockItem, Block as ActionsBlockItemType, TYPE as ACTIONS_ITEM_TYPE } from './ActionsBlockItem'
import { ActionsNextBlock, Block as ActionsNextBlockType, TYPE as ACTIONS_NEXT_TYPE } from './ActionsNextBlock'
import { ActionsPriorBlock, Block as ActionsPriorBlockType, TYPE as ACTIONS_PRIOR_TYPE } from './ActionsPriorBlock'
import { Block as CommentsBlockItemType, CommentsBlockItem, TYPE as COMMENTS_ITEM_TYPE } from './CommentsBlockItem'
import { Block as FeedbackBlockType, FeedbackBlock, TYPE as FEEDBACK_TYPE } from './FeedbackBlock'
import { Block as FeedbackBlockItemType, FeedbackBlockItem, TYPE as FEEDBACK_ITEM_TYPE } from './FeedbackBlockItem'
import { Block as GoalsBlockItemType, GoalsBlockItem, TYPE as GOALS_ITEM_TYPE } from './GoalsBlockItem'
import { Block as GoodThingsBlockType, GoodThingsBlock, TYPE as GOOD_THINGS_TYPE } from './GoodThingsBlock'
import {
  Block as GoodThingsBlockItemType,
  GoodThingsBlockItem,
  TYPE as GOOD_THINGS_ITEM_TYPE,
} from './GoodThingsBlockItem'
import { Block as IssuesBlockType, IssuesBlock, TYPE as ISSUES_TYPE } from './IssuesBlock'
import { Block as IssuesBlockItemType, IssuesBlockItem, TYPE as ISSUES_ITEM_TYPE } from './IssuesBlockItem'
import { Block as MeetingLibraryBlockType, LibraryBlock, TYPE as MEETING_LIBRARY_TYPE } from './LibraryBlock'
import {
  Block as MeetingSummaryBlockType,
  MeetingSummaryBlock,
  TYPE as MEETING_SUMMARY_TYPE,
} from './MeetingSummaryBlock'
import { Block as MeetingPrepBlockType, MeetingPrepBlock, TYPE as MEETING_PREP_TYPE } from './MeetingPrepBlock'
import {
  Block as ParticipantsBlockItemType,
  ParticipantsBlockItem,
  TYPE as PARTICIPANTS_ITEM_TYPE,
} from './ParticipantsBlockItem'
import { Block as TopicsBlockType, TopicsBlock, TYPE as TOPICS_TYPE } from './TopicsBlock'
import { Block as TopicsBlockItemType, TopicsBlockItem, TYPE as TOPICS_ITEM_TYPE } from './TopicsBlockItem'

type PossibleBlocks =
  | MeetingLibraryBlockType
  | ActionsBlockItemType
  | ActionsPriorBlockType
  | ActionsNextBlockType
  | CommentsBlockItemType
  | FeedbackBlockType
  | FeedbackBlockItemType
  | GoalsBlockItemType
  | GoodThingsBlockType
  | GoodThingsBlockItemType
  | IssuesBlockType
  | IssuesBlockItemType
  | MeetingSummaryBlockType
  | MeetingPrepBlockType
  | ParticipantsBlockItemType
  | TopicsBlockType
  | TopicsBlockItemType

export const BlockResolver = React.memo(function BlockResolver({
  block,
  dragHandleProps,
  allowAddVideo,
}: {
  block: PossibleBlocks
  dragHandleProps?: DraggableProvidedDragHandleProps
  allowAddVideo?: boolean
}) {
  switch (block.type) {
    case MEETING_LIBRARY_TYPE:
      return <LibraryBlock {...block} />
    case ACTIONS_ITEM_TYPE:
      return <ActionsBlockItem {...block} />
    case ACTIONS_PRIOR_TYPE:
      return <ActionsPriorBlock {...block} />
    case ACTIONS_NEXT_TYPE:
      return <ActionsNextBlock {...block} />
    case COMMENTS_ITEM_TYPE:
      return <CommentsBlockItem {...block} allowAddVideo={allowAddVideo} />
    case FEEDBACK_TYPE:
      return <FeedbackBlock {...block} />
    case FEEDBACK_ITEM_TYPE:
      return <FeedbackBlockItem {...block} />
    case GOALS_ITEM_TYPE:
      return <GoalsBlockItem {...block} />
    case GOOD_THINGS_TYPE:
      return <GoodThingsBlock {...block} />
    case GOOD_THINGS_ITEM_TYPE:
      return <GoodThingsBlockItem {...block} />
    case ISSUES_TYPE:
      return <IssuesBlock {...block} />
    case ISSUES_ITEM_TYPE:
      return <IssuesBlockItem {...block} />
    case MEETING_SUMMARY_TYPE:
      return <MeetingSummaryBlock {...block} />
    case MEETING_PREP_TYPE:
      return <MeetingPrepBlock {...block} />
    case PARTICIPANTS_ITEM_TYPE:
      return <ParticipantsBlockItem {...block} />
    case TOPICS_TYPE:
      return <TopicsBlock {...block} />
    case TOPICS_ITEM_TYPE:
      return <TopicsBlockItem {...block} dragHandleProps={dragHandleProps} />
    default:
      return null
  }
},
_.isEqual)

export default BlockResolver
