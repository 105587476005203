import React from 'react'
import _ from 'lodash'
import { format } from 'date-fns'
import { useSelector } from 'react-redux'
import { Meeting } from '@mm/backend/meetings/model'
import { selectors } from '../store'
import { selectors as groupsSelectors } from '../../groups'
import MeetingInfo from './MeetingInfo'
import { Card, FlexRow, Text } from '../../interface'

interface Props {
  getGroupFromMeetingId?: string
  excludeId?: string
}

const MeetingPreview: React.FC<Meeting> = ({ id, title, startAt }) => {
  const [show, setShow] = React.useState(false)
  return (
    <>
      {show ? (
        <MeetingInfo id={id} />
      ) : (
        <Card padding={1} marginBottom={0.5} style={{ cursor: 'pointer' }} onClick={() => setShow(!show)}>
          <FlexRow>
            <Text weight="heavy" color="dark" style={{ flex: 1 }}>
              {title}
            </Text>
            <Text style={{ flex: 3 }}>{format(startAt, 'LLL dd yyyy, h:mm aa')}</Text>
          </FlexRow>
        </Card>
      )}
    </>
  )
}

export const PreviousMeetings: React.FC<Props> = ({ getGroupFromMeetingId = '', excludeId }) => {
  const meetingToGetGroup = useSelector(selectors.getMeetingById(getGroupFromMeetingId)) || {}
  const meetingsStore = useSelector(selectors.getMeetings())
  const group = useSelector(groupsSelectors.getGroupById(meetingToGetGroup.groupId))

  const meetings = _(meetingsStore)
    .filter((meeting) => group && group.id === meeting.groupId && meeting.id !== excludeId)
    .orderBy((meeting) => meeting.startAt, 'desc')
    .value()

  return (
    <div style={{ paddingBottom: 50 }}>
      {meetings.length ? (
        <h2 style={{ fontSize: 20 }} id={`previousHeader-${getGroupFromMeetingId}`}>
          Previous Meetings
        </h2>
      ) : null}
      {meetings.map((meeting) => (
        <MeetingPreview key={meeting.id} {...meeting} />
      ))}
    </div>
  )
}

export default PreviousMeetings
