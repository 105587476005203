import React, { useState } from 'react'
import { standardActions, StandardActions } from './standardActions'
import { ToolbarAction } from './ToolbarAction'
import { ToolbarButton } from './ToolbarButton'
import { Editor } from '@tiptap/core'
import { ToolbarContainer } from './ToolbarContainer'
import { DropdownMenu, DropdownMenuButton } from './DropdownMenu'
import { BubbleMenu } from './BubbleMenu'

export type ToolbarItem = keyof StandardActions | ToolbarAction

export type SelectionToolbarProps = {
  editor: Editor
  items?: readonly ToolbarItem[]
  toolbarCallbackParam?: any
}

export const defaultItems: readonly ToolbarItem[] = [
  'block',
  'bold',
  'italic',
  'underline',
  'link',
  'bullet-list',
  'ordered-list',
  'blockquote',
]

export function SelectionToolbar({
  editor,
  items = defaultItems,
  toolbarCallbackParam,
}: SelectionToolbarProps): React.ReactElement {
  const [activeMenu, setActiveMenu] = useState(-1)
  const actions = items
    .map((item) => (typeof item === 'string' ? standardActions[item] : item))
    .map((factory) => factory(editor))
  const hasVisibleActions = actions.some((action) => !action.hidden)
  if (!hasVisibleActions) {
    return <></>
  }
  return (
    <BubbleMenu editor={editor} onHide={() => setActiveMenu(-1)}>
      <ToolbarContainer>
        {actions.map((action, index) =>
          action.hidden ? null : (
            <ToolbarButton
              key={index}
              onClick={(e) => {
                e.preventDefault()
                action.onClick?.(toolbarCallbackParam)
                if (action.options != null) {
                  setActiveMenu((activeIdx) => (activeIdx === index ? -1 : index))
                  editor.commands.focus()
                }
              }}
              active={action.isActive}
              disabled={action.disabled}
              menu={
                action.options != null && (
                  <DropdownMenu visible={activeMenu === index}>
                    {action.options.map((option, index) => (
                      <DropdownMenuButton
                        key={index}
                        onClick={() => {
                          setActiveMenu(-1)
                          option.onClick?.()
                        }}
                        disabled={option.disabled}
                      >
                        {option.label}
                      </DropdownMenuButton>
                    ))}
                  </DropdownMenu>
                )
              }
            >
              {action.label}
            </ToolbarButton>
          ),
        )}
      </ToolbarContainer>
    </BubbleMenu>
  )
}
