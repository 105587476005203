import { useQuery } from '@apollo/client'
import { Spin } from 'antd'
import React from 'react'
import InfiniteScroller from 'react-infinite-scroller'
import { Div, FlexRow, Text } from '../../interface'
import { IssueListTableDocument } from '../../../gen/graphql/documents'
import { IssueListTableRow } from './IssueListTableRow'
import Fuse from 'fuse.js'

interface Props {
  companyId?: string | null
  search?: string
}

export const IssueListTable: React.FC<Props> = ({ companyId, search }) => {
  const { data, loading, fetchMore } = useQuery(IssueListTableDocument, {
    variables: {
      companyId,
    },
  })
  const issues = data?.issues

  if (issues == null) {
    if (loading) {
      return (
        <Div style={{ minHeight: 600 }}>
          <FlexRow paddingVertical={1} gap={1}>
            <Text color="light" style={{ flex: 4 }}>
              Title
            </Text>

            <Text color="light" style={{ flex: 1 }}>
              Created
            </Text>

            <Text color="light" style={{ flex: 1 }}>
              Status
            </Text>

            <Text color="light" style={{ flex: 1 }}>
              Proposer
            </Text>

            <Text color="light" style={{ flex: 1 }}>
              Decision Maker
            </Text>

            <Text color="light" style={{ flex: 1 }}>
              Due Date
            </Text>

            <Div style={{ width: 24 }} />
          </FlexRow>

          <FlexRow paddingVertical={1} style={{ borderTop: '1px solid #eee' }} gap={2}>
            <Div height={1} background="background" style={{ flex: 4 }} />
            <Div height={1} background="background" style={{ flex: 1 }} />
            <Div height={1} background="background" style={{ flex: 1 }} />
            <Div height={1} background="background" style={{ flex: 1 }} />
            <Div height={1} background="background" style={{ flex: 1 }} />
            <Div height={1} background="background" style={{ flex: 1 }} />
            <Div style={{ width: 24 }} />
          </FlexRow>

          <FlexRow paddingVertical={1} style={{ borderTop: '1px solid #eee' }} gap={2}>
            <Div height={1} background="background" style={{ flex: 4 }} />
            <Div height={1} background="background" style={{ flex: 1 }} />
            <Div height={1} background="background" style={{ flex: 1 }} />
            <Div height={1} background="background" style={{ flex: 1 }} />
            <Div height={1} background="background" style={{ flex: 1 }} />
            <Div height={1} background="background" style={{ flex: 1 }} />
            <Div style={{ width: 24 }} />
          </FlexRow>

          <FlexRow paddingVertical={1} style={{ borderTop: '1px solid #eee' }} gap={2}>
            <Div height={1} background="background" style={{ flex: 4 }} />
            <Div height={1} background="background" style={{ flex: 1 }} />
            <Div height={1} background="background" style={{ flex: 1 }} />
            <Div height={1} background="background" style={{ flex: 1 }} />
            <Div height={1} background="background" style={{ flex: 1 }} />
            <Div height={1} background="background" style={{ flex: 1 }} />
            <Div style={{ width: 24 }} />
          </FlexRow>
          <FlexRow justifyContent="center" vertical={1}>
            <Spin />
          </FlexRow>
        </Div>
      )
    } else {
      return <>Failed to load issues</>
    }
  }

  const nodes = issues.edges.map(({ node }) => node)
  const fuse = new Fuse(nodes, {
    keys: ['title'],
    shouldSort: false,
    ignoreLocation: true,
    threshold: 0.5,
  })
  const results = search ? fuse.search(search) : nodes

  return issues.edges.length ? (
    <Div style={{ minHeight: 600 }}>
      <InfiniteScroller
        loadMore={() =>
          fetchMore({
            variables: {
              after: issues.pageInfo.endCursor,
            },
          })
        }
        hasMore={issues.pageInfo.hasNextPage}
        loader={
          <FlexRow justifyContent="center">
            <Spin />
          </FlexRow>
        }
      >
        <FlexRow paddingVertical={1} gap={1}>
          <Text color="light" style={{ flex: 4 }}>
            Title
          </Text>

          <Text color="light" style={{ flex: 1 }}>
            Created
          </Text>

          <Text color="light" style={{ flex: 1 }}>
            Status
          </Text>

          <Text color="light" style={{ flex: 1 }}>
            Proposer
          </Text>

          <Text color="light" style={{ flex: 1 }}>
            Decision Maker
          </Text>

          <Text color="light" style={{ flex: 1 }}>
            Due Date
          </Text>

          <Div style={{ width: 24 }} />
        </FlexRow>

        {results.map((node) => (
          <IssueListTableRow key={node.id} issueId={node.id} />
        ))}
      </InfiniteScroller>
    </Div>
  ) : (
    <FlexRow style={{ height: 160, border: '1px solid #eee', borderRadius: 4 }} justifyContent="center">
      <Text color="light">No issues yet.</Text>
    </FlexRow>
  )
}

export default IssueListTable
