import { Reducer } from 'redux'
import { Meeting } from '@mm/backend/meetings/model'
import { actionTypes as authActionTypes, actions as authActions } from '../auth'

// TODO: Come up with better name. Key? SelectorKey? StateKey
export const selector = 'meetings'

export const actionTypes = {
  VIEW: 'meetings/VIEW',
  CREATE_REQUEST: 'meetings/CREATE_REQUEST',
  CREATE_SUCCESS: 'meetings/CREATE_SUCCESS',
  CREATE_FAILURE: 'meetings/CREATE_FAILURE',
  UPDATE_REQUEST: 'meetings/UPDATE_REQUEST',
  UPDATE_SUCCESS: 'meetings/UPDATE_SUCCESS',
  UPDATE_FAILURE: 'meetings/UPDATE_FAILURE',
  DELETE: 'meetings/DELETE',
  SET: 'meetings/SET',
  ADD: 'meetings/ADD',
  REMOVE: 'meetings/REMOVE',
  CLOSE: 'meetings/CLOSE',
  FETCH_UPDATE: 'meetings/FETCH_UPDATE',
  REGENERATE_UPDATES: 'meetings/REGENERATE_UPDATE',
} as const

interface Participant {
  id: string
  role: string
}

type CreateRequestAction = Pick<
  Meeting,
  'title' | 'startAt' | 'endAt' | 'recurring' | 'conferenceUrl' | 'companyId' | 'groupId'
> & {
  participants: Array<Participant>
  syncWithGCal: boolean
}

interface CreateSuccessAction {
  meeting: Meeting
}

interface ViewAction {
  id: string
  updateId?: string
}

interface CreateFailureAction {
  error: Error
}

interface UpdateRequestAction {
  meeting: Partial<
    Pick<
      Meeting,
      | 'title'
      | 'startAt'
      | 'endAt'
      | 'complete'
      | 'groupId'
      | 'companyId'
      | 'contributedToByIds'
      | 'ownerAccountId'
      | 'updates'
      | 'recurring'
      | 'orderedTopicIds'
      | 'participants'
      | 'conferenceUrl'
    >
  > &
    Pick<Meeting, 'id'>
}

interface UpdateSuccessAction {
  meeting: Meeting
}

interface UpdateFailureAction {
  error: Error
}

type DeleteAction = Pick<Meeting, 'id'>

interface SetAction {
  meetings: Record<string, Meeting>
}

interface AddAction {
  meeting: Meeting
}

interface RemoveAction {
  meeting: Meeting
}
interface CloseAction {
  meeting: Meeting
}

interface RegenerateUpdatesAction {
  meetingId: string
}

export const actions = {
  view: ({ id, updateId }: ViewAction) => ({
    type: actionTypes.VIEW,
    id,
    updateId,
  }),
  createRequest: ({
    title,
    startAt,
    endAt,
    participants,
    companyId,
    groupId,
    recurring,
    conferenceUrl,
    syncWithGCal = false,
  }: CreateRequestAction) => ({
    type: actionTypes.CREATE_REQUEST,
    title,
    startAt,
    endAt,
    participants,
    companyId,
    groupId,
    recurring,
    conferenceUrl,
    syncWithGCal,
  }),
  createSuccess: ({ meeting }: CreateSuccessAction) => ({
    type: actionTypes.CREATE_SUCCESS,
    meeting,
  }),
  createFailure: ({ error }: CreateFailureAction) => ({
    type: actionTypes.CREATE_FAILURE,
    error,
  }),
  updateRequest: ({ meeting }: UpdateRequestAction) => ({
    type: actionTypes.UPDATE_REQUEST,
    meeting,
  }),
  updateSuccess: ({ meeting }: UpdateSuccessAction) => ({
    type: actionTypes.UPDATE_SUCCESS,
    meeting,
  }),
  updateFailure: ({ error }: UpdateFailureAction) => ({
    type: actionTypes.UPDATE_FAILURE,
    error,
  }),
  delete: ({ id }: DeleteAction) => ({
    type: actionTypes.DELETE,
    id,
  }),
  set: ({ meetings }: SetAction) => ({
    type: actionTypes.SET,
    meetings,
  }),
  add: ({ meeting }: AddAction) => ({
    type: actionTypes.ADD,
    meeting,
  }),
  remove: ({ meeting }: RemoveAction) => ({
    type: actionTypes.REMOVE,
    meeting,
  }),
  close: ({ meeting }: CloseAction) => ({
    type: actionTypes.CLOSE,
    meeting,
  }),
  regenerateUpdates: ({ meetingId }: RegenerateUpdatesAction) => ({
    type: actionTypes.REGENERATE_UPDATES,
    meetingId,
  }),
}

export type actions = ReturnType<typeof actions[keyof typeof actions]>

interface State {
  initialize: boolean
  byId: { [id: string]: Meeting }
  creating: boolean
}

const initialState: State = {
  initialize: false,
  byId: {},
  creating: false,
}

export const reducer: Reducer<State, actions | authActions> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET: {
      return {
        initialize: true,
        creating: false,
        byId: {
          ...state.byId,
          ...action.meetings,
        },
      }
    }
    case actionTypes.CREATE_REQUEST: {
      return {
        ...state,
        creating: true,
      }
    }
    case actionTypes.CREATE_FAILURE:
    case actionTypes.CREATE_SUCCESS: {
      return {
        ...state,
        creating: false,
      }
    }
    case actionTypes.ADD:
    case actionTypes.UPDATE_SUCCESS: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.meeting.id]: action.meeting,
        },
      }
    }
    case actionTypes.UPDATE_REQUEST: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.meeting.id]: {
            ...state.byId[action.meeting.id],
            ...action.meeting,
          },
        },
      }
    }
    case actionTypes.REMOVE: {
      // TODO: Change to delete object key
      // delete state.byId[action.meeting.id]
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [action.meeting.id]: _meetingToDelete, ...restMeetings } = state.byId
      return {
        ...state,
        byId: restMeetings,
      }
    }
    case authActionTypes.LOG_OUT:
      return initialState
    default:
      return state
  }
}

export const selectors = {
  getInitialize: () => (state: Record<typeof selector, State>) => state[selector].initialize,
  getMeetings: () => (state: Record<typeof selector, State>) => state[selector].byId,
  getMeetingById: (id: string) => (state: Record<typeof selector, State>) => state[selector].byId[id],
  getCreating: () => (state: Record<typeof selector, State>) => state[selector].creating,
}
