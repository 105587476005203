import { ExclamationCircleOutlined } from '@ant-design/icons'
import { AccountIntegrationTypes, IntegrationStatus, IntegrationTypes } from '@mm/backend/integrations/model'
import { Avatar, Button, List, Modal } from 'antd'
import Router, { useRouter } from 'next/router'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CalendarIntegrations } from '../../calendars'
import { useFeatureFlags } from '../../featureFlags'
import { FlexColumn } from '../../interface'
import { useAccountLinkStatus } from '../../slack/hooks'
import { AsanaModal, MondayModal } from '../index'
import { actions, selectors } from '../store'

interface ItemType {
  id: string
  title: string
  description: string
  logo: string
  connect: () => void
  disconnect?: () => void
  isConnected: boolean
  isLoading: boolean
}

export const IntegrationList: React.FC = () => {
  const dispatch = useDispatch()
  const { query } = useRouter()

  // Monday Modal
  const [openMondayModal, setOpenMondayModal] = React.useState(false)
  const closeMondayModal = () => setOpenMondayModal(false)

  // Asana Modal
  const [openAsanaModal, setOpenAsanaModal] = React.useState(false)
  const closeAsanaModal = () => setOpenAsanaModal(false)
  React.useEffect(() => {
    // Open Asana modal if it's in the middle of setup
    if ('asana' in query && query.asana === 'setup-required') setOpenAsanaModal(true)
  }, [query])

  const { gcalV2 = false } = useFeatureFlags()

  // Helper functions
  const isIntegrationLoading = (integrationType: IntegrationTypes) => {
    return useSelector(selectors.isLoading(integrationType))
  }
  const isIntegrationConnected = (integrationType: IntegrationTypes) => {
    return useSelector(selectors.getStatus(integrationType)) === IntegrationStatus.CONNECTED
  }

  // Slack integartion
  const slackLinkStatus = useAccountLinkStatus()
  const isSlackLoading = slackLinkStatus.isLoading
  const slackData = slackLinkStatus.data

  // Integration list items
  const integrations: ItemType[] = [
    // {
    //   id: CompanyIntegrationTypes.MONDAY,
    //   title: 'Monday.com',
    //   description: 'Actions created within your meetings will be exported to a Monday.com board of your choice.',
    //   logo: '/monday.png',
    //   connect: () => setOpenMondayModal(true),
    //   disconnect: () => showDisconnectConfirm(CompanyIntegrationTypes.MONDAY),
    //   isConnected: isIntegrationConnected(CompanyIntegrationTypes.MONDAY),
    //   isLoading: isIntegrationLoading(CompanyIntegrationTypes.MONDAY),
    // },
    // {
    //   id: AccountIntegrationTypes.ASANA,
    //   title: 'Asana',
    //   description:
    //     'Actions created wtihin your meetings will be exported to your "My Tasks" pane within your Asana dashboard.',
    //   logo: '/asana.png',
    //   connect: () => setOpenAsanaModal(true),
    //   disconnect: () => showDisconnectConfirm(AccountIntegrationTypes.ASANA),
    //   isConnected: isIntegrationConnected(AccountIntegrationTypes.ASANA),
    //   isLoading: isIntegrationLoading(AccountIntegrationTypes.ASANA),
    // },
    {
      id: AccountIntegrationTypes.SLACK,
      title: 'Slack',
      description: 'Get notified on Slack.',
      logo: '/slack.png',
      connect: () => {
        if (slackData?.status == 'inactive') Router.push(slackData.installUrl)
      },
      isConnected: slackData?.status === 'active',
      isLoading: isSlackLoading,
    },
    {
      id: AccountIntegrationTypes.GOOGLE_CALENDAR,
      title: 'Google Calendar',
      description:
        'Meetings scheduled in Mochary Method will appear in your Google Calendar with all participants invited via email.',
      logo: '/calendar-logo.png',
      connect: () => dispatch(actions.connectCalendar()),
      disconnect: () => showDisconnectConfirm(AccountIntegrationTypes.GOOGLE_CALENDAR),
      isConnected: isIntegrationConnected(AccountIntegrationTypes.GOOGLE_CALENDAR),
      isLoading: isIntegrationLoading(AccountIntegrationTypes.GOOGLE_CALENDAR),
    },
  ]

  const showDisconnectConfirm = (type: IntegrationTypes) => {
    Modal.confirm({
      title: 'Are you sure you want to disconnect this Integration?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Disconnect',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        dispatch(actions.disconnect({ name: type }))
      },
    })
  }
  const getButtonText = (item: ItemType) => {
    if (item.isConnected && !item.disconnect) return 'Connected'
    else if (item.isConnected) return 'Disconnect'
    else return 'Connect'
  }

  return (
    <FlexColumn gap={6} alignItems="stretch">
      <MondayModal visible={openMondayModal} onOk={closeMondayModal} onCancel={closeMondayModal} />
      <AsanaModal visible={openAsanaModal} onOk={closeAsanaModal} onCancel={closeAsanaModal} />
      <List
        dataSource={integrations}
        bordered
        renderItem={(item) => (
          <List.Item key={item.id}>
            <List.Item.Meta avatar={<Avatar src={item.logo} />} title={item.title} description={item.description} />
            <Button
              loading={item.isLoading}
              type={item.isConnected ? 'dashed' : 'primary'}
              disabled={item.isConnected && !item.disconnect}
              onClick={() => {
                if (item.isConnected && item.disconnect) {
                  item.disconnect()
                } else {
                  item.connect()
                }
              }}
            >
              {getButtonText(item)}
            </Button>
          </List.Item>
        )}
      />
      {gcalV2 && <CalendarIntegrations />}
    </FlexColumn>
  )
}

export default IntegrationList
