import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import HeartCircleOutlineIcon from '@icons/material/HeartCircleOutlineIcon'
import { actions as goodThingsActions, selectors as goodThingsSelectors } from '../../goodThings'
import { Account } from '@mm/backend/accounts/model'
import { FocusColorWrap } from '../../ui'
import { Div } from '../../interface'
import BlockAdd from './BlockAdd'
import BlockEmpty from './BlockEmpty'
import BlockResolver from './BlockResolver'
import BlockTitle from './BlockTitle'
import { Block as GoodThingBlockItemType } from './GoodThingsBlockItem'

export const TYPE = 'goodThings'

export interface Block {
  type: typeof TYPE
  id: typeof TYPE
  activeAccount: Account
  meetingId: string
  title: string
  subtitle: string
  color: string
  updateId?: string
  reviewView?: boolean
  blocks: Array<GoodThingBlockItemType>
}

export const GoodThingsBlock = ({
  id,
  title,
  subtitle,
  color,
  activeAccount,
  meetingId,
  blocks = [],
  updateId,
  reviewView = false,
}: Block) => {
  const dispatch = useDispatch()
  const modal = {
    title: 'About the Good Things section',
    youtubeId: 'kOqE22yKd9k',
    content: (
      <div>
        <p>
          One of the fundamental tenets of the Mochary Method is that our brains work best when we are in a state of joy
          and excitement.
        </p>
        <p>
          Furthermore, our brains work worst when we are in a state of fear or anger. Let&apos;s get into a state of joy
          by celebrating how good life really is!
        </p>
      </div>
    ),
  }

  const form = useSelector(goodThingsSelectors.getForm())

  const handleAdd = (type: 'work' | 'personal') => () =>
    dispatch(
      goodThingsActions.createRequest({
        description: '',
        type,
        meetingId,
        updateId: updateId || '',
        attachments: [],
      }),
    )

  const renderGoodThingsBlocks = (goodThingType: string) => {
    if (blocks.length) {
      let blockList = blocks.sort((a, b) => {
        if (a.firstName === b.firstName) {
          return a.goodThing.type < b.goodThing.type ? -1 : 1
        } else {
          return a.firstName < b.firstName ? -1 : 1
        }
      })

      if (updateId && goodThingType) {
        blockList = blocks.filter((block) => block.goodThing.type === goodThingType)
      }

      return blockList.map((block) => {
        return <BlockResolver key={block.id} block={block} />
      })
    }

    if (updateId) {
      return <></>
    }

    return <BlockEmpty label="No good things yet." />
  }

  const renderAddBlocks = () => {
    if (!reviewView) {
      return (
        <Div data-cy="goodthing-block-add">
          <BlockAdd
            disabled={form.loading}
            loading={form.loading}
            label="Add a personal good thing"
            onClick={handleAdd('personal')}
          />
          <BlockAdd
            disabled={form.loading}
            loading={form.loading}
            label="Add a work good thing"
            onClick={handleAdd('work')}
          />
        </Div>
      )
    }
  }

  return (
    <FocusColorWrap color={color}>
      <BlockTitle
        id={`${id}-${updateId || meetingId}`}
        title={title}
        subtitle={subtitle}
        color={color}
        icon={<HeartCircleOutlineIcon />}
        modal={modal}
        content={
          <>
            {updateId ? (
              <>
                {renderGoodThingsBlocks('personal')}
                {renderGoodThingsBlocks('work')}
                {renderAddBlocks()}
              </>
            ) : (
              <>
                {renderGoodThingsBlocks('')}
                {renderAddBlocks()}
              </>
            )}
          </>
        }
      />
    </FocusColorWrap>
  )
}

export default GoodThingsBlock
