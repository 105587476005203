import { featureFlags } from '@mm/common'
import { camelCaseKeys } from 'launchdarkly-react-client-sdk'
import { Provider } from 'launchdarkly-react-client-sdk/lib/context'
import React, { useState } from 'react'

export type FeatureFlagsProviderProps = {
  children?: React.ReactNode
}

export const FeatureFlagsProvider = ({ children }: FeatureFlagsProviderProps) => {
  const [value] = useState({
    flags: camelCaseKeys(featureFlags),
  })

  return <Provider value={value}>{children}</Provider>
}
