import React from 'react'
import _ from 'lodash'
import { Select } from 'antd'
import { selectors } from '../store'
import { useSelector } from 'react-redux'

interface Props {
  value?: string
  placeholder?: string
  width?: number | string
  onChange?: (value: string) => void
}

export const CompanyFilter: React.FC<Props> = ({ value, placeholder, width = 200, onChange }) => {
  const companies = Object.values(useSelector(selectors.getCompanies()))
  const sortedCompanies = _.orderBy(companies, (company) => company.name.toLocaleLowerCase())

  return (
    <Select
      value={value}
      placeholder={placeholder}
      showSearch={true}
      style={{ width }}
      onChange={(id) => onChange && onChange(id)}
      filterOption={(input, inputOption) => inputOption?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    >
      <Select.Option value={''}>All Companies</Select.Option>
      {sortedCompanies.map((company) => (
        <Select.Option key={company.id} value={company.id}>
          {company.name}
        </Select.Option>
      ))}
    </Select>
  )
}

export default CompanyFilter
