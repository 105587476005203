import React from 'react'
import { useSelector } from 'react-redux'
import { selectors } from '../store'
import { Div, Text } from '../../interface'

const generateWelcomeString = () => {
  const hour = new Date().getHours()

  if (hour >= 22) {
    return 'night owl'
  } else if (hour >= 18) {
    return 'good evening'
  } else if (hour >= 12) {
    return 'good afternoon'
  } else if (hour >= 5) {
    return 'good morning'
  } else {
    return 'early bird'
  }
}

export const AccountHello = () => {
  const activeAccount = useSelector(selectors.getActiveAccount())

  return (
    <Div>
      <Text size="medium" color="lightTinted" block={true}>
        <span style={{ fontSize: 20, marginRight: 8 }}>👋</span> Hey, {generateWelcomeString()}
      </Text>
      <Text size="xLarge" color="dark" lineHeight={1}>
        {activeAccount ? activeAccount.name : ''}
      </Text>
    </Div>
  )
}

export default AccountHello
