import { Middleware } from 'redux'
import _ from 'lodash'
import firebase, { getRefFormatByIds, inflateDates, listenForNewUpdates } from '../firebase'
import { actions, actionTypes } from './store'
import {
  actionTypes as accountActionTypes,
  actions as accountActions,
  selectors as accountSelectors,
} from '../accounts'
import {
  actionTypes as meetingActionTypes,
  actions as meetingActions,
  selectors as meetingsSelectors,
} from '../meetings'
import { dateKeys, Goal } from '@mm/backend/goals/model'
import { ReqBody as CreateReqBody, ResBody as CreateResBody } from '../../pages/api/goals/create'
import { ReqBody as UpdateReqBody, ResBody as UpdateResBody } from '../../pages/api/goals/update'
import { ReqBody as DeleteReqBody, ResBody as DeleteResBody } from '../../pages/api/goals/delete'
import { captureException } from '../sentry'
import axios from 'axios'
import { notification } from 'antd'
import { v4 as uuid } from 'uuid'

// Key listeners based off meeting.id so that expanding other meetings
// will not remove listeners from the main meeting that is loaded.
const listeners: Record<string, Array<() => void>> = {}

const middleware: Middleware = ({ dispatch, getState }) => {
  return (next) => async (action: actions | accountActions | meetingActions) => {
    next(action)

    switch (action.type) {
      case actionTypes.CREATE_REQUEST: {
        try {
          const state = getState()
          const account = accountSelectors.getActiveAccount()(state)

          if (!account) {
            throw new Error('Must be logged in')
          }
          const res = await axios.post<CreateResBody, CreateReqBody>('/api/goals/create', {
            description: action.description,
            accountId: action.accountId || account.id,
            parentId: action.parentId,
            startAt: action.startAt,
            endAt: action.endAt,
            endAtLabel: action.endAtLabel,
          })
          const goal = res.data

          dispatch(actions.createSuccess({ goal }))
        } catch (error) {
          if (!(error instanceof Error)) return
          console.log(error)
          dispatch(actions.createFailure({ error }))
          notification.error({
            message: 'There was an error creating a goal',
          })
        }
        break
      }

      case actionTypes.EDIT: {
        const changeId = uuid()
        const itemId = action.id
        try {
          const patch = {
            description: action.description,
            startAt: action.startAt,
            endAt: action.endAt,
            endAtLabel: action.endAtLabel,
          }
          if (action.optimistic) {
            dispatch(
              actions.optimisticChange({
                changeId,
                itemId,
                patch,
              }),
            )
          }
          const res = await axios.post<UpdateResBody, UpdateReqBody>('/api/goals/update', {
            id: itemId,
            ...patch,
          })
          dispatch(
            actions.optimisticCommit({
              changeId,
              item: inflateDates(res.data, dateKeys),
            }),
          )
        } catch (error) {
          if (action.optimistic) {
            dispatch(
              actions.optimisticRollback({
                changeId,
                itemId,
              }),
            )
          }
          console.log(error)
          notification.error({
            message: 'There was an error editing the goal',
          })
        }
        break
      }

      case actionTypes.DELETE: {
        try {
          await axios.post<DeleteResBody, DeleteReqBody>('/api/goals/delete', {
            id: action.id,
          })
        } catch (error) {
          console.log(error)

          notification.error({
            message: 'There was an error deleting the goal',
          })
        }
        break
      }

      case meetingActionTypes.VIEW: {
        try {
          const state = getState()
          const meeting = meetingsSelectors.getMeetingById(action.id)(state)
          let meetingListeners = listeners[action.id] || []

          // This action gets fired when hot reloading on the meeting page.
          // We need to clear old listeners otherwise they will stack up.
          if (meetingListeners.length) {
            meetingListeners.forEach((detach) => detach())
            meetingListeners = []
          }

          // Create listeners for firebase based off chunks of 10 participants.
          const chunks = _.chunk(meeting.participantIds, 10)
          await Promise.all(
            chunks.map(async (participantsChunk) => {
              const goalRef = firebase.collection('goals').where('accountId', 'in', participantsChunk)

              // Fetch all the documents once on view and set them.
              const goals = await getRefFormatByIds<Goal>(goalRef, ['startAt', 'endAt'])
              dispatch(actions.set({ goals }))

              // Listen for updates from Firebase on same documents.
              const listener = listenForNewUpdates<Goal>(
                goalRef,
                (change, goal) => {
                  if (change.type === 'added') {
                    dispatch(actions.add({ goal }))
                  }
                  if (change.type === 'modified') {
                    dispatch(actions.change({ goal }))
                  }
                  if (change.type === 'removed') {
                    dispatch(actions.remove({ goal }))
                  }
                },
                ['startAt', 'endAt'],
              )
              meetingListeners.push(listener)
            }),
          )
          listeners[action.id] = meetingListeners
        } catch (error) {
          // When Firebase has a permissions error, catch the exception and
          // send it to Sentry.
          console.warn(error)
          captureException(error)
        }
        break
      }

      case accountActionTypes.VIEW_PROFILE: {
        try {
          let accountListener = listeners['profile'] || []

          // This action gets fired when hot reloading on the meeting page.
          // We need to clear old listeners otherwise they will stack up.
          if (accountListener.length) {
            accountListener.forEach((detach) => detach())
            accountListener = []
          }

          const goalRef = firebase.collection('goals').where('accountId', '==', action.accountId)

          // Fetch all the documents once on view and set them.
          const goals = await getRefFormatByIds<Goal>(goalRef, ['startAt', 'endAt'])
          dispatch(actions.set({ goals }))

          // Listen for updates from Firebase on same documents.
          const listener = listenForNewUpdates<Goal>(
            goalRef,
            (change, goal) => {
              if (change.type === 'added') {
                dispatch(actions.add({ goal }))
              }
              if (change.type === 'modified') {
                dispatch(actions.change({ goal }))
              }
              if (change.type === 'removed') {
                dispatch(actions.remove({ goal }))
              }
            },
            ['startAt', 'endAt'],
          )

          listeners['account'] = [listener]
        } catch (error) {
          // When Firebase has a permissions error, catch the exception and
          // send it to Sentry.
          console.warn(error)
          captureException(error)
        }
        break
      }
      default:
        break
    }
  }
}

export default middleware
