import { ApolloCache } from '@apollo/client'
import _ from 'lodash'
import { FutureMeetingsListDocument, FutureMeetingsListMeetingFragment } from '../../../../gen/graphql/documents'

export function addMeetingToListCache(
  cache: ApolloCache<unknown>,
  meeting: FutureMeetingsListMeetingFragment & { endAt: number },
) {
  if (meeting.endAt > Date.now()) {
    const currentData = cache.readQuery({
      query: FutureMeetingsListDocument,
    })
    if (currentData?.futureMeetingsAndEvents) {
      const { edges, pageInfo } = currentData.futureMeetingsAndEvents
      // TODO[MM-587]
      //  This may sometimes result in the same meeting shown twice:
      //  imagine several meetings starting at the same time: [A, B, C]
      //  then we create a new for the same time. This algorithm places it at the start of this array: [D, A, B, C]
      //  but since on the backend we use some stable sort we cannot predict at what place it will actually be
      //  placed. it can be [A, D, B, C] or [A, B, C, D], etc. And then when we request the next page it may turn
      //  out that backend actually placed D at the end of this list, so we end up having following list:
      //  [D, A, B, C, D]
      const insertAt = _(edges)
        .map(({ node }) => node.startAt)
        .sortedIndex(meeting.startAt)
      if (!pageInfo.hasNextPage || insertAt !== edges.length) {
        const newEdges = [...edges]
        newEdges.splice(insertAt, 0, {
          node: {
            __typename: 'Meeting2',
            ...meeting,
          },
        })
        const newData = {
          ...currentData,
          futureMeetingsAndEvents: {
            ...currentData.futureMeetingsAndEvents,
            edges: newEdges,
          },
        }
        cache.writeQuery({
          query: FutureMeetingsListDocument,
          data: newData,
        })
      }
    }
  }
}
