import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import Linkify from 'react-linkify'

interface Props {
  htmlString: string
}

const componentDecorator = (href: string, text: string, key: number) => (
  <a href={href} key={key} target="_blank" rel="noopener noreferrer">
    {text}
  </a>
)

const RichText: React.FC<Props> = ({ htmlString }) => {
  return (
    <Linkify componentDecorator={componentDecorator}>
      <div className="ql-editor" style={{ padding: '0' }}>
        {ReactHtmlParser(htmlString)}
      </div>
    </Linkify>
  )
}

export default RichText
