import { Reducer } from 'redux'
import { Update } from '@mm/backend/update/model'
import { Account } from '@mm/backend/accounts/model'
import { Meeting } from '@mm/backend/meetings/model'
import { actions as authActions } from '../auth'

export const selector = 'updates'

export const actionTypes = {
  EDIT_FAILURE: 'updates/EDIT_FAILURE' as 'updates/EDIT_FAILURE',
  SET: 'updates/SET' as 'updates/SET',
  ADD: 'updates/ADD' as 'updates/ADD',
  CHANGE: 'updates/CHANGE' as 'updates/CHANGE',
  REMOVE: 'updates/REMOVE' as 'updates/REMOVE',
  SAVE_UPDATE_TO_MEETING: 'updates/SAVE_UPDATE_TO_MEETING' as 'updates/SAVE_UPDATE_TO_MEETING',
  REGENERATE_UPDATE: 'updates/REGENERATE_UPDATE' as 'updates/REGENERATE_UPDATE',
  LOADING: 'updates/LOADING' as 'updates/LOADING',
  IS_SUBMITTING: 'updates/IS_SUBMITTING' as 'updates/IS_SUBMITTING',
  STARTED_UPDATE: 'updates/STARTED_UPDATE' as 'updates/STARTED_UPDATE',
  REVIEWED_UPDATE: 'updates/REVIEWED_UPDATE' as 'updates/REVIEWED_UPDATE',
}
interface EditFailure {
  error: Error
}

interface SetAction {
  update: Record<string, Update>
}

interface AddAction {
  update: Update
}

interface ChangeAction {
  update: Update
}

interface RemoveAction {
  update: Update
}

interface SaveUpdateToMeetingAction {
  activeUpdate: Update
}

interface RegenerateUpdateAction {
  meeting: Meeting
  activeAccount: Account
}
interface LoadingAction {
  isLoading: boolean
}

interface SubmittingAction {
  isSubmitting: boolean
}

interface StartedUpdateAction {
  startedUpdate: boolean
  updateId: string
}

interface ReviewedUpdateAction {
  reviewedUpdate: boolean
  updateId: string
}

export const actions = {
  editFailure: ({ error }: EditFailure) => ({
    type: actionTypes.EDIT_FAILURE,
    error,
  }),
  set: ({ update }: SetAction) => ({
    type: actionTypes.SET,
    update,
  }),
  add: ({ update }: AddAction) => ({
    type: actionTypes.ADD,
    update,
  }),
  change: ({ update }: ChangeAction) => ({
    type: actionTypes.CHANGE,
    update,
  }),
  remove: ({ update }: RemoveAction) => ({
    type: actionTypes.REMOVE,
    update,
  }),
  saveUpdateToMeeting: ({ activeUpdate }: SaveUpdateToMeetingAction) => ({
    type: actionTypes.SAVE_UPDATE_TO_MEETING,
    activeUpdate,
  }),
  regenerateUpdate: ({ meeting, activeAccount }: RegenerateUpdateAction) => ({
    type: actionTypes.REGENERATE_UPDATE,
    meeting,
    activeAccount,
  }),
  loading: ({ isLoading }: LoadingAction) => ({
    type: actionTypes.LOADING,
    isLoading,
  }),
  isSubmitting: ({ isSubmitting }: SubmittingAction) => ({
    type: actionTypes.IS_SUBMITTING,
    isSubmitting,
  }),
  startedUpdate: ({ startedUpdate, updateId }: StartedUpdateAction) => ({
    type: actionTypes.STARTED_UPDATE,
    startedUpdate,
    updateId,
  }),
  reviewedUpdate: ({ reviewedUpdate, updateId }: ReviewedUpdateAction) => ({
    type: actionTypes.REVIEWED_UPDATE,
    reviewedUpdate,
    updateId,
  }),
}

export type actions = ReturnType<typeof actions[keyof typeof actions]>

interface State {
  byId: { [id: string]: Update }
  isLoading: boolean
  isSubmitting: boolean
}

const initialState: State = {
  byId: {},
  isLoading: false,
  isSubmitting: false,
}

export const reducer: Reducer<State, actions | authActions> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET: {
      return {
        ...state,
        byId: {
          ...state.byId,
          ...action.update,
        },
      }
    }
    case actionTypes.ADD:
    case actionTypes.CHANGE: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.update.id]: action.update,
        },
      }
    }
    case actionTypes.REMOVE: {
      const byIdClone = { ...state.byId }
      delete byIdClone[action.update.id]

      return {
        ...state,
        byId: byIdClone,
      }
    }
    case actionTypes.LOADING: {
      return {
        ...state,
        isLoading: action.isLoading,
      }
    }
    case actionTypes.IS_SUBMITTING: {
      return {
        ...state,
        isSubmitting: action.isSubmitting,
      }
    }
    default:
      return state
  }
}

export const selectors = {
  get: () => (state: Record<typeof selector, State>) => state[selector].byId,
  getUpdateById: (id: string) => (state: Record<typeof selector, State>) => state[selector].byId[id],
  isLoading: () => (state: Record<typeof selector, State>) => state[selector].isLoading,
  isSubmitting: () => (state: Record<typeof selector, State>) => state[selector].isSubmitting,
}
