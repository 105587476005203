import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Input, Button, Typography } from 'antd'
import { ForgotPasswordModal } from './ForgotPasswordModal'
import { actions, selectors, PageTypes } from '../store'
import { useRouter } from 'next/router'

interface Fields {
  email: string
  password: string
}

export default function LoginForm() {
  const router = useRouter()
  const dispatch = useDispatch()
  const error = useSelector(selectors.getError(PageTypes.LOGIN))
  const loading = useSelector(selectors.getLoading(PageTypes.LOGIN))

  const handleSubmit = (values: Fields) => {
    dispatch(
      actions.signInRequest({
        ...values,
        redirectTo: router.query.redirect ? decodeURI(router.query.redirect as string) : undefined,
      }),
    )
  }

  // Forgot password modal
  const [openForgotPasswordModal, setOpenForgotPasswordModal] = React.useState(false)
  const closeForgotPasswordModal = () => setOpenForgotPasswordModal(false)

  return (
    <>
      <ForgotPasswordModal visible={openForgotPasswordModal} onCancel={closeForgotPasswordModal} />
      <Form name="basic" layout="vertical" onFinish={handleSubmit} onFinishFailed={console.log}>
        <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Email is required' }]}>
          <Input />
        </Form.Item>

        <Form.Item label="Password" name="password" rules={[{ required: true, message: 'Password is required' }]}>
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button data-cy="login-submit" type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Form.Item>

        <Button type="link" onClick={() => setOpenForgotPasswordModal(true)} style={{ padding: 0 }}>
          Forgot password?
        </Button>

        {error && <Typography.Paragraph type="danger">{error.message}</Typography.Paragraph>}
      </Form>
    </>
  )
}
