import { useQuery } from '@apollo/client'
import styled from '@emotion/styled'
import { Button, message, Spin } from 'antd'
import React from 'react'
import { DisconnectOutlined } from '@ant-design/icons'
import { FlexRow } from '../../interface'
import { color } from '../../interface/theme'
import { CalendarConnectionDocument } from '../../../gen/graphql/documents'
import GCalIcon from './GCal.svg'

export type CalendarConnectionProps = {
  id: string
}

export function CalendarConnection({ id }: CalendarConnectionProps): React.ReactElement {
  const { data, loading } = useQuery(CalendarConnectionDocument, {
    variables: { id },
  })

  if (loading) {
    return <Spin />
  }

  if (data?.calendar == null) {
    return <></>
  }

  const { email, provider } = data.calendar

  return (
    <FlexRow justifyContent="space-between" padding={2} background="backgroundWhite" rounded>
      <FlexRow gap={2} alignItems="center">
        {provider === 'GMAIL' && <GCalStyledIcon />}
        <Email>{email}</Email>
      </FlexRow>
      <Button
        type="dashed"
        icon={<DisconnectOutlined />}
        onClick={() => {
          message.warn('Not implemented yet')
        }}
      >
        Disconnect
      </Button>
    </FlexRow>
  )
}

const GCalStyledIcon = styled(GCalIcon)`
  width: 32px;
  height: 32px;
`

const Email = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${color('grayscale.black')};
`
