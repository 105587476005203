import { useMutation, useQuery } from '@apollo/client'
import { Form, notification } from 'antd'
import { Duration } from 'luxon'
import React, { useCallback, useEffect } from 'react'
import { usePrevious } from 'react-use'
import { CreateNextMeetingModalCreateDocument, CreateNextMeetingModalDocument } from '../../../../gen/graphql/documents'
import { addMeetingToListCache } from './addMeetingToListCache.util'
import { CreateEditMeetingModalView, Fields } from './CreateEditMeetingModal.view'

export type CreateNextMeetingModalProps = {
  visible: boolean
  previousMeetingId: string

  onComplete?: () => void
  onCancel?: () => void
}

export function CreateNextMeetingModal({
  visible,
  previousMeetingId,
  onComplete,
  onCancel,
}: CreateNextMeetingModalProps): React.ReactElement {
  const [form] = Form.useForm<Fields>()

  const handleFailedToCreateMeeting = () => {
    notification.error({
      message: 'Error',
      description: 'Failed to schedule next meeting',
    })
    onCancel?.()
  }
  const { data, loading } = useQuery(CreateNextMeetingModalDocument, {
    variables: {
      meetingId: previousMeetingId,
    },
    onCompleted: (data) => {
      if (data.meeting2 == null) {
        handleFailedToCreateMeeting()
      }
    },
    onError: () => {
      handleFailedToCreateMeeting()
    },
  })

  const [createMeeting, { loading: creating }] = useMutation(CreateNextMeetingModalCreateDocument, {
    onCompleted: (data) => {
      if (data.createMeetingInSeries?.__typename !== 'Meeting2') {
        console.error('Failed to create meeting', data.createMeetingInSeries)
        notification.error({
          message: `There was an error when trying to create the meeting`,
        })
      } else {
        onComplete?.()
      }
    },
    update: (cache, result) => {
      if (result.data?.createMeetingInSeries.__typename === 'Meeting2') {
        addMeetingToListCache(cache, result.data.createMeetingInSeries)
      }
    },
  })

  const handleSubmit = useCallback(async () => {
    try {
      if (data?.meeting2 == null) {
        throw new Error('Previous meeting has not loaded yet')
      }
      const values = await form.validateFields()
      await createMeeting({
        variables: {
          data: {
            participants: values.participants.map(({ id, role }) => ({ accountId: id, role })),
            title: values.title,
            startAt: values.startAt.getTime(),
            timezone: 'America/Los_Angeles', // TODO [MM-561] add an input
            duration: Duration.fromObject({ minutes: values.duration }).toMillis(),
            recurrence: values.recurrence,
            calendarId: values.calendarId,
            conferenceUrl: values.conferenceUrl,
          },
          seriesId: data.meeting2.series.id,
        },
      })
    } catch (error) {
      console.error('Failed to create meeting', error)
      notification.error({
        message: `There was an error when trying to create the meeting`,
      })
    }
  }, [form, createMeeting, data])

  const wasVisible = usePrevious(visible)
  useEffect(() => {
    if (!wasVisible && visible) {
      form.resetFields()
    }
  }, [wasVisible, visible, form])

  const formInitialValues: Partial<Fields> = data?.meeting2
    ? {
        title: data.meeting2.title,
        startAt: new Date(),
        duration: Duration.fromMillis(data.meeting2.duration).as('minutes'),
        companyId: data.meeting2.company.id,
        participants: data.meeting2.participants.map(({ account: { id }, role }) => ({ id, role })),
        conferenceUrl: data.meeting2.conferenceUrl ?? undefined,
      }
    : {}

  return (
    <CreateEditMeetingModalView
      form={form}
      title="Schedule Next Meeting"
      submitText="Create"
      visible={visible}
      loading={loading}
      submitting={creating}
      canChangeCompanyId={false}
      initialValues={formInitialValues}
      onSubmit={handleSubmit}
      onCancel={onCancel}
    />
  )
}
