import React from 'react'
import Head from 'next/head'
import Link from 'next/link'
import styled from '@emotion/styled'
import { useTheme } from '@emotion/react'
import { ErrorBoundary } from 'react-error-boundary'
import theme, { spacing, SpacingInterval } from './theme'
import { PageHeaderProfileDropdown } from '../accounts'
import { ProtectRoutes } from '../auth'
import { useRouter } from 'next/router'
import { Button, Flex, Div, ErrorFallback } from '../interface'
import { selectors as accountSelectors } from '../accounts'
import { useSelector } from 'react-redux'
import CommentQuestionOutlineIcon from '@icons/material/CommentQuestionOutlineIcon'
import { Divider, Typography } from 'antd'
import { BsBoxArrowUpRight } from 'react-icons/bs'

const VIDEO_ASK_URL: string = process.env.NEXT_PUBLIC_VIDEO_ASK_URL as string

interface Props {
  pageTitle?: string
  protectedRoute?: boolean
  scopeCompanyId?: string
}

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    videoask: any
  }
}

export const Page: React.FC<Props> = ({ children, pageTitle, scopeCompanyId, protectedRoute = true }) => {
  const title = pageTitle ? `${pageTitle} – Mochary Method` : 'Mochary Method'
  const theme = useTheme()
  const activeAccount = useSelector(accountSelectors.getActiveAccount())
  const router = useRouter()

  const [isVideoAskOpen, setIsVideoAskOpen] = React.useState(false)

  React.useEffect(() => {
    const s = document.createElement('script')
    s.setAttribute('src', 'https://www.videoask.com/embed/embed.js')
    s.setAttribute('async', 'true')
    document.head.appendChild(s)
  }, [])

  const getVideoAskUrl = (): string => {
    if (activeAccount) {
      const { email, name, id } = activeAccount

      return VIDEO_ASK_URL + `#contact_email=${email}&contact_name=${name}&user_id=${id}`
    }

    return VIDEO_ASK_URL
  }

  const renderLink = (title: string, href: string) => (
    <Link href={href} passHref={true}>
      <LinkStyle>{title}</LinkStyle>
    </Link>
  )

  const issuesLinkHref = '/issues' + (scopeCompanyId == null ? '' : `?companyId=${scopeCompanyId}`)

  const pageHead = (
    <header>
      <PageContent>
        <VideoAsk
          onClick={() => {
            if (!isVideoAskOpen) {
              window.videoask.loadModal({ url: getVideoAskUrl() }, { onCloseModal: () => setIsVideoAskOpen(false) })
              setIsVideoAskOpen(true)
            }
          }}
        >
          <CommentQuestionOutlineIcon style={{ height: 36, width: 36, color: 'rgba(255,255,255,0.9)' }} />
        </VideoAsk>
        <Flex justifyContent="space-between" height={5}>
          <Link href="/dashboard" passHref={true}>
            <a>
              <img src="/logo.png" alt="Mochary Method" height="28px" style={{ display: 'block', height: '28px' }} />
            </a>
          </Link>
          <Nav>
            <ol>
              {activeAccount ? (
                <>
                  <li>{renderLink('Meetings', '/dashboard')}</li>
                  <li>{renderLink('Issues', issuesLinkHref)}</li>
                  <li>{renderLink('Actions', '/profile/_/actions')}</li>
                  <li>{renderLink('Goals', '/profile/_/goals')}</li>
                </>
              ) : null}

              <li>
                <LinkStyle
                  href="https://docs.google.com/document/d/18FiJbYn53fTtPmphfdCKT2TMWH-8Y2L-MLqDk-MFV4s"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Curriculum
                  <OpensInNewTabIcon />
                </LinkStyle>
              </li>

              <li>
                {activeAccount ? (
                  <PageHeaderProfileDropdown activeAccount={activeAccount} />
                ) : (
                  <Button onClick={() => router.push('/login')} color="blue">
                    Login
                  </Button>
                )}
              </li>
            </ol>
          </Nav>
        </Flex>
      </PageContent>
    </header>
  )

  const pageContent = (
    <PageContent paddingBottom={4} paddingTop={4}>
      {children}
    </PageContent>
  )

  const pageFooter = (
    <PageContent paddingBottom={1}>
      <footer>
        <Divider />
        <Flex justifyContent="space-between">
          <Typography.Text style={{ color: `${theme.font.color.light}` }}>©2021 Mochary Method</Typography.Text>
          <div>
            <LinkStyle href="/terms" rel="noopener noreferrer" target="_blank" style={{ marginRight: 16 }}>
              Terms of Service
            </LinkStyle>
            <LinkStyle href="/privacy" rel="noopener noreferrer" target="_blank" style={{ marginRight: 16 }}>
              Privacy Policy
            </LinkStyle>
            <LinkStyle href="mailto:hello@mocharymethod.com" rel="noopener noreferrer" target="_blank">
              Contact
            </LinkStyle>
          </div>
        </Flex>
      </footer>
    </PageContent>
  )

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <link rel="shortcut icon" type="image/x-icon" href="/favicon.ico" />
      </Head>
      <Div
        style={{
          background: 'rgba(255,255,255,0.95)',
          position: 'sticky',
          top: 0,
          zIndex: 100,
          boxShadow: '0 2px 2px rgba(0,0,0,0.05), 0 4px 16px rgba(0,0,0,0.02)',
        }}
      >
        {pageHead}
      </Div>
      <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => router.reload()}>
        <Flex justifyContent="space-between" direction="column" style={{ minHeight: '92vh' }}>
          {protectedRoute ? <ProtectRoutes>{pageContent}</ProtectRoutes> : pageContent}
          {pageFooter}
        </Flex>
      </ErrorBoundary>

      <style jsx global>{`
        body,
        footer {
          background: ${theme.color.background};
          font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, 'Apple Color Emoji', Arial, sans-serif,
            'Segoe UI Emoji', 'Segoe UI Symbol';
          font-size: 16px;
          line-height: 1.5;
          color: rgba(0, 0, 0, 0.6);
        }
      `}</style>
    </>
  )
}

const LinkStyle = styled.a`
  color: ${theme.font.color.light};
  :hover {
    color: ${theme.font.color.blue};
  }
`

const OpensInNewTabIcon = styled(BsBoxArrowUpRight)`
  vertical-align: text-bottom;
  margin-left: 4px;
  margin-bottom: 1px;
`

const Nav = styled.nav`
  ol {
    margin: 8px 0;
    list-style-type: none;
    padding-left: 0;
    display: flex;
    align-items: center;
  }
  ol > li {
    margin-left: 24px;
  }
`
const VideoAsk = styled.div`
  background: #4579e4;
  border-radius: 100%;
  width: 60px;
  height: 60px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    background: #5488f0;
  }
`
interface PageContentProps {
  width?: number
  paddingTop?: SpacingInterval
  paddingBottom?: SpacingInterval
}
const PageContent = styled.div<PageContentProps>(({ width = 1060, paddingTop, paddingBottom }) => ({
  margin: '0 auto',
  maxWidth: width,
  width: '100%',
  paddingLeft: spacing(1),
  paddingRight: spacing(1),
  paddingTop: spacing(paddingTop),
  paddingBottom: spacing(paddingBottom),
}))
