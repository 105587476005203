import React from 'react'
import BlockResolver from './BlockResolver'
import BlockTitle from './BlockTitle'
import { Block as ParticipantsBlockItemType } from './ParticipantsBlockItem'
import { FocusColorWrap } from '../../ui'
import ArrowRightCircleOutlineIcon from '@icons/material/ArrowRightCircleOutlineIcon'

export const TYPE = 'actionsNext'

export interface Block {
  type: typeof TYPE
  id: typeof TYPE
  title: string
  subtitle: string
  color: string
  meetingId: string
  updateId?: string
  showDrawer?: boolean
  blocks: Array<ParticipantsBlockItemType>
}

export const ActionsNextBlock = ({
  id,
  title,
  subtitle,
  color,
  meetingId,
  updateId,
  showDrawer = true,
  blocks = [],
}: Block) => {
  const modal = {
    title: 'Creating actions towards goals',
    youtubeId: 'bcGsFzNNre4',
    content: (
      <div>
        <p>The action you choose to push your goal forward should be something both specific and under your control.</p>
        <p>
          For example, &quot;Ask Jake to meet to discuss proposal&quot; and &quot;Draft ideas for board meeting
          agenda&quot; are both specific, do-able actions.
        </p>
      </div>
    ),
  }

  return (
    <FocusColorWrap color={color}>
      <BlockTitle
        id={`${id}-${updateId || meetingId}`}
        title={title}
        subtitle={subtitle}
        color={color}
        icon={<ArrowRightCircleOutlineIcon />}
        modal={modal}
        content={
          <>{blocks.map((block) => (showDrawer ? <BlockResolver key={block.id} block={block} /> : block.children))}</>
        }
      />
    </FocusColorWrap>
  )
}

export default ActionsNextBlock
