/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, SetStateAction } from 'react'
import 'react-quill/dist/quill.bubble.css'

let MagicUrl
let Quill: any

if (process.browser) {
  MagicUrl = require('quill-magic-url')
  Quill = require('quill')

  const Link = Quill.import('formats/link')

  Link.sanitize = function (url: string) {
    if (url.indexOf('http://') == -1 && url.indexOf('https://') == -1) {
      return 'http://' + url
    }
    return url
  }

  Quill.register('modules/magicUrl', MagicUrl.default, true)
}

interface Props {
  value: string
  className?: string
  style?: React.CSSProperties
  tabIndex?: number
  toolbarActions?: Array<Array<string>>
  toolbarHandlers?: Record<string, () => void>
  focusOnNew?: boolean
  editable?: boolean
  placeholder?: string
  onChange: (html: string) => void
  onDeleteWhenEmpty?: () => void
  setHighLightedContent: Dispatch<SetStateAction<string>>
  setParentQuill?: Dispatch<SetStateAction<typeof Quill | undefined>>
}

const ReactQuill: React.FC<Props> = ({
  value,
  className,
  style,
  tabIndex,
  toolbarActions,
  toolbarHandlers,
  focusOnNew,
  placeholder,
  editable = true,
  onChange,
  setHighLightedContent,
  setParentQuill,
}) => {
  const ref = React.createRef<HTMLDivElement>()
  const tempQuillRef = React.createRef<HTMLDivElement>()
  const [quill, setQuill] = React.useState<typeof Quill>()
  const [tempQuill, setTempQuill] = React.useState<typeof Quill>()
  const [focused, setFocused] = React.useState(false)

  React.useEffect(() => {
    const quill = new Quill(ref.current, {
      theme: 'bubble',
      placeholder,
      readOnly: !editable,
      modules: {
        history: {
          delay: 1000,
          maxStack: 100,
          userOnly: true,
        },
        magicUrl: true,
        toolbar: toolbarActions
          ? {
              container: toolbarActions,
              handlers: toolbarHandlers,
            }
          : false,
      },
      formats: ['bold', 'italic', 'link', 'strike', 'underline', 'indent', 'list'],
      // scrollingContainer: document.documentElement,
    })

    setQuill(quill)
    setParentQuill?.(quill)
    setTempQuill(new Quill(tempQuillRef.current))
  }, [])

  React.useEffect(() => {
    const handlerKeys = Object.keys(toolbarHandlers || [])
    if (quill && toolbarHandlers && handlerKeys.length) {
      const toolbar = quill.getModule('toolbar')
      handlerKeys.map((key) => {
        toolbar.addHandler(key, toolbarHandlers[key])
      })
      toolbar.container.addEventListener('mousedown', (e: MouseEvent): void => {
        e.preventDefault()
      })
    }
  }, [quill, toolbarActions])

  React.useEffect(() => {
    if (quill) {
      quill.on('text-change', handleChange)
      quill.on('selection-change', handleFocus)

      if (value === '' && focusOnNew) {
        quill?.focus()
      }

      return () => {
        quill.off('text-change', handleChange)
        quill.off('selection-change', handleFocus)
      }
    }
  }, [quill])

  React.useEffect(() => {
    // Only replace contents when not focused
    if (quill && !focused) {
      const range = quill.getSelection()
      quill && quill.setContents(quill.clipboard.convert(value), 'silent')
      if (range) {
        quill.setSelection(range.index, range.length)
      }
    }
  }, [quill, value, focused])

  const handleChange = (delta: any, oldContents: any, source: string) => {
    // Quill fires onChange on first load, this is the easiest way to guard against it
    // https://github.com/zenoamaro/react-quill/issues/259
    if (source == 'user' && delta !== oldContents) {
      // Get quill contents as HTML
      // https://github.com/quilljs/quill/issues/903
      quill && onChange(quill.container.firstChild.innerHTML)
    }
  }

  const handleFocus = (range: any) => {
    // https://quilljs.com/docs/api/#selection-change
    if (range && !focused) {
      const selectedContent = quill.getContents(range.index, range.length)
      tempQuill.setContents(selectedContent)
      const selectedHTML = tempQuill.container.firstChild.innerHTML

      if (selectedHTML) {
        setHighLightedContent(selectedHTML)
      }
      setFocused(true)
    } else {
      setFocused(false)
    }
  }

  return (
    <>
      <div
        ref={ref}
        className={className}
        style={{
          ...style,
          flexGrow: 1,
        }}
        tabIndex={tabIndex}
        onClick={(e: any) => {
          const anchorElem = e.target.closest('a')
          const href = anchorElem ? anchorElem.href : null

          if (href) {
            window.open(href, '_blank', 'noopener noreferrer')
          }
        }}
      />
      <div ref={tempQuillRef} style={{ display: 'none' }}></div>
    </>
  )
}

export default ReactQuill
