import { Action } from '@mm/backend/actions/model'
import { Meeting } from '@mm/backend/meetings/model'
import { Reducer } from 'redux'
import { actions as authActions, actionTypes as authActionTypes } from '../auth'
import {
  clear,
  optimisticChange,
  OptimisticChange,
  optimisticCommit,
  OptimisticCommit,
  optimisticRollback,
  OptimisticRollback,
  remove,
  removeById,
  set,
  State,
  upsert,
} from '../optimistic-redux'

export const selector = 'actions'

export const actionTypes = {
  EDIT: 'actions/EDIT',
  DELETE: 'actions/DELETE',
  CREATE_REQUEST: 'actions/CREATE_REQUEST',
  CREATE_SUCCESS: 'actions/CREATE_SUCCESS',
  CREATE_FAILURE: 'actions/CREATE_FAILURE',
  CLEAR: 'actions/CLEAR',
  SET: 'actions/SET',
  ADD: 'actions/ADD',
  CHANGE: 'actions/CHANGE',
  REMOVE: 'actions/REMOVE',

  OPTIMISTIC_CHANGE: 'actions/OPTIMISTIC_CHANGE',
  OPTIMISTIC_ROLLBACK: 'actions/OPTIMISTIC_ROLLBACK',
  OPTIMISTIC_COMMIT: 'actions/OPTIMISTIC_COMMIT',
} as const

type EditAction = Partial<
  Pick<
    Action,
    | 'description'
    | 'status'
    | 'statusChangedInMeetingId'
    | 'reason'
    | 'assignedTo'
    | 'featureId'
    | 'featureType'
    | 'meetingId'
    | 'dueAt'
  >
> &
  Pick<Action, 'id'> & {
    optimistic?: boolean
  }

type DeleteAction = Pick<Action, 'id'>

type CreateRequestAction = Pick<
  Action,
  | 'description'
  | 'accountId'
  | 'assignedTo'
  | 'featureId'
  | 'featureType'
  | 'featurePreviewText'
  | 'groupIds'
  | 'participantIds'
  | 'dueAt'
  | 'meetingIds'
>

interface CreateSuccessAction {
  action: Action
}

interface CreateFailureAction {
  error: Error
}

interface SetAction {
  actions: Record<string, Action>
}

interface AddAction {
  action: Action
}

interface ChangeAction {
  action: Action
}

interface RemoveAction {
  action: Action
}

export const actions = {
  edit: ({ id, description, assignedTo, status, statusChangedInMeetingId, reason, dueAt, optimistic }: EditAction) => ({
    type: actionTypes.EDIT,
    id,
    description,
    assignedTo,
    status,
    statusChangedInMeetingId,
    reason,
    dueAt,
    optimistic,
  }),
  delete: ({ id }: DeleteAction) => ({
    type: actionTypes.DELETE,
    id,
  }),
  createRequest: ({
    description,
    accountId,
    assignedTo,
    featureId,
    featureType,
    featurePreviewText,
    meetingIds,
    groupIds,
    participantIds,
    dueAt,
  }: CreateRequestAction) => ({
    type: actionTypes.CREATE_REQUEST,
    description,
    accountId,
    assignedTo,
    featureId,
    featureType,
    featurePreviewText,
    meetingIds,
    groupIds,
    participantIds,
    dueAt,
  }),
  createSuccess: ({ action }: CreateSuccessAction) => ({
    type: actionTypes.CREATE_SUCCESS,
    action,
  }),
  createFailure: ({ error }: CreateFailureAction) => ({
    type: actionTypes.CREATE_FAILURE,
    error,
  }),
  clear: () => ({
    type: actionTypes.CLEAR,
  }),
  add: ({ action }: AddAction) => ({
    type: actionTypes.ADD,
    action,
  }),
  set: ({ actions }: SetAction) => ({
    type: actionTypes.SET,
    actions,
  }),
  change: ({ action }: ChangeAction) => ({
    type: actionTypes.CHANGE,
    action,
  }),
  remove: ({ action }: RemoveAction) => ({
    type: actionTypes.REMOVE,
    action,
  }),
  optimisticChange: (action: OptimisticChange<Action>) => ({
    ...action,
    type: actionTypes.OPTIMISTIC_CHANGE,
  }),
  optimisticRollback: (action: OptimisticRollback) => ({
    ...action,
    type: actionTypes.OPTIMISTIC_ROLLBACK,
  }),
  optimisticCommit: (action: OptimisticCommit<Action>) => ({
    ...action,
    type: actionTypes.OPTIMISTIC_COMMIT,
  }),
}

export type actions = ReturnType<typeof actions[keyof typeof actions]>

interface ActionsState extends State<Action> {
  form: {
    loading: boolean
    error?: Error
  }
  new: string[]
  deleted: string[]
}

const initialState: ActionsState = {
  byId: {},
  optimisticStorage: {},
  form: {
    loading: false,
  },
  new: [],
  deleted: [],
}

export const reducer: Reducer<ActionsState, actions | authActions> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET: {
      return set(state, action.actions)
    }
    case actionTypes.ADD:
    case actionTypes.CHANGE: {
      return upsert(state, action.action)
    }
    case actionTypes.REMOVE: {
      return remove(state, action.action)
    }
    case actionTypes.DELETE: {
      return {
        ...removeById(state, action.id),
        deleted: [...state.deleted, action.id],
      }
    }
    case actionTypes.CLEAR: {
      return clear(state)
    }
    case actionTypes.CREATE_REQUEST: {
      return {
        ...state,
        form: {
          loading: true,
        },
      }
    }
    case actionTypes.CREATE_SUCCESS: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.action.id]: action.action,
        },
        form: {
          loading: false,
        },
        new: [...state.new, action.action.id],
      }
    }
    case actionTypes.CREATE_FAILURE: {
      return {
        ...state,
        form: {
          loading: false,
          error: action.error,
        },
      }
    }
    case actionTypes.OPTIMISTIC_CHANGE: {
      return optimisticChange(state, action)
    }
    case actionTypes.OPTIMISTIC_ROLLBACK: {
      return optimisticRollback(state, action)
    }
    case actionTypes.OPTIMISTIC_COMMIT: {
      return optimisticCommit(state, action)
    }
    case authActionTypes.LOG_OUT:
      return initialState

    default:
      return state
  }
}

export const selectors = {
  get: () => (state: Record<typeof selector, ActionsState>) => state[selector].byId,
  getForm: () => (state: Record<typeof selector, ActionsState>) => state[selector].form,
  getById: (id: string) => (state: Record<typeof selector, ActionsState>) => state[selector].byId[id],
  getByGroupId: (groupId: string) => (state: Record<typeof selector, ActionsState>) => {
    const actions = Object.values(state[selector].byId)
    return actions.filter((action) => action.groupId === groupId)
  },
  getByAssignedToId: (accountId: string) => (state: Record<typeof selector, ActionsState>) => {
    const actions = Object.values(state[selector].byId)
    return actions.filter((action) => action.assignedTo === accountId)
  },
  getByFeatureId: (featureId: string) => (state: Record<typeof selector, ActionsState>) => {
    const actions = Object.values(state[selector].byId)
    return actions.filter((action) => action.featureId === featureId)
  },
  getNewlyCreatedIds: () => (state: Record<typeof selector, ActionsState>) => state[selector].new,
  getDeletedIds: () => (state: Record<typeof selector, ActionsState>) => state[selector].deleted,
}
