import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { CompanyFilter, selectors as companySelectors } from '../../companies'
import { useFeatureFlags } from '../../featureFlags'
import { Button, Card, FlexRow, Input } from '../../interface'
import IssueListTable from './IssueListTable'
import { IssueListTableOld } from './IssueListTableOld'

export const IssueDashboard = () => {
  const router = useRouter()
  const companies = useSelector(companySelectors.getCompanies())
  const { issuesGraphqlIntegration = false } = useFeatureFlags()
  const companiesSize = Object.keys(companies).length
  const companyFilter = typeof router.query.companyId === 'string' ? router.query.companyId : ''
  const setCompanyFilter = (companyId: string) => {
    router.replace(`/issues?companyId=${companyId}`, undefined, { shallow: true })
  }

  const [search, setSearch] = React.useState('')
  React.useEffect(() => {
    // router.query is empty on first render
    const path = router.asPath
    const match = path.match(/q=([^&]*)/)
    if (match && match.length > 1) {
      const decodedQuery = decodeURIComponent(match[1])
      setSearch(decodedQuery)
    }
  }, [])

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    debouncedChange(e.target.value)
  }
  const updateURL = (queryStr: string) => {
    router.push(`/issues?q=${queryStr}`, undefined, { shallow: true })
  }
  const debouncedChange = React.useCallback(_.throttle(updateURL, 1000), [])

  return (
    <Card
      title="Issues"
      renderRight={
        <FlexRow gap={2}>
          {companiesSize > 1 ? <CompanyFilter value={companyFilter} onChange={setCompanyFilter} /> : null}
          <Input style={{ width: 200, marginRight: 24 }} placeholder="Search..." value={search} onChange={onSearch} />
          <Link href="/issues/create">
            <Button size="small" color="primary">
              Create Issue
            </Button>
          </Link>
        </FlexRow>
      }
    >
      {issuesGraphqlIntegration ? (
        <IssueListTable companyId={companyFilter || null} search={search} />
      ) : (
        <IssueListTableOld companyId={companyFilter || null} search={search} />
      )}
    </Card>
  )
}

export default IssueDashboard
