import { Middleware } from 'redux'
import firebase from '../firebase'
import { actionTypes, actions } from './store'
import { actions as groupsActions } from '../groups'
import { actionTypes as companyActionTypes, actions as companyActions } from '../companies'
import { selectors as accountSelectors, actions as accountActions } from '../accounts'
import { notification } from 'antd'
import { actionTypes as authActionTypes, actions as authActions } from '../auth'
import { actions as integrationActions } from '../integrations'
import { ReqBody as CreateReqBody, ResBody as CreateResBody } from '../../pages/api/accounts/create'
import { ReqBody as UpdateReqBody, ResBody as UpdateResBody } from '../../pages/api/accounts/update'
import { Account } from '@mm/backend/accounts/model'
import { ReqBody as GetAccountsReqBody, ResBody as GetAccountsResBody } from '../../pages/api/accounts/getAccounts'
import { ReqBody as AddAccountReqBody, ResBody as AddAccountResBody } from '../../pages/api/companies/addAccount'
import {
  ReqBody as RemoveAccountReqBody,
  ResBody as RemoveAccountResBody,
} from '../../pages/api/companies/removeAccount'
import axios from 'axios'

let detachListner: () => void

const middleware: Middleware = ({ dispatch, getState }) => {
  return (next) => async (
    action: actions | groupsActions | authActions | integrationActions | companyActions | accountActions,
  ) => {
    next(action)

    switch (action.type) {
      case authActionTypes.USER_CHANGE: {
        if (action.user) {
          if (detachListner) {
            detachListner()
          }

          const accountRef = firebase.collection('accounts').doc(action.user.uid)
          // TODO: This is a hack, .get() should not need to be called for .onSnapshot to return data.
          await accountRef.get()

          detachListner = accountRef.onSnapshot((doc) => {
            const account = doc.data() as Account
            dispatch(actions.setActive({ account }))
          })
        }
        break
      }
      case actionTypes.CREATE_REQUEST: {
        try {
          const res = await axios.post<CreateResBody, CreateReqBody>('/api/accounts/create', {
            email: action.email,
            name: action.name,
            password: action.password,
          })
          const account = res.data
          dispatch(actions.createSuccess({ account, password: action.password }))
        } catch (error) {
          if (!(error instanceof Error)) return
          console.error(error)
          dispatch(actions.createFailure({ error }))
          notification.error({
            message: 'There was an error creating a new account',
          })
        }
        break
      }
      case actionTypes.UPDATE_REQUEST: {
        try {
          const res = await axios.post<UpdateResBody, UpdateReqBody>('/api/accounts/update', {
            id: action.account.id,
            email: action.account.email,
            name: action.account.name,
            bio: action.account.bio,
          })
          const account = res.data
          dispatch(actions.updateSuccess({ account }))
        } catch (error) {
          if (!(error instanceof Error)) return
          console.error(error)
          dispatch(actions.updateFailure({ error }))
          notification.error({
            message: 'There was an error updating your account',
          })
        }
        break
      }
      case actionTypes.ADD_COMPANY_REQUEST: {
        try {
          const res = await axios.post<AddAccountResBody, AddAccountReqBody>('/api/companies/addAccount', {
            email: action.email,
            name: action.name,
            companyId: action.companyId,
            senderId: action.senderId,
          })
          const account = res.data
          dispatch(actions.addCompanySuccess({ account }))
        } catch (error) {
          if (!(error instanceof Error)) return
          console.error(error)
          dispatch(actions.addCompanyFailure({ error }))
          notification.error({
            message: 'There was an error adding a company',
          })
        }
        break
      }

      case actionTypes.REMOVE_COMPANY_REQUEST: {
        try {
          const res = await axios.post<RemoveAccountResBody, RemoveAccountReqBody>('/api/companies/removeAccount', {
            accountId: action.id,
            companyId: action.companyId,
          })
          const account = res.data
          dispatch(actions.removeCompanySuccess({ account }))
          notification.success({
            message: 'Successfully deleted member',
          })
        } catch (error) {
          if (!(error instanceof Error)) return
          console.error(error)
          dispatch(actions.removeCompanyFailure({ error }))
          notification.error({
            message: 'There was an error removing a company',
          })
        }
        break
      }

      // Get all company members accounts
      case companyActionTypes.SET: {
        const state = getState()
        const account = accountSelectors.getActiveAccount()(state)

        // Member ids associated with companies
        const memberIds: Array<string> = []
        for (const company of action.companies) {
          const newMembersId = company.members.filter(
            (memberId) => memberId !== account.id && !memberIds.includes(memberId),
          )
          newMembersId.forEach((newMemberId) => memberIds.push(newMemberId))
        }

        try {
          // Get all accounts
          const res = await axios.post<GetAccountsResBody, GetAccountsReqBody>('/api/accounts/getAccounts', {
            accountIds: memberIds,
          })

          dispatch(actions.set({ accounts: res.data }))
        } catch (error) {
          console.error(error)
          notification.error({
            message: 'There was an error fetching accounts',
          })
        }
        break
      }

      default:
        break
    }
  }
}

export default middleware
