import { Middleware } from 'redux'
import _ from 'lodash'
import firebase, { getRefFormatByIds, listenForNewUpdates, inflateDates } from '../firebase'
import { actions, actionTypes } from './store'
import {
  actionTypes as meetingsActionTypes,
  actions as meetingsActions,
  selectors as meetingsSelectors,
} from '../meetings'
import { selectors as accountSelectors } from '../accounts'
import { dateKeys, Topic } from '@mm/backend/topics/model'
import { ReqBody as CreateReqBody, ResBody as CreateResBody } from '../../pages/api/topics/create'
import { ReqBody as UpdateReqBody, ResBody as UpdateResBody } from '../../pages/api/topics/update'
import { ReqBody as DeleteReqBody, ResBody as DeleteResBody } from '../../pages/api/topics/delete'
import { captureException } from '../sentry'
import axios from 'axios'
import { notification } from 'antd'
import { v4 as uuid } from 'uuid'

// Key listeners based off meeting.id so that expanding other meetings
// will not remove listeners from the main meeting that is loaded.
const listeners: Record<string, Array<() => void>> = {}

const middleware: Middleware = ({ dispatch, getState }) => {
  return (next) => async (action: actions | meetingsActions) => {
    next(action)

    switch (action.type) {
      case actionTypes.CREATE_REQUEST: {
        try {
          const state = getState()
          const account = accountSelectors.getActiveAccount()(state)
          if (!account) {
            throw new Error('Must be logged in')
          }
          const res = await axios.post<CreateResBody, CreateReqBody>('/api/topics/create', {
            description: action.description,
            meetingId: action.meetingId,
            accountId: account.id,
            attachments: action.attachments,
            updateId: action.updateId,
          })
          const topic = inflateDates(res.data, ['createdAt'])
          dispatch(actions.createSuccess({ topic }))
        } catch (error) {
          if (!(error instanceof Error)) return
          console.log(error)
          dispatch(actions.createFailure({ error }))
          notification.error({
            message: 'There was an error creating a new topic',
          })
        }
        break
      }

      case actionTypes.EDIT: {
        const itemId = action.id
        const changeId = uuid()
        try {
          const patch = {
            accountId: action.accountId,
            description: action.description,
            discussed: action.discussed,
            attachments: action.attachments,
            likeAccountIds: action.likeAccountIds,
            recurring: action.recurring,
            updateId: action.updateId,
          }
          if (action.optimistic) {
            dispatch(
              actions.optimisticChange({
                changeId,
                patch,
                itemId,
              }),
            )
          }
          const response = await axios.post<UpdateResBody, UpdateReqBody>('/api/topics/update', {
            id: action.id,
            ...patch,
          })
          dispatch(
            actions.optimisticCommit({
              changeId,
              item: inflateDates(response.data, dateKeys),
            }),
          )
        } catch (error) {
          if (action.optimistic) {
            dispatch(
              actions.optimisticRollback({
                changeId,
                itemId,
              }),
            )
          }
          console.log(error)
          notification.error({
            message: 'There was an error editing the topic',
          })
        }
        break
      }

      case actionTypes.DELETE: {
        try {
          await axios.post<DeleteResBody, DeleteReqBody>('/api/topics/delete', {
            id: action.id,
            updateId: action.updateId,
          })
        } catch (error) {
          console.log(error)
          notification.error({
            message: 'There was an error deleting the topic',
          })
        }
        break
      }

      case actionTypes.LIKE: {
        const updatedData = {
          likes: action.topic.likes ? action.topic.likes + 1 : 1,
          likeAccountIds: [...(action.topic.likeAccountIds || []), action.accountId],
        }
        dispatch(
          actions.change({
            topic: { ...action.topic, ...updatedData },
          }),
        )
        try {
          await axios.post<UpdateResBody, UpdateReqBody>('/api/topics/update', {
            id: action.topic.id,
            ...updatedData,
          })
        } catch (error) {
          console.log(error)
          notification.error({
            message: 'There was an error liking the topic',
          })
        }
        break
      }

      case actionTypes.UNLIKE: {
        const updatedData = {
          likes: action.topic.likes - 1,
          likeAccountIds: action.topic.likeAccountIds.filter((id) => id !== action.accountId),
        }
        dispatch(
          actions.change({
            topic: { ...action.topic, ...updatedData },
          }),
        )
        try {
          await axios.post<UpdateResBody, UpdateReqBody>('/api/topics/update', {
            id: action.topic.id,
            ...updatedData,
          })
        } catch (error) {
          console.log(error)
          notification.error({
            message: 'There was an error disliking the topic',
          })
        }
        break
      }

      case meetingsActionTypes.VIEW: {
        try {
          const state = getState()
          const meeting = meetingsSelectors.getMeetingById(action.id)(state)
          let meetingListeners = listeners[action.id] || []

          // This action gets fired when hot reloading on the meeting page.
          // We need to clear old listeners otherwise they will stack up.
          if (meetingListeners.length) {
            meetingListeners.forEach((detach) => detach())
            meetingListeners = []
          }

          // Create listeners for firebase based off chunks of 10 participants.
          const chunks = _.chunk(meeting.participantIds, 10)
          await Promise.all(
            chunks.map(async (participantsChunk) => {
              let topicsRef = firebase.collection('topics').where('accountId', 'in', participantsChunk)

              if (action.updateId) {
                topicsRef = topicsRef.where('updateId', '==', action.updateId)
              } else {
                topicsRef = topicsRef.where('meetingIds', 'array-contains', action.id)
              }

              // Fetch all the documents once on view and set them.
              const topics = await getRefFormatByIds<Topic>(topicsRef, ['createdAt'])
              dispatch(actions.set({ topics }))

              // Listen for updates from Firebase on same documents.
              const listener = listenForNewUpdates<Topic>(
                topicsRef,
                (change, topic) => {
                  if (change.type === 'added') {
                    dispatch(actions.add({ topic }))
                  }
                  if (change.type === 'modified') {
                    dispatch(actions.change({ topic }))
                  }
                  if (change.type === 'removed') {
                    dispatch(actions.remove({ topic }))
                  }
                },
                ['createdAt'],
              )

              meetingListeners.push(listener)
            }),
          )

          listeners[action.id] = meetingListeners
        } catch (error) {
          // When Firebase has a permissions error, catch the exception and
          // send it to Sentry.
          console.warn(error)
          captureException(error)
        }

        break
      }
      default:
        break
    }
  }
}

export default middleware
