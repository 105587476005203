import { Account } from '@mm/backend/accounts/model'
import { Feedback } from '@mm/backend/feedback/model'
import { Reducer } from 'redux'
import { actions as authActions, actionTypes as authActionTypes } from '../auth'
import {
  clear,
  optimisticChange,
  OptimisticChange,
  optimisticCommit,
  OptimisticCommit,
  optimisticRollback,
  OptimisticRollback,
  remove,
  removeById,
  set,
  State,
  upsert,
} from '../optimistic-redux'

export const selector = 'feedback'

export const actionTypes = {
  CREATE_REQUEST: 'feedback/CREATE_REQUEST',
  CREATE_SUCCESS: 'feedback/CREATE_SUCCESS',
  CREATE_FAILURE: 'feedback/CREATE_FAILURE',
  EDIT: 'feedback/EDIT',
  DELETE: 'feedback/DELETE',
  CLEAR: 'feedback/CLEAR',
  SET: 'feedback/SET',
  ADD: 'feedback/ADD',
  CHANGE: 'feedback/CHANGE',
  REMOVE: 'feedback/REMOVE',
  LIKE: 'feedback/LIKE',
  UNLIKE: 'feedback/UNLIKE',

  OPTIMISTIC_CHANGE: 'feedback/OPTIMISTIC_CHANGE',
  OPTIMISTIC_ROLLBACK: 'feedback/OPTIMISTIC_ROLLBACK',
  OPTIMISTIC_COMMIT: 'feedback/OPTIMISTIC_COMMIT',
} as const

type CreateRequestAction = Pick<
  Feedback,
  'likeText' | 'wishThatText' | 'recipientId' | 'meetingId' | 'attachments' | 'updateId'
>

interface CreateSuccessAction {
  feedback: Feedback
}

interface CreateFailureAction {
  error: Error
}

type EditAction = Partial<
  Pick<
    Feedback,
    'id' | 'likeText' | 'wishThatText' | 'attachments' | 'recipientId' | 'accountId' | 'visibleToRecipient' | 'updateId'
  >
> &
  Pick<Feedback, 'id'> & {
    optimistic?: boolean
  }

type DeleteAction = Pick<Feedback, 'id' | 'updateId'>

interface SetAction {
  feedback: Record<string, Feedback>
}

interface AddAction {
  feedback: Feedback
}

interface ChangeAction {
  feedback: Feedback
}

interface RemoveAction {
  feedback: Feedback
}

interface LikeAction {
  feedback: Feedback
  accountId: Account['id']
}

interface UnlikeAction {
  feedback: Feedback
  accountId: Account['id']
}

export const actions = {
  createRequest: ({ likeText, wishThatText, recipientId, meetingId, attachments, updateId }: CreateRequestAction) => ({
    type: actionTypes.CREATE_REQUEST,
    likeText,
    wishThatText,
    recipientId,
    meetingId,
    attachments,
    updateId,
  }),
  createSuccess: ({ feedback }: CreateSuccessAction) => ({
    type: actionTypes.CREATE_SUCCESS,
    feedback,
  }),
  createFailure: ({ error }: CreateFailureAction) => ({
    type: actionTypes.CREATE_FAILURE,
    error,
  }),
  edit: ({
    id,
    likeText,
    wishThatText,
    attachments,
    recipientId,
    accountId,
    visibleToRecipient,
    optimistic,
    updateId,
  }: EditAction) => ({
    type: actionTypes.EDIT,
    id,
    likeText,
    wishThatText,
    attachments,
    recipientId,
    accountId,
    visibleToRecipient,
    optimistic,
    updateId,
  }),
  delete: ({ id, updateId }: DeleteAction) => ({
    type: actionTypes.DELETE,
    id,
    updateId,
  }),
  clear: () => ({
    type: actionTypes.CLEAR,
  }),
  set: ({ feedback }: SetAction) => ({
    type: actionTypes.SET,
    feedback,
  }),
  add: ({ feedback }: AddAction) => ({
    type: actionTypes.ADD,
    feedback,
  }),
  change: ({ feedback }: ChangeAction) => ({
    type: actionTypes.CHANGE,
    feedback,
  }),
  remove: ({ feedback }: RemoveAction) => ({
    type: actionTypes.REMOVE,
    feedback,
  }),
  like: ({ feedback, accountId }: LikeAction) => ({
    type: actionTypes.LIKE,
    feedback,
    accountId,
  }),
  unlike: ({ feedback, accountId }: UnlikeAction) => ({
    type: actionTypes.UNLIKE,
    feedback,
    accountId,
  }),
  optimisticChange: (action: OptimisticChange<Feedback>) => ({
    ...action,
    type: actionTypes.OPTIMISTIC_CHANGE,
  }),
  optimisticRollback: (action: OptimisticRollback) => ({
    ...action,
    type: actionTypes.OPTIMISTIC_ROLLBACK,
  }),
  optimisticCommit: (action: OptimisticCommit<Feedback>) => ({
    ...action,
    type: actionTypes.OPTIMISTIC_COMMIT,
  }),
}

export type actions = ReturnType<typeof actions[keyof typeof actions]>

interface FeedbackState extends State<Feedback> {
  form: {
    loading: boolean
    error?: Error
  }
}

const initialState: FeedbackState = {
  byId: {},
  optimisticStorage: {},
  form: {
    loading: false,
  },
}

export const reducer: Reducer<FeedbackState, actions | authActions> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET: {
      return set(state, action.feedback)
    }
    case actionTypes.ADD:
    case actionTypes.CHANGE: {
      return upsert(state, action.feedback)
    }
    case actionTypes.REMOVE: {
      return remove(state, action.feedback)
    }
    case actionTypes.DELETE: {
      return removeById(state, action.id)
    }
    case actionTypes.CLEAR: {
      return clear(state)
    }
    case actionTypes.CREATE_REQUEST: {
      return {
        ...state,
        form: {
          loading: true,
        },
      }
    }
    case actionTypes.CREATE_SUCCESS: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.feedback.id]: action.feedback,
        },
        form: {
          loading: false,
        },
      }
    }
    case actionTypes.CREATE_FAILURE: {
      return {
        ...state,
        form: {
          loading: false,
          error: action.error,
        },
      }
    }
    case actionTypes.OPTIMISTIC_CHANGE: {
      return optimisticChange(state, action)
    }
    case actionTypes.OPTIMISTIC_ROLLBACK: {
      return optimisticRollback(state, action)
    }
    case actionTypes.OPTIMISTIC_COMMIT: {
      return optimisticCommit(state, action)
    }
    case authActionTypes.LOG_OUT:
      return initialState
    default:
      return state
  }
}

export const selectors = {
  get: () => (state: Record<typeof selector, FeedbackState>) => state[selector].byId,
  getForm: () => (state: Record<typeof selector, FeedbackState>) => state[selector].form,
  getById: (id: string) => (state: Record<typeof selector, FeedbackState>) => state[selector].byId[id],
}
