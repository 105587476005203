import CloseIcon from '@icons/material/CloseIcon'
import { MeetingParticipantRole, roleMap } from '@mm/common'
import { notification, Popconfirm, Select } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import { AccountsSelector, selectors as accountsSelectors } from '../../../accounts'
import { Div, FlexRow, Text } from '../../../interface'

interface Participant {
  id: string
  role: MeetingParticipantRole
}

export type MeetingParticipantsInputProps = {
  value?: Participant[]
  onChange?: (value: Participant[]) => void
  disabled?: boolean
}

export function MeetingParticipantsInput({ value = [], onChange, disabled = false }: MeetingParticipantsInputProps) {
  const activeAccount = useSelector(accountsSelectors.getActiveAccount())
  const accounts = Object.values(useSelector(accountsSelectors.getAccounts()))

  const handleAdd = (userId: string) => {
    const memberAlreadyExists = value.find(({ id }) => id === userId)
    if (!memberAlreadyExists) {
      onChange?.([...value, { id: userId, role: roleMap.participant }])
      return
    }

    return notification.error({
      message: `Member you are trying to add is already in the meeting`,
    })
  }

  const handleEdit = (id: string, role: MeetingParticipantRole) => {
    const newValue = value.map((mp) => {
      if (mp.id === id) {
        return {
          id,
          role,
        }
      }

      return mp
    })
    onChange?.(newValue)
  }

  const handleRemove = (accountId: string) => () => {
    if (activeAccount && activeAccount.id === accountId) {
      return notification.error({
        message: `Can't remove yourself when creating a meeting.`,
      })
    }
    const newValue = value.filter((mp) => mp.id !== accountId)
    onChange?.(newValue)
  }

  return (
    <>
      {value?.map((mp) => {
        const account = accounts.find((account) => account.id === mp.id)
        return account ? (
          <FlexRow
            key={account.id}
            paddingVertical={0.5}
            paddingHorizontal={1}
            style={{ border: '1px solid #eee', marginBottom: 6, borderRadius: 4 }}
            justifyContent="space-between"
          >
            <Text>{`${account.name} <${account.email}>`}</Text>
            <Div paddingHorizontal={1}>
              <Select
                tokenSeparators={[',']}
                defaultValue={mp.role}
                placeholder="Select member role"
                optionFilterProp="children"
                onChange={(val) => handleEdit(account.id, val)}
                disabled={disabled}
              >
                {Object.entries(roleMap).map(([role, value]) => (
                  <Select.Option value={value} key={value}>
                    {role}
                  </Select.Option>
                ))}
              </Select>
            </Div>
            <Popconfirm
              title={`Are you sure you want to remove ${account.name} from meeting?`}
              onConfirm={handleRemove(account.id)}
              okText={`Remove ${account.name}`}
              cancelText="Cancel"
              okType="danger"
              disabled={disabled}
            >
              <CloseIcon
                style={{
                  color: '#999',
                  cursor: 'pointer',
                }}
              />
            </Popconfirm>
          </FlexRow>
        ) : (
          ''
        )
      })}

      <Div marginVertical={1}>
        <Text>Add new participant</Text>
      </Div>

      <AccountsSelector
        width={270}
        onChange={(id: string) => handleAdd(id)}
        disabled={disabled}
        allowClear={true}
        excludeIds={value.map((mp) => mp.id)}
        value=""
      />
    </>
  )
}
