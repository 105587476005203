import React, { useEffect } from 'react'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Radio } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { FlexRow, RichText } from '../../ui'
import { Account } from '@mm/backend/accounts/model'
import { Meeting } from '@mm/backend/meetings/model'
import { actions, selectors as actionSelectors } from '../../actions'
import { selectors as groupSelectors } from '../../groups'
import { selectors as accountSelectors } from '../../accounts'
import ActionsBlockItem, { Block } from '../../meetings/components/ActionsBlockItem'
import { format, isPast } from 'date-fns'
import { getActionDueDate } from '../../meetings/utils/meetingInfoHelpers'

export type AccountSettingsActionsTabProps = {
  account: Account
}

const getAccountFirstName = (account: Account) => {
  const [firstName] = account && 'name' in account ? account.name.split(' ') : ['']
  return firstName
}

export const AccountSettingsActionsTab = ({ account }: AccountSettingsActionsTabProps) => {
  const dispatch = useDispatch()
  const [actionsView, setActionsView] = React.useState<'rich' | 'plain'>('rich')
  const [actionVisibilityValue, setActionVisiblilityToggle] = React.useState('progress')
  const activeAccount = useSelector(accountSelectors.getActiveAccount())
  const accounts = useSelector(accountSelectors.getAccounts())
  const actionsStore = useSelector(actionSelectors.get())
  // redux always contains all groups active user is participant of (and may contain other groups)
  const groups = useSelector(groupSelectors.getGroups())
  const actionsForm = useSelector(actionSelectors.getForm())
  const newlyCreatedIds = useSelector(actionSelectors.getNewlyCreatedIds())
  const newlyDeletedIds = useSelector(actionSelectors.getDeletedIds())
  const [actionsState, setActionsState] = React.useState<{ initialized: boolean; all: string[]; created: string[] }>({
    all: [],
    created: [],
    initialized: false,
  })
  useEffect(() => {
    if (!actionsState.initialized && Object.keys(actionsStore).length) {
      const relevantGroups = Object.values(groups)
        .filter((group) => group.participantIds.includes(activeAccount.id))
        .map((group) => group.id)
      const relevantActions = Object.values(actionsStore).filter(
        (action) =>
          action.assignedTo === account.id &&
          (action.groupIds?.some((groupId) => relevantGroups.includes(groupId)) ||
            (action.groupId && relevantGroups.includes(action.groupId)) ||
            (!action.groupIds && !action.groupId)),
      )
      setActionsState({
        initialized: true,
        all: relevantActions.map((action) => action.id),
        created: relevantActions.filter((action) => action.status === 'CREATED').map((action) => action.id),
      })
    }
  }, [Object.keys(actionsStore).length])

  const usersActions = Object.values(actionsStore).filter((action) => {
    return (
      !newlyDeletedIds.includes(action.id) &&
      (newlyCreatedIds.includes(action.id) || actionsState.all.includes(action.id))
    )
  })

  const actionsByFeatureId = _.groupBy(usersActions, 'featureId')
  const subActionIds: Array<string> = []
  const usersActionsOrdered = _.orderBy(usersActions, 'dueAt', actionVisibilityValue === 'progress' ? 'asc' : 'desc')
  usersActionsOrdered.forEach((action) => {
    const subActions = actionsByFeatureId[action.id]
    if (subActions?.length) {
      subActions.forEach((subAction) => subActionIds.push(subAction.id))
    }
  })

  const filteredUserActions = usersActionsOrdered.filter((action) => {
    const subActions = actionsByFeatureId[action.id]
    if (action.status !== 'CREATED' && subActions && subActions.some((subAction) => subAction.status === 'CREATED')) {
      return true
    }

    return actionVisibilityValue === 'progress'
      ? action.status === 'CREATED' && !subActionIds.includes(action.id)
      : !subActionIds.includes(action.id)
  })

  const actionsBlocksForFeatureId = (id: string, showNotDone = false) => {
    const blocks: Array<Block> = []
    const actionsForFeature = actionsByFeatureId[id] || []
    actionsForFeature.map((action) => {
      blocks.push({
        type: 'action',
        id: action.id,
        firstName: getAccountFirstName(account),
        showNotDone,
        participants,
        activeAccount,
        meeting: {} as Meeting,
        action,
        blocks: [],
        allowAddActions: false,
        allowAddComments: false,
      })
    })
    return blocks
  }

  const participants = _(accounts)
    .map((account: Account) => ({
      name: getAccountFirstName(account),
      id: account.id,
    }))
    .uniqBy((participant) => participant.id)
    .value()

  const handleCreate = () => {
    if (!account) {
      return
    }

    dispatch(
      actions.createRequest({
        description: '',
        accountId: account.id,
        assignedTo: account.id,
        featureId: '',
        featureType: 'action',
        featurePreviewText: '',
        meetingIds: [],
        groupIds: [],
        participantIds: participants.map((participant) => participant.id),
        dueAt: getActionDueDate(null),
      }),
    )
  }

  return (
    <div>
      <FlexRow style={{ justifyContent: 'space-between', marginBottom: 17 }}>
        <h2 style={{ marginBottom: 0 }}>Actions</h2>

        <FlexRow>
          <Radio.Group buttonStyle="outline" value={actionsView}>
            <Radio.Button value="rich" onClick={() => setActionsView('rich')}>
              Actions
            </Radio.Button>
            <Radio.Button value="plain" onClick={() => setActionsView('plain')}>
              Plain Text
            </Radio.Button>
          </Radio.Group>

          <Radio.Group buttonStyle="outline" value={actionVisibilityValue}>
            <Radio.Button value="progress" onClick={() => setActionVisiblilityToggle('progress')}>
              In progress
            </Radio.Button>
            <Radio.Button value="all" onClick={() => setActionVisiblilityToggle('all')}>
              All
            </Radio.Button>
          </Radio.Group>
          <Button
            data-cy="actions-page-create-action"
            type="primary"
            loading={actionsForm.loading}
            icon={<PlusOutlined />}
            onClick={handleCreate}
          >
            Create Action
          </Button>
        </FlexRow>
      </FlexRow>

      {actionsView === 'rich'
        ? filteredUserActions.map((action) => (
            <ActionsBlockItem
              type="action"
              key={action.id}
              id={action.id}
              firstName={getAccountFirstName(account)}
              activeAccount={activeAccount}
              showContext={true}
              showNotDone={isPast(action.dueAt)}
              action={action}
              participants={participants}
              blocks={actionsBlocksForFeatureId(action.id)}
              allowAddActions
              allowAddComments={false}
              meeting={null}
            />
          ))
        : usersActionsOrdered
            .filter((action) => action.assignedTo === account.id && action.description)
            .map((action) => (
              <li key={action.id} style={{ overflowWrap: 'break-word', wordWrap: 'break-word' }}>
                {action.featurePreviewText
                  ? `${_.startCase(action.featureType.toLowerCase())}: ${action.featurePreviewText}`
                  : ''}
                <RichText htmlString={action.description + (action.dueAt ? ` (${format(action.dueAt, 'M/d')})` : '')} />
              </li>
            ))}
    </div>
  )
}

export default AccountSettingsActionsTab
