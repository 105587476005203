export type IntegrationTypes = AccountIntegrationTypes | CompanyIntegrationTypes

export enum AccountIntegrationTypes {
  GOOGLE_CALENDAR = 'GOOGLE_CALENDAR',
  ASANA = 'ASANA',
  SLACK = 'SLACK',
}

export enum CompanyIntegrationTypes {
  MONDAY = 'MONDAY',
}

export enum IntegrationStatus {
  NOT_CONNECTED = 'NOT_CONNECTED',
  CONNECTED = 'CONNECTED',
  PAUSED = 'PAUSED',
  SETUP_REQUIRED = 'SETUP_REQUIRED',
}
