import { Account } from '@mm/backend/accounts/model'
import { actions as accountActions, selectors } from '../../accounts'
import { Editable } from '../../editor'
import { Button } from '../../interface'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

export type AccountSettingsProfileTabProps = {
  account: Account
}

const stripHtml = (string: string) => string.replace(/(<([^>]+)>)|(&.*?;)/gi, '')

export const AccountSettingsProfileTab = ({ account }: AccountSettingsProfileTabProps) => {
  const dispatch = useDispatch()

  const form = useSelector(selectors.getForm())

  const { control, handleSubmit } = useForm({
    defaultValues: {
      name: account.name,
      bio: account.bio,
    },
  })

  const onSubmit = handleSubmit((data) => {
    dispatch(
      accountActions.updateRequest({
        account: {
          id: account.id,
          name: stripHtml(data.name),
          bio: data.bio,
        },
      }),
    )
  })

  return (
    <div>
      <form onSubmit={onSubmit}>
        <h2>Profile</h2>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <Editable
              selectionToolbar={false}
              prefix={<strong style={{ marginLeft: 4 }}>Name:</strong>}
              initialValue={field.value}
              onChangeHtml={field.onChange}
              onBlur={field.onBlur}
            />
          )}
        />

        <div style={{ height: 20 }} />

        <Controller
          name="bio"
          control={control}
          render={({ field }) => (
            <Editable
              selectionToolbar={false}
              prefix={<strong style={{ marginLeft: 4 }}>Personal Info:</strong>}
              initialValue={field.value}
              onChangeHtml={field.onChange}
              onBlur={field.onBlur}
            />
          )}
        />

        <div style={{ height: 20 }} />

        <Button type="submit" color="primary" loading={form.loading ?? false}>
          Save
        </Button>
      </form>
    </div>
  )
}
