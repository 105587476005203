import { useMutation } from 'react-query'
import axios from 'axios'
import { DeleteReqBody, DeleteResBody } from '../../../pages/api/issues/[id]'

const deleteIssue = async (id: string) => {
  const { data } = await axios.delete<DeleteResBody, DeleteReqBody>(`/api/issues/${id}`)
  return data
}

const useDeleteIssueMutation = () => {
  return useMutation(deleteIssue)
}

export default useDeleteIssueMutation
