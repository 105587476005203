import React from 'react'
import { FlexRow } from '../../interface'
import { Tooltip } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

interface Props {
  title: string
  Icon: React.FC<any>
  loading?: boolean
  onClick: (params?: any) => void
}
const NOOP = () => {} // eslint-disable-line

const TooltipIconButton: React.FC<Props> = ({ title, Icon, loading, onClick }) => (
  <Tooltip title={title}>
    <FlexRow
      style={{ color: '#999', cursor: 'pointer' }}
      background="background"
      paddingVertical={0.5}
      paddingHorizontal={1}
      rounded={true}
      onClick={loading ? NOOP : onClick}
    >
      {loading ? <LoadingOutlined style={{ fontSize: 18 }} spin /> : <Icon width={18} height={18} />}
    </FlexRow>
  </Tooltip>
)

export default TooltipIconButton
