import React from 'react'
import { SpacingInterval } from './theme'
import { Div, Props as DivProps } from './Div'
import { FlexRow } from './Flex'
import { TitleText } from './Text'

interface Props extends DivProps {
  id?: string
  padding?: SpacingInterval
  title?: string
  style?: React.CSSProperties
  renderRight?: React.ReactElement
  onClick?: () => void
}

export const Card: React.FC<Props> = ({ title, padding = 2, children, renderRight, onClick, style, ...props }) => {
  return (
    <Div background="backgroundWhite" rounded={true} onClick={onClick} {...props} style={{ ...style }}>
      {title || renderRight ? (
        <FlexRow padding={padding} paddingBottom={0} justifyContent="space-between">
          <TitleText lineHeight={1}>{title}</TitleText>
          {renderRight}
        </FlexRow>
      ) : null}
      <Div padding={padding}>{children}</Div>
    </Div>
  )
}
