import React from 'react'
import _ from 'lodash'
import BlockTitle from './BlockTitle'
import BlockIndent from './BlockIndent'
import { Meeting } from '@mm/backend/meetings/model'
import { Account } from '@mm/backend/accounts/model'
import { Action } from '@mm/backend/actions/model'
import { FocusColorWrap, RichText } from '../../ui'
import ClipboardCheckOutlineIcon from '@icons/material/ClipboardCheckOutlineIcon'
import useSendActionEmailMutation from '../hooks/useSendActionEmailMutation'
import useCompleteMeetingMutation from '../hooks/useCompleteMeetingMutation'
import { Button, Radio } from 'antd'
import { Block as ActionsBlockItem } from './ActionsBlockItem'
import BlockResolver from './BlockResolver'
import { format } from 'date-fns'
import { FlexRow, Text } from '../../interface'
import { notification } from 'antd'

export const TYPE = 'meetingSummary'

export interface Block {
  type: typeof TYPE
  id: typeof TYPE
  title: string
  subtitle: string
  meeting: Meeting
  participants: Array<Account>
  actions: Array<Action>
  actionsBlocks: Array<ActionsBlockItem>
  color: string
}

const getAccountFirstName = (account: Account) => {
  const [firstName] = account && 'name' in account ? account.name.split(' ') : ['']
  return firstName
}

export const MeetingSummaryBlock = ({
  id,
  meeting,
  participants,
  title,
  subtitle,
  actions,
  actionsBlocks,
  color,
}: Block) => {
  const sendActionEmailMutation = useSendActionEmailMutation()
  const completeMeetingMutation = useCompleteMeetingMutation()
  const [actionsView, setActionsView] = React.useState<'rich' | 'plain'>('rich')

  const modal = {
    title: 'About the Summary section',
    youtubeId: '9HOZ982aFic',
    content: (
      <div>
        <p>Review the actions that were created during this meeting.</p>
      </div>
    ),
  }

  const handleSendActionEmail = async () => {
    try {
      await sendActionEmailMutation.mutateAsync(meeting.id)

      notification.success({
        message: 'An email with actions from this meeting has been sent.',
      })
    } catch (error) {
      console.error(error)
      notification.error({
        message: 'There was a problem sending an email with actions form this meeting.',
      })
    }
  }

  return (
    <FocusColorWrap color={color}>
      <BlockTitle
        id={`${id}-${meeting.id}`}
        title={title}
        subtitle={subtitle}
        color={color}
        modal={modal}
        icon={<ClipboardCheckOutlineIcon />}
        content={
          <BlockIndent>
            <Radio.Group
              value={actionsView}
              onChange={(e) => setActionsView(e.target.value)}
              style={{ marginBottom: 15 }}
            >
              <Radio.Button value="rich">Actions</Radio.Button>
              <Radio.Button value="plain">Plain Text</Radio.Button>
            </Radio.Group>

            {actionsView === 'rich'
              ? participants.map((account) =>
                  account ? (
                    <div key={account.id} className="ant-comment-content-detail">
                      <strong>{getAccountFirstName(account)}</strong>
                      {actionsBlocks
                        .filter((block) => block.action.assignedTo === account.id && block.action.description)
                        .map((block) => (
                          <BlockResolver key={block.id} block={block} />
                        ))}
                    </div>
                  ) : null,
                )
              : participants.map((account) =>
                  account ? (
                    <div key={account.id} className="ant-comment-content-detail plain-text">
                      <strong>{getAccountFirstName(account)}</strong>
                      <div contentEditable={true}>
                        <ul>
                          {actions
                            .filter((action) => action.assignedTo === account.id && action.description)
                            .map((action) => (
                              <li key={action.id} style={{ overflowWrap: 'break-word', wordWrap: 'break-word' }}>
                                {action.featurePreviewText
                                  ? `${_.startCase(action.featureType.toLowerCase())}: ${action.featurePreviewText}`
                                  : ''}
                                <RichText
                                  htmlString={
                                    action.description + (action.dueAt ? ` (${format(action.dueAt, 'M/d')})` : '')
                                  }
                                />
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  ) : null,
                )}

            <FlexRow top={2} gap={1}>
              <Button
                loading={sendActionEmailMutation.isLoading}
                onClick={handleSendActionEmail}
                disabled={!actions.length}
                size="large"
              >
                Send Email with Actions
              </Button>

              {meeting.complete ? (
                <Text>Meeting Complete 🎉</Text>
              ) : (
                <Button
                  loading={completeMeetingMutation.isLoading}
                  onClick={() => completeMeetingMutation.mutate(meeting.id)}
                  size="large"
                >
                  Close Meeting
                </Button>
              )}
            </FlexRow>
          </BlockIndent>
        }
      />
    </FocusColorWrap>
  )
}

export default MeetingSummaryBlock
