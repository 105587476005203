import { Middleware } from 'redux'
import firebase, { getRefFormatByIds, listenForNewUpdates } from '../firebase'
import { actions, actionTypes } from './store'
import {
  actions as meetingsActions,
  actionTypes as meetingsActionTypes,
  selectors as meetingsSelectors,
} from '../meetings'
import { Issue } from '@mm/backend/issues/model'
import { ReqBody as UpdateReqBody, ResBody as UpdateResBody } from '../../pages/api/issues/update'
import { ReqBody as DeleteReqBody, ResBody as DeleteResBody } from '../../pages/api/issues/delete'
import { captureException } from '../sentry'
import axios from 'axios'
import { notification } from 'antd'

// Key listeners based off meeting.id so that expanding other meetings
// will not remove listeners from the main meeting that is loaded.
const listeners: Record<string, Array<() => void>> = {}

const middleware: Middleware = ({ dispatch, getState }) => {
  return (next) => async (action: actions | meetingsActions) => {
    next(action)

    switch (action.type) {
      case actionTypes.EDIT: {
        try {
          await axios.post<UpdateResBody, UpdateReqBody>('/api/issues/update', {
            id: action.id,
            accountId: action.accountId,
            description: action.description,
            whatWasDoneText: action.whatWasDoneText,
            proposedSolutionText: action.proposedSolutionText,
            finalSolutionText: action.finalSolutionText,
            type: action.thingType,
            approvedByIds: action.approvedByIds,
            attachments: action.attachments,
          })
        } catch (error) {
          console.log(error)
          notification.error({
            message: 'There was an error editing the issue',
          })
        }
        break
      }

      case actionTypes.DELETE: {
        try {
          await axios.post<DeleteResBody, DeleteReqBody>('/api/issues/delete', {
            id: action.id,
          })
        } catch (error) {
          console.log(error)
          notification.error({
            message: 'There was an error deleting the issue',
          })
        }
        break
      }

      case actionTypes.LIKE: {
        const updatedData = {
          likes: action.issue.likes ? action.issue.likes + 1 : 1,
          likeAccountIds: [...(action.issue.likeAccountIds || []), action.accountId],
        }
        dispatch(
          actions.change({
            issue: { ...action.issue, ...updatedData },
          }),
        )

        try {
          await axios.post<UpdateResBody, UpdateReqBody>('/api/issues/update', {
            id: action.issue.id,
            ...updatedData,
          })
        } catch (error) {
          console.log(error)
          notification.error({
            message: 'There was an error liking the issue',
          })
        }
        break
      }

      case actionTypes.UNLIKE: {
        const updatedData = {
          likes: action.issue.likes - 1,
          likeAccountIds: action.issue.likeAccountIds.filter((id) => id !== action.accountId),
        }
        dispatch(
          actions.change({
            issue: { ...action.issue, ...updatedData },
          }),
        )

        try {
          await axios.post<UpdateResBody, UpdateReqBody>('/api/issues/update', {
            id: action.issue.id,
            ...updatedData,
          })
        } catch (error) {
          console.log(error)
          notification.error({
            message: 'There was an error disliking the issue',
          })
        }
        break
      }

      case meetingsActionTypes.VIEW: {
        try {
          const state = getState()
          const meeting = meetingsSelectors.getMeetingById(action.id)(state)
          let meetingListeners = listeners[action.id] || []

          // This action gets fired when hot reloading on the meeting page.
          // We need to clear old listeners otherwise they will stack up.
          if (meetingListeners.length) {
            meetingListeners.forEach((detach) => detach())
            meetingListeners = []
          }

          let issuesRef

          if (action.updateId) {
            issuesRef = firebase.collection('issues').where('updateId', '==', action.updateId)
          } else {
            issuesRef = firebase.collection('issues').where('meetingIds', 'array-contains', action.id)
          }

          // Fetch all the documents once on view and set them.
          const issues = await getRefFormatByIds<Issue>(issuesRef, [
            'createdAt',
            'updatedAt',
            'decisionDueDate',
            'commentsDueDate',
          ])
          dispatch(actions.set({ issues }))

          // Listen for updates from Firebase on same documents.
          const listener = listenForNewUpdates<Issue>(
            issuesRef,
            (change, issue) => {
              if (change.type === 'added') {
                dispatch(actions.add({ issue }))
              }
              if (change.type === 'modified') {
                dispatch(actions.change({ issue }))
              }
              if (change.type === 'removed') {
                dispatch(actions.remove({ issue }))
              }
            },
            ['createdAt', 'updatedAt', 'decisionDueDate', 'commentsDueDate'],
          )
          meetingListeners.push(listener)

          listeners[action.id] = meetingListeners
        } catch (error) {
          // When Firebase has a permissions error, catch the exception and
          // send it to Sentry.
          console.warn(error)
          captureException(error)
        }
        break
      }
      default:
        break
    }
  }
}

export default middleware
