import styled from '@emotion/styled'
import { color, ColorName, spacing, SpacingInterval } from './theme'

export interface Props {
  height?: SpacingInterval
  width?: SpacingInterval

  top?: SpacingInterval
  right?: SpacingInterval
  bottom?: SpacingInterval
  left?: SpacingInterval
  vertical?: SpacingInterval
  horizontal?: SpacingInterval
  spacing?: SpacingInterval

  marginTop?: SpacingInterval
  marginRight?: SpacingInterval
  marginBottom?: SpacingInterval
  marginLeft?: SpacingInterval
  marginVertical?: SpacingInterval
  marginHorizontal?: SpacingInterval
  margin?: SpacingInterval

  paddingTop?: SpacingInterval
  paddingRight?: SpacingInterval
  paddingBottom?: SpacingInterval
  paddingLeft?: SpacingInterval
  paddingVertical?: SpacingInterval
  paddingHorizontal?: SpacingInterval
  padding?: SpacingInterval

  background?: ColorName
  rounded?: boolean
}

export const Div = styled.div<Props>(
  ({
    theme,
    height,
    width,
    top,
    right,
    bottom,
    left,
    vertical,
    horizontal,
    spacing: spacingProp,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    marginVertical,
    marginHorizontal,
    margin,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    paddingVertical,
    paddingHorizontal,
    padding,
    background,
    rounded,
  }) => ({
    height: spacing(height),
    width: spacing(width),

    margin: spacing(spacingProp || margin),
    marginTop: spacing(top || marginTop),
    marginRight: spacing(right || marginRight),
    marginBottom: spacing(bottom || marginBottom),
    marginLeft: spacing(left || marginLeft),
    ...(vertical || marginVertical
      ? {
          marginTop: spacing(vertical || marginVertical),
          marginBottom: spacing(vertical || marginVertical),
        }
      : {}),
    ...(horizontal || marginHorizontal
      ? {
          marginRight: spacing(horizontal || marginHorizontal),
          marginLeft: spacing(horizontal || marginHorizontal),
        }
      : {}),

    padding: spacing(padding),
    paddingTop: spacing(paddingTop),
    paddingRight: spacing(paddingRight),
    paddingBottom: spacing(paddingBottom),
    paddingLeft: spacing(paddingLeft),
    ...(paddingVertical
      ? {
          paddingTop: spacing(paddingVertical),
          paddingBottom: spacing(paddingVertical),
        }
      : {}),
    ...(paddingHorizontal
      ? {
          paddingRight: spacing(paddingHorizontal),
          paddingLeft: spacing(paddingHorizontal),
        }
      : {}),

    backgroundColor: background && color(background)({ theme }),
    borderRadius: rounded ? theme.radius : undefined,
  }),
)
