import { getSelectionHtml, ToolbarItem } from '../../editor'

export type AddActionHandler = (description: string) => void

export const addActionToolbarItem = (handleAddAction: AddActionHandler): ToolbarItem => (editor) => ({
  label: 'Add Action',
  onClick: () => {
    handleAddAction(getSelectionHtml(editor))
  },
})
