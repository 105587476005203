import React from 'react'
import { Typography } from 'antd'
import BlockResolver from './BlockResolver'
import BlockEmpty from './BlockEmpty'
import BlockTitle from './BlockTitle'
import { Block as ParticipantsBlockItemType } from './ParticipantsBlockItem'
import { Block as ActionsBlockItemType } from './ActionsBlockItem'
import { FocusColorWrap } from '../../ui'
import CheckCircleOutlineIcon from '@icons/material/CheckCircleOutlineIcon'

export const TYPE = 'actionsPrior'

export interface Block {
  type: typeof TYPE
  id: typeof TYPE
  title: string
  subtitle: string
  color: string
  showIfEmpty?: boolean
  meetingId?: string
  blocks: Array<ParticipantsBlockItemType | ActionsBlockItemType>
  updateId?: string
}

export const ActionsPriorBlock = ({ id, title, subtitle, color, meetingId = '', blocks = [], updateId }: Block) => {
  const modal = {
    title: 'About the Actions from Last Section',
    youtubeId: 'iIiaEllQG0I',
    content: (
      <div>
        <p>This is where you check in on the actions you committed to doing by the start of this meeting.</p>
        <p>If an action is marked Not Done and Regrettable, please add a follow-up action.</p>
      </div>
    ),
  }

  const renderActionPriorBlocks = (): JSX.Element[] | JSX.Element | React.ReactNode => {
    if (blocks.length) {
      return blocks.map((block) => {
        const hasBlocks = React.Children.count(block.children)
        const key = block.id

        if (updateId && !hasBlocks) {
          return <BlockEmpty label="No actions from you in previous meetings" />
        }

        if (hasBlocks) {
          return updateId ? block.children : <BlockResolver key={key} block={block} />
        }

        return block.showIfEmpty ? (
          <Typography.Paragraph type="secondary" style={{ textAlign: 'center' }} key={key}>
            {block.firstName} had no actions from previous meetings.
          </Typography.Paragraph>
        ) : (
          <React.Fragment key={key} />
        )
      })
    }

    return (
      <Typography.Paragraph type="secondary" style={{ textAlign: 'center' }}>
        No actions from previous meetings.
      </Typography.Paragraph>
    )
  }

  return (
    <FocusColorWrap color={color}>
      <BlockTitle
        id={`${id}-${updateId || meetingId}`}
        title={title}
        subtitle={subtitle}
        color={color}
        icon={<CheckCircleOutlineIcon />}
        modal={modal}
        content={renderActionPriorBlocks()}
      />
    </FocusColorWrap>
  )
}

export default ActionsPriorBlock
