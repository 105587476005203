import { Account } from '../accounts'
import { KeysOfType } from '../helpers'
import { Meeting } from '../meetings'
import { Group } from '../groups'

/**
 * Actions are created by users in the UI and represent an intent to perform an action (i.e. a task) before a subsequent
 * meeting.
 *
 * Most of the time, actions will be associated with another meeting feature
 * (e.g. `goodThing`, `issue`, `topic`, `goal`, `feedback`) and will have the corresponding
 * `featureId` and `featureType` to support that.
 *
 * If they are created without a tie to another feature the `featureId` will be `null`
 * and the `featureType` will be `action`.
 */
export interface Action {
  id: string
  description: string
  status: 'CREATED' | 'DONE' | 'NOT_DONE' | 'NOT_DONE_REGRETTABLE' | 'NOT_DONE_NOT_REGRETTABLE'
  statusChangedInMeetingId: Meeting['id'] // To keep track of actions that were intracted with in a particular meeting.
  reason: string
  accountId: Account['id']
  assignedTo: Account['id']
  featureId: string | null // Actions with null featureId are standalone actions, not associated with any feature.
  featureType: 'goodThing' | 'issue' | 'topic' | 'goal' | 'feedback' | 'action' | 'comment' | 'library' // “Action” featureType is a standalone action, not associated with any feature
  featurePreviewText: string
  meetingId?: Meeting['id']
  meetingIds: Array<Meeting['id']>
  participantIds: Array<Account['id']>
  groupId?: Group['id']
  groupIds?: Array<Group['id']>
  createdAt: Date
  updatedAt: Date
  dueAt: Date
}

export const dateKeys: KeysOfType<Action, Date>[] = ['updatedAt', 'createdAt', 'dueAt']
