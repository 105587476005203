import { Goal } from '@mm/backend/goals/model'
import { Meeting } from '@mm/backend/meetings/model'
import { isPast } from 'date-fns'
import _ from 'lodash'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { selectors as accountsSelectors } from '../../accounts'
import { selectors as actionsSelectors } from '../../actions'
import { selectors as commentsSelectors } from '../../comments'
import { selectors as groupsSelectors } from '../../groups'
import { BlockResolver } from '../../meetings'
import { Block as ActionsBlockItem, Block as ActionsBlock } from '../../meetings/components/ActionsBlockItem'
import BlockAdd from '../../meetings/components/BlockAdd'
import { Block as CommentsBlock } from '../../meetings/components/CommentsBlockItem'
import { Block as GoalsBlock } from '../../meetings/components/GoalsBlockItem'
import { selectors } from '../store'
import { CreateGoal } from './CreateGoal'

export type UserGoalsWithActionsProps = {
  accountId: string
  meeting: Meeting | null
  showPastGoalsAndActions?: boolean
  reviewView?: boolean
}

export function UserGoalsWithActions({
  accountId,
  meeting,
  showPastGoalsAndActions = false,
  reviewView = false,
}: UserGoalsWithActionsProps): React.ReactElement {
  const allGoals = useSelector(selectors.get())
  const accountsStore = useSelector(accountsSelectors.getAccounts())
  const group = useSelector(meeting == null ? () => null : groupsSelectors.getGroupById(meeting.groupId))
  const actionsStore = useSelector(actionsSelectors.get())
  const actionsArray = Object.values(actionsStore)
  const actionsByFeatureId = _.groupBy(actionsArray, 'featureId')
  const commentsStore = useSelector(commentsSelectors.get())
  const commentsArray = Object.values(commentsStore)
  const commentsByParentId = _.groupBy(commentsArray, 'parentId')
  const activeAccount = useSelector(accountsSelectors.getActiveAccount())
  const [showNewForm, setShowNewForm] = useState(false)
  const currentRootGoals = _(allGoals)
    .filter((goal) => goal.accountId === accountId && goal.endAt && !isPast(goal.endAt) && goal.parentId == null)
    .orderBy('endAt', 'asc')
    .value()
  const pastRootGoals = _(allGoals)
    .filter((goal) => goal.accountId === accountId && goal.endAt && isPast(goal.endAt) && goal.parentId == null)
    .orderBy('endAt', 'desc')
    .value()

  const goalsByParentId = _.groupBy(allGoals, 'parentId')

  const getAccountFirstName = (id: string) => {
    const account = accountsStore[id]
    const [firstName] = account && 'name' in account ? account.name.split(' ') : ['']
    return firstName
  }

  const participants =
    group?.participantIds.map((id) => ({
      name: getAccountFirstName(id),
      id,
    })) ??
    _.map(accountsStore, ({ id }) => ({
      name: getAccountFirstName(id),
      id,
    }))

  const commentsBlocksForFeatureId = (id: string, replyTo?: string) =>
    _(commentsByParentId[id] ?? [])
      .filter((comment) => comment.pathToRoot?.[0] === replyTo)
      .orderBy('createdAt')
      .map(
        (comment): CommentsBlock => ({
          type: 'comment',
          id: comment.id,
          activeAccount,
          participants,
          firstName: getAccountFirstName(comment.accountId),
          comment,
          meeting,
          blocks: [...actionsBlocksForFeatureId(comment.id), ...commentsBlocksForFeatureId(id, comment.id)],
        }),
      )
      .value()

  const actionsBlocksForFeatureId = (id: string, showNotDone = false) =>
    (actionsByFeatureId[id] ?? []).map(
      (action): ActionsBlockItem => ({
        type: 'action',
        id: action.id,
        firstName: getAccountFirstName(action.assignedTo),
        showNotDone,
        participants,
        activeAccount,
        meeting,
        action,
        blocks: [...actionsBlocksForFeatureId(action.id), ...commentsBlocksForFeatureId(action.id)],
      }),
    )

  const goalBlocksForParentId = (id: string) => {
    return (goalsByParentId[id] ?? [])
      .filter((goal) => showPastGoalsAndActions || !isPast(goal.endAt))
      .map(
        (goal): GoalsBlock => ({
          type: 'goal',
          id: goal.id,
          goal,
          activeAccount,
          meeting,
          reviewView,
          blocks: actionsNextForGoalId(goal.id).map((action) => ({
            type: 'action',
            id: action.id,
            activeAccount,
            firstName: getAccountFirstName(action.assignedTo),
            participants,
            action,
            meeting,
            reviewView,
            blocks: [...actionsBlocksForFeatureId(action.id), ...commentsBlocksForFeatureId(action.id)],
          })),
        }),
      )
  }

  const actionsNextForGoalId = (goalId: string) =>
    actionsArray.filter((action) => {
      return (
        // action.groupId === meeting.groupId &&
        action.featureId === goalId &&
        (showPastGoalsAndActions ||
          action.status === 'CREATED' ||
          (!!action.statusChangedInMeetingId && action.statusChangedInMeetingId === meeting?.id))
      )
    })

  const renderGoal = (goal: Goal) => (
    <BlockResolver
      key={goal.id}
      block={{
        type: 'goal',
        id: goal.id,
        goal,
        reviewView,
        activeAccount,
        meeting,
        blocks: [
          ...actionsNextForGoalId(goal.id).map(
            (action): ActionsBlock => ({
              type: 'action',
              id: action.id,
              activeAccount,
              firstName: getAccountFirstName(action.assignedTo),
              participants,
              action,
              meeting,
              blocks: [...actionsBlocksForFeatureId(action.id), ...commentsBlocksForFeatureId(action.id)],
            }),
          ),
          ...goalBlocksForParentId(goal.id),
        ],
      }}
    />
  )

  return (
    <div>
      {currentRootGoals.map(renderGoal)}
      {showNewForm && !reviewView && (
        <CreateGoal
          accountId={accountId}
          onCreate={() => setShowNewForm(false)}
          onCancel={() => setShowNewForm(false)}
        />
      )}
      {!showNewForm && !reviewView && (
        <BlockAdd
          label={'Add Goal'}
          color={activeAccount.id === accountId ? '#42a5f5' : '#999'}
          onClick={() => setShowNewForm(true)}
          indent={false}
        />
      )}
      {showPastGoalsAndActions && pastRootGoals.map(renderGoal)}
    </div>
  )
}
