import React from 'react'
import { Action } from '@mm/backend/actions/model'
import { useDispatch, useSelector } from 'react-redux'
import { actions, selectors } from '../store'
import firebase from '../../firebase'

const useRealtimeActions = (featureId: string) => {
  const dispatch = useDispatch()

  React.useEffect(() => {
    const actionsRef = firebase.collection('actions').where('featureId', '==', featureId)

    const unsubscribe = actionsRef.onSnapshot((querySnapshot) => {
      for (const change of querySnapshot.docChanges()) {
        const docData = change.doc.data() as Action
        const action = {
          ...docData,
          createdAt: (docData.createdAt as any).toDate
            ? (docData.createdAt as any).toDate()
            : new Date(docData.createdAt),
          dueAt: (docData.dueAt as any).toDate ? (docData.dueAt as any).toDate() : new Date(docData.dueAt),
        }

        switch (change.type) {
          case 'added':
            dispatch(actions.add({ action }))
            break
          case 'modified':
            dispatch(actions.change({ action }))
            break
          case 'removed':
            dispatch(actions.remove({ action }))
            break
        }
      }
    })

    return unsubscribe
  }, [featureId])

  return useSelector(selectors.getByFeatureId(featureId))
}

export default useRealtimeActions
