import { useQuery } from 'react-query'
import axios from 'axios'
import { ResBody } from '../../../pages/api/slack/linkStatus'
import { UseQueryOptions } from 'react-query/types/react/types'

export function useAccountLinkStatus(options?: UseQueryOptions<ResBody>) {
  return useQuery(
    'slack/activeLink',
    () =>
      axios
        .get<ResBody>('/api/slack/linkStatus', {
          responseType: 'json',
        })
        .then((res) => res.data),
    options,
  )
}
