import { Middleware } from 'redux'
import { actions, actionTypes, selectors } from './store'
import { ResBody as CreateUserResBody } from '../../pages/api/admin/createAccount'
import { ReqBody as LoginReqBody, ResBody as LoginResBody } from '../../pages/api/admin/login'
import { actionTypes as authActionTypes, actions as authActions } from '../auth'
import axios from 'axios'
import { notification } from 'antd'
import firebase, { getRefFormatByIds } from '../firebase'
import { Meeting } from '@mm/backend/meetings'

const middleware: Middleware = ({ dispatch, getState }) => {
  return (next) => async (action: actions | authActions) => {
    next(action)

    switch (action.type) {
      case actionTypes.FETCH_MEETINGS: {
        try {
          const firebaseMeetings = firebase.collection('meetings')
          const meetings = await getRefFormatByIds<Meeting>(firebaseMeetings, ['startAt', 'endAt'])
          dispatch(actions.setMeetings(Object.values(meetings)))
        } catch (error) {
          console.log(error)
        }
        break
      }
      case actionTypes.CREATE_ACCOUNT: {
        try {
          dispatch(actions.adminStart())

          const state = getState()
          const adminKey = selectors.getAdminKey()(state)
          if (!adminKey) throw new Error('Invalid admin credentials. Refresh and try again.')

          const res = await axios.post<CreateUserResBody>('/api/admin/createAccount', action.data, {
            headers: {
              'admin-key': adminKey,
              'Content-Type': 'application/json',
            },
          })
          const { message } = res.data

          dispatch(actions.adminSuccessful({ adminKey, message }))
        } catch (error) {
          if (!(error instanceof Error)) return
          console.log(error)
          dispatch(actions.adminFail({ message: error.message }))
          notification.error({
            message: error.message,
          })
        }
        break
      }
      case actionTypes.LOGIN_ADMIN: {
        try {
          dispatch(actions.adminStart())

          const res = await axios.post<LoginResBody, LoginReqBody>(
            '/api/admin/login',
            {},
            {
              headers: {
                'admin-key': action.adminKey,
                'Content-Type': 'application/json',
              },
            },
          )
          const { errorMessage } = res.data
          if (errorMessage) throw new Error(errorMessage)

          localStorage.setItem('adminKey', action.adminKey)
          dispatch(
            actions.adminSuccessful({
              adminKey: action.adminKey,
              message: '',
            }),
          )
        } catch (error) {
          if (!(error instanceof Error)) return
          console.log(error)
          localStorage.setItem('adminKey', '')
          dispatch(actions.adminFail({ message: error.message }))
        }
        break
      }
      case authActionTypes.LOG_OUT: {
        localStorage.setItem('adminKey', '')
        break
      }
      default:
        break
    }
  }
}

export default middleware
