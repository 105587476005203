import React from 'react'
import { useSelector } from 'react-redux'
import Link from 'next/link'
import { useTheme } from '@emotion/react'
import { Collapse, Typography, Radio, Space, Button } from 'antd'
import { differenceInBusinessDays, isSameDay, subBusinessDays, isPast, format } from 'date-fns'
import ArrowRightIcon from '@icons/material/ArrowRightIcon'
import BlockIndent from './BlockIndent'
import { Meeting } from '@mm/backend/meetings/model'
import { Group } from '@mm/backend/groups/model'
import { Account } from '@mm/backend/accounts/model'
import { roleMap, meetingPrepTimeLine } from '@mm/common'
import { FocusColorWrap } from '../../ui'
import { Div, Text, Flex } from '../../interface'

import { selectors as updateSelectors } from '../../updates'
import { selectors as accountsSelectors } from '../../accounts'
import { getAccountFirstName, getParticipants } from '../utils/meetingInfoHelpers'

export const TYPE = 'meetingPrep'
export interface Block {
  type: typeof TYPE
  id: typeof TYPE
  title: string
  subtitle: string
  activeAccount: Account
  meeting: Meeting
  group: Group
  color: string
}

export const MeetingPrepBlock = ({ meeting, group, color, activeAccount }: Block) => {
  const theme = useTheme()
  let isObserver = false
  const participants = meeting?.participants

  if (participants && activeAccount) {
    isObserver = participants[activeAccount.id]?.role === roleMap.observer
  }
  const updateMetaDataMap = {
    inProgress: 0,
    completed: 0,
    reviewed: 0,
  }
  const meetingUpdates = useSelector(updateSelectors.get())
  const accountsStore = useSelector(accountsSelectors.getAccounts())
  const participantList = getParticipants(group, accountsStore).filter(
    (participant) => participants[participant.id]?.role !== roleMap.observer,
  )

  const participantUpdateLength = Object.keys(meeting.updates).filter(
    (accountId) => accountId !== activeAccount.id && participants[accountId]?.role !== roleMap.observer,
  ).length

  const getUpdateForAccountId = (id: string) => {
    const updateId = meeting.updates[id]?.updateFormId || ''
    return meetingUpdates[updateId]
  }

  for (const accountId in meeting.updates) {
    const update = getUpdateForAccountId(accountId)
    const isUserUpdate = update?.accountId === activeAccount.id

    if (update?.complete) {
      updateMetaDataMap.completed += 1
    }

    if (update?.startedUpdate && !update?.complete) {
      updateMetaDataMap.inProgress += 1
    }

    if (!isUserUpdate && activeAccount && update?.reviewedBy?.includes(activeAccount.id)) {
      updateMetaDataMap.reviewed += 1
    }
  }

  const getUpdateStatus = (participantId: string) => {
    const update = getUpdateForAccountId(participantId)

    if (update?.complete) {
      return 'Completed'
    }

    if (update?.startedUpdate) {
      return 'In process'
    }

    return 'Not started'
  }

  const getCallToAction = (participantId: string) => {
    const update = getUpdateForAccountId(participantId)
    const updateReviewed = update?.reviewedBy?.includes(activeAccount?.id)
    let component

    if (update?.startedUpdate || updateReviewed) {
      component = <ArrowRightIcon style={{ color: theme.font.color.light }} />
    }

    if (update?.complete && !updateReviewed) {
      component = <Button>Review Update</Button>
    }

    if (component) {
      return (
        <Div style={{ cursor: 'pointer' }}>
          <Link href={`/meetings/${meeting.id}/update/${participantId}`}>{component}</Link>
        </Div>
      )
    }
  }

  const getMeetingPrepNextStep = () => {
    if (!meeting.updates[activeAccount.id].complete) {
      return (
        <Flex
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          style={{ padding: theme.font.size.medium }}
        >
          <Typography.Paragraph type="secondary" style={{ margin: 0 }}>
            <Text style={{ fontWeight: 'bold', color: theme.font.color.dark, marginRight: '12px' }}>Next Step:</Text>
            Prepare your update for the meeting
          </Typography.Paragraph>
          <Link href={`/meetings/${meeting.id}/update`}>
            <Button type="primary" data-cy="fill-out-update">
              Prepare Update
            </Button>
          </Link>
        </Flex>
      )
    }

    if (meeting.updates) {
      return (
        <Flex
          direction="column"
          justifyContent="space-evenly"
          alignItems="center"
          style={{ padding: theme.font.size.medium }}
        >
          {participantList.map((participant) => {
            return (
              <Flex
                key={participant.id}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                style={{ width: '100%', paddingBottom: '10px' }}
              >
                <Typography.Paragraph type="secondary" style={{ fontSize: theme.font.size.medium, margin: 0 }}>
                  <Text style={{ color: theme.font.color.dark, marginRight: '12px' }}>
                    {`${getAccountFirstName(accountsStore, participant.id)} `}
                  </Text>
                  {getUpdateStatus(participant.id)}
                </Typography.Paragraph>
                {getCallToAction(participant.id)}
              </Flex>
            )
          })}
        </Flex>
      )
    }

    return <></>
  }

  const getStepValue = () => {
    let stepValue = 1
    const differenceInWorkDays = differenceInBusinessDays(meeting.startAt, new Date())
    meetingPrepTimeLine.forEach(({ key, subDays }) => {
      const sameDay = isSameDay(new Date(), subBusinessDays(meeting.startAt, subDays))
      if (!sameDay && differenceInWorkDays <= 1) {
        stepValue = 2
      }

      if (sameDay) {
        stepValue = key
      }

      if (isPast(meeting.startAt)) {
        stepValue = 3
      }
    })

    return stepValue
  }

  const getRadioBlockColor = (key: number) => {
    return getStepValue() === key ? theme.font.color.dark : theme.font.color.light
  }

  const renderMeetingPrepTimeline = (meeting: Meeting) => {
    return meetingPrepTimeLine.map(({ key, subDays, dateFormat }) => {
      return (
        <Typography.Paragraph
          key={key}
          style={{
            margin: '4px 12px 4px 0px',
            color: getRadioBlockColor(key),
          }}
        >
          {format(subBusinessDays(meeting.startAt, subDays), dateFormat)}
        </Typography.Paragraph>
      )
    })
  }

  const isCollapsedByDefault = (meeting: Meeting) => {
    if (isPast(meeting.startAt)) {
      return 0
    }

    return 1
  }

  return (
    <FocusColorWrap color={color} visibleColor={group.library ? '#fff' : '#ddd'}>
      <BlockIndent>
        {meeting && activeAccount && meeting.updates && meeting.updates[activeAccount.id] && !isObserver && (
          <Collapse
            defaultActiveKey={[isCollapsedByDefault(meeting)]}
            expandIconPosition={'right'}
            style={{ fontSize: theme.font.size.medium }}
          >
            <Collapse.Panel header="Meeting Prep" key={1}>
              <Flex style={{ padding: theme.font.size.medium }}>
                <Flex direction="column">{renderMeetingPrepTimeline(meeting)}</Flex>
                <Radio.Group value={getStepValue()} style={{ fontSize: theme.font.size.medium }}>
                  <Space direction="vertical">
                    <Radio value={1} style={{ color: getRadioBlockColor(1), fontSize: theme.font.size.medium }}>
                      {`Updates Prepared (${updateMetaDataMap.inProgress} in process, ${updateMetaDataMap.completed} completed)`}
                    </Radio>
                    <Radio value={2} style={{ color: getRadioBlockColor(2), fontSize: theme.font.size.medium }}>
                      {`Review updates from participants (${updateMetaDataMap.reviewed} / ${participantUpdateLength})`}
                    </Radio>
                    <Radio value={3} style={{ color: getRadioBlockColor(3), fontSize: theme.font.size.medium }}>
                      Meeting starts
                    </Radio>
                  </Space>
                </Radio.Group>
              </Flex>
              <Div style={{ borderBottom: `1px solid ${theme.color.lightGray}`, margin: '10px 0px' }}></Div>
              {getMeetingPrepNextStep()}
            </Collapse.Panel>
          </Collapse>
        )}
      </BlockIndent>
    </FocusColorWrap>
  )
}

export default MeetingPrepBlock
