import { PlusOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { Button } from 'antd'
import React from 'react'
import { FlexRow } from '../../interface'
import { color } from '../../interface/theme'
import { useConnectGoogleCalendar } from '../hooks'
import { CalendarsList } from './CalendarsList'
import CurvedArrow from './CurvedArrow.svg'

export function CalendarIntegrations(): React.ReactElement {
  const [state, connectGoogleCalendar] = useConnectGoogleCalendar()

  return (
    <div>
      <FlexRow justifyContent="space-between" alignItems="center">
        <Title>Calendar Connections</Title>
        <Button icon={<PlusOutlined />} onClick={connectGoogleCalendar} type="primary" loading={state.loading}>
          Connect Calendar
        </Button>
      </FlexRow>
      <CalendarsList
        renderEmptyList={() => (
          <GuideBox>
            <GuideMessage>Connect a Google Calendar to sync events to MocharyMethod.</GuideMessage>
            <CurvedArrow />
          </GuideBox>
        )}
      />
    </div>
  )
}

const Title = styled.div`
  font-size: 20px;
  line-height: 150%;
  font-weight: 500;
  color: ${color('grayscale.black')};
`

const GuideBox = styled.div`
  float: right;
  margin-right: 115px;
  margin-top: 16px;

  display: flex;
  flex-direction: row;
  gap: 8px;

  color: ${color('darkBlue.300')};
`

const GuideMessage = styled.div`
  width: 164px;
  margin-top: 20px;

  text-align: right;
  font-size: 13px;
  line-height: 140%;
`
