import { Middleware } from 'redux'
import { datadogRum } from '@datadog/browser-rum'

import { actions as accountsActions, actionTypes as accountsActionTypes } from '../accounts'

const middleware: Middleware = () => {
  return (next) => async (action: accountsActions) => {
    next(action)

    switch (action.type) {
      case accountsActionTypes.SET_ACTIVE: {
        datadogRum.setUser({
          id: action.account.id,
          name: action.account.name,
          email: action.account.email,
        })
        break
      }
      default:
        break
    }
  }
}

export default middleware
