import { useMutation } from '@apollo/client'
import { roleMap } from '@mm/common'
import { Form, notification } from 'antd'
import { Duration } from 'luxon'
import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { usePrevious } from 'react-use'
import { CreateMeetingModalCreateDocument } from '../../../../gen/graphql/documents'
import { selectors as accountSelectors } from '../../../accounts'
import { selectors as companySelectors } from '../../../companies'
import { addMeetingToListCache } from './addMeetingToListCache.util'
import { CreateEditMeetingModalView, Fields } from './CreateEditMeetingModal.view'

export type CreateMeetingModalProps = {
  visible: boolean

  onComplete?: () => void
  onCancel?: () => void
}

export function CreateMeetingModal({ visible, onComplete, onCancel }: CreateMeetingModalProps): React.ReactElement {
  const [form] = Form.useForm<Fields>()
  const activeAccount = useSelector(accountSelectors.getActiveAccount())
  const activeCompanyId = useSelector(companySelectors.getActiveCompanyId())

  const [createMeeting, { loading: creating }] = useMutation(CreateMeetingModalCreateDocument, {
    onCompleted: (data) => {
      if (data.createMeeting2.__typename !== 'Meeting2') {
        console.error('Failed to create meeting', data.createMeeting2)
        notification.error({
          message: `There was an error when trying to create the meeting`,
        })
      } else {
        onComplete?.()
      }
    },
    update: (cache, result) => {
      if (result.data?.createMeeting2.__typename === 'Meeting2') {
        addMeetingToListCache(cache, result.data.createMeeting2)
      }
    },
  })

  const handleSubmit = useCallback(async () => {
    try {
      const values = await form.validateFields()
      await createMeeting({
        variables: {
          data: {
            participants: values.participants.map(({ id, role }) => ({ accountId: id, role })),
            title: values.title,
            startAt: values.startAt.getTime(),
            timezone: 'America/Los_Angeles', // TODO [MM-561] add an input
            duration: Duration.fromObject({ minutes: values.duration }).toMillis(),
            recurrence: values.recurrence,
            calendarId: values.calendarId,
            conferenceUrl: values.conferenceUrl,
          },
          companyId: values.companyId,
        },
      })
    } catch (error) {
      console.error('Failed to create meeting', error)
      notification.error({
        message: `There was an error when trying to create the meeting`,
      })
    }
  }, [form, createMeeting])

  const wasVisible = usePrevious(visible)
  useEffect(() => {
    if (!wasVisible && visible) {
      form.resetFields()
    }
  }, [wasVisible, visible, form])

  const formInitialValues: Partial<Fields> = {
    startAt: new Date(),
    duration: 50,
    companyId: activeCompanyId,
    participants: activeAccount ? [{ id: activeAccount.id, role: roleMap.owner }] : [],
  }

  return (
    <CreateEditMeetingModalView
      form={form}
      title="Add Meeting"
      submitText="Create"
      visible={visible}
      loading={false}
      submitting={creating}
      initialValues={formInitialValues}
      onSubmit={handleSubmit}
      onCancel={onCancel}
    />
  )
}
