import { actions as authActions, actionTypes as authActionTypes } from '../auth'
import { Account } from '@mm/backend/accounts/model'
import { Comment } from '@mm/backend/comments/model'
import { Reducer } from 'redux'

export const selector = 'comments'

export const actionTypes = {
  CREATE_REQUEST: 'comments/CREATE_REQUEST',
  CREATE_SUCCESS: 'comments/CREATE_SUCCESS',
  CREATE_FAILURE: 'comments/CREATE_FAILURE',
  EDIT: 'comments/EDIT',
  DELETE: 'comments/DELETE',
  CLEAR: 'comments/CLEAR',
  SET: 'comments/SET',
  ADD: 'comments/ADD',
  CHANGE: 'comments/CHANGE',
  REMOVE: 'comments/REMOVE',
  LIKE: 'comments/LIKE',
  UNLIKE: 'comments/UNLIKE',
} as const

type CreateRequestAction = Pick<Comment, 'description' | 'parentId' | 'meetingId'> & {
  replyTo?: Comment['id']
}

interface CreateSuccessAction {
  comment: Comment
}

interface CreateFailureAction {
  error: Error
}

type EditAction = Partial<Pick<Comment, 'id' | 'accountId' | 'description'>> & Pick<Comment, 'id'>

type DeleteAction = Pick<Comment, 'id'>

interface SetAction {
  comments: Record<string, Comment>
}

interface AddAction {
  comment: Comment
}

interface ChangeAction {
  comment: Comment
}

interface RemoveAction {
  comment: Comment
}

interface LikeAction {
  comment: Comment
  accountId: Account['id']
}

interface UnlikeAction {
  comment: Comment
  accountId: Account['id']
}

export const actions = {
  createRequest: ({ description, parentId, meetingId, replyTo }: CreateRequestAction) => ({
    type: actionTypes.CREATE_REQUEST,
    description,
    parentId,
    meetingId,
    replyTo,
  }),
  createSuccess: ({ comment }: CreateSuccessAction) => ({
    type: actionTypes.CREATE_SUCCESS,
    comment,
  }),
  createFailure: ({ error }: CreateFailureAction) => ({
    type: actionTypes.CREATE_FAILURE,
    error,
  }),
  edit: ({ id, accountId, description }: EditAction) => ({
    type: actionTypes.EDIT,
    id,
    accountId,
    description,
  }),
  delete: ({ id }: DeleteAction) => ({
    type: actionTypes.DELETE,
    id,
  }),
  clear: () => ({
    type: actionTypes.CLEAR,
  }),
  set: ({ comments }: SetAction) => ({
    type: actionTypes.SET,
    comments,
  }),
  add: ({ comment }: AddAction) => ({
    type: actionTypes.ADD,
    comment,
  }),
  change: ({ comment }: ChangeAction) => ({
    type: actionTypes.CHANGE,
    comment,
  }),
  remove: ({ comment }: RemoveAction) => ({
    type: actionTypes.REMOVE,
    comment,
  }),
  like: ({ comment, accountId }: LikeAction) => ({
    type: actionTypes.LIKE,
    comment,
    accountId,
  }),
  unlike: ({ comment, accountId }: UnlikeAction) => ({
    type: actionTypes.UNLIKE,
    comment,
    accountId,
  }),
}

export type actions = ReturnType<typeof actions[keyof typeof actions]>

interface State {
  byId: { [id: string]: Comment }
  form: {
    loading: boolean
    error?: Error
  }
}

const initialState: State = {
  byId: {},
  form: {
    loading: false,
  },
}

export const reducer: Reducer<State, actions | authActions> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET: {
      return {
        ...state,
        byId: {
          ...state.byId,
          ...action.comments,
        },
      }
    }
    case actionTypes.ADD:
    case actionTypes.CHANGE: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.comment.id]: action.comment,
        },
      }
    }
    case actionTypes.REMOVE: {
      const newThingsById = { ...state.byId }

      delete newThingsById[action.comment.id]

      return {
        ...state,
        byId: newThingsById,
      }
    }
    case actionTypes.DELETE: {
      const newThingsById = { ...state.byId }

      delete newThingsById[action.id]

      return {
        ...state,
        byId: newThingsById,
      }
    }
    case actionTypes.CLEAR: {
      return {
        ...state,
        byId: {},
      }
    }
    case actionTypes.CREATE_REQUEST: {
      return {
        ...state,
        form: {
          loading: true,
        },
      }
    }
    case actionTypes.CREATE_SUCCESS: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.comment.id]: action.comment,
        },
        form: {
          loading: false,
        },
      }
    }
    case actionTypes.CREATE_FAILURE: {
      return {
        ...state,
        form: {
          loading: false,
          error: action.error,
        },
      }
    }
    case authActionTypes.LOG_OUT:
      return initialState
    default:
      return state
  }
}

export const selectors = {
  get: () => (state: Record<typeof selector, State>) => state[selector].byId,
  getForm: () => (state: Record<typeof selector, State>) => state[selector].form,
  getById: (id: string) => (state: Record<typeof selector, State>) => state[selector].byId[id],
  getByParentId: (parentId: string) => (state: Record<typeof selector, State>) => {
    const comments = Object.values(selectors.get()(state))
    return comments.filter((comment) => comment.parentId === parentId)
  },
}
