import { Middleware } from 'redux'
import _ from 'lodash'
import firebase, { getRefFormatByIds, listenForNewUpdates, inflateDates } from '../firebase'
import { actions, actionTypes } from './store'
import {
  actionTypes as meetingsActionTypes,
  actions as meetingsActions,
  selectors as meetingsSelectors,
} from '../meetings'
import { selectors as accountSelectors } from '../accounts'
import { dateKeys, Feedback } from '@mm/backend/feedback/model'
import { ReqBody as CreateReqBody, ResBody as CreateResBody } from '../../pages/api/feedback/create'
import { ReqBody as UpdateReqBody, ResBody as UpdateResBody } from '../../pages/api/feedback/update'
import { ReqBody as DeleteReqBody, ResBody as DeleteResBody } from '../../pages/api/feedback/delete'
import { captureException } from '../sentry'
import axios from 'axios'
import { notification } from 'antd'
import { v4 as uuid } from 'uuid'

// Key listeners based off meeting.id so that expanding other meetings
// will not remove listeners from the main meeting that is loaded.
const listeners: Record<string, Array<() => void>> = {}

const middleware: Middleware = ({ dispatch, getState }) => {
  return (next) => async (action: actions | meetingsActions) => {
    next(action)

    switch (action.type) {
      case actionTypes.CREATE_REQUEST: {
        try {
          const state = getState()
          const account = accountSelectors.getActiveAccount()(state)

          if (!account) {
            throw new Error('Must be logged in')
          }
          const res = await axios.post<CreateResBody, CreateReqBody>('/api/feedback/create', {
            likeText: action.likeText,
            wishThatText: action.wishThatText,
            meetingId: action.meetingId,
            accountId: account.id,
            recipientId: action.recipientId,
            attachments: action.attachments,
            visibleToRecipient: true,
            updateId: action.updateId,
          })
          const feedback = inflateDates(res.data, ['createdAt'])
          dispatch(actions.createSuccess({ feedback }))
        } catch (error) {
          if (!(error instanceof Error)) return
          console.log(error)
          dispatch(actions.createFailure({ error }))
          notification.error({
            message: 'There was an error creating a new feedback',
          })
        }
        break
      }

      case actionTypes.EDIT: {
        const changeId = uuid()
        const itemId = action.id
        try {
          const patch = {
            likeText: action.likeText,
            wishThatText: action.wishThatText,
            attachments: action.attachments,
            recipientId: action.recipientId,
            accountId: action.accountId,
            visibleToRecipient: action.visibleToRecipient,
            updateId: action.updateId,
          }

          if (action.optimistic) {
            dispatch(
              actions.optimisticChange({
                changeId,
                itemId,
                patch,
              }),
            )
          }

          const res = await axios.post<UpdateResBody, UpdateReqBody>('/api/feedback/update', {
            id: itemId,
            ...patch,
          })

          dispatch(
            actions.optimisticCommit({
              changeId,
              item: inflateDates(res.data, dateKeys),
            }),
          )
        } catch (error) {
          if (action.optimistic) {
            dispatch(
              actions.optimisticRollback({
                changeId,
                itemId,
              }),
            )
          }
          console.log(error)
          notification.error({
            message: 'There was an error editing this feedback',
          })
        }
        break
      }

      case actionTypes.DELETE: {
        try {
          await axios.post<DeleteResBody, DeleteReqBody>('/api/feedback/delete', {
            id: action.id,
            updateId: action.updateId,
          })
        } catch (error) {
          console.log(error)
          notification.error({
            message: 'There was an error deleting this feedback',
          })
        }
        break
      }

      case actionTypes.LIKE: {
        const updatedData = {
          likes: action.feedback.likes ? action.feedback.likes + 1 : 1,
          likeAccountIds: [...(action.feedback.likeAccountIds || []), action.accountId],
        }
        dispatch(
          actions.change({
            feedback: { ...action.feedback, ...updatedData },
          }),
        )
        try {
          await axios.post<UpdateResBody, UpdateReqBody>('/api/feedback/update', {
            id: action.feedback.id,
            ...updatedData,
          })
        } catch (error) {
          console.log(error)
          notification.error({
            message: 'There was an error liking this feedback',
          })
        }
        break
      }

      case actionTypes.UNLIKE: {
        const updatedData = {
          likes: action.feedback.likes - 1,
          likeAccountIds: action.feedback.likeAccountIds.filter((id) => id !== action.accountId),
        }
        dispatch(
          actions.change({
            feedback: { ...action.feedback, ...updatedData },
          }),
        )
        try {
          await axios.post<UpdateResBody, UpdateReqBody>('/api/feedback/update', {
            id: action.feedback.id,
            ...updatedData,
          })
        } catch (error) {
          console.log(error)
          notification.error({
            message: 'There was an error removing the like of this feedback',
          })
        }
        break
      }

      case meetingsActionTypes.VIEW: {
        try {
          const state = getState()
          const meeting = meetingsSelectors.getMeetingById(action.id)(state)
          let meetingListeners = listeners[action.id] || []

          // This action gets fired when hot reloading on the meeting page.
          // We need to clear old listeners otherwise they will stack up.
          if (meetingListeners.length) {
            meetingListeners.forEach((detach) => detach())
            meetingListeners = []
          }

          // Create listeners for firebase based off chunks of 10 participants.
          const chunks = _.chunk(meeting.participantIds, 10)
          await Promise.all(
            chunks.map(async (participantsChunk) => {
              let feedbackRef = firebase.collection('feedback').where('accountId', 'in', participantsChunk)

              if (action.updateId) {
                feedbackRef = feedbackRef.where('updateId', '==', action.updateId)
              } else {
                feedbackRef = feedbackRef.where('meetingId', '==', action.id)
              }

              // Fetch all the documents once on view and set them.
              const feedback = await getRefFormatByIds<Feedback>(feedbackRef, ['createdAt'])
              dispatch(actions.set({ feedback }))

              // Listen for updates from Firebase on same documents.
              const listener = listenForNewUpdates<Feedback>(
                feedbackRef,
                (change, feedback) => {
                  if (change.type === 'added') {
                    dispatch(actions.add({ feedback }))
                  }
                  if (change.type === 'modified') {
                    dispatch(actions.change({ feedback }))
                  }
                  if (change.type === 'removed') {
                    dispatch(actions.remove({ feedback }))
                  }
                },
                ['createdAt'],
              )
              meetingListeners.push(listener)
            }),
          )

          listeners[action.id] = meetingListeners
        } catch (error) {
          // When Firebase has a permissions error, catch the exception and
          // send it to Sentry.
          console.warn(error)
          captureException(error)
        }
        break
      }
      default:
        break
    }
  }
}

export default middleware
