import { relayStylePagination } from '@apollo/client/utilities'
import { TypedTypePolicies } from '../../gen/graphql/type-policies'

export const typePolicies: TypedTypePolicies = {
  Query: {
    fields: {
      account: (_, { args, toReference }) => toReference({ __typename: 'Account', id: args?.id }),
      calendar: (_, { args, toReference }) => toReference({ __typename: 'Calendar', id: args?.id }),
      issue: (_, { args, toReference }) => toReference({ __typename: 'Issue', id: args?.id }),
      meeting2: (_, { args, toReference }) => toReference({ __typename: 'Meeting2', id: args?.id }),
      calendarEvent: (_, { args, toReference }) => toReference({ __typename: 'CalendarEvent', id: args?.id }),
      issues: relayStylePagination(['companyId']),
      futureMeetingsAndEvents: relayStylePagination(),
    },
  },
}
