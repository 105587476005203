import { actions as authActions, actionTypes as authActionTypes } from '../auth'
import { Account } from '@mm/backend/accounts/model'
import { Issue } from '@mm/backend/issues/model'
import { Reducer } from 'redux'

export const selector = 'issues'

export const actionTypes = {
  EDIT: 'issues/EDIT',
  DELETE: 'issues/DELETE',
  CLEAR: 'issues/CLEAR',
  SET: 'issues/SET',
  ADD: 'issues/ADD',
  CHANGE: 'issues/CHANGE',
  REMOVE: 'issues/REMOVE',
  LIKE: 'issues/LIKE',
  UNLIKE: 'issues/UNLIKE',
  VIEW: 'issues/VIEW',
} as const

type EditAction = Partial<
  Pick<
    Issue,
    | 'accountId'
    | 'description'
    | 'whatWasDoneText'
    | 'proposedSolutionText'
    | 'finalSolutionText'
    | 'type'
    | 'approvedByIds'
    | 'attachments'
  >
> &
  Pick<Issue, 'id'>

interface DeleteAction {
  id: string
  updateId?: string
}

interface SetAction {
  issues: Record<string, Issue>
}

interface AddAction {
  issue: Issue
}

interface ChangeAction {
  issue: Issue
}

interface RemoveAction {
  issue: Issue
}

interface LikeAction {
  issue: Issue
  accountId: Account['id']
}

interface UnlikeAction {
  issue: Issue
  accountId: Account['id']
}

interface ViewAction {
  issuesIds: Array<Issue['id']>
}

export const actions = {
  edit: ({
    id,
    accountId,
    description,
    whatWasDoneText,
    proposedSolutionText,
    finalSolutionText,
    type: thingType,
    approvedByIds,
    attachments,
  }: EditAction) => ({
    type: actionTypes.EDIT,
    id,
    accountId,
    description,
    whatWasDoneText,
    proposedSolutionText,
    finalSolutionText,
    thingType,
    approvedByIds,
    attachments,
  }),
  delete: ({ id, updateId }: DeleteAction) => ({
    type: actionTypes.DELETE,
    id,
    updateId,
  }),
  clear: () => ({
    type: actionTypes.CLEAR,
  }),
  set: ({ issues }: SetAction) => ({
    type: actionTypes.SET,
    issues,
  }),
  add: ({ issue }: AddAction) => ({
    type: actionTypes.ADD,
    issue,
  }),
  change: ({ issue }: ChangeAction) => ({
    type: actionTypes.CHANGE,
    issue,
  }),
  remove: ({ issue }: RemoveAction) => ({
    type: actionTypes.REMOVE,
    issue,
  }),
  like: ({ issue, accountId }: LikeAction) => ({
    type: actionTypes.LIKE,
    issue,
    accountId,
  }),
  unlike: ({ issue, accountId }: UnlikeAction) => ({
    type: actionTypes.UNLIKE,
    issue,
    accountId,
  }),
  view: ({ issuesIds }: ViewAction) => ({
    type: actionTypes.VIEW,
    issuesIds,
  }),
}

export type actions = ReturnType<typeof actions[keyof typeof actions]>

interface State {
  byId: { [id: string]: Issue }
  form: {
    loading: boolean
    error?: Error
  }
}

const initialState: State = {
  byId: {},
  form: {
    loading: false,
  },
}

export const reducer: Reducer<State, actions | authActions> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET: {
      return {
        ...state,
        byId: {
          ...state.byId,
          ...action.issues,
        },
      }
    }
    case actionTypes.ADD:
    case actionTypes.CHANGE: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.issue.id]: action.issue,
        },
      }
    }
    case actionTypes.REMOVE: {
      const newThingsById = { ...state.byId }

      delete newThingsById[action.issue.id]

      return {
        ...state,
        byId: newThingsById,
      }
    }
    case actionTypes.DELETE: {
      const newThingsById = { ...state.byId }

      delete newThingsById[action.id]

      return {
        ...state,
        byId: newThingsById,
      }
    }
    case actionTypes.CLEAR: {
      return {
        ...state,
        byId: {},
      }
    }
    case authActionTypes.LOG_OUT:
      return initialState
    default:
      return state
  }
}

export const selectors = {
  get: () => (state: Record<typeof selector, State>) => state[selector].byId,
  getForm: () => (state: Record<typeof selector, State>) => state[selector].form,
  getById: (id: string) => (state: Record<typeof selector, State>) => state[selector].byId[id],
}
