import { Reducer } from 'redux'
import { Account } from '@mm/backend/accounts/model'
import { actionTypes as authActionTypes, actions as authActions } from '../auth'
import { selectors as companySelectors } from '../companies'

export const selector = 'accounts'

export const actionTypes = {
  CREATE_REQUEST: 'accounts/CREATE_REQUEST' as 'accounts/CREATE_REQUEST',
  CREATE_SUCCESS: 'accounts/CREATE_SUCCESS' as 'accounts/CREATE_SUCCESS',
  CREATE_FAILURE: 'accounts/CREATE_FAILURE' as 'accounts/CREATE_FAILURE',
  UPDATE_REQUEST: 'accounts/UPDATE_REQUEST' as 'accounts/UPDATE_REQUEST',
  UPDATE_SUCCESS: 'accounts/UPDATE_SUCCESS' as 'accounts/UPDATE_SUCCESS',
  UPDATE_FAILURE: 'accounts/UPDATE_FAILURE' as 'accounts/UPDATE_FAILURE',
  ADD_COMPANY_REQUEST: 'accounts/ADD_COMPANY_REQUEST' as 'accounts/ADD_COMPANY_REQUEST',
  ADD_COMPANY_SUCCESS: 'accounts/ADD_COMPANY_SUCCESS' as 'accounts/ADD_COMPANY_SUCCESS',
  ADD_COMPANY_FAILURE: 'accounts/ADD_COMPANY_FAILURE' as 'accounts/ADD_COMPANY_FAILURE',
  REMOVE_COMPANY_REQUEST: 'accounts/REMOVE_COMPANY_REQUEST' as 'accounts/REMOVE_COMPANY_REQUEST',
  REMOVE_COMPANY_SUCCESS: 'accounts/REMOVE_COMPANY_SUCCESS' as 'accounts/REMOVE_COMPANY_SUCCESS',
  REMOVE_COMPANY_FAILURE: 'accounts/REMOVE_COMPANY_FAILURE' as 'accounts/REMOVE_COMPANY_FAILURE',
  SET: 'accounts/SET' as 'accounts/SET',
  SET_ACTIVE: 'accounts/SET_ACTIVE' as 'accounts/SET_ACTIVE',
  VIEW_PROFILE: 'accounts/VIEW_PROFILE' as 'accounts/VIEW_PROFILE',
}

interface CreateAction {
  email: Account['email']
  password: string
  name: Account['name']
  companyId: string
}

interface CreateSuccessAction {
  account: Account
  password: string
}

interface CreateFailureAction {
  error: Error
}

interface UpdateRequestAction {
  account: Partial<Pick<Account, 'email' | 'name' | 'bio'>> & Pick<Account, 'id'>
}

interface UpdateSuccessAction {
  account: Account
}

interface UpdateFailureAction {
  error: Error
}

interface AddCompanyAction {
  email: Account['email']
  name: Account['name']
  companyId: string
  senderId: string
}

interface AddCompanySuccessAction {
  account: Account
}

interface AddCompanyFailureAction {
  error: Error
}

interface RemoveCompanyAction {
  id: Account['id']
  companyId: string
}

interface RemoveCompanySuccessAction {
  account: Account
}

interface RemoveCompanyFailureAction {
  error: Error
}

interface SetAction {
  accounts: Array<Account>
}

interface SetActiveAction {
  account: Account
}

interface ViewProfileAction {
  accountId: string
}

export const actions = {
  createRequest: ({ email, password, name, companyId }: CreateAction) => ({
    type: actionTypes.CREATE_REQUEST,
    email,
    password,
    name,
    companyId,
  }),
  createSuccess: ({ account, password }: CreateSuccessAction) => ({
    type: actionTypes.CREATE_SUCCESS,
    account,
    password,
  }),
  createFailure: ({ error }: CreateFailureAction) => ({
    type: actionTypes.CREATE_FAILURE,
    error,
  }),
  updateRequest: ({ account }: UpdateRequestAction) => ({
    type: actionTypes.UPDATE_REQUEST,
    account,
  }),
  updateSuccess: ({ account }: UpdateSuccessAction) => ({
    type: actionTypes.UPDATE_SUCCESS,
    account,
  }),
  updateFailure: ({ error }: UpdateFailureAction) => ({
    type: actionTypes.UPDATE_FAILURE,
    error,
  }),
  addCompanyRequest: ({ email, name, companyId, senderId }: AddCompanyAction) => ({
    type: actionTypes.ADD_COMPANY_REQUEST,
    email,
    name,
    companyId,
    senderId,
  }),
  addCompanySuccess: ({ account }: AddCompanySuccessAction) => ({
    type: actionTypes.ADD_COMPANY_SUCCESS,
    account,
  }),
  addCompanyFailure: ({ error }: AddCompanyFailureAction) => ({
    type: actionTypes.ADD_COMPANY_FAILURE,
    error,
  }),
  removeCompanyRequest: ({ id, companyId }: RemoveCompanyAction) => ({
    type: actionTypes.REMOVE_COMPANY_REQUEST,
    id,
    companyId,
  }),
  removeCompanySuccess: ({ account }: RemoveCompanySuccessAction) => ({
    type: actionTypes.REMOVE_COMPANY_SUCCESS,
    account,
  }),
  removeCompanyFailure: ({ error }: RemoveCompanyFailureAction) => ({
    type: actionTypes.REMOVE_COMPANY_FAILURE,
    error,
  }),
  set: ({ accounts }: SetAction) => ({
    type: actionTypes.SET,
    accounts,
  }),
  setActive: ({ account }: SetActiveAction) => ({
    type: actionTypes.SET_ACTIVE,
    account,
  }),
  viewProfile: ({ accountId }: ViewProfileAction) => ({
    type: actionTypes.VIEW_PROFILE,
    accountId,
  }),
}

export type actions = ReturnType<typeof actions[keyof typeof actions]>

interface State {
  activeAccountId: string
  byId: { [id: string]: Account }
  form: {
    loading: boolean
    success: boolean
    error?: Error
  }
}

const initialState: State = {
  activeAccountId: '',
  byId: {},
  form: {
    loading: false,
    success: false,
  },
}

export const reducer: Reducer<State, actions | authActions> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_SUCCESS:
    case actionTypes.SET_ACTIVE: {
      return {
        ...state,
        activeAccountId: action.account.id,
        byId: {
          ...state.byId,
          [action.account.id]: action.account,
        },
        form: {
          loading: false,
          success: true,
        },
      }
    }
    case actionTypes.ADD_COMPANY_SUCCESS:
    case actionTypes.REMOVE_COMPANY_SUCCESS:
    case actionTypes.UPDATE_SUCCESS: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.account.id]: action.account,
        },
        form: {
          loading: false,
          success: true,
        },
      }
    }
    case actionTypes.SET: {
      const byId: { [id: string]: Account } = {}
      action.accounts.map((account) => {
        byId[account.id] = account
      })
      return {
        ...state,
        byId: {
          ...state.byId,
          ...byId,
        },
      }
    }
    case actionTypes.ADD_COMPANY_REQUEST:
    case actionTypes.REMOVE_COMPANY_REQUEST:
    case actionTypes.CREATE_REQUEST:
    case actionTypes.UPDATE_REQUEST: {
      return {
        ...state,
        form: {
          loading: true,
          success: false,
        },
      }
    }
    case actionTypes.ADD_COMPANY_FAILURE:
    case actionTypes.REMOVE_COMPANY_FAILURE:
    case actionTypes.CREATE_FAILURE:
    case actionTypes.UPDATE_FAILURE: {
      return {
        ...state,
        form: {
          loading: false,
          success: false,
          error: action.error,
        },
      }
    }
    case authActionTypes.LOG_OUT:
      return initialState

    default:
      return state
  }
}

export const selectors = {
  getActiveAccountId: () => (state: Record<typeof selector, State>) => state[selector].activeAccountId,
  getActiveAccount: () => (state: Record<typeof selector, State>) => {
    const activeAccountId = state[selector].activeAccountId
    const accounts = state[selector].byId
    return accounts[activeAccountId]
  },
  getAccountById: (id: string) => (state: Record<typeof selector, State>) => state[selector].byId[id],
  getAccountsByCompanyId: (companyId: string) => (state: Record<typeof selector, State>) => {
    const company = companySelectors.getCompanyById(companyId)(state as any)
    const accounts = selectors.getAccounts()(state)

    const companyAccounts: Array<Account> = []

    company?.members.forEach((memberId) => {
      if (accounts[memberId]) {
        companyAccounts.push(accounts[memberId])
      }
    })

    return companyAccounts
  },
  getAccounts: () => (state: Record<typeof selector, State>) => state[selector].byId,
  getForm: () => (state: Record<typeof selector, State>) => state[selector].form,
}
