import { Account } from '../accounts'
import { KeysOfType } from '../helpers'

export interface Goal {
  id: string
  description: string
  accountId: Account['id']
  parentId: string | null
  createdAt: Date
  updatedAt: Date
  startAt: Date
  endAt: Date
  endAtLabel: string
}

export const dateKeys: KeysOfType<Goal, Date>[] = ['createdAt', 'updatedAt', 'endAt', 'startAt']
