import React, { useState } from 'react'
import moment, { Moment } from 'moment'
import { useSelector } from 'react-redux'
import { selectors as accountSelectors } from '../../accounts'
import { Form, Input, Button, DatePicker, notification } from 'antd'
import { endOfQuarter, getQuarter, getYear, startOfDay } from 'date-fns'
import Router from 'next/router'
import { useMutation } from '@apollo/client'
import { InitializeGoalsDocument } from '../../../gen/graphql/documents'

interface Fields {
  goals: Array<{
    description: string
    endAt: Moment
    endAtLabel?: string
  }>
}

export const InitializeGoals: React.FC = () => {
  const accountId = useSelector(accountSelectors.getActiveAccountId())
  const [form] = Form.useForm()
  const [creating, setCreating] = useState(false)

  const [createMutation] = useMutation(InitializeGoalsDocument)

  const handleSubmit = async (values: Fields) => {
    try {
      setCreating(true)
      const now = Date.now()
      const goals = values.goals.map((goal, index) => ({
        ...goal,
        endAtLabel: goal.endAtLabel || '',
        accountId,
        parentId: null,
        startAt: now,
        endAt: goal.endAt.valueOf() + index * 1,
      }))

      const { data } = await createMutation({
        variables: { accountId, goal1: goals[0], goal2: goals[1], goal3: goals[2] },
      })

      if (
        data?.createGoal1.__typename === 'Goal' ||
        data?.createGoal2.__typename === 'Goal' ||
        data?.createGoal3.__typename === 'Goal' ||
        data?.completeOnboarding.__typename === 'Account'
      ) {
        form.resetFields()
        Router.push('/dashboard')
        notification.success({
          message: 'Goals created. You are all set!',
        })
      } else {
        notification.error({
          message: 'Could not create goals. Please try again.',
        })
      }

      setCreating(false)
    } catch (error) {
      setCreating(false)
      notification.error({
        message: 'Could not create goals. Please try again.',
      })
    }
  }

  const NewGoal: React.FC<{ index: number }> = ({ index }) => (
    <div style={{ marginBottom: 12 }}>
      <Form.Item
        label={`Goal 0${index + 1}:`}
        name={[index, 'description']}
        rules={[{ required: true, message: 'Description is required' }]}
        style={{ flex: 1, marginBottom: 10 }}
      >
        <Input placeholder="Description" />
      </Form.Item>
      <div style={{ display: 'flex', flexDirection: 'row', marginBottom: -12 }}>
        <Form.Item
          name={[index, 'endAt']}
          rules={[{ required: true, message: 'End date' }]}
          style={{ paddingRight: 10 }}
        >
          <DatePicker placeholder="End date" />
        </Form.Item>
        <Form.Item name={[index, 'endAtLabel']} style={{ flex: 1 }}>
          <Input placeholder="Label: Q1, Q2, 2021, etc..." />
        </Form.Item>
      </div>
    </div>
  )

  const endAt = moment(startOfDay(endOfQuarter(new Date())))
  const endAtLabel = `Q${getQuarter(new Date())} ${getYear(new Date())}`

  return (
    <Form form={form} layout="vertical" onFinish={handleSubmit}>
      <Form.List name="goals" initialValue={[...Array(3)].map(() => ({ endAt, endAtLabel }))}>
        {(fields, _actions) => (
          <div>
            {fields.map((field, index) => (
              <NewGoal key={field.key} index={index} />
            ))}
          </div>
        )}
      </Form.List>

      <Form.Item>
        <Button type="primary" size="large" htmlType="submit" loading={creating}>
          Create Goals
        </Button>
      </Form.Item>
    </Form>
  )
}

export default InitializeGoals
