import styled from '@emotion/styled'

interface Props {
  color: string
  visibleColor?: string
}

export const FocusColorWrap = styled.div<Props>(({ color, visibleColor }) => ({
  '& .ql-editor': {
    outline: `2px auto ${visibleColor}`,
  },
  '& .ql-editor:focus': {
    outline: `2px auto ${color}`,
  },
}))

export default FocusColorWrap
