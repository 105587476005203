import { Select } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions, selectors } from '../store'

interface Props {
  value?: string
  width?: number | string
  onChange?: (value: string | undefined) => void
  disabled?: boolean
}

export const CompanySelector: React.FC<Props> = ({ width = 200, value, onChange, disabled }) => {
  const dispatch = useDispatch()
  const companyStore = useSelector(selectors.getCompanies())

  const companies = Object.values(companyStore).sort((co1, co2) => {
    if (co1.name.toLocaleLowerCase() < co2.name.toLocaleLowerCase()) return -1
    else return 1
  })

  const handleChange = (value: string | undefined) => {
    onChange?.(value)
    dispatch(actions.setActive({ companyId: value ?? '' }))
  }

  return (
    <Select
      value={value}
      showSearch
      style={{ width }}
      disabled={disabled}
      onChange={handleChange}
      optionFilterProp="children"
    >
      {companies.map((company) => (
        <Select.Option key={company.id} value={company.id}>
          {company.name}
        </Select.Option>
      ))}
    </Select>
  )
}
