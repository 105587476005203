import React from 'react'
import Link from 'next/link'

interface Props {
  id: string
}

export const BlockAccountLink: React.FC<Props> = ({ id, children }) => {
  return (
    <Link href={`/profile/${id}`}>
      <strong style={{ cursor: 'pointer' }}>{children}</strong>
    </Link>
  )
}

export default BlockAccountLink
