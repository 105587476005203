import React from 'react'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { selectors, actions } from '../store'
import { Form, Input, Button, Card, DatePicker } from 'antd'
import { Goal } from '@mm/backend/goals/model'

interface Fields {
  description: string
  endAt: moment.Moment
  endAtLabel?: string
}

interface Props {
  goal: Goal
  onEdit?: () => void
  onCancel?: () => void
}

export const EditGoal: React.FC<Props> = ({ goal, onEdit, onCancel }) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const formControl = useSelector(selectors.getForm())

  const handleSubmit = ({ description, endAt, endAtLabel = '' }: Fields) => {
    dispatch(
      actions.edit({
        id: goal.id,
        description,
        endAt: endAt.toDate(),
        endAtLabel,
        startAt: goal.startAt,
        optimistic: true,
      }),
    )
    onEdit && onEdit()
  }

  React.useEffect(() => {
    if (!formControl.loading && !formControl.error) {
      form.resetFields()
    }
  }, [formControl.loading, formControl.error])

  const handleCancel = () => {
    form.resetFields()
    onCancel && onCancel()
  }

  return (
    <Card>
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          description: goal.description,
          endAt: moment(goal.endAt),
          endAtLabel: goal.endAtLabel,
        }}
        onFinish={handleSubmit}
      >
        <Form.Item
          name="description"
          rules={[{ required: true, message: 'Description is required' }]}
          style={{ flex: 1, marginBottom: 10 }}
        >
          <Input placeholder="Goal description" />
        </Form.Item>

        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: -24 }}>
          <Form.Item
            name="endAt"
            rules={[{ required: true, message: 'End date is required' }]}
            style={{ paddingRight: 10 }}
          >
            <DatePicker placeholder="End date" />
          </Form.Item>

          <Form.Item name="endAtLabel" style={{ flex: 1, paddingRight: 10 }}>
            <Input placeholder="Label: Q1, Q2, 2021, etc..." />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={formControl.loading}>
              Save
            </Button>
          </Form.Item>

          <Button type="text" loading={formControl.loading} onClick={handleCancel}>
            Cancel
          </Button>
        </div>

        {formControl.error}
      </Form>
    </Card>
  )
}

export default EditGoal
