import { MeetingParticipantRole } from '@mm/common'
import { Reducer } from 'redux'
import { Group } from '@mm/backend/groups/model'
import { actionTypes as authActionTypes, actions as authActions } from '../auth'

// TODO: Come up with better name. Key? SelectorKey? StateKey
export const selector = 'groups'

export const actionTypes = {
  EDIT: 'groups/EDIT',
  SET: 'groups/SET',
  ADD: 'groups/ADD',
  CHANGE: 'groups/CHANGE',
  REMOVE: 'groups/REMOVE',
  ADD_ACCOUNT: 'groups/ADD_ACCOUNT',
  REMOVE_ACCOUNT: 'groups/REMOVE_ACCOUNT',
} as const

type EditAction = Pick<Group, 'id' | 'library'>

interface SetAction {
  groups: Record<string, Group>
}

interface AddAction {
  group: Group
}

interface ChangeAction {
  group: Group
}

interface RemoveAction {
  group: Group
}

interface AddAccountAction {
  groupId: string
  accountId: string
  role: MeetingParticipantRole
}

interface RemoveAccountAction {
  groupId: string
  accountId: string
}

export const actions = {
  edit: ({ id, library }: EditAction) => ({
    type: actionTypes.EDIT,
    id,
    library,
  }),
  set: ({ groups }: SetAction) => ({
    type: actionTypes.SET,
    groups,
  }),
  add: ({ group }: AddAction) => ({
    type: actionTypes.ADD,
    group,
  }),
  change: ({ group }: ChangeAction) => ({
    type: actionTypes.CHANGE,
    group,
  }),
  remove: ({ group }: RemoveAction) => ({
    type: actionTypes.REMOVE,
    group,
  }),
  addAccount: ({ groupId, accountId, role }: AddAccountAction) => ({
    type: actionTypes.ADD_ACCOUNT,
    groupId,
    accountId,
    role,
  }),
  removeAccount: ({ groupId, accountId }: RemoveAccountAction) => ({
    type: actionTypes.REMOVE_ACCOUNT,
    groupId,
    accountId,
  }),
}

export type actions = ReturnType<typeof actions[keyof typeof actions]>

interface State {
  byId: { [id: string]: Group }
}

const initialState: State = {
  byId: {},
}

export const reducer: Reducer<State, actions | authActions> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET: {
      return {
        byId: {
          ...state.byId,
          ...action.groups,
        },
      }
    }
    case actionTypes.ADD:
    case actionTypes.CHANGE: {
      return {
        byId: {
          ...state.byId,
          [action.group.id]: action.group,
        },
      }
    }
    case actionTypes.REMOVE: {
      const byIdClone = { ...state.byId }
      delete byIdClone[action.group.id]

      return {
        byId: byIdClone,
      }
    }
    case authActionTypes.LOG_OUT:
      return initialState
    default:
      return state
  }
}

export const selectors = {
  getGroups: () => (state: Record<typeof selector, State>) => state[selector].byId,
  getGroupById: (id: string) => (state: Record<typeof selector, State>) => state[selector].byId[id],
}
