import { notification } from 'antd'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { useAsyncFn } from 'react-use'
import type {
  ReqBody as GetCalendarRedirectUrlReqBody,
  ResBody as GetCalendarRedirectUrlResBody,
} from '../../../pages/api/integrations/googleCalendar/getRedirectUrl'
import { selectors as accountSelectors } from '../../accounts'

export function useConnectGoogleCalendar() {
  const account = useSelector(accountSelectors.getActiveAccount())

  return useAsyncFn(async () => {
    try {
      if (account == null) {
        notification.error({
          message: 'Error',
          description: 'You must be logged in.',
        })
        return
      }

      // Get url for the google auth and redirect
      const res = await axios.get<GetCalendarRedirectUrlResBody, GetCalendarRedirectUrlReqBody>(
        `/api/integrations/nylas/gcal-redirect-url`,
      )
      const { redirectUrl } = res.data
      if (typeof redirectUrl !== 'string') {
        throw new Error('Invalid redirect url')
      }
      return window.open(redirectUrl, '_self')
    } catch (e) {
      console.error('Failed to add a calendar integration', e)
      notification.error({
        message: 'Error',
        description: 'Failed to add a calendar integration, please try again later.',
      })
    }
  }, [account])
}
