import React from 'react'
import { Popconfirm } from 'antd'
import { FlexRow, Text, Div, RecordVideoButton, Notice } from '../../interface'

interface Props {
  shareUrl?: string
  embedUrl?: string
  canDelete?: boolean
  onSave: (links: { embedUrl: string; shareUrl: string }) => void
  onRemove: () => void
}

export const IssueVideoBox: React.FC<Props> = ({ shareUrl, canDelete, onSave, onRemove }) => {
  return (
    <Div top={2} bottom={1}>
      {shareUrl ? (
        <FlexRow gap={1}>
          <Text weight="heavy" color="dark">
            Video
          </Text>
          <Text style={{ flex: 1 }}>
            <a target="__blank" href={shareUrl}>
              {shareUrl}
            </a>
          </Text>

          <Popconfirm
            title={<Text>Are you sure you want to remove the video from this issue?</Text>}
            onConfirm={onRemove}
            okText="Yes, Remove"
            cancelText="Cancel"
            okType="danger"
          >
            <Text>[x]</Text>
          </Popconfirm>
        </FlexRow>
      ) : null}
    </Div>
  )
}

export default IssueVideoBox
