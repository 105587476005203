import { addCommentToolbarItem, AddCommentHandler } from '../../meetings/utils/addCommentToolbarItem'
import { defaultItems, ToolbarItem } from '../../editor'
import { AddActionHandler, addActionToolbarItem } from '../../meetings/utils/addActionToolbarItem'

export type ExtraToolbarItems = {
  addComment?: AddCommentHandler
  addAction?: AddActionHandler
}

export const buildToolbarItems = ({ addComment, addAction }: ExtraToolbarItems): ToolbarItem[] => {
  const items = [...defaultItems]
  if (addComment != null) {
    items.push(addCommentToolbarItem(addComment))
  }
  if (addAction != null) {
    items.push(addActionToolbarItem(addAction))
  }
  return items
}
