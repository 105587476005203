import { Reducer } from 'redux'
import { Goal } from '@mm/backend/goals/model'
import { actionTypes as authActionTypes, actions as authActions } from '../auth'
import {
  optimisticChange,
  OptimisticChange,
  optimisticCommit,
  OptimisticCommit,
  optimisticRollback,
  OptimisticRollback,
  remove,
  removeById,
  set,
  State,
  upsert,
} from '../optimistic-redux'

export const selector = 'goals'

export const actionTypes = {
  CREATE_REQUEST: 'goals/CREATE_REQUEST',
  CREATE_SUCCESS: 'goals/CREATE_SUCCESS',
  CREATE_FAILURE: 'goals/CREATE_FAILURE',
  EDIT: 'goals/EDIT',
  DELETE: 'goals/DELETE',
  SET: 'goals/SET',
  ADD: 'goals/ADD',
  CHANGE: 'goals/CHANGE',
  REMOVE: 'goals/REMOVE',

  OPTIMISTIC_CHANGE: 'goals/OPTIMISTIC_CHANGE',
  OPTIMISTIC_ROLLBACK: 'goals/OPTIMISTIC_ROLLBACK',
  OPTIMISTIC_COMMIT: 'goals/OPTIMISTIC_COMMIT',
} as const

type CreateRequestAction = Pick<Goal, 'description' | 'parentId' | 'startAt' | 'endAt' | 'endAtLabel'> & {
  accountId?: string
}

interface CreateSuccessAction {
  goal: Goal
}

interface CreateFailureAction {
  error: Error
}

type EditAction = Pick<Goal, 'id' | 'description' | 'startAt' | 'endAt' | 'endAtLabel'> & {
  optimistic?: boolean
}

type DeleteAction = Pick<Goal, 'id'>

interface SetAction {
  goals: Record<string, Goal>
}

interface AddAction {
  goal: Goal
}

interface ChangeAction {
  goal: Goal
}

interface RemoveAction {
  goal: Goal
}

export const actions = {
  createRequest: ({ description, accountId, parentId, startAt, endAt, endAtLabel }: CreateRequestAction) => ({
    type: actionTypes.CREATE_REQUEST,
    description,
    accountId,
    parentId,
    startAt,
    endAt,
    endAtLabel,
  }),
  createSuccess: ({ goal }: CreateSuccessAction) => ({
    type: actionTypes.CREATE_SUCCESS,
    goal,
  }),
  createFailure: ({ error }: CreateFailureAction) => ({
    type: actionTypes.CREATE_FAILURE,
    error,
  }),
  edit: ({ id, description, startAt, endAt, endAtLabel, optimistic }: EditAction) => ({
    type: actionTypes.EDIT,
    id,
    description,
    startAt,
    endAt,
    endAtLabel,
    optimistic,
  }),
  delete: ({ id }: DeleteAction) => ({
    type: actionTypes.DELETE,
    id,
  }),
  set: ({ goals }: SetAction) => ({
    type: actionTypes.SET,
    goals,
  }),
  add: ({ goal }: AddAction) => ({
    type: actionTypes.ADD,
    goal,
  }),
  change: ({ goal }: ChangeAction) => ({
    type: actionTypes.CHANGE,
    goal,
  }),
  remove: ({ goal }: RemoveAction) => ({
    type: actionTypes.REMOVE,
    goal,
  }),
  optimisticChange: (action: OptimisticChange<Goal>) => ({
    ...action,
    type: actionTypes.OPTIMISTIC_CHANGE,
  }),
  optimisticRollback: (action: OptimisticRollback) => ({
    ...action,
    type: actionTypes.OPTIMISTIC_ROLLBACK,
  }),
  optimisticCommit: (action: OptimisticCommit<Goal>) => ({
    ...action,
    type: actionTypes.OPTIMISTIC_COMMIT,
  }),
}

export type actions = ReturnType<typeof actions[keyof typeof actions]>

interface GoalsState extends State<Goal> {
  form: {
    loading: boolean
    error?: Error
  }
}

const initialState: GoalsState = {
  byId: {},
  optimisticStorage: {},
  form: {
    loading: false,
  },
}

export const reducer: Reducer<GoalsState, actions | authActions> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET: {
      return set(state, action.goals)
    }
    case actionTypes.ADD:
    case actionTypes.CHANGE: {
      return upsert(state, action.goal)
    }
    case actionTypes.REMOVE: {
      return remove(state, action.goal)
    }
    case actionTypes.DELETE: {
      return removeById(state, action.id)
    }
    case actionTypes.CREATE_REQUEST: {
      return {
        ...state,
        form: {
          loading: true,
        },
      }
    }
    case actionTypes.CREATE_SUCCESS: {
      return {
        ...state,
        form: {
          loading: false,
        },
      }
    }
    case actionTypes.CREATE_FAILURE: {
      return {
        ...state,
        form: {
          loading: false,
          error: action.error,
        },
      }
    }
    case actionTypes.OPTIMISTIC_CHANGE: {
      return optimisticChange(state, action)
    }
    case actionTypes.OPTIMISTIC_ROLLBACK: {
      return optimisticRollback(state, action)
    }
    case actionTypes.OPTIMISTIC_COMMIT: {
      return optimisticCommit(state, action)
    }
    case authActionTypes.LOG_OUT:
      return initialState
    default:
      return state
  }
}

export const selectors = {
  get: () => (state: Record<typeof selector, GoalsState>) => state[selector].byId,
  getForm: () => (state: Record<typeof selector, GoalsState>) => state[selector].form,
  getById: (id: string) => (state: Record<typeof selector, GoalsState>) => state[selector].byId[id],
}
