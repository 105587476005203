import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Input, Button, Typography } from 'antd'
import { actions, selectors, PageTypes } from '../store'
import { selectors as goalSelectors } from '../../goals'
import { actions as accountActions } from '../../accounts'
import { selectors as authSelectors } from '../../auth'
import { useFeatureFlags } from '../../featureFlags'

interface Fields {
  password: string
  confirmPassword: string
}
export default function UpdatePasswordForm() {
  const dispatch = useDispatch()
  const user = useSelector(authSelectors.getUser())
  const goals = useSelector(goalSelectors.get())
  const error = useSelector(selectors.getError(PageTypes.UPDATE))
  const loading = useSelector(selectors.getLoading(PageTypes.UPDATE))
  const { removeOnboardingTypeform = false } = useFeatureFlags()

  React.useEffect(() => {
    if (user) dispatch(accountActions.viewProfile({ accountId: user.uid }))
  }, [])

  const handleSubmit = async (values: Fields) => {
    let redirectTo = '/onboarding'
    // Redirect to dashboard if goals were set
    if (!!Object.keys(goals).length && removeOnboardingTypeform) {
      redirectTo = '/dashboard'
    }
    dispatch(actions.updatePasswordRequest({ password: values.password, redirectTo }))
  }

  return (
    <Form name="basic" layout="vertical" onFinish={handleSubmit}>
      <Form.Item
        label="Password"
        name="password"
        rules={[
          { required: true, message: 'Password is required' },
          { type: 'string', min: 6, message: 'Password should have at least 6 characters.' },
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        label="Confirm Password"
        name="confirmPassword"
        rules={[
          { required: true, message: 'Please confirm your password!' },
          { type: 'string', min: 6, message: 'Password should have at least 6 characters.' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve()
              }
              return Promise.reject('The two passwords that you entered do not match!')
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item>
        <Button type="primary" size="large" htmlType="submit" loading={loading}>
          Update Password
        </Button>
      </Form.Item>
      {error && <Typography.Paragraph type="danger">{error.message}</Typography.Paragraph>}
    </Form>
  )
}
