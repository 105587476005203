import React from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '@emotion/react'
import { selectors } from '../store'
import { roleMap } from '@mm/common'
import { Account } from '@mm/backend/accounts'

interface Props {
  ids?: Array<string>
  omitActiveAccount?: boolean
  emptyText?: string
  meeting?: any
  showRole?: boolean
  profileLink?: boolean
}

export const AccountNames: React.FC<Props> = ({
  ids,
  omitActiveAccount = false,
  meeting,
  emptyText = 'No Likes Yet',
  showRole,
  profileLink,
}) => {
  const theme = useTheme()
  const activeAccount = useSelector(selectors.getActiveAccount())
  const allAccounts = useSelector(selectors.getAccounts())

  if (!ids || ids.length === 0) return <>{emptyText}</>

  const showComma = (index: number, arr: Array<string>): string => {
    if (arr.length - 1 !== index) {
      return ', '
    }

    return ''
  }

  const hasRole = (account: Account): boolean => {
    return account && showRole && meeting && meeting.participants && meeting.participants[account.id]
  }

  const getRoleName = (role: string): string => {
    return role.toLowerCase().replace(/_/g, ' ')
  }

  const getSortedRoleArr = (a: string, b: string): number => {
    const accountA = allAccounts[a]
    const accountB = allAccounts[b]
    if (hasRole(accountA) && hasRole(accountB)) {
      const roleA = meeting.participants[accountA.id].role
      const roleB = meeting.participants[accountB.id].role
      if (roleA === roleMap.owner) {
        return -1
      }

      if (roleA === roleMap.participant && roleB === roleMap.observer) {
        return -1
      }

      if (roleA === roleMap.participant && roleB === roleMap.owner) {
        return 1
      }

      return 1
    }

    return 1
  }

  const renderAccountNames = ids
    .filter((id) => (omitActiveAccount && activeAccount && id === activeAccount.id ? false : true))
    .sort((a, b): number => getSortedRoleArr(a, b))
    .map((id, index, arr) => {
      const account = allAccounts[id]
      if (account) {
        const role = hasRole(account) ? meeting.participants[account.id].role : null
        const renderedAccountInfo =
          account.name +
          (role && role !== roleMap.participant
            ? ` (${getRoleName(role)})${showComma(index, arr)}`
            : showComma(index, arr))

        if (profileLink) {
          return (
            <a key={id} href={`/profile/${id}`} style={{ color: theme.font.color.light }} target="__blank">
              {`${renderedAccountInfo}`}
            </a>
          )
        }

        return renderedAccountInfo
      }
    })

  return <>{renderAccountNames}</>
}

export default AccountNames
