import React from 'react'
import { Modal, Form, Input, Typography } from 'antd'
import { auth } from '../../firebase'

interface Props {
  visible: boolean
  onCancel: () => void
}

interface Fields {
  email: string
}

export const ForgotPasswordModal: React.FC<Props> = ({ visible, onCancel }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = React.useState(false)
  const [message, setMessage] = React.useState('')
  const [error, setError] = React.useState(false)

  const onSubmit = async () => {
    try {
      const values = (await form.validateFields()) as Fields
      const { email } = values

      setError(false)
      setLoading(true)

      await auth.sendPasswordResetEmail(email)

      setLoading(false)
      setMessage(`Recovery email sent to ${email}.`)
    } catch (error) {
      if (!(error instanceof Error)) return
      console.log(error)
      setLoading(false)
      setMessage(error.message)
      setError(true)
    }
  }

  return (
    <Modal
      title="Forgot password"
      visible={visible}
      onOk={onSubmit}
      onCancel={onCancel}
      okText="Send Email"
      confirmLoading={loading}
    >
      <Form form={form} name="basic" layout="vertical">
        <Form.Item label="Recovery Email" name="email" rules={[{ required: true, message: 'Email is required' }]}>
          <Input />
        </Form.Item>
      </Form>
      {message && error && <Typography.Paragraph type="danger">{message}</Typography.Paragraph>}
      {message && !error && <Typography.Paragraph type="success">{message}</Typography.Paragraph>}
    </Modal>
  )
}

export default ForgotPasswordModal
