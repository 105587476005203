import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions, selectors } from '..'
import { CompanySelector } from './CompanySelector'

interface Props {
  initialValue?: string
  width?: number | string
  disabled?: boolean
}

export const ActiveCompanySelector: React.FC<Props> = ({ width = 200, initialValue, disabled }) => {
  const dispatch = useDispatch()
  const activeCompanyId = useSelector(selectors.getActiveCompanyId())
  const companyStore = useSelector(selectors.getCompanies())

  useEffect(() => {
    if (initialValue && activeCompanyId !== initialValue && companyStore[initialValue]) {
      dispatch(actions.setActive({ companyId: initialValue }))
      return
    }
  }, [])

  return <CompanySelector value={activeCompanyId} width={width} disabled={disabled} />
}
