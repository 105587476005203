import React from 'react'
import { ColorName } from './theme'
import { Div } from './Div'
import { Text } from './Text'
import { Flex } from './Flex'

interface Props {
  type?: 'default' | 'info'
  title?: string
  body?: string
  icon?: React.ReactElement
  action?: React.ReactElement
  style?: React.CSSProperties
}

const typeToBackgroundName = (type: Props['type']): Record<string, ColorName | undefined> => {
  switch (type) {
    case 'default':
      return {
        color: 'gray',
        background: 'grayBackground',
      }
    case 'info':
      return {
        color: 'blue',
        background: 'blueBackground',
      }
    default:
      return {
        color: undefined,
        background: undefined,
      }
  }
}

export const Notice: React.FC<Props> = ({ type = 'default', title, body, icon, action, style }) => {
  const { background } = typeToBackgroundName(type)
  return (
    <Flex padding={2} gap={1} background={background} rounded={true} style={style}>
      {icon}

      <Div style={{ flex: 1 }}>
        {title ? (
          <Text size="large" color="dark" block={true}>
            {title}
          </Text>
        ) : null}
        {body ? <Text>{body}</Text> : null}
      </Div>

      {action}
    </Flex>
  )
}
