import React, { useEffect, useState } from 'react'
import { Div, Text } from '../../interface'

interface Props {
  items: Array<{
    title: string
    id: string
  }>
  width?: number
  postfix?: string
  scrollOffset?: number
  showPreviousLink?: boolean
}

const useMeetingOutlineLocation = (querySelector = 'h2,h3') => {
  const [activeKeys, setActiveKeys] = React.useState<Record<string, boolean>>({})

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const changedKeys: Record<string, boolean> = {}
        entries.forEach((entry) => {
          const id = entry.target.getAttribute('id')
          if (id) {
            changedKeys[id] = entry.isIntersecting
          }
        })
        setActiveKeys((staleKeys) => ({ ...staleKeys, ...changedKeys }))
      },
      { threshold: 0.1, rootMargin: '-60px 0px 0px 0px' },
    )

    document.querySelectorAll(querySelector).forEach((header) => observer.observe(header))
    return () => observer.disconnect()
  }, [])

  return [activeKeys]
}

export const MeetingOutline: React.FC<Props> = ({ items, postfix, width, showPreviousLink, scrollOffset }) => {
  const effectiveItems = [...items, ...(showPreviousLink ? [{ id: 'previousHeader', title: 'Previous Meetings' }] : [])]
  const [activeKeys] = useMeetingOutlineLocation()
  const [clickedKey, setClickedKey] = useState<string>()
  const [scrolling, setScrolling] = useState(false)

  const activeItem =
    clickedKey && activeKeys[clickedKey]
      ? effectiveItems.find(({ id }) => `${id}-${postfix}` == clickedKey)
      : effectiveItems.find(({ id }) => activeKeys[`${id}-${postfix}`])

  useEffect(() => {
    if (clickedKey) {
      if (scrolling) {
        if (activeKeys[clickedKey]) {
          setScrolling(false)
        }
      } else {
        if (!activeKeys[clickedKey]) {
          setClickedKey(undefined)
        }
      }
    }
  }, [activeKeys, clickedKey, scrolling])

  const handleMove = (id: string) => () => {
    const clickedKey = `${id}-${postfix}`
    const item = document.querySelector(`#${clickedKey}`)

    if (item) {
      setClickedKey(clickedKey)
      setScrolling(true)
      const top = item.getBoundingClientRect().top + window.pageYOffset + -(scrollOffset || 80) // height of header
      window.scroll({ top, left: 0, behavior: 'smooth' })
    }
  }

  return (
    <Div style={{ position: 'sticky', top: 80, marginBottom: 20, width }}>
      {effectiveItems.map(({ title, id }) => (
        <Div key={id} style={{ marginBottom: 10 }}>
          <Text
            onClick={handleMove(id)}
            color={activeItem?.id === id ? 'normal' : 'light'}
            style={{
              letterSpacing: -0.2,
              paddingLeft: 5,
              borderLeft: activeItem?.id === id ? '2px solid' : 'none',
              cursor: 'pointer',
            }}
          >
            {title}
          </Text>
        </Div>
      ))}
    </Div>
  )
}

export default MeetingOutline
