import React from 'react'
import { Button } from './Button'
import { setup } from '@loomhq/loom-sdk'
import theme, { ColorName } from './theme'

interface Props {
  onComplete: (params: { embedUrl: string; shareUrl: string }) => void
  size?: keyof theme['button']['size']
  color?: ColorName
}

export const RecordVideoButton: React.FC<Props> = ({ size, color, onComplete, children }) => {
  const ref = React.useRef(null)
  const [loom, setLoom] = React.useState<any>(null)

  React.useEffect(() => {
    const run = async () => {
      if (loom === null) {
        const instance = await setup({ apiKey: 'fce51bce-c6a9-4205-b67d-7b3c3dd33a6e' })
        setLoom(instance)
      }
    }

    run()
  }, [loom])

  React.useLayoutEffect(() => {
    if (ref?.current && loom !== null) {
      loom.configureButton({
        element: ref.current,
        hooks: {
          onInsertClicked: (embed: any) => {
            onComplete({ embedUrl: embed.embedUrl, shareUrl: embed.sharedUrl })
          },
        },
      })
    }
  }, [loom])

  return (
    <div ref={ref}>
      {children || (
        <Button size={size} color={color}>
          Record Video
        </Button>
      )}
    </div>
  )
}

export default RecordVideoButton
