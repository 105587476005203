import DotsVerticalIcon from '@icons/material/DotsVerticalIcon'
import { notification, Popover, Spin } from 'antd'
import { format } from 'date-fns'
import _ from 'lodash'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import InfiniteScroller from 'react-infinite-scroller'
import { useQueryClient } from 'react-query'
import { AccountName } from '../../accounts'
import { Div, FlexRow, Text } from '../../interface'
import useDeleteIssueMutation from '../hooks/useDeleteIssueMutation'
import useIssues from '../hooks/useIssues'
import Fuse from 'fuse.js'

interface Props {
  companyId?: string | null
  search?: string
}

export const stripHtml = (string: string) => string.replace(/(<([^>]+)>)|(&.*?;)/gi, '')

function truncateWithEllipses(text: string, max: number) {
  return text.substr(0, max - 1) + (text.length > max ? '&hellip;' : '')
}

const createDescTitleString = (description: string) => {
  const rawDescription = stripHtml(description)
  return truncateWithEllipses(rawDescription, 20)
}

export const IssueListTableOld: React.FC<Props> = ({ companyId, search }) => {
  const { data, fetchNextPage, hasNextPage, status } = useIssues(companyId || undefined)
  const issues = data?.pages.flatMap((page) =>
    page.edges.map(({ node }) => node).filter((issue) => !companyId || issue.companyId === companyId),
  )
  const router = useRouter()
  const deleteMutation = useDeleteIssueMutation()
  const queryClient = useQueryClient()

  const loading = status === 'loading'
  const hasMore = hasNextPage ?? false

  const handleEditIssue = (id: string) => () => {
    router.push(`/issues/${id}/edit`)
  }

  const handleDeleteIssue = (id: string) => async () => {
    await deleteMutation.mutateAsync(id)
    notification.success({
      message: 'Issue deleted',
    })
    await queryClient.invalidateQueries('issues')
  }

  if (!issues || loading) {
    return (
      <Div style={{ minHeight: 600 }}>
        <FlexRow paddingVertical={1} gap={1}>
          <Text color="light" style={{ flex: 4 }}>
            Title
          </Text>

          <Text color="light" style={{ flex: 1 }}>
            Created
          </Text>

          <Text color="light" style={{ flex: 1 }}>
            Status
          </Text>

          <Text color="light" style={{ flex: 1 }}>
            Proposer
          </Text>

          <Text color="light" style={{ flex: 1 }}>
            Decision Maker
          </Text>

          <Text color="light" style={{ flex: 1 }}>
            Due Date
          </Text>

          <Div style={{ width: 24 }} />
        </FlexRow>

        <FlexRow paddingVertical={1} style={{ borderTop: '1px solid #eee' }} gap={2}>
          <Div height={1} background="background" style={{ flex: 4 }} />
          <Div height={1} background="background" style={{ flex: 1 }} />
          <Div height={1} background="background" style={{ flex: 1 }} />
          <Div height={1} background="background" style={{ flex: 1 }} />
          <Div height={1} background="background" style={{ flex: 1 }} />
          <Div height={1} background="background" style={{ flex: 1 }} />
          <Div style={{ width: 24 }} />
        </FlexRow>

        <FlexRow paddingVertical={1} style={{ borderTop: '1px solid #eee' }} gap={2}>
          <Div height={1} background="background" style={{ flex: 4 }} />
          <Div height={1} background="background" style={{ flex: 1 }} />
          <Div height={1} background="background" style={{ flex: 1 }} />
          <Div height={1} background="background" style={{ flex: 1 }} />
          <Div height={1} background="background" style={{ flex: 1 }} />
          <Div height={1} background="background" style={{ flex: 1 }} />
          <Div style={{ width: 24 }} />
        </FlexRow>

        <FlexRow paddingVertical={1} style={{ borderTop: '1px solid #eee' }} gap={2}>
          <Div height={1} background="background" style={{ flex: 4 }} />
          <Div height={1} background="background" style={{ flex: 1 }} />
          <Div height={1} background="background" style={{ flex: 1 }} />
          <Div height={1} background="background" style={{ flex: 1 }} />
          <Div height={1} background="background" style={{ flex: 1 }} />
          <Div height={1} background="background" style={{ flex: 1 }} />
          <Div style={{ width: 24 }} />
        </FlexRow>

        <FlexRow justifyContent="center" vertical={1}>
          <Spin />
        </FlexRow>
      </Div>
    )
  }

  const fuse = new Fuse(issues, {
    keys: ['title'],
    shouldSort: false,
    ignoreLocation: true,
    threshold: 0.5,
  })
  const results = search ? fuse.search(search) : issues

  return results.length ? (
    <Div style={{ minHeight: 600 }}>
      <InfiniteScroller
        loadMore={() => fetchNextPage()}
        hasMore={hasMore}
        loader={
          <FlexRow justifyContent="center">
            <Spin />
          </FlexRow>
        }
      >
        <FlexRow paddingVertical={1} gap={1}>
          <Text color="light" style={{ flex: 4 }}>
            Title
          </Text>

          <Text color="light" style={{ flex: 1 }}>
            Created
          </Text>

          <Text color="light" style={{ flex: 1 }}>
            Status
          </Text>

          <Text color="light" style={{ flex: 1 }}>
            Proposer
          </Text>

          <Text color="light" style={{ flex: 1 }}>
            Decision Maker
          </Text>

          <Text color="light" style={{ flex: 1 }}>
            Due Date
          </Text>

          <Div style={{ width: 24 }} />
        </FlexRow>

        {results.map((issue) => (
          <FlexRow key={issue.id} paddingVertical={1} style={{ borderTop: '1px solid #eee' }} gap={1}>
            <Link href={`/issues/${issue.id}`} passHref={true}>
              <a style={{ flex: 4 }} data-cy="issue-table-link">
                {issue.title || createDescTitleString(issue.description)}
              </a>
            </Link>

            <Text color="light" style={{ flex: 1 }}>
              {issue.createdAt ? format(new Date(issue.createdAt), 'LLL dd') : ''}
            </Text>

            <Text color="light" style={{ flex: 1 }}>
              {_.startCase((issue.status || 'CREATED').toLowerCase())}
            </Text>

            <Text color="light" style={{ flex: 1 }}>
              <AccountName id={issue.accountId} skipYou={true} />
            </Text>

            <Text color="light" style={{ flex: 1 }}>
              {issue.decisionMaker ? <AccountName id={issue.decisionMaker} skipYou={true} /> : null}
            </Text>

            <Text color="light" style={{ flex: 1 }}>
              {issue.decisionDueDate ? format(issue.decisionDueDate, 'LLL dd') : ''}
            </Text>

            <Div style={{ width: 24 }}>
              <Popover
                placement="leftTop"
                content={
                  <Div>
                    <Div bottom={1} style={{ cursor: 'pointer' }} onClick={handleEditIssue(issue.id)}>
                      Edit
                    </Div>
                    <Div
                      top={1}
                      style={{ cursor: 'pointer' }}
                      onClick={!deleteMutation.isLoading ? handleDeleteIssue(issue.id) : undefined}
                    >
                      <Text color={deleteMutation.isLoading ? 'light' : 'dark'}>Delete</Text>
                    </Div>
                  </Div>
                }
                trigger="click"
              >
                <DotsVerticalIcon style={{ color: '#999', display: 'block', cursor: 'pointer' }} />
              </Popover>
            </Div>
          </FlexRow>
        ))}
      </InfiniteScroller>
    </Div>
  ) : (
    <FlexRow style={{ height: 160, border: '1px solid #eee', borderRadius: 4 }} justifyContent="center">
      <Text color="light">No issues yet.</Text>
    </FlexRow>
  )
}
