import { Middleware } from 'redux'
import firebase, { getRefFormatByIds, listenForNewUpdates } from '../firebase'
import { actions, actionTypes } from './store'
import { actionTypes as authActionTypes, actions as authActions } from '../auth'
import { Group } from '@mm/backend/groups/model'
import { ReqBody as UpdateReqBody, ResBody as UpdateResBody } from '../../pages/api/groups/update'
import { ReqBody as AddAccountReqBody, ResBody as AddAccountResBody } from '../../pages/api/groups/addAccount'
import { ReqBody as RemoveAccountReqBody, ResBody as RemoveAccountResBody } from '../../pages/api/groups/removeAccount'
import axios from 'axios'
import { notification } from 'antd'

let detachListner: () => void

const middleware: Middleware = ({ dispatch }) => {
  return (next) => async (action: actions | authActions) => {
    next(action)

    switch (action.type) {
      case actionTypes.EDIT: {
        try {
          await axios.post<UpdateResBody, UpdateReqBody>('/api/groups/update', {
            id: action.id,
            library: action.library,
          })
        } catch (error) {
          if (!(error instanceof Error)) return
          console.log(error)
          notification.error({
            message: error.message,
          })
        }
        break
      }
      case actionTypes.ADD_ACCOUNT: {
        try {
          await axios.post<AddAccountResBody, AddAccountReqBody>('/api/groups/addAccount', {
            groupId: action.groupId,
            accountId: action.accountId,
            role: action.role,
          })
        } catch (error) {
          if (!(error instanceof Error)) return
          console.log(error)
          notification.error({
            message: error.message,
          })
        }
        break
      }
      case actionTypes.REMOVE_ACCOUNT: {
        try {
          await axios.post<RemoveAccountResBody, RemoveAccountReqBody>('/api/groups/removeAccount', {
            groupId: action.groupId,
            accountId: action.accountId,
          })
        } catch (error) {
          if (!(error instanceof Error)) return
          console.log(error)
          notification.error({
            message: error.message,
          })
        }
        break
      }

      case authActionTypes.USER_CHANGE: {
        if (action.user) {
          if (detachListner) {
            detachListner()
          }

          const groupsRef = firebase.collection('groups').where('participantIds', 'array-contains', action.user.uid)

          const groups = await getRefFormatByIds<Group>(groupsRef)
          dispatch(actions.set({ groups }))

          detachListner = listenForNewUpdates<Group>(
            groupsRef,
            (change, group) => {
              if (change.type === 'added') {
                dispatch(actions.add({ group }))
              }
              if (change.type === 'modified') {
                dispatch(actions.change({ group }))
              }
              if (change.type === 'removed') {
                dispatch(actions.remove({ group }))
              }
            },
            ['createdAt'],
          )
        }
        break
      }
      default:
        break
    }
  }
}

export default middleware
