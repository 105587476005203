import { useMutation, useQuery } from '@apollo/client'
import DotsVerticalIcon from '@icons/material/DotsVerticalIcon'
import { notification, Popover, Spin } from 'antd'
import { format } from 'date-fns'
import _ from 'lodash'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import { AccountName } from '../../accounts'
import { Div, FlexRow, Text } from '../../interface'
import { IssueListTableRowDeleteDocument, IssuesListTableRowDocument } from '../../../gen/graphql/documents'

export type IssueListTableRowProps = {
  issueId: string
}

export function IssueListTableRow({ issueId }: IssueListTableRowProps): React.ReactElement {
  const { data, loading } = useQuery(IssuesListTableRowDocument, {
    variables: {
      id: issueId,
    },
  })
  const [deleteMutation, { loading: deleting }] = useMutation(IssueListTableRowDeleteDocument, {
    variables: {
      id: issueId,
    },
    update: (cache, { data }) => {
      if (data?.deleteIssue.__typename === 'Success') {
        try {
          cache.evict({
            id: cache.identify({ __typename: 'Issue', id: issueId }),
          })
          cache.gc()
        } catch (e) {
          console.error('Failed to remove delete issue from cache', e)
        }
      }
    },
  })
  const router = useRouter()

  const issue = data?.issue

  const handleEditIssue = () => () => {
    router.push(`/issues/${issueId}/edit`)
  }

  const handleDeleteIssue = async () => {
    const { data } = await deleteMutation()
    if (data?.deleteIssue.__typename === 'Success') {
      notification.success({
        message: data.deleteIssue.message,
      })
    } else {
      notification.error({
        message: data?.deleteIssue.message ?? 'Failed to delete the issue',
      })
    }
  }

  if (issue == null) {
    if (loading) {
      return (
        <FlexRow paddingVertical={1} style={{ borderTop: '1px solid #eee' }} gap={1} justifyContent="center">
          <Spin />
        </FlexRow>
      )
    } else {
      return <></>
    }
  }

  return (
    <FlexRow paddingVertical={1} style={{ borderTop: '1px solid #eee' }} gap={1}>
      <Link href={`/issues/${issue.id}`} passHref={true}>
        <a data-cy="issue-table-link" style={{ flex: 4 }}>
          {issue.title}
        </a>
      </Link>

      <Text color="light" style={{ flex: 1 }}>
        {issue.createdAt ? format(new Date(issue.createdAt), 'LLL dd') : ''}
      </Text>

      <Text color="light" style={{ flex: 1 }}>
        {_.startCase((issue.status || 'CREATED').toLowerCase())}
      </Text>

      <Text color="light" style={{ flex: 1 }}>
        <AccountName id={issue.proposer.id} skipYou={true} />
      </Text>

      <Text color="light" style={{ flex: 1 }}>
        {issue.decisionMaker ? <AccountName id={issue.decisionMaker.id} skipYou={true} /> : null}
      </Text>

      <Text color="light" style={{ flex: 1 }}>
        {issue.decisionDueDate ? format(issue.decisionDueDate, 'LLL dd') : ''}
      </Text>

      <Div style={{ width: 24 }}>
        <Popover
          placement="leftTop"
          content={
            <Div>
              <Div bottom={1} style={{ cursor: 'pointer' }} onClick={handleEditIssue}>
                Edit
              </Div>
              <Div top={1} style={{ cursor: 'pointer' }} onClick={!deleting ? handleDeleteIssue : undefined}>
                <Text color={deleting ? 'light' : 'dark'}>Delete</Text>
              </Div>
            </Div>
          }
          trigger="click"
        >
          <DotsVerticalIcon style={{ color: '#999', display: 'block', cursor: 'pointer' }} />
        </Popover>
      </Div>
    </FlexRow>
  )
}
