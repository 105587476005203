import React from 'react'
import { Select } from 'antd'
import { useSelector } from 'react-redux'
import { selectors } from '../store'
import { selectors as companySelectors } from '../../companies/store'

interface Props {
  width: number
  mode?: 'tags' | undefined
  onChange?: (id: string) => void
  disabled?: boolean
  allowClear?: boolean
  value?: string
  excludeIds?: Array<string>
  firstNameOnly?: boolean
}
// restProps gets passed down to Select so the value gets picked up when used inside a Form component
export const AccountSelector: React.FC<Props> = ({ width = 400, excludeIds, firstNameOnly, ...restProps }) => {
  const activeAccount = useSelector(selectors.getActiveAccount())
  const accounts = Object.values(useSelector(selectors.getAccounts()))

  const activeCompany = useSelector(companySelectors.getActiveCompany())
  const otherMemberIds = activeCompany
    ? activeCompany.members.filter((id) => activeAccount && id !== activeAccount.id)
    : []

  // Only allow all other company members to join
  const allOtherMembers = accounts.filter((account) => {
    const allowed = activeAccount && account.id !== activeAccount.id && otherMemberIds.includes(account.id)

    if (excludeIds && excludeIds.length) {
      return allowed && !excludeIds.includes(account.id)
    }

    return allowed
  })

  return (
    <Select
      showSearch={true}
      tokenSeparators={[',']}
      style={{ width }}
      placeholder="Add user"
      optionFilterProp="children"
      data-cy="test-account-selector"
      {...restProps}
    >
      {allOtherMembers.map((account, index) => (
        <Select.Option value={account.id} key={`${account.id} - ${index}`}>
          {firstNameOnly ? account.name.split(' ')[0] : `${account.name} <${account.email}>`}
        </Select.Option>
      ))}
    </Select>
  )
}

export default AccountSelector
