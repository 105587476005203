import { ExclamationCircleTwoTone } from '@ant-design/icons'
import { MeetingParticipantRole, roleMap } from '@mm/common'
import { Button, Form, Input, Modal, Popconfirm, Spin, Typography } from 'antd'
import type { FormInstance } from 'antd/es/form'
import React from 'react'
import DatePicker from 'react-datepicker'
import { useSelector } from 'react-redux'
import { selectors as accountsSelectors } from '../../../accounts'
import { CompanySelector } from '../../../companies'
import { FlexColumn } from '../../../interface'
import { CalendarSelector } from './CalendarSelector'
import { MeetingParticipantsInput } from './MeetingParticipantsInput'
import { RecurrenceInput } from './RecurrenceInput'

export type CreateEditMeetingModalViewProps = {
  form: FormInstance<Fields>
  title: string
  submitText: string
  visible: boolean
  loading: boolean
  submitting: boolean
  initialValues: Partial<Fields>

  canChangeCompanyId?: boolean
  updateView?: boolean

  onSubmit?: () => void
  onCancel?: () => void
  onRegenerateUpdates?: () => void
  onDelete?: () => void
}

interface Participant {
  id: string
  role: MeetingParticipantRole
}

export interface Fields {
  title: string
  startAt: Date
  companyId: string
  duration: number
  recurrence: string
  conferenceUrl: string
  participants: Participant[]
  calendarId: string | null
}

export function CreateEditMeetingModalView({
  form,
  title,
  submitText,
  visible,
  loading,
  submitting,
  initialValues,
  canChangeCompanyId = true,
  updateView = false,
  onSubmit,
  onCancel,
  onRegenerateUpdates,
  onDelete,
}: CreateEditMeetingModalViewProps): React.ReactElement {
  const activeAccountId = useSelector(accountsSelectors.getActiveAccountId())

  const defaultParticipants: Participant[] = activeAccountId
    ? [
        {
          id: activeAccountId,
          role: roleMap.owner,
        },
      ]
    : []

  return (
    <Modal
      title={title}
      visible={visible}
      okText={submitText}
      onOk={() => onSubmit?.()}
      onCancel={() => onCancel?.()}
      okButtonProps={{
        disabled: submitting,
        loading: submitting,
      }}
      wrapProps={{
        'data-cy': 'create-edit-meeting-modal',
      }}
    >
      {loading ? (
        <FlexColumn alignItems="center" paddingVertical={2}>
          <Spin size="large" />
        </FlexColumn>
      ) : (
        <>
          <Form form={form} name="basic" layout="vertical" initialValues={initialValues}>
            <Form.Item
              label="Meeting Title"
              name="title"
              rules={[{ required: true, message: 'Meeting title is required' }]}
            >
              <Input disabled={submitting} />
            </Form.Item>

            <Input.Group compact={true}>
              <Form.Item
                label="Start"
                name="startAt"
                rules={[{ required: true, message: 'Start time is required' }]}
                valuePropName="selected"
                style={{ marginRight: 12 }}
              >
                <DatePicker showTimeSelect={true} dateFormat="Pp" onChange={() => null} disabled={submitting} />
              </Form.Item>

              <Form.Item
                label="Duration (min)"
                name="duration"
                rules={[{ required: true, message: 'Duration is required' }]}
              >
                <Input type="number" disabled={submitting} />
              </Form.Item>
            </Input.Group>

            <Form.Item label="Recurrence" name="recurrence">
              <RecurrenceInput />
            </Form.Item>

            <Form.Item
              label="Video Conference Link"
              name="conferenceUrl"
              rules={[{ type: 'url', message: 'Must be a valid URL' }]}
            >
              <Input placeholder="https://us02web.zoom.us/..." disabled={submitting} />
            </Form.Item>

            <Form.Item
              label="Company"
              name="companyId"
              rules={[{ required: true, message: 'Company selection is required' }]}
            >
              <CompanySelector
                onChange={() =>
                  form.setFieldsValue({
                    participants: defaultParticipants,
                  })
                }
                width={270}
                disabled={updateView || submitting || !canChangeCompanyId}
              />
            </Form.Item>

            <Form.Item
              label="Participants"
              name="participants"
              rules={[
                {
                  type: 'array',
                  required: true,
                  min: 1,
                },
              ]}
            >
              <MeetingParticipantsInput disabled={submitting} />
            </Form.Item>

            <Form.Item label="Add to calendar" name="calendarId">
              <CalendarSelector />
            </Form.Item>
          </Form>

          {updateView && (
            <>
              <Popconfirm
                title={
                  <>
                    <Typography.Text type="secondary">
                      Are you sure you want to regenerate updates for all participants?
                    </Typography.Text>
                  </>
                }
                icon={<ExclamationCircleTwoTone />}
                onConfirm={() => onRegenerateUpdates?.()}
                okText="Yes, Regenerate"
                cancelText="Cancel"
                okType="danger"
              >
                <Button type="text" danger>
                  Regenerate Update Forms
                </Button>
              </Popconfirm>
              <Popconfirm
                title={
                  <>
                    <Typography.Title level={5}>Are you sure you want to delete this meeting?</Typography.Title>
                    <Typography.Text type="secondary">You will be redirected to the Meetings page.</Typography.Text>
                  </>
                }
                icon={<ExclamationCircleTwoTone />}
                onConfirm={() => onDelete?.()}
                okText="Yes, Delete"
                cancelText="Cancel"
                okType="danger"
              >
                <Button type="text" danger>
                  Delete Meeting
                </Button>
              </Popconfirm>
            </>
          )}
        </>
      )}
    </Modal>
  )
}
