import styled from '@emotion/styled'
import { color, font, spacing } from '../../interface/theme'
import React from 'react'

export type ToolbarButtonProps = {
  active?: boolean
  menu?: React.ReactNode
  children?: React.ReactNode
  onClick?: (e: React.MouseEvent) => void
  disabled?: boolean
}

const ToolbarButtonContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: stretch;
`

const InnerButton = styled.button<ToolbarButtonProps>`
  display: flex;
  align-items: center;

  border: 1px solid ${color('lightGray')};
  border-right: 0;
  ${ToolbarButtonContainer}:first-of-type > & {
    border-top-left-radius: ${({ theme }) => theme.button.radius}px;
    border-bottom-left-radius: ${({ theme }) => theme.button.radius}px;
  }
  ${ToolbarButtonContainer}:last-of-type > & {
    border-top-right-radius: ${({ theme }) => theme.button.radius}px;
    border-bottom-right-radius: ${({ theme }) => theme.button.radius}px;
    border: 1px solid ${color('lightGray')};
  }
  background: ${color('grayBackground')};
  padding: ${spacing(0.5)}px ${spacing(1)}px;
  cursor: pointer;

  &:active {
    background: ${color('blueBackground')};
  }

  &:disabled {
    background: ${color('lightGray')};
  }

  ${({ active }) => font({ color: active ? 'blue' : 'dark' })}
`

export function ToolbarButton({ active, children, disabled, menu, onClick }: ToolbarButtonProps): React.ReactElement {
  return (
    <ToolbarButtonContainer>
      <InnerButton active={active} onClick={onClick} disabled={disabled}>
        {children}
      </InnerButton>
      {menu}
    </ToolbarButtonContainer>
  )
}
