import { Middleware } from 'redux'
import _ from 'lodash'
import firebase, { getRefFormatByIds, listenForNewUpdates, inflateDates } from '../firebase'
import { actions, actionTypes } from './store'
import {
  actionTypes as meetingsActionTypes,
  actions as meetingsActions,
  selectors as meetingsSelectors,
} from '../meetings'
import { selectors as accountSelectors } from '../accounts'
import { dateKeys, GoodThing } from '@mm/backend/goodThings/model'
import { ReqBody as CreateReqBody, ResBody as CreateResBody } from '../../pages/api/goodThings/create'
import { ReqBody as UpdateReqBody, ResBody as UpdateResBody } from '../../pages/api/goodThings/update'
import { ReqBody as DeleteReqBody, ResBody as DeleteResBody } from '../../pages/api/goodThings/delete'
import { captureException } from '../sentry'
import axios from 'axios'
import { notification } from 'antd'
import { v4 as uuid } from 'uuid'

// Key listeners based off meeting.id so that expanding other meetings
// will not remove listeners from the main meeting that is loaded.
const listeners: Record<string, Array<() => void>> = {}

const middleware: Middleware = ({ dispatch, getState }) => {
  return (next) => async (action: actions | meetingsActions) => {
    next(action)

    switch (action.type) {
      case actionTypes.CREATE_REQUEST: {
        try {
          const state = getState()
          const account = accountSelectors.getActiveAccount()(state)
          if (!account) {
            throw new Error('Must be logged in')
          }
          const res = await axios.post<CreateResBody, CreateReqBody>('/api/goodThings/create', {
            description: action.description,
            type: action.thingType,
            commentIds: [],
            meetingId: action.meetingId,
            updateId: action.updateId,
            accountId: account.id,
            attachments: action.attachments,
          })
          const goodThing = inflateDates(res.data, ['createdAt'])
          dispatch(actions.createSuccess({ goodThing }))
        } catch (error) {
          if (!(error instanceof Error)) return
          console.log(error)
          dispatch(actions.createFailure({ error }))
          notification.error({
            message: 'There was an error creating a new good thing',
          })
        }
        break
      }

      case actionTypes.EDIT: {
        const changeId = uuid()
        const itemId = action.id
        try {
          const patch = {
            accountId: action.accountId,
            description: action.description,
            type: action.thingType,
            attachments: action.attachments,
            updateId: action.updateId,
          }
          if (action.optimistic) {
            dispatch(
              actions.optimisticChange({
                changeId,
                itemId,
                patch,
              }),
            )
          }
          const res = await axios.post<UpdateResBody, UpdateReqBody>('/api/goodThings/update', {
            id: action.id,
            ...patch,
          })
          dispatch(
            actions.optimisticCommit({
              changeId,
              item: inflateDates(res.data, dateKeys),
            }),
          )
        } catch (error) {
          dispatch(
            actions.optimisticRollback({
              changeId,
              itemId,
            }),
          )
          console.log(error)
          notification.error({
            message: 'There was an error editing the good thing',
          })
        }
        break
      }

      case actionTypes.DELETE: {
        try {
          await axios.post<DeleteResBody, DeleteReqBody>('/api/goodThings/delete', {
            id: action.id,
            updateId: action.updateId,
          })
        } catch (error) {
          console.log(error)
          notification.error({
            message: 'There was an error deleting the good thing',
          })
        }
        break
      }

      case actionTypes.LIKE: {
        const updatedData = {
          likes: action.goodThing.likes ? action.goodThing.likes + 1 : 1,
          likeAccountIds: [...(action.goodThing.likeAccountIds || []), action.accountId],
        }
        dispatch(
          actions.change({
            goodThing: { ...action.goodThing, ...updatedData },
          }),
        )
        try {
          await axios.post<UpdateResBody, UpdateReqBody>('/api/goodThings/update', {
            id: action.goodThing.id,
            ...updatedData,
          })
        } catch (error) {
          console.log(error)
          notification.error({
            message: 'There was an error liking the good thing',
          })
        }
        break
      }

      case actionTypes.UNLIKE: {
        const updatedData = {
          likes: action.goodThing.likes - 1,
          likeAccountIds: action.goodThing.likeAccountIds.filter((id) => id !== action.accountId),
        }
        dispatch(
          actions.change({
            goodThing: { ...action.goodThing, ...updatedData },
          }),
        )
        try {
          await axios.post<UpdateResBody, UpdateReqBody>('/api/goodThings/update', {
            id: action.goodThing.id,
            ...updatedData,
          })
        } catch (error) {
          console.log(error)
          notification.error({
            message: 'There was an error disliking the good thing',
          })
        }
        break
      }

      case meetingsActionTypes.VIEW: {
        try {
          const state = getState()
          const meeting = meetingsSelectors.getMeetingById(action.id)(state)
          let meetingListeners = listeners[action.id] || []

          // This action gets fired when hot reloading on the meeting page.
          // We need to clear old listeners otherwise they will stack up.
          if (meetingListeners.length) {
            meetingListeners.forEach((detach) => detach())
            meetingListeners = []
          }

          // Create listeners for firebase based off chunks of 10 participants.
          const chunks = _.chunk(meeting.participantIds, 10)
          await Promise.all(
            chunks.map(async (participantsChunk) => {
              let goodThingsRef = firebase.collection('goodThings').where('accountId', 'in', participantsChunk)

              if (action.updateId) {
                goodThingsRef = goodThingsRef.where('updateId', '==', action.updateId)
              } else {
                goodThingsRef = goodThingsRef.where('meetingId', '==', action.id)
              }

              // Fetch all the documents once on view and set them.
              const goodThings = await getRefFormatByIds<GoodThing>(goodThingsRef, ['createdAt'])
              dispatch(actions.set({ goodThings }))

              // Listen for updates from Firebase on same documents.
              const listener = listenForNewUpdates<GoodThing>(
                goodThingsRef,
                (change, goodThing) => {
                  if (change.type === 'added') {
                    dispatch(actions.add({ goodThing }))
                  }
                  if (change.type === 'modified') {
                    dispatch(actions.change({ goodThing }))
                  }
                  if (change.type === 'removed') {
                    dispatch(actions.remove({ goodThing }))
                  }
                },
                ['createdAt'],
              )
              meetingListeners.push(listener)
            }),
          )

          listeners[action.id] = meetingListeners
        } catch (error) {
          // When Firebase has a permissions error, catch the exception and
          // send it to Sentry.
          console.warn(error)
          captureException(error)
        }
        break
      }
      default:
        break
    }
  }
}

export default middleware
