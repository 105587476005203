import { ErrorMessage } from '@hookform/error-message'
import { Issue } from '@mm/backend/issues/model'
import { Button as AntButton, Checkbox, notification, Popconfirm, Popover, Select } from 'antd'
import { addDays } from 'date-fns'
import React, { useEffect, useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { selectors as accountsSelectors } from '../../accounts'
import { ActiveCompanySelector, selectors as companySelectors } from '../../companies'
import { Editable, EditableRef } from '../../editor'
import { Button, Card, Div, FlexRow, Input, Text, theme } from '../../interface'
import useCreateIssueMutation from '../hooks/useCreateIssueMutation'
import useDeleteIssueMutation from '../hooks/useDeleteIssueMutation'
import useEditIssueMutation from '../hooks/useEditIssueMutation'
import IssueVideoBox from './IssueVideoBox'

interface SubmitButtonProps {
  isNewIssue: boolean
  participantIds: string[]
  loading?: boolean
  formId?: string
}

const SubmitButton: React.FC<SubmitButtonProps> = ({ isNewIssue, participantIds, loading, formId }) => {
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false)

  return participantIds.length > 1 ? (
    <Button data-cy="issue-submit-button" color="primary" type="submit" loading={loading}>
      {isNewIssue ? 'Create Issue' : 'Update Issue'}
    </Button>
  ) : (
    <Popover
      trigger="click"
      placement="topRight"
      visible={popoverVisible}
      content={
        <Div
          style={{
            width: 244,
          }}
        >
          <Div bottom={1}>
            <Text weight="heavy" color="dark" size="medium">
              It&apos;s lonely here
            </Text>
          </Div>
          <Div bottom={2}>
            Issues are solved better with <strong>comments from your team</strong>. Select team members who you want to
            request comments from. Create issue without commenters?
          </Div>
          <Div
            bottom={1}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <AntButton
              style={{ color: theme.color.gray, borderColor: theme.color.gray }}
              onClick={() => setPopoverVisible(false)}
            >
              Cancel
            </AntButton>
            <AntButton
              htmlType="submit"
              form={formId}
              onClick={() => {
                setPopoverVisible(false)
              }}
              style={{ marginLeft: 8, color: theme.color.blue, borderColor: theme.color.blue }}
            >
              Create anyway
            </AntButton>
          </Div>
        </Div>
      }
    >
      <Button data-cy="issue-submit-button" color="primary" type="button" onClick={() => setPopoverVisible(true)}>
        {isNewIssue ? 'Create Issue' : 'Update Issue'}
      </Button>
    </Popover>
  )
}

interface Props {
  cardTitle?: string
  inline?: boolean
  issue?: Issue
  initialValues?: {
    participantIds?: Array<string>
    companyId?: string
    meetingId?: string
    updateId?: string
  }
  onCreate?: (issue: Issue) => void
  onEdit?: (issue: Issue) => void
  onDelete?: (issue: Issue) => void
}

interface FormData {
  title: string
  description: string
  participantIds?: Array<string>
  decisionMaker: string
  decisionDueDate: Date
  commentsDueDate: Date
  video: { embedUrl: string; shareUrl: string }
}

const initialValue = `
<h3>Issue</h3>
<p data-cy="issue-description" data-placeholder="Describe the issue you are facing." />
<p/>
<h3>What I did to help create this situation</h3>
<p data-cy="issue-steps" data-placeholder="What steps would someone have to make to get themselves in the exact same position?" />
<p/>
<h3>Proposed solution</h3>
<p data-cy="issue-proposed-solution" data-placeholder="You likely know the steps needed to back out of the situation. Even if you have no confidence in your solution, write it out anyway." />
<p/>
<h3>Video Link</h3>
<p data-placeholder="Now that you have written up the issue, please also record a video of you describing the issue and place the link here." />
`

export const CreateEditIssueOld: React.FC<Props> = ({
  cardTitle,
  inline,
  issue = {},
  initialValues = {},
  onCreate,
  onEdit,
  onDelete,
}) => {
  const descriptionEditableRef = useRef<EditableRef>(null)
  const companies = useSelector(companySelectors.getCompanies())
  const activeCompany = useSelector(companySelectors.getActiveCompany()) || {}
  const activeAccount = useSelector(accountsSelectors.getActiveAccount()) || {}
  const companiesSize = Object.keys(companies).length
  const companyAccounts = useSelector(accountsSelectors.getAccountsByCompanyId(activeCompany.id))
  const isNewIssue = !issue.id
  const createMutation = useCreateIssueMutation()
  const editMutation = useEditIssueMutation()
  const deleteMutation = useDeleteIssueMutation()

  const [initialParticipantIds, setInitialParticipantIds] = React.useState<Array<string>>([])

  const { register, handleSubmit, formState, control, getValues, setValue, clearErrors, reset } = useForm<FormData>({
    reValidateMode: 'onSubmit',
    defaultValues: {
      title: issue.title || '',
      description: initialValue,
      participantIds: initialValues.participantIds || issue.participantIds || [activeAccount.id],
      decisionMaker: issue.decisionMaker || '',
      decisionDueDate: issue.decisionDueDate || addDays(new Date(), 7),
      commentsDueDate: issue.commentsDueDate || addDays(new Date(), 6),
      video: issue.video,
    },
  })
  const { errors } = formState

  useEffect(() => {
    if (isNewIssue) {
      setValue(
        'participantIds',
        initialValues.participantIds?.length ? initialValues.participantIds : [activeAccount.id],
      )
    }
    setInitialParticipantIds(initialValues.participantIds || issue.participantIds || [activeAccount.id])
  }, [activeCompany])

  const onSubmit = handleSubmit(async (params: FormData) => {
    clearErrors()

    try {
      if (isNewIssue) {
        const newIssue = await createMutation.mutateAsync({
          title: params.title,
          description: params.description,
          participantIds: params.participantIds,
          commentsDueDate: params.commentsDueDate,
          decisionDueDate: params.decisionDueDate,
          decisionMaker: params.decisionMaker,
          accountId: activeAccount.id,
          type: 'work',
          meetingId: initialValues.meetingId || '',
          updateId: initialValues.updateId || '',
          companyId: activeCompany.id,
          video: params.video,
        })

        notification.success({
          message: 'Issue created',
        })

        reset()
        descriptionEditableRef.current?.editor?.chain().setContent(initialValue).run()
        onCreate && onCreate(newIssue)
      } else {
        if (!issue.id) {
          throw new Error('You are trying to edit an issue without an id.')
        }

        await editMutation.mutateAsync({
          id: issue.id,
          issue: {
            title: params.title,
            participantIds: params.participantIds,
            commentsDueDate: params.commentsDueDate,
            decisionDueDate: params.decisionDueDate,
            decisionMaker: params.decisionMaker,
            video: params.video,
          },
        })

        notification.success({
          message: 'Issue saved',
        })

        onEdit && onEdit(issue as Issue)
      }
    } catch (error) {
      // TODO: Add this error message to the page
      console.log(error)
    }
  })

  // TODO: Move this into standalone component to keep the entire form from re-rendering
  const participantIds = useWatch({
    control,
    name: 'participantIds',
    defaultValue: [],
  })

  const mapDecisionMakers = (participantsArray: string[]) =>
    participantsArray.map((id: string) => {
      const account = companyAccounts.find((account) => account.id === id)

      return account ? (
        <Select.Option data-cy="issue-decision-maker-list" value={account.id} key={account.id}>
          {account.name}
        </Select.Option>
      ) : null
    })

  const handleDelete = async () => {
    if (issue.id) {
      await deleteMutation.mutateAsync(issue.id)
      notification.success({
        message: 'Issue deleted',
      })
      onDelete && onDelete(issue as Issue)
    }
  }

  return activeAccount.id ? (
    <Card title={cardTitle} padding={inline ? 0 : 2}>
      <Div style={{ borderTop: cardTitle ? '1px solid #eee' : '' }}>
        <form id="createEditIssueForm" onSubmit={onSubmit}>
          <FlexRow alignItems="flex-start">
            <Div style={{ flex: 1 }} right={4}>
              <Div vertical={1}>
                <Text weight="heavy">Title</Text>{' '}
                <ErrorMessage
                  errors={errors}
                  name="title"
                  render={({ message }: any) => <Text color="red">{message}</Text>}
                />
              </Div>
              <Input
                data-cy="issue-title-input"
                {...register('title', { required: 'Required' })}
                errorOutline={!!errors.title}
              />

              <Div marginTop={2}>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <Editable
                      ref={descriptionEditableRef}
                      documentId={issue?.descriptionDocId}
                      initialValue={issue?.descriptionDocId == null ? field.value : undefined}
                      onChangeHtml={field.onChange}
                      onBlur={field.onBlur}
                    />
                  )}
                />
              </Div>

              <Controller
                name="video"
                control={control}
                render={({ field }) => (
                  <IssueVideoBox
                    shareUrl={field.value?.shareUrl}
                    embedUrl={field.value?.embedUrl}
                    canDelete={issue.accountId === activeAccount.id}
                    onSave={field.onChange}
                    onRemove={() => field.onChange({})}
                  />
                )}
              />
            </Div>

            <Div style={{ width: 220 }}>
              {companiesSize > 1 ? (
                <>
                  <Div vertical={1}>
                    <Text weight="heavy">Company</Text>
                  </Div>
                  {/* TODO: Make this controllable from here */}
                  <ActiveCompanySelector
                    width="100%"
                    initialValue={initialValues.companyId}
                    disabled={!isNewIssue || inline}
                  />
                </>
              ) : null}
              <Div vertical={1}>
                <Text weight="heavy">Request Comments From</Text>
              </Div>
              <Div style={{ maxHeight: 290, overflow: 'scroll' }}>
                <Controller
                  name="participantIds"
                  control={control}
                  render={({ field }) => {
                    const handleChange = (ids: Array<any>) => {
                      if (!ids?.includes(getValues('decisionMaker'))) {
                        setValue('decisionMaker', '')
                      }
                      field.onChange(ids)
                    }
                    return (
                      <Checkbox.Group value={field.value} onChange={handleChange}>
                        {companyAccounts.map((account) => {
                          return (
                            <Div padding={0.5} key={account.id}>
                              <Checkbox
                                data-cy="issue-participant-checkbox"
                                value={account.id}
                                disabled={account.id === activeAccount.id}
                              >
                                {account.name}
                              </Checkbox>
                            </Div>
                          )
                        })}
                      </Checkbox.Group>
                    )
                  }}
                />
              </Div>
              <Div vertical={1}>
                <Text weight="heavy">Decision Maker</Text>{' '}
                <ErrorMessage
                  errors={errors}
                  name="decisionMaker"
                  render={({ message }: any) => <Text color="red">{message}</Text>}
                />
              </Div>
              <Div>
                <Controller
                  name="decisionMaker"
                  control={control}
                  rules={{ required: 'Required.' }}
                  render={({ field }) => (
                    <Select
                      data-cy="issue-decision-maker-select"
                      showSearch
                      style={{ width: '100%', border: errors.decisionMaker ? '1px solid red' : '' }}
                      value={field.value}
                      onChange={field.onChange}
                    >
                      {mapDecisionMakers(participantIds?.length ? participantIds : initialParticipantIds)}
                    </Select>
                  )}
                />
              </Div>

              <Div vertical={1}>
                <Text weight="heavy">Decision Due</Text>
              </Div>
              <Controller
                name="decisionDueDate"
                control={control}
                render={({ field }) => <DatePicker selected={field.value} onChange={field.onChange} />}
              />
              <Div vertical={1}>
                <Text weight="heavy">Comments Due</Text>
              </Div>
              <Controller
                name="commentsDueDate"
                control={control}
                render={({ field }: any) => <DatePicker selected={field.value} onChange={field.onChange} />}
              />
            </Div>
          </FlexRow>

          <FlexRow justifyContent="flex-end" top={2} gap={2}>
            {!isNewIssue ? (
              <Popconfirm
                title={
                  <>
                    <Text>Are you sure you want to delete this issue?</Text>
                  </>
                }
                onConfirm={handleDelete}
                okText="Yes, Delete"
                cancelText="Cancel"
                okType="danger"
              >
                <Button color="red" loading={deleteMutation.isLoading} data-cy="delete-issue-button">
                  Delete
                </Button>
              </Popconfirm>
            ) : null}
            <SubmitButton
              loading={createMutation.isLoading || editMutation.isLoading}
              isNewIssue={isNewIssue}
              participantIds={participantIds?.length ? participantIds : initialParticipantIds}
              formId="createEditIssueForm"
            />
          </FlexRow>
        </form>
      </Div>
    </Card>
  ) : null
}
