import { Middleware } from 'redux'
import axios from 'axios'
import Router from 'next/router'
import { notification } from 'antd'
import firebase, { getRefFormatByIds, listenForNewUpdates } from '../firebase'
import { actions, actionTypes } from './store'
import { actions as accountActions } from '../accounts'
import { actionTypes as meetingsActionTypes, actions as meetingsActions } from '../meetings'
import { Update } from '@mm/backend/update/model'
import { ReqBody as SaveReqBody, ResBody as SaveResBody } from '../../pages/api/updates/save'
import { ReqBody as CreateReqBody, ResBody as CreateResBody } from '../../pages/api/updates/create'
import { ReqBody as StartedUpdateReqBody, ResBody as StartedUpdateResBody } from '../../pages/api/updates/startedUpdate'
import {
  ReqBody as ReviewedUpdateReqBody,
  ResBody as ReviewedUpdateResBody,
} from '../../pages/api/updates/reviewedUpdate'

let detachListner: () => void

const middleware: Middleware = ({ dispatch, getState }) => {
  return (next) => async (action: actions | accountActions | meetingsActions) => {
    next(action)
    switch (action.type) {
      case actionTypes.SAVE_UPDATE_TO_MEETING: {
        try {
          dispatch(actions.isSubmitting({ isSubmitting: true }))
          const res = await axios.post<SaveResBody, SaveReqBody>('/api/updates/save', {
            activeUpdate: action.activeUpdate,
          })
          notification.success({
            message: 'Successfully submitted update',
          })
          Router.push(`/meetings/${res.data.id}`)
        } catch (error) {
          if (!(error instanceof Error)) return
          console.log(error)
          notification.error({
            message: error.message,
          })
        } finally {
          dispatch(actions.isSubmitting({ isSubmitting: false }))
        }
        break
      }
      case actionTypes.REGENERATE_UPDATE: {
        try {
          const res = await axios.post<CreateResBody, CreateReqBody>('/api/updates/create', {
            meeting: action.meeting,
            activeAccount: action.activeAccount,
          })

          const update = res.data

          notification.success({
            message: 'Successfully created update',
          })

          dispatch(meetingsActions.view({ id: update.meetingId, updateId: update.id }))

          Router.push(`/meetings/${update.meetingId}/update`)
        } catch (error) {
          if (!(error instanceof Error)) return
          console.log(error)
          notification.error({
            message: error.message,
          })
        }
        break
      }
      case actionTypes.STARTED_UPDATE: {
        try {
          await axios.post<StartedUpdateResBody, StartedUpdateReqBody>('/api/updates/startedUpdate', {
            startedUpdate: action.startedUpdate,
            updateId: action.updateId,
          })
        } catch (error) {
          console.log(error)
        }
        break
      }
      case actionTypes.REVIEWED_UPDATE: {
        try {
          await axios.post<ReviewedUpdateResBody, ReviewedUpdateReqBody>('/api/updates/reviewedUpdate', {
            reviewedUpdate: action.reviewedUpdate,
            updateId: action.updateId,
          })
        } catch (error) {
          console.log(error)
        }
        break
      }
      case meetingsActionTypes.VIEW: {
        if (detachListner) {
          detachListner()
        }

        dispatch(actions.loading({ isLoading: true }))

        const updatesRef = firebase.collection('updates').where('meetingId', '==', action.id)

        const update = await getRefFormatByIds<Update>(updatesRef, ['createdAt'])

        dispatch(actions.set({ update }))
        dispatch(actions.loading({ isLoading: false }))

        detachListner = listenForNewUpdates<Update>(
          updatesRef,
          (change, update) => {
            if (change.type === 'added') {
              dispatch(actions.add({ update }))
            }
            if (change.type === 'modified') {
              dispatch(actions.change({ update }))
            }
            if (change.type === 'removed') {
              dispatch(actions.remove({ update }))
            }
          },
          ['createdAt'],
        )
        break
      }
      default:
        break
    }
  }
}

export default middleware
