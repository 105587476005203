import styled from '@emotion/styled'

export const FlexRow = styled.div({
  display: 'flex',
  alignItems: 'center',

  '& > *': {
    marginRight: 10,
  },
})

export default FlexRow
