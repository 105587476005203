export const featureFlags = {
  'user-invite-flow': false,
  'issues-authorization': false,
  'gcal-v2': false,
  'recurring-topics': false,
  'meetings-v2': false,
  'nylas-meeting-creation': false,
  'topics-graphql-rearrangement': false,
  'remove-onboarding-typeform': false,
  'nylas-google-calendar': false,
  'issues-graphql-integration': false,
  'slack-notifications': true,
  'test-ld': false,
}
