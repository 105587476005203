import React from 'react'
import { useDispatch } from 'react-redux'
import BlockAdd from './BlockAdd'
import BlockIndent from './BlockIndent'
import { Meeting } from '@mm/backend/meetings/model'
import { Group } from '@mm/backend/groups/model'
import { Account } from '@mm/backend/accounts/model'
import { Block as ActionsBlockItemType } from './ActionsBlockItem'
import { actions as actionsActions } from '../../actions'
import BlockResolver from './BlockResolver'
import { FocusColorWrap } from '../../ui'
import BlockTitle from './BlockTitle'
import BookOpenOutlineIcon from '@icons/material/BookOpenOutlineIcon'
import useScopedCreateActionLoadingState from '../../actions/hooks/useScopedCreateActionLoadingState'
import { Editable } from '../../editor'
import { buildToolbarItems } from '../../meetings/utils'
import { getActionDueDate } from '../utils/meetingInfoHelpers'

export const TYPE = 'meetingLibrary'

export interface Block {
  type: typeof TYPE
  id: typeof TYPE
  title: string
  subtitle: string
  activeAccount: Account
  meeting: Meeting
  group: Group
  color: string
  blocks: Array<ActionsBlockItemType>
}

export const LibraryBlock = ({ id, meeting, group, color, title, subtitle, activeAccount, blocks = [] }: Block) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useScopedCreateActionLoadingState()

  const modal = {
    title: 'About the Library section',
    youtubeId: 'hzNcc7uESPw',
    content: (
      <div>
        <p>
          The library is meant to house any links or any written information that you want to continue from meeting to
          meeting.
        </p>
      </div>
    ),
  }

  const handleAddAction = (description = '') => {
    setLoading(true)
    dispatch(
      actionsActions.createRequest({
        description,
        accountId: activeAccount.id,
        assignedTo: activeAccount.id,
        featureId: meeting.id,
        featureType: 'library',
        featurePreviewText: description || `Meeting "${meeting.title}" library`,
        meetingIds: [meeting.id],
        groupIds: [meeting.groupId],
        participantIds: meeting.participantIds,
        dueAt: getActionDueDate(meeting),
      }),
    )
  }

  const toolbarItems = buildToolbarItems({
    addAction: handleAddAction,
  })

  return (
    <>
      <FocusColorWrap color={color} visibleColor={'#f5f5f5'}>
        <BlockTitle
          id={`${id}-${meeting.id}`}
          title={title}
          subtitle={subtitle}
          color={color}
          icon={<BookOpenOutlineIcon />}
          modal={modal}
          content={
            <BlockIndent>
              <Editable
                documentId={group.libraryDocId}
                selectionToolbar={toolbarItems}
                placeholder="Place recurring meetings notes here"
              />
            </BlockIndent>
          }
        />
      </FocusColorWrap>
      <BlockIndent>
        {blocks.map((block) => (
          <BlockResolver key={block.id} block={block} />
        ))}
      </BlockIndent>
      <BlockAdd label="Add action" disabled={loading} loading={loading} onClick={handleAddAction} />
    </>
  )
}

export default LibraryBlock
