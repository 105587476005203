/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Timezones } from '@mm/backend/accounts/timezones'
import { AccountIntegrationTypes } from '@mm/backend/integrations/model'
import { Button, Checkbox, DatePicker, Divider, Form, Input, Radio, Select, Tabs, Typography } from 'antd'
import React from 'react'
import ReactDatePicker from 'react-datepicker'
import { useDispatch, useSelector } from 'react-redux'
import { selectors as accountSelectors } from '../../accounts'
import { ActiveCompanySelector, selectors as companySelectors } from '../../companies'
import { selectors as integrationsSelectors } from '../../integrations'
import { actions, selectors } from '../store'

export type Fields = {
  senderId: string
  email: string
  name: string
  bio?: string
  magicalOutcome?: string
  companyName?: string
  companyBio?: string
  companyId?: string
  goals?: Goals
  actions?: Actions
  timezone?: Timezones
  meetingTitle?: string
  meetingDuration?: number
  meetingRecurring?: string
  meetingStartAt?: Date
  meetingSync?: boolean
  shouldAddMatt?: boolean
}
type Actions = Array<{
  description: string
  due: string
  context?: string
}>
type Goals = Array<{
  description: string
  actions?: Actions
}>
type CompanyList = Array<{
  name: string
  id: string
}>
interface Props {
  companies: CompanyList
}

export const CreateAccountForm: React.FC<Props> = ({ companies = [] }) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const isLoading = useSelector(selectors.isLoading())
  const error = useSelector(selectors.getError())
  const message = useSelector(selectors.getMessage())
  const senderId = useSelector(accountSelectors.getActiveAccountId())
  const [createMeeting, setCreateMeeting] = React.useState(true)

  // Checks if user is a mochary method member
  const isMocharyMember = !!useSelector(companySelectors.getCompanyById('I98w4c8NAsJqemrlHWxQ'))
  const activeCompanyId = useSelector(companySelectors.getActiveCompanyId())

  const googleCalConnected =
    useSelector(integrationsSelectors.getStatus(AccountIntegrationTypes.GOOGLE_CALENDAR)) === 'CONNECTED'

  const calendarNotAllowed = typeof window !== 'undefined' && location.protocol !== 'https:'

  const onSubmit = async () => {
    let values = (await form.validateFields()) as Fields

    // Populate activeCompanyId for non-mm members
    if (!isMocharyMember && activeCompanyId) values = { ...values, companyId: activeCompanyId }

    // Turn moment dates into string dates in Actions
    if (values && values.actions) {
      for (let i = 0; i < values.actions.length; i++) {
        values.actions[i].due = values.actions[i].due.toString()
      }
    }
    if (values && values.goals) {
      for (let i = 0; i < values.goals.length; i++) {
        if (values.goals[i].actions) {
          for (let j = 0; j < values.goals[i].actions!.length; j++) {
            values.goals[i].actions![j].due = values.goals[i].actions![j].due.toString()
          }
        }
      }
    }

    // Replace \n with rich text paragraphs
    const addParagraph = (str: string) => `<p>${str.split('\n').join('</p><p>')}</p>`
    if (values && values.bio) values.bio = addParagraph(values.bio)
    if (values && values.companyBio) values.companyBio = addParagraph(values.companyBio)

    // Create account
    dispatch(actions.createAccount({ ...values, senderId }))
    form.resetFields()
  }

  // Create timezone Selector
  const timezones: Array<Timezones> = Object.values(Timezones)
  const options: Array<JSX.Element> = timezones.map((timezone) => (
    <Select.Option key={timezone} value={timezone}>
      {timezone}
    </Select.Option>
  ))

  const [companyType, setCompanyType] = React.useState('new')
  const handleCompanyChange = (value: string) => {
    setCompanyType(value)
    form.setFieldsValue({ companyName: '', companyBio: '', companyId: '' })
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 24,
        }}
      >
        <Typography.Title level={2}>Create User Account</Typography.Title>
        {!isMocharyMember && <ActiveCompanySelector />}
      </div>

      <Form
        form={form}
        name="basic"
        layout="vertical"
        initialValues={{
          timezone: 'America/Los_Angeles',
          meetingStartAt: new Date(),
          meetingDuration: 50,
          meetingRecurring: 'none',
          meetingSync: calendarNotAllowed ? false : true,
          shouldAddMatt: true,
        }}
      >
        <Typography.Title level={3}>Account</Typography.Title>

        <Form.Item label="Full Name" name="name" rules={[{ required: true, message: 'Name is required' }]}>
          <Input placeholder="Full Name" />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: 'Email is required' },
            { type: 'email', message: 'The input is not valid E-mail!' },
          ]}
        >
          <Input placeholder="email@gmail.com" />
        </Form.Item>

        <Form.Item name="timezone" label="Timezone">
          <Select showSearch style={{ width: 200 }} placeholder="Select the timezone" optionFilterProp="children">
            {options}
          </Select>
        </Form.Item>

        <Divider />
        <Typography.Title level={3}>Personal</Typography.Title>

        <Form.Item label="Bio" name="bio">
          <Input.TextArea placeholder="Tell about yourself" />
        </Form.Item>

        <Divider />
        {isMocharyMember && (
          <div>
            <Typography.Title level={3}>Company</Typography.Title>
            <Tabs defaultActiveKey={companyType} onChange={handleCompanyChange}>
              <Tabs.TabPane tab="New Company" key="new">
                <Form.Item
                  label="Company Name"
                  name="companyName"
                  rules={[{ required: companyType === 'new', message: 'Company Name is required' }]}
                >
                  <Input placeholder="Company Name" />
                </Form.Item>
                <Form.Item label="Company Bio" name="companyBio">
                  <Input.TextArea placeholder="Tell about your company" />
                </Form.Item>
                <Form.Item name="shouldAddMatt" valuePropName="checked">
                  <Checkbox>Matt will be added to the company member and meeting owner.</Checkbox>
                </Form.Item>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Existing Company" key="existing">
                <Form.Item
                  label="Company"
                  name="companyId"
                  rules={[{ required: companyType === 'existing', message: 'Company is required' }]}
                >
                  <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder="Select company"
                    filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {companies
                      // Order Alphabetically
                      .sort((a, b) => {
                        if (a.name < b.name) return -1
                        if (a.name > b.name) return 1
                        return 0
                      })
                      // Selection options
                      .map((company) => (
                        <Select.Option key={company.id} value={company.id}>
                          {company.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Tabs.TabPane>
            </Tabs>
            <Divider />

            <Typography.Title level={3}>First Meeting</Typography.Title>
            <Checkbox onChange={() => setCreateMeeting((s) => !s)} checked={createMeeting} style={{ marginBottom: 24 }}>
              Create new meeting?
            </Checkbox>
            {createMeeting && (
              <>
                <Form.Item
                  label="Meeting Title"
                  name="meetingTitle"
                  rules={[{ required: true, message: 'Title is required' }]}
                >
                  <Input />
                </Form.Item>

                <Input.Group compact={true}>
                  <Form.Item
                    label="Start"
                    name="meetingStartAt"
                    rules={[{ required: true, message: 'Start time is required' }]}
                    valuePropName="selected"
                  >
                    <ReactDatePicker showTimeSelect={true} dateFormat="Pp" onChange={() => null} />
                  </Form.Item>

                  <Form.Item
                    label="Duration (min)"
                    name="meetingDuration"
                    rules={[{ required: true, message: 'Duration is required' }]}
                  >
                    <Input type="number" />
                  </Form.Item>
                </Input.Group>

                <Form.Item label="Recurring" name="meetingRecurring">
                  <Radio.Group>
                    <Radio value="daily">Daily</Radio>
                    <Radio value="weekly">Weekly</Radio>
                    <Radio value="bi-weekly">Bi-weekly</Radio>
                    <Radio value="monthly">Monthly</Radio>
                    <Radio value="none">None</Radio>
                  </Radio.Group>
                </Form.Item>

                {googleCalConnected ? (
                  <Form.Item name="meetingSync" valuePropName="checked">
                    <Checkbox disabled={calendarNotAllowed}>
                      Create Google Calendar Event {calendarNotAllowed ? '(only on https)' : ''}
                    </Checkbox>
                  </Form.Item>
                ) : null}

                <Divider />
              </>
            )}
          </div>
        )}
        <Typography.Title level={3}>Goals</Typography.Title>
        <Form.List name="goals">
          {(fields, actions) => {
            return (
              <div>
                {fields.map((field, index) => (
                  <div key={field.key}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Form.Item
                        name={[index, 'description']}
                        label="Goal"
                        rules={[{ required: true }]}
                        style={{ flexBasis: '85%' }}
                      >
                        <Input placeholder="Goal description" />
                      </Form.Item>
                      {fields.length > 0 ? (
                        <Button
                          type="text"
                          onClick={() => actions.remove(field.name)}
                          icon={<MinusCircleOutlined />}
                          style={{ flexBasis: '15%' }}
                        >
                          Remove
                        </Button>
                      ) : null}
                    </div>

                    <Form.List name={[index, 'actions']}>
                      {(nestedFields, nestedActions) => {
                        return (
                          <div>
                            {nestedFields.map((nestedField, nestedIndex) => (
                              <div
                                key={nestedField.key}
                                style={{ display: 'flex', alignItems: 'center', marginLeft: 28 }}
                              >
                                <Form.Item
                                  name={[nestedIndex, 'description']}
                                  label="Action"
                                  rules={[{ required: true }]}
                                  style={{ flexBasis: '85%' }}
                                >
                                  <Input placeholder="Action description" />
                                </Form.Item>

                                <Form.Item
                                  name={[nestedIndex, 'due']}
                                  label="Due Date"
                                  rules={[{ required: true }]}
                                  style={{ flexBasis: '20%', cursor: 'pointer' }}
                                >
                                  <DatePicker
                                    placeholder="Date"
                                    suffixIcon={null}
                                    allowClear={false}
                                    style={{ cursor: 'pointer' }}
                                  />
                                </Form.Item>
                                {nestedFields.length > 0 ? (
                                  <Button
                                    type="text"
                                    onClick={() => nestedActions.remove(nestedField.name)}
                                    icon={<MinusCircleOutlined />}
                                    style={{ flexBasis: '15%' }}
                                  >
                                    Remove
                                  </Button>
                                ) : null}
                              </div>
                            ))}
                            <Form.Item>
                              <Button
                                type="dashed"
                                onClick={() => nestedActions.add()}
                                style={{ width: '40%', marginLeft: 28 }}
                              >
                                <PlusOutlined />
                                Add Nested Action
                              </Button>
                            </Form.Item>
                          </div>
                        )
                      }}
                    </Form.List>
                  </div>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={() => actions.add()} style={{ width: '60%' }}>
                    <PlusOutlined />
                    Add Goal
                  </Button>
                </Form.Item>
              </div>
            )
          }}
        </Form.List>

        <Divider />

        <Typography.Title level={3}>Stand-alone Actions</Typography.Title>
        <Form.List name="actions">
          {(fields, actions) => {
            return (
              <div>
                {fields.map((field, index) => (
                  <div key={field.key} style={{ display: 'flex', alignItems: 'center' }}>
                    <Form.Item
                      name={[index, 'description']}
                      label="Action"
                      rules={[{ required: true }]}
                      style={{ flexBasis: '85%' }}
                    >
                      <Input placeholder="Action description" />
                    </Form.Item>
                    <Form.Item
                      name={[index, 'due']}
                      label="Due Date"
                      rules={[{ required: true }]}
                      style={{ flexBasis: '25%', cursor: 'pointer' }}
                    >
                      <DatePicker
                        placeholder="Date"
                        suffixIcon={null}
                        allowClear={false}
                        style={{ cursor: 'pointer' }}
                      />
                    </Form.Item>
                    <Form.Item name={[index, 'context']} label="Context" style={{ flexBasis: '55%' }}>
                      <Input placeholder="Action context" />
                    </Form.Item>
                    {fields.length > 0 ? (
                      <Button
                        type="text"
                        onClick={() => actions.remove(field.name)}
                        icon={<MinusCircleOutlined />}
                        style={{ flexBasis: '15%' }}
                      >
                        Remove
                      </Button>
                    ) : null}
                  </div>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={() => actions.add()} style={{ width: '60%' }}>
                    <PlusOutlined />
                    Add Action
                  </Button>
                </Form.Item>
              </div>
            )
          }}
        </Form.List>

        <Divider style={{ marginBottom: 48 }} />
        <Button onClick={onSubmit} loading={isLoading} size="large" type="primary" style={{ marginBottom: 16 }}>
          Create New User
        </Button>
      </Form>
      {message && error && <Typography.Paragraph type="danger">{message}</Typography.Paragraph>}
      {message && !error && <Typography.Paragraph type="success">{message}</Typography.Paragraph>}
    </>
  )
}

export default CreateAccountForm
