import { Meeting } from '@mm/backend/meetings/model'
import { Spin } from 'antd'
import { addHours } from 'date-fns'
import Fuse from 'fuse.js'
import _ from 'lodash'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { AccountNames } from '../../accounts'
import { selectors as companySelectors } from '../../companies'
import { Div, FlexRow, RowAction, Table, Text } from '../../interface'
import { selectors } from '../store'
import { CreateEditMeetingModalOld, Fields } from './CreateEditMeetingModal'

interface Props {
  search?: string
  meetingType: 'upcoming' | 'prior'
}

export const MeetingsList: React.FC<Props> = ({ search, meetingType }) => {
  const now = addHours(new Date(), -2)
  const companies = useSelector(companySelectors.getCompanies())
  const initialized = useSelector(selectors.getInitialize())
  const [modalInitialValues, setModalInitialValues] = useState<Partial<Fields>>()
  const meetings = Object.values(useSelector(selectors.getMeetings()))

  const isUpComing = meetingType === 'upcoming'
  const sortedItems = _(meetings)
    .filter(({ id, startAt }) => {
      if (isUpComing) {
        return !!id && startAt >= now
      } else {
        return !!id && startAt < now
      }
    })
    .orderBy('startAt', isUpComing ? 'asc' : 'desc')
    .value()

  const columnSchema = [
    {
      title: 'Title',
      key: 'title',
      component: 'link',
      linkKey: 'meetingLink',
      filter: false,
      sort: true,
    },
    {
      title: 'Start Time',
      key: 'startTime',
      component: 'date',
      filter: false,
      sort: true,
      width: 200,
    },
    {
      title: 'Participants',
      key: 'participants',
      component: 'text',
      filter: false,
      sort: false,
      width: 300,
    },
    {
      title: 'Company',
      key: 'company',
      component: 'text',
      filter: false,
      sort: true,
    },
    {
      title: 'Complete',
      key: 'complete',
      component: 'text',
      filter: false,
      sort: true,
    },
  ]

  const getMeetingCompanyName = (companyId: string): string => {
    if (companyId && companies[companyId]) {
      return companies[companyId].name
    }

    return ''
  }

  const tableItems = sortedItems.map((item) => {
    const { id, title, recurring, startAt, participantIds, companyId, complete } = item
    return {
      item,
      key: id,
      meetingLink: `/meetings/${id}`,
      title: title + (recurring ? ` (${recurring})` : ''),
      startTime: startAt,
      participants: (
        <AccountNames
          showRole
          profileLink
          ids={participantIds}
          meeting={meetings.find((meeting) => meeting.id === id)}
          omitActiveAccount={false}
          emptyText=""
        />
      ),
      company: getMeetingCompanyName(companyId),
      complete: complete ? 'Complete' : '',
    }
  })

  const fuse = new Fuse(tableItems, {
    keys: ['title', 'participants', 'company'],
    shouldSort: false,
    ignoreLocation: true,
    threshold: 0.5,
  })

  const results = search ? fuse.search(search) : tableItems

  const actions: RowAction[] = [
    {
      title: 'Schedule Next Meeting',
      onClick: ({ item }) => {
        setModalInitialValues({
          groupId: item.groupId,
          title: item.title,
          conferenceUrl: item.conferenceUrl ?? '',
          company: item.companyId,
          participants: Object.entries((item as Meeting).participants).map(([id, { role }]) => ({ id, role })),
          recurring: item.recurring || 'none',
          sync: item.gCalSync ?? true,
        })
      },
    },
  ]

  if (!initialized) {
    return (
      <FlexRow style={{ height: 160, border: '1px solid #eee', borderRadius: 4 }} justifyContent="center">
        <Spin size="large" />
      </FlexRow>
    )
  }

  return results.length ? (
    <Div>
      <CreateEditMeetingModalOld
        initialValues={modalInitialValues}
        visible={modalInitialValues != null}
        onComplete={() => setModalInitialValues(undefined)}
        onCancel={() => setModalInitialValues(undefined)}
      />
      <Table columnSchema={columnSchema} tableData={results} actions={actions} />
    </Div>
  ) : (
    <FlexRow style={{ height: 160, border: '1px solid #eee', borderRadius: 4 }} justifyContent="center">
      <Text color="light">No meetings.</Text>
    </FlexRow>
  )
}

export default MeetingsList
