import React from 'react'
import _ from 'lodash'
import { actions, selectors } from '../'
import { useDispatch, useSelector } from 'react-redux'
import { Dropdown, Menu } from 'antd'
import { Card } from '../../ui'
import ChevronDownIcon from '@icons/material/ChevronDownIcon'
import CompanySettingsProfileTab from './CompanySettingsProfileTab'
import { CompanySettingsMembersTab } from '../../companies/components/CompanySettingsMembersTab'
import { css } from '@emotion/react'

enum Tabs {
  profile = 'profile',
  members = 'members',
}

export const CompanySettings = () => {
  const dispatch = useDispatch()
  const activeCompanyId = useSelector(selectors.getActiveCompanyId())
  const companies = useSelector(selectors.getCompanies())
  const activeCompany = companies[activeCompanyId] || {}
  const [tab, setTab] = React.useState(Tabs.members)

  const menu = (
    <Menu onClick={({ key }) => dispatch(actions.setActive({ companyId: key as string }))}>
      {_.orderBy(companies, 'name').map((company) => (
        <Menu.Item key={company.id}>{company.name}</Menu.Item>
      ))}
    </Menu>
  )

  return (
    <Card
      style={{
        padding: 0,
        margin: '0 24px',
        backgroundColor: '#fff',
        display: 'flex',
        alignItems: 'stretch',
        fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol"`,
        fontSize: 16,
        lineHeight: 1.5,
      }}
    >
      <div
        style={{ width: 250, borderRight: '1px solid #ddd', backgroundColor: '#fafafa', borderRadius: '3px 0 0 3px' }}
      >
        <Dropdown overlay={menu} trigger={['click']}>
          <div
            css={css`
              padding: 20px;
              font-size: 18px;
              border-bottom: 1px solid #ddd;
              display: flex;
              align-items: center;
              justify-content: space-between;
              word-break: break-all;
              color: #333;

              &:hover {
                background: rgba(0, 0, 0, 0.02);
              }
            `}
          >
            {activeCompany.name}
            <ChevronDownIcon />
          </div>
        </Dropdown>

        <Menu
          mode="inline"
          style={{ border: 'none', backgroundColor: 'transparent', marginTop: 10 }}
          defaultSelectedKeys={[Tabs.members]}
          onClick={({ key }) => setTab(key as Tabs)}
        >
          <Menu.Item key={Tabs.members}>Members</Menu.Item>
          <Menu.Item key={Tabs.profile}>Profile</Menu.Item>
        </Menu>
      </div>
      <div style={{ flex: 1, padding: 17, minHeight: '90vh' }}>
        {tab === Tabs.profile ? <CompanySettingsProfileTab /> : null}
        {tab === Tabs.members ? <CompanySettingsMembersTab /> : null}
      </div>
    </Card>
  )
}

export default CompanySettings
