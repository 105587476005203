import React from 'react'
import styled from '@emotion/styled'
import theme from './theme'

interface Props {
  // size?: keyof theme['font']['size']
  // color?: keyof theme['font']['color']
  // weight?: keyof theme['font']['weight']
  // lineHeight?: number | string
  // block?: boolean
  errorOutline?: boolean
}

export const Input = styled.input<Props>(({ errorOutline }) => ({
  outline: 'none',
  color: theme.font.color.dark,
  padding: 5,
  border: '1px solid #eee',
  borderRadius: 4,
  lineHeight: 1.5,
  width: '100%',

  ...(errorOutline
    ? {
        border: `1px solid ${theme.color.red}`,
      }
    : {}),
}))
