import React, { useState } from 'react'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { selectors, actions } from '../store'
import { Form, Input, Button, Card, DatePicker } from 'antd'
import { endOfQuarter, getQuarter, getYear } from 'date-fns'

interface Fields {
  description: string
  startAt: Date
  endAt: Date
  endAtLabel?: string
}

interface Props {
  accountId?: string
  parentId?: string
  endAt?: Date
  endAtLabel?: string
  onCreate?: () => void
  onCancel?: () => void
}

export const CreateGoal: React.FC<Props> = ({ accountId, parentId, endAt, endAtLabel, onCreate, onCancel }) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const formControl = useSelector(selectors.getForm())
  const [creating, setCreating] = useState(false)

  const handleSubmit = ({ description, endAt, endAtLabel = '' }: Fields) => {
    setCreating(true)
    dispatch(
      actions.createRequest({
        accountId,
        parentId: parentId || null,
        description,
        startAt: new Date(),
        endAt,
        endAtLabel,
      }),
    )
  }

  React.useEffect(() => {
    if (!formControl.loading && creating) {
      setCreating(false)
      if (!formControl.error) {
        form.resetFields()
        onCreate && onCreate()
      }
    }
  }, [formControl.loading, formControl.error])

  const handleCancel = () => {
    form.resetFields()
    onCancel && onCancel()
  }

  return (
    <Card>
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          endAt: endAt ? moment(endAt) : moment(endOfQuarter(new Date())),
          endAtLabel: endAtLabel ? endAtLabel : `Q${getQuarter(new Date())} ${getYear(new Date())}`,
        }}
        onFinish={handleSubmit}
      >
        <Form.Item
          name="description"
          rules={[{ required: true, message: 'Description is required' }]}
          style={{ flex: 1, marginBottom: 10 }}
        >
          <Input placeholder="Goal description" />
        </Form.Item>

        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: -24 }}>
          <Form.Item
            name="endAt"
            rules={[{ required: true, message: 'End date is required' }]}
            style={{ paddingRight: 10 }}
          >
            <DatePicker placeholder="End date" />
          </Form.Item>

          <Form.Item name="endAtLabel" style={{ flex: 1, paddingRight: 10 }}>
            <Input placeholder="Label: Q1, Q2, 2021, etc..." />
          </Form.Item>

          <Form.Item>
            <Button data-cy="add-goal-button" type="primary" htmlType="submit" loading={creating}>
              Add Goal
            </Button>
          </Form.Item>

          <Button type="text" loading={creating} onClick={handleCancel}>
            Cancel
          </Button>
        </div>

        {formControl.error?.message}
      </Form>
    </Card>
  )
}

export default CreateGoal
