import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as generateID } from 'uuid'
import { Account } from '@mm/backend/accounts/model'
import { actions as feedbackActions, selectors as feedbackSelectors } from '../../feedback'
import BlockResolver from './BlockResolver'
import BlockTitle from './BlockTitle'
import BlockEmpty from './BlockEmpty'
import BlockAdd from './BlockAdd'
import { Block as FeedbackBlockItemType } from './FeedbackBlockItem'
import { FocusColorWrap } from '../../ui'
import { Div } from '../../interface'
import AccountCircleOutlineIcon from '@icons/material/AccountCircleOutlineIcon'

export const TYPE = 'feedback'

export interface Block {
  type: typeof TYPE
  id: typeof TYPE
  meetingId: string
  title: string
  subtitle: string
  color: string
  participants: Array<Account>
  activeAccount: Account
  blocks: Array<FeedbackBlockItemType>
  updateId?: string
  reviewView?: boolean
}

const getAccountFirstName = (account: Account) => {
  const [firstName] = account && 'name' in account ? account.name.split(' ') : ['']
  return firstName
}

export const FeedbackBlock = ({
  id,
  title,
  subtitle,
  color,
  meetingId,
  participants,
  activeAccount,
  blocks = [],
  updateId,
  reviewView = false,
}: Block) => {
  const dispatch = useDispatch()
  const modal = {
    title: 'About the Feedback section',
    youtubeId: 'ieMQmLHbY1g',
    content: (
      <div>
        <p>
          Frequent, transparent feedback is critical for building a strong culture and a thriving business. Feedback is
          instrumental for building trust. Without trust, communication breaks down.
        </p>
        <p>
          It is recommended that you give critical feedback in real time during the meeting so that if the recipient
          becomes defensive and angry, you can tell them that your intent was to be helpful, rather than to make them
          angry.
        </p>
        <p>
          There is one exception to this rule: If you already know the person to be open, curious and desirous of
          critical feedback.
        </p>
      </div>
    ),
  }
  const form = useSelector(feedbackSelectors.getForm())

  const participantsStillNeedingFeedback = participants.filter((participant) => {
    if (!participant || (participant && participant.id === activeAccount.id)) {
      return false // Filter out your own account
    }
    const feedbackBlock = blocks.find(
      (block) => block.feedback.accountId === activeAccount.id && block.feedback.recipientId === participant.id,
    )

    return feedbackBlock ? false : true
  })

  const handleAdd = (recipientId: string) => () =>
    dispatch(
      feedbackActions.createRequest({
        likeText: '',
        wishThatText: '',
        recipientId,
        meetingId,
        attachments: [],
        updateId: updateId || '',
      }),
    )

  const renderFeedbackAddBlock = () => {
    if (reviewView) {
      return <></>
    }

    if (updateId) {
      return (
        <Div data-cy="feedback-block-add">
          <BlockAdd
            key={generateID()}
            disabled={form.loading}
            loading={form.loading}
            label="Add"
            onClick={handleAdd('')}
            color={color}
          />
        </Div>
      )
    }

    return participantsStillNeedingFeedback.map((account) => {
      const name = getAccountFirstName(account)
      return account ? (
        <Div data-cy="feedback-block-add">
          <BlockAdd
            key={account.id}
            disabled={form.loading}
            loading={form.loading}
            label={`Add feedback for ${name}`}
            onClick={handleAdd(account.id)}
            color={color}
          />
        </Div>
      ) : null
    })
  }

  return (
    <FocusColorWrap color={color}>
      <BlockTitle
        id={`${id}-${updateId || meetingId}`}
        title={title}
        subtitle={subtitle}
        color={color}
        icon={<AccountCircleOutlineIcon />}
        modal={modal}
        content={
          <>
            {blocks.length ? (
              blocks.map((block) => <BlockResolver key={block.id} block={block} />)
            ) : (
              <BlockEmpty label="No feedback yet." />
            )}
            {renderFeedbackAddBlock()}
          </>
        }
      />
    </FocusColorWrap>
  )
}

export default FeedbackBlock
