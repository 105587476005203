import styled from '@emotion/styled'

export const ToolbarContainer = styled.div`
  font-size: 14px;

  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex-wrap: nowrap;
  width: max-content;
`
