import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Editor } from '@tiptap/core'
import { EditorContent, EditorOptions, useEditor } from '@tiptap/react'
import React, { useEffect, useImperativeHandle } from 'react'
import { color, ColorName, variants } from '../../interface/theme'
import { SelectionToolbar, ToolbarItem } from './SelectionToolbar'

export type TipTapEditorProps = {
  editorOptions: Partial<Omit<EditorOptions, 'editable'>>
  selectionToolbar?: ToolbarItem[] | false
  prefix?: React.ReactNode
  editable?: boolean
  toolbarCallbackParam?: any

  mode?: 'full' | 'compact'
  background?: ColorName
}

export type TipTapEditorRef = {
  editor: Editor | null
}

const compact = css`
  > .ProseMirror {
    padding: 4px 8px;

    border: transparent solid 1px;
    border-radius: 2px;

    overflow-wrap: anywhere;
    // fallback to deprecated break-word in case overflow-wrap: anywhere is not supported (eg Safari)
    word-break: break-word;

    &:focus {
      border: #009688 solid 1px;
    }
  }

  p:last-of-type {
    margin-block-end: 0;
  }
`

const full = css`
  > .ProseMirror {
    padding: 10px;

    border: #d9d9d9 solid 1px;
    border-radius: 2px;

    &:focus {
      border: #009688 solid 1px;
    }
  }

  p:last-of-type {
    margin-block-end: 0;
  }
`

type StyledEditorProps = {
  mode?: 'full' | 'compact'
  background: ColorName
}

const StyledEditor = styled(EditorContent)<StyledEditorProps>`
  ${variants(
    'mode',
    {
      compact,
      full,
    },
    'full',
  )}

  > .ProseMirror {
    background: ${(props) => color(props.background)};
    outline: none;
  }

  ul {
    list-style-type: disc;
    padding-inline-start: 30px;
  }
  ol {
    list-style-type: decimal;
    padding-inline-start: 30px;
  }
  p {
    margin-block-end: 0.5em;
  }
  a[href] {
    text-decoration: underline;
  }

  h1 {
    font-size: 2em;
  }
  h2 {
    font-size: 1.5em;
  }
  h3 {
    font-size: 1.17em;
  }
  h4 {
    font-size: 1em;
  }
  h5 {
    font-size: 0.83em;
  }
  h6 {
    font-size: 0.67em;
  }

  blockquote {
    background: #f9f9f9;
    border-left: 4px solid #ccc;
    margin: 0.5em 0;
    padding: 0.5em 10px 1px 10px;
  }

  pre {
    background: ${color('grayBackground')};
    border: 1px solid ${color('lightGray')};
    border-radius: 4px;
    padding: 8px 12px;
  }

  p.is-editor-empty:first-of-type::before {
    content: attr(data-placeholder);
    float: left;
    color: #ced4da;
    pointer-events: none;
    height: 0;
  }

  p[data-placeholder].is-empty::after {
    content: attr(data-placeholder);
    color: #ced4da;
    pointer-events: none;
    display: block;
    margin-top: -24px;
  }

  .collaboration-cursor__caret {
    position: relative;
    margin-left: -1px;
    margin-right: -1px;
    border-left: 1px solid #0d0d0d;
    border-right: 1px solid #0d0d0d;
    word-break: normal;
    pointer-events: none;
  }

  /* Render the username above the caret */
  .collaboration-cursor__label {
    position: absolute;
    top: -1.4em;
    left: -1px;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    user-select: none;
    color: #0d0d0d;
    padding: 0.1rem 0.3rem;
    border-radius: 3px 3px 3px 0;
    white-space: nowrap;
    z-index: 2;
  }
`

const PrefixContainer = styled.div<{ mode?: 'compact' | 'full' }>`
  float: left;
  margin: ${(props) => (props.mode === 'compact' ? 5 : 11)}px 6px 0 6px;
  position: relative;
  z-index: 2;
`

// pitfall: this component does not respect changes in editorOptions, it is only used for initialization
// try using editor.setOptions? -- TipTap does not seem to respect changes to the options once it is initialized
export const TipTapEditor = React.forwardRef<TipTapEditorRef, TipTapEditorProps>(function TipTapEditor(
  {
    editorOptions,
    selectionToolbar,
    prefix,
    editable = true,
    background = 'backgroundWhite',
    mode,
    toolbarCallbackParam,
  },
  ref,
): React.ReactElement {
  const editor = useEditor({
    ...editorOptions,
    editable,
  })
  useImperativeHandle(ref, () => ({ editor }), [editor])
  useEffect(() => {
    if (editor != null && editor.isEditable !== editable) {
      editor.setEditable(editable)
    }
  }, [editor, editable])

  return (
    <>
      {editor && selectionToolbar !== false && (
        <SelectionToolbar editor={editor} items={selectionToolbar} toolbarCallbackParam={toolbarCallbackParam} />
      )}
      {prefix && <PrefixContainer mode={mode}>{prefix}</PrefixContainer>}
      <StyledEditor data-cy="tipTap-editor" editor={editor} background={background} mode={mode} />
    </>
  )
})
