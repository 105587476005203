import React from 'react'
import styled from '@emotion/styled'

export interface Props {
  label: string
  size?: Sizes
  onClick?: () => unknown
}

export enum Sizes {
  small = 'small',
  large = 'large',
}

const sizes: Record<Sizes, Record<string, number>> = {
  small: {
    height: 30,
    fontSize: 14,
    paddingLeft: 10,
    paddingRight: 10,
  },
  large: {
    height: 45,
    fontSize: 16,
    paddingLeft: 15,
    paddingRight: 15,
  },
}

const BaseButton = styled.button<Partial<Props>>(({ size }) => {
  const suppliedSize = size ? sizes[size] : sizes['small']
  return {
    ...suppliedSize,

    cursor: 'pointer',
  }
})

export const Button = ({ label, size = Sizes.small, onClick }: Props) => (
  <BaseButton size={size} onClick={onClick}>
    {label}
  </BaseButton>
)
