import React from 'react'
import { useDispatch } from 'react-redux'
import { Menu } from 'antd'
import { Card } from '../../ui'
import { Account } from '@mm/backend/accounts/model'
import { LogOutButton } from '../../auth'
import { actions as accountActions } from '../../accounts'
import { AccountSettingsGoalsTab } from './AccountSettingsGoalsTab'
import { AccountSettingsProfileTab } from './AccountSettingsProfileTab'
import { AccountSettingsActionsTab } from './AccountSettingsActionsTab'

type TabName = 'actions' | 'goals' | 'info'

interface Props {
  account: Account
  editable: boolean
  activeTab: TabName
  onChangeTab: (tabName: TabName) => void
}

export const stripHtml = (string: string) => string.replace(/(<([^>]+)>)|(&.*?;)/gi, '')

export const Profile: React.FC<Props> = ({ account, activeTab, onChangeTab }) => {
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (account.id) {
      dispatch(accountActions.viewProfile({ accountId: account.id }))
    }
  }, [account])

  return (
    <Card
      style={{
        padding: 0,
        margin: '0 24px',
        backgroundColor: '#fff',
        display: 'flex',
        alignItems: 'stretch',
        fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol"`,
        fontSize: 16,
        lineHeight: 1.5,
      }}
    >
      <div
        style={{
          width: 250,
          borderRight: '1px solid #ddd',
          backgroundColor: '#fafafa',
          borderRadius: '3px 0 0 3px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            padding: 20,
            fontSize: 18,
            borderBottom: '1px solid #ddd',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            wordBreak: 'break-all',
          }}
        >
          {account.name}
        </div>

        <div style={{ flex: 1 }}>
          <Menu
            mode="inline"
            style={{ border: 'none', backgroundColor: 'transparent', marginTop: 10 }}
            selectedKeys={[activeTab]}
            onClick={({ key }) => onChangeTab(key as TabName)}
          >
            <Menu.Item key="info">Profile</Menu.Item>
            <Menu.Item key="actions">Actions</Menu.Item>
            <Menu.Item key="goals">Goals</Menu.Item>
          </Menu>
        </div>

        <div style={{ padding: 20 }}>
          <LogOutButton />
        </div>
      </div>
      <div style={{ flex: 1, minWidth: 0, padding: 17, minHeight: '90vh' }}>
        {activeTab === 'info' ? <AccountSettingsProfileTab account={account} /> : null}
        {activeTab === 'actions' ? <AccountSettingsActionsTab account={account} /> : null}
        {activeTab === 'goals' ? <AccountSettingsGoalsTab account={account} /> : null}
      </div>
    </Card>
  )
}

export default Profile
