import React from 'react'
import { Meeting } from '@mm/backend/meetings/model'
import { Company } from '@mm/backend/companies/model'
import { useSelector } from 'react-redux'
import { selectors as accountsSelectors } from '../../accounts'
import { selectors as issuesSelectors } from '../../issues'
import BlockResolver from './BlockResolver'
import BlockTitle from './BlockTitle'
import BlockEmpty from './BlockEmpty'
import BlockAdd from './BlockAdd'
import { Block as IssuesBlockItemType } from './IssuesBlockItem'
import { FocusColorWrap } from '../../ui'
import AlertCircleOutlineIcon from '@icons/material/AlertCircleOutlineIcon'
import { CreateEditIssue, ImportIssuesList } from '../../issues'
import { Modal, Spin, Dropdown } from 'antd'
import { Button, Div, Flex, Text } from '../../interface'

export const TYPE = 'issues'

export interface Block {
  type: typeof TYPE
  id: typeof TYPE
  title: string
  subtitle: string
  color: string
  meeting: Meeting | null
  company: Company
  blocks: Array<IssuesBlockItemType>
  updateId?: string
  reviewView?: boolean
}

export const IssuesBlock = ({
  id,
  title,
  subtitle,
  color,
  meeting,
  company,
  blocks = [],
  updateId,
  reviewView = false,
}: Block) => {
  const [createModalVisible, setCreateModalVisible] = React.useState(false)
  const activeAccount = useSelector(accountsSelectors.getActiveAccount()) || {}

  const modal = {
    title: 'About the Issues section',
    youtubeId: 'lPkYPJW9U_E',
    content: (
      <div>
        <p>Issues are challenges that you are facing in your life right now. </p>
        <p>These are things that make you angry or cause you to feel fear.</p>
      </div>
    ),
  }

  const form = useSelector(issuesSelectors.getForm())
  const renderIssues = () => {
    if (blocks.length) {
      return (
        <Div style={{ border: '1px solid #eee', borderRadius: 6 }} marginBottom={1}>
          <Flex gap={1} paddingVertical={0.5} paddingHorizontal={1} style={{ background: '#fafafa' }}>
            <Div style={{ flex: 5 }}>
              <Text size="small" weight="heavy">
                Title
              </Text>
            </Div>
            <Div style={{ flex: 2 }}>
              <Text size="small" weight="heavy">
                Author
              </Text>
            </Div>
            <Div style={{ flex: 2 }}>
              <Text size="small" weight="heavy">
                Decision Maker
              </Text>
            </Div>
            <Div style={{ flex: 2 }}>
              <Text size="small" weight="heavy">
                Status
              </Text>
            </Div>
            <Div style={{ width: 24 }} margin={1} />
          </Flex>

          {blocks.map((block) => (
            <BlockResolver key={block.id} block={block} />
          ))}
        </Div>
      )
    } else if (updateId) {
      return <></>
    } else {
      return (
        <Div style={{ border: '1px solid #eee', borderRadius: 6 }} marginBottom={1}>
          <BlockEmpty label="No issues yet." />
        </Div>
      )
    }
  }

  return (
    <FocusColorWrap color={color}>
      <BlockTitle
        id={`${id}-${updateId || meeting?.id}`}
        title={title}
        subtitle={subtitle}
        color={color}
        icon={<AlertCircleOutlineIcon />}
        modal={modal}
        content={
          <>
            {renderIssues()}

            {updateId ? (
              <Div marginLeft={2}>
                {reviewView ? (
                  <BlockEmpty label="No issues yet." />
                ) : (
                  <Button
                    color="primary"
                    type="submit"
                    loading={form.loading}
                    onClick={() => setCreateModalVisible(true)}
                  >
                    Add an issue
                  </Button>
                )}
              </Div>
            ) : (
              <Div data-cy="issue-block-add">
                <BlockAdd
                  disabled={form.loading}
                  loading={form.loading}
                  label="Create an issue"
                  onClick={() => setCreateModalVisible(true)}
                />
                {meeting ? (
                  <Dropdown
                    placement="bottomLeft"
                    overlay={
                      <ImportIssuesList
                        companyId={company.id}
                        meetingId={meeting.id}
                        alreadyImported={meeting.orderedIssueIds}
                        warnIfParticipantsDontMatch={meeting?.participantIds}
                      />
                    }
                    trigger={['click']}
                  >
                    <BlockAdd label="Import existing issue" />
                  </Dropdown>
                ) : null}
              </Div>
            )}
          </>
        }
      />

      <Modal
        title="Create Issue"
        className="revert-font-size"
        width="80vw"
        visible={createModalVisible}
        onCancel={() =>
          Modal.confirm({
            title: 'Do you want to abort creating an issue?',
            content: "Your changes won't be saved",
            okText: 'Yes',
            onOk: () => setCreateModalVisible(false),
          })
        }
        footer={null}
      >
        {meeting ? (
          <CreateEditIssue
            inline={true}
            initialValues={{
              participantIds: [...meeting.participantIds],
              companyId: company.id,
              meetingId: meeting.id,
              updateId: updateId,
            }}
            onCreate={() => {
              setCreateModalVisible(false)
            }}
          />
        ) : (
          <Spin />
        )}
      </Modal>
    </FocusColorWrap>
  )
}

export default IssuesBlock
