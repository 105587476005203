import { Middleware } from 'redux'
import _ from 'lodash'
import firebase, { getRefFormatByIds, listenForNewUpdates, inflateDates } from '../firebase'
import { actions, actionTypes } from './store'
import {
  actionTypes as meetingsActionTypes,
  actions as meetingsActions,
  selectors as meetingsSelectors,
} from '../meetings'
import { actionTypes as topicsActionTypes, actions as topicsActions } from '../topics'
import { selectors as accountSelectors } from '../accounts'
import { Comment } from '@mm/backend/comments/model'
import { ReqBody as CreateReqBody, ResBody as CreateResBody } from '../../pages/api/comments/create'
import { ReqBody as UpdateReqBody, ResBody as UpdateResBody } from '../../pages/api/comments/update'
import { ReqBody as DeleteReqBody, ResBody as DeleteResBody } from '../../pages/api/comments/delete'
import { captureException } from '../sentry'
import axios from 'axios'
import { notification } from 'antd'

// Key listeners based off meeting.id so that expanding other meetings
// will not remove listeners from the main meeting that is loaded.
const listeners: Record<string, Array<() => void>> = {}

const middleware: Middleware = ({ dispatch, getState }) => {
  return (next) => async (action: actions | meetingsActions | topicsActions) => {
    next(action)

    switch (action.type) {
      case actionTypes.CREATE_REQUEST: {
        try {
          const state = getState()
          const account = accountSelectors.getActiveAccount()(state)

          if (!account) {
            throw new Error('Must be logged in')
          }

          const res = await axios.post<CreateResBody, CreateReqBody>('/api/comments/create', {
            description: action.description,
            parentId: action.parentId,
            accountId: account.id,
            meetingId: action.meetingId,
            replyTo: action.replyTo,
          })
          const comment = inflateDates(res.data, ['createdAt'])
          dispatch(actions.createSuccess({ comment }))
        } catch (error) {
          if (!(error instanceof Error)) return
          console.log(error)
          dispatch(actions.createFailure({ error }))
          notification.error({
            message: 'There was an error creating a new comment',
          })
        }
        break
      }

      case actionTypes.EDIT: {
        try {
          await axios.post<UpdateResBody, UpdateReqBody>('/api/comments/update', {
            id: action.id,
            accountId: action.accountId,
            description: action.description,
          })
        } catch (error) {
          console.log(error)
          notification.error({
            message: 'There was an error editing this comment',
          })
        }
        break
      }

      case actionTypes.DELETE: {
        try {
          await axios.post<DeleteResBody, DeleteReqBody>('/api/comments/delete', {
            id: action.id,
          })
        } catch (error) {
          console.log(error)
          notification.error({
            message: 'There was an error deleting this comment',
          })
        }
        break
      }

      case actionTypes.LIKE: {
        const updatedData = {
          likes: action.comment.likes ? action.comment.likes + 1 : 1,
          likeAccountIds: [...(action.comment.likeAccountIds || []), action.accountId],
        }
        dispatch(
          actions.change({
            comment: { ...action.comment, ...updatedData },
          }),
        )

        try {
          await axios.post<UpdateResBody, UpdateReqBody>('/api/comments/update', {
            id: action.comment.id,
            ...updatedData,
          })
        } catch (error) {
          console.log(error)
          notification.error({
            message: 'There was an error liking this item',
          })
        }
        break
      }

      case actionTypes.UNLIKE: {
        const updatedData = {
          likes: action.comment.likes - 1,
          likeAccountIds: action.comment.likeAccountIds.filter((id) => id !== action.accountId),
        }
        dispatch(
          actions.change({
            comment: { ...action.comment, ...updatedData },
          }),
        )

        try {
          await axios.post<UpdateResBody, UpdateReqBody>('/api/comments/update', {
            id: action.comment.id,
            ...updatedData,
          })
        } catch (error) {
          console.log(error)
          notification.error({
            message: 'There was an error removing the like of this item',
          })
        }
        break
      }

      case topicsActionTypes.VIEW: {
        const chunks = _.chunk(action.topicsIds, 10)

        await Promise.all(
          chunks.map(async (topicsIds) => {
            const commentsRef = firebase.collection('comments').where('parentId', 'in', topicsIds)
            const comments = await getRefFormatByIds<Comment>(commentsRef, ['createdAt'])
            dispatch(
              actions.set({
                comments,
              }),
            )
          }),
        )
        break
      }

      case meetingsActionTypes.VIEW: {
        try {
          const state = getState()
          const meeting = meetingsSelectors.getMeetingById(action.id)(state)
          let meetingListeners = listeners[action.id] || []

          // This action gets fired when hot reloading on the meeting page.
          // We need to clear old listeners otherwise they will stack up.
          if (meetingListeners.length) {
            meetingListeners.forEach((detach) => detach())
            meetingListeners = []
          }

          // Create listeners for firebase based off chunks of 10 participants.
          const chunks = _.chunk(meeting.participantIds, 10)
          await Promise.all(
            chunks.map(async (participantsChunk) => {
              let commentsRef = firebase.collection('comments').where('accountId', 'in', participantsChunk)

              if (action.updateId) {
                commentsRef = commentsRef.where('updateId', '==', action.updateId)
              } else {
                commentsRef = commentsRef.where('meetingId', '==', action.id)
              }

              const comments = await getRefFormatByIds<Comment>(commentsRef, ['createdAt'])
              dispatch(actions.set({ comments }))

              const listener = listenForNewUpdates<Comment>(
                commentsRef,
                (change, comment) => {
                  if (change.type === 'added') {
                    dispatch(actions.add({ comment }))
                  }
                  if (change.type === 'modified') {
                    dispatch(actions.change({ comment }))
                  }
                  if (change.type === 'removed') {
                    dispatch(actions.remove({ comment }))
                  }
                },
                ['createdAt'],
              )
              meetingListeners.push(listener)
            }),
          )

          listeners[action.id] = meetingListeners
        } catch (error) {
          // When Firebase has a permissions error, catch the exception and
          // send it to Sentry.
          console.warn(error)
          captureException(error)
        }

        break
      }
      default:
        break
    }
  }
}

export default middleware
