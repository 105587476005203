import { Reducer } from 'redux'

export const selector = 'auth'

export const actionTypes = {
  SIGN_IN_REQUEST: 'auth/SIGN_IN_REQUEST' as 'auth/SIGN_IN_REQUEST',
  SIGN_IN_SUCCESS: 'auth/SIGN_IN_SUCCESS' as 'auth/SIGN_IN_SUCCESS',
  SIGN_IN_FAILURE: 'auth/SIGN_IN_FAILURE' as 'auth/SIGN_IN_FAILURE',
  UPDATE_PASSWORD_REQUEST: 'auth/UPDATE_PASSWORD_REQUEST' as 'auth/UPDATE_PASSWORD_REQUEST',
  UPDATE_PASSWORD_SUCCESS: 'auth/UPDATE_PASSWORD_SUCCESS' as 'auth/UPDATE_PASSWORD_SUCCESS',
  UPDATE_PASSWORD_FAILURE: 'auth/UPDATE_PASSWORD_FAILURE' as 'auth/UPDATE_PASSWORD_FAILURE',
  USER_CHANGE: 'auth/USER_CHANGE' as 'auth/USER_CHANGE',
  LOG_OUT: 'auth/LOG_OUT' as 'auth/LOG_OUT',
}

interface SignInRequestAction {
  email: string
  password: string
  redirectTo?: string
}
interface SignInFailureAction {
  error: Error
}
interface UpdatePasswordRequestAction {
  password: string
  redirectTo?: string
}
interface UpdatePasswordFailureAction {
  error: Error
}
interface UserChangeAction {
  user: User | null
}

export const actions = {
  signInRequest: ({ email, password, redirectTo = '/' }: SignInRequestAction) => ({
    type: actionTypes.SIGN_IN_REQUEST,
    email,
    password,
    redirectTo,
  }),
  signInSuccess: ({ user }: UserChangeAction) => ({
    type: actionTypes.SIGN_IN_SUCCESS,
    user,
  }),
  signInFailure: ({ error }: SignInFailureAction) => ({
    type: actionTypes.SIGN_IN_FAILURE,
    error,
  }),
  updatePasswordRequest: ({ password, redirectTo }: UpdatePasswordRequestAction) => ({
    type: actionTypes.UPDATE_PASSWORD_REQUEST,
    password,
    redirectTo,
  }),
  updatePasswordSuccess: () => ({
    type: actionTypes.UPDATE_PASSWORD_SUCCESS,
  }),
  updatePasswordFailure: ({ error }: UpdatePasswordFailureAction) => ({
    type: actionTypes.UPDATE_PASSWORD_FAILURE,
    error,
  }),
  userChange: ({ user }: UserChangeAction) => ({
    type: actionTypes.USER_CHANGE,
    user,
  }),
  logOut: () => ({
    type: actionTypes.LOG_OUT,
  }),
}

export type actions = ReturnType<typeof actions[keyof typeof actions]>

interface User {
  email: string
  emailVerified: boolean
  uid: string
}

export enum PageTypes {
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  UPDATE = 'UPDATE',
}

interface State {
  user: User | null
  initialized: boolean
  [PageTypes.LOGIN]: {
    loading: boolean
    error: Error | null
  }
  [PageTypes.REGISTER]: {
    loading: boolean
    error: Error | null
  }
  [PageTypes.UPDATE]: {
    loading: boolean
    error: Error | null
  }
}

const initialState: State = {
  user: null,
  initialized: false,
  [PageTypes.LOGIN]: {
    loading: false,
    error: null,
  },
  [PageTypes.REGISTER]: {
    loading: false,
    error: null,
  },
  [PageTypes.UPDATE]: {
    loading: false,
    error: null,
  },
}

export const reducer: Reducer<State, actions> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.USER_CHANGE: {
      return {
        ...state,
        user: action.user,
        initialized: true,
      }
    }
    case actionTypes.SIGN_IN_REQUEST:
      return {
        ...state,
        [PageTypes.LOGIN]: {
          loading: true,
          error: null,
        },
      }
    case actionTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        user: action.user,
        [PageTypes.LOGIN]: {
          loading: false,
          error: null,
        },
      }
    case actionTypes.SIGN_IN_FAILURE: {
      return {
        ...state,
        [PageTypes.LOGIN]: {
          loading: false,
          error: action.error,
        },
      }
    }
    case actionTypes.UPDATE_PASSWORD_REQUEST:
      return {
        ...state,
        [PageTypes.UPDATE]: {
          loading: true,
          error: null,
        },
      }
    case actionTypes.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        [PageTypes.UPDATE]: {
          loading: false,
          error: null,
        },
      }
    case actionTypes.UPDATE_PASSWORD_FAILURE: {
      return {
        ...state,
        [PageTypes.UPDATE]: {
          loading: false,
          error: action.error,
        },
      }
    }
    case actionTypes.LOG_OUT:
      return initialState
    default:
      return state
  }
}

export const selectors = {
  getInitialized: () => (state: Record<typeof selector, State>) => state[selector].initialized,
  getUser: () => (state: Record<typeof selector, State>) => state[selector].user,
  getError: (pageType: PageTypes) => (state: Record<typeof selector, State>) => state[selector][pageType].error,
  getLoading: (pageType: PageTypes) => (state: Record<typeof selector, State>) => state[selector][pageType].loading,
}
