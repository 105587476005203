import { useMutation, UseMutationOptions } from 'react-query'
import axios from 'axios'
import { AddReqBody, AddResBody } from '../../../pages/api/issues/[id]/addToMeeting'

interface AddIssueToMeeting {
  meetingId: string
  issueId: string
}

const addIssueToMeeting = async ({ meetingId, issueId }: AddIssueToMeeting) => {
  const { data } = await axios.post<AddResBody, AddReqBody>(`/api/issues/${issueId}/addToMeeting`, { meetingId })
  return data
}

const useAddIssueToMeetingMutation = (
  options?: UseMutationOptions<AddResBody, unknown, AddIssueToMeeting, unknown>,
) => {
  return useMutation(addIssueToMeeting, options)
}

export default useAddIssueToMeetingMutation
