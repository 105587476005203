import { Middleware } from 'redux'
import firebase, { getRefFormatByIds, listenForNewUpdates } from '../firebase'
import { selectors as companySelectors } from '../companies'
import { actionTypes, actions } from './store'
import {
  actionTypes as accountActionTypes,
  actions as accountActions,
  selectors as accountSelectors,
} from '../accounts'
import { actions as groupsActions } from '../groups'
import { Meeting } from '@mm/backend/meetings/model'
import { ReqBody as CreateReqBody, ResBody as CreateResBody } from '../../pages/api/meetings/create'
import { ReqBody as UpdateReqBody, ResBody as UpdateResBody } from '../../pages/api/meetings/update'
import {
  ReqBody as RegenerateUpdatesReqBody,
  ResBody as RegenerateUpdatesResBody,
} from '../../pages/api/meetings/regenerateUpdates'
import { ReqBody as CloseReqBody, ResBody as CloseResBody } from '../../pages/api/meetings/close'
import { ReqBody as DeleteReqBody, ResBody as DeleteResBody } from '../../pages/api/meetings/delete'
import axios from 'axios'
import { notification } from 'antd'

let detachListner: () => void

const middleware: Middleware = ({ dispatch, getState }) => {
  return (next) => async (action: actions | accountActions | groupsActions) => {
    next(action)

    switch (action.type) {
      case actionTypes.CLOSE: {
        try {
          const res = await axios.post<CloseResBody, CloseReqBody>('/api/meetings/close', {
            meetingId: action.meeting.id,
          })
          console.log('response from axios', res)
          dispatch(
            actions.updateSuccess({
              meeting: {
                ...action.meeting,
                complete: true,
              },
            }),
          )
        } catch (error) {
          if (!(error instanceof Error)) return
          console.log(error)
          dispatch(actions.updateFailure({ error }))
          notification.error({
            message: 'There was an error closing this meeting',
          })
        }
        break
      }
      case actionTypes.CREATE_REQUEST: {
        const state = getState()
        const account = accountSelectors.getActiveAccount()(state)
        try {
          const res = await axios.post<CreateResBody, CreateReqBody>('/api/meetings/create', {
            title: action.title,
            startAt: action.startAt,
            endAt: action.endAt,
            participants: action.participants,
            userId: account?.id,
            companyId: action.companyId,
            groupId: action.groupId || '',
            recurring: action.recurring,
            syncWithGCal: action.syncWithGCal,
            conferenceUrl: action.conferenceUrl,
          })
          const meeting = res.data
          dispatch(actions.createSuccess({ meeting }))
        } catch (error) {
          if (!(error instanceof Error)) return
          console.error(error)
          dispatch(actions.createFailure({ error }))
          notification.error({
            message: error.message,
          })
        }
        break
      }
      case actionTypes.UPDATE_REQUEST: {
        try {
          const state = getState()
          const account = accountSelectors.getActiveAccount()(state)
          const res = await axios.post<UpdateResBody, UpdateReqBody>('/api/meetings/update', {
            id: action.meeting.id,
            title: action.meeting.title,
            startAt: action.meeting.startAt,
            endAt: action.meeting.endAt,
            contributedToByIds: action.meeting.contributedToByIds,
            ownerAccountId: action.meeting.ownerAccountId,
            groupId: action.meeting.groupId,
            companyId: action.meeting.companyId,
            complete: action.meeting.complete,
            userId: account?.id,
            updates: action.meeting.updates,
            recurring: action.meeting.recurring,
            orderedTopicIds: action.meeting.orderedTopicIds,
            participants: action.meeting.participants,
            conferenceUrl: action.meeting.conferenceUrl,
          })

          const meeting = res.data
          meeting.startAt = new Date(meeting.startAt)
          meeting.endAt = new Date(meeting.endAt)
          dispatch(actions.updateSuccess({ meeting }))
        } catch (error) {
          if (!(error instanceof Error)) return
          console.log(error)
          dispatch(actions.updateFailure({ error }))
          notification.error({
            message: error.message,
          })
        }
        break
      }
      case actionTypes.DELETE: {
        try {
          const state = getState()
          const account = accountSelectors.getActiveAccount()(state)
          await axios.post<DeleteResBody, DeleteReqBody>('/api/meetings/delete', {
            id: action.id,
            userId: account?.id,
          })
        } catch (error) {
          if (!(error instanceof Error)) return
          console.log(error)
          notification.error({
            message: error.message,
          })
        }
        break
      }

      case accountActionTypes.SET_ACTIVE: {
        if (detachListner) {
          detachListner()
        }
        const meetingsRef = firebase.collection('meetings').where('participantIds', 'array-contains', action.account.id)

        const meetings = await getRefFormatByIds<Meeting>(meetingsRef, ['startAt', 'endAt'])
        dispatch(actions.set({ meetings }))

        detachListner = listenForNewUpdates<Meeting>(
          meetingsRef,
          (change, meeting) => {
            if (change.type === 'added') {
              dispatch(actions.add({ meeting }))
            }
            if (change.type === 'modified') {
              dispatch(actions.updateSuccess({ meeting }))
            }
            if (change.type === 'removed') {
              dispatch(actions.remove({ meeting }))
            }
          },
          ['startAt', 'endAt'],
        )
        break
      }
      case actionTypes.REGENERATE_UPDATES: {
        const { meetingId } = action
        try {
          await axios.post<RegenerateUpdatesResBody, RegenerateUpdatesReqBody>('/api/meetings/regenerateUpdates', {
            meetingId,
          })
          notification.success({ message: 'Successfully regenerated updates.' })
        } catch (error) {
          console.log(error)
          notification.error({
            message: 'There was an error regenerating the updates.',
          })
        }
        break
      }

      default:
        break
    }
  }
}

export default middleware
