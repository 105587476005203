import axios from 'axios'
import { PatchReqBody, PatchResBody } from '../../../pages/api/documents/[id]/content'
import { captureException } from '../../sentry'

export async function updateContent(documentId: string, update: Uint8Array): Promise<void> {
  try {
    await axios.patch<PatchResBody, PatchReqBody>(`/api/documents/${documentId}/content`, update, {
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    })
  } catch (error) {
    console.error(error)
    captureException(error)
  }
}
