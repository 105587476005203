import React from 'react'
import { Modal, Form, Input, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { actions, selectors } from '../store'

interface Props {
  visible: boolean
  onOk: () => void
  onCancel: () => void
}

interface Fields {
  adminKey: string
}

export const LoginAdminModal: React.FC<Props> = ({ visible, onOk }) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const isLoading = useSelector(selectors.isLoading())
  const error = useSelector(selectors.getError())
  const message = useSelector(selectors.getMessage())

  const [submitted, setSubmitted] = React.useState(false)

  // Close modal & clear on successfull submit
  React.useEffect(() => {
    if (submitted && !isLoading && !error) {
      return onOk()
    }
  }, [submitted, isLoading, error])

  const onSubmit = async () => {
    try {
      const values = (await form.validateFields()) as Fields

      dispatch(actions.loginAdmin({ adminKey: values.adminKey }))
      setSubmitted(true)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Modal
      title="Sign in as Admin"
      visible={visible}
      okText="Sign in"
      onOk={onSubmit}
      onCancel={() => {
        console.log('The only way to close this modal is by adding the correct admin code.')
      }}
      confirmLoading={isLoading}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <p>Please insert the Admin Key. </p>
      <Form form={form} name="basic" layout="vertical">
        <Form.Item
          label="Enter your Admin Key"
          name="adminKey"
          rules={[{ required: true, message: 'Admin key is required' }]}
        >
          <Input />
        </Form.Item>
      </Form>
      {message && error && <Typography.Paragraph type="danger">{message}</Typography.Paragraph>}
    </Modal>
  )
}

export default LoginAdminModal
