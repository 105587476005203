import _ from 'lodash'
import { ToolbarAction } from './ToolbarAction'
import {
  BsBlockquoteLeft,
  BsChevronDown,
  BsCode,
  BsLink45Deg,
  BsListOl,
  BsListUl,
  BsType,
  BsTypeBold,
  BsTypeH1,
  BsTypeH2,
  BsTypeH3,
  BsTypeItalic,
  BsTypeUnderline,
} from 'react-icons/bs'
import React from 'react'
import { Node as ProsemirrorNode } from 'prosemirror-model'
import styled from '@emotion/styled'

const ToolbarIcon = styled.div`
  width: 1.25em;
  height: 1.25em;
`

const DropdownIcon = styled.div`
  vertical-align: text-bottom;
  width: 1.25em;
  height: 1.25em;
  margin-right: 4px;
`

const DropdownChevron = styled(BsChevronDown)`
  height: 0.75em;
  width: 0.75em;
  margin-left: 4px;
`

const BoldIcon = ToolbarIcon.withComponent(BsTypeBold)
const bold: ToolbarAction = (editor) => ({
  label: <BoldIcon />,
  isActive: editor.isActive('bold'),
  onClick: () => editor.chain().focus().toggleBold().run(),
  disabled: !editor.can().toggleBold(),
  hidden: !editor.isEditable,
})

const ItalicIcon = ToolbarIcon.withComponent(BsTypeItalic)
const italic: ToolbarAction = (editor) => ({
  label: <ItalicIcon />,
  isActive: editor.isActive('italic'),
  onClick: () => editor.chain().focus().toggleItalic().run(),
  disabled: !editor.can().toggleItalic(),
  hidden: !editor.isEditable,
})

const UnderlineIcon = ToolbarIcon.withComponent(BsTypeUnderline)
const underline: ToolbarAction = (editor) => ({
  label: <UnderlineIcon />,
  isActive: editor.isActive('underline'),
  onClick: () => editor.chain().focus().toggleUnderline().run(),
  disabled: !editor.can().toggleUnderline(),
  hidden: !editor.isEditable,
})

const LinkIcon = ToolbarIcon.withComponent(BsLink45Deg)
const link: ToolbarAction = (editor) => ({
  label: <LinkIcon />,
  isActive: editor.isActive('link'),
  onClick: () => {
    if (editor.isActive('link')) {
      editor.chain().focus().unsetLink().run()
    } else {
      const href = window.prompt('URL')
      if (href != null) {
        editor.chain().focus().setLink({ href }).run()
      }
    }
  },
  disabled: !editor.can().toggleLink({ href: '' }),
  hidden: !editor.isEditable,
})

const headingIcons = _.mapValues(
  {
    1: DropdownIcon.withComponent(BsTypeH1),
    2: DropdownIcon.withComponent(BsTypeH2),
    3: DropdownIcon.withComponent(BsTypeH3),
  },
  (Icon) => <Icon />,
)

const TextIcon = DropdownIcon.withComponent(BsType)
const CodeIcon = DropdownIcon.withComponent(BsCode)

const block: ToolbarAction = (editor) => {
  const { from, to } = editor.state.selection
  const nodes: ProsemirrorNode[] = []
  editor.state.doc.nodesBetween(from, to, (node, _, parent) => {
    if (node.isBlock) {
      nodes.push(node)
      if (nodes.indexOf(parent) !== -1) {
        nodes.splice(nodes.indexOf(parent), 1)
      }
    }
  })
  const [label, ...rest] = nodes
    .map((node) => {
      switch (node.type.name) {
        case 'paragraph':
          return 'Text'
        case 'heading':
          return `Heading ${node.attrs.level}`
        case 'codeBlock':
          return 'Code block'
        default:
          return 'Mixed'
      }
    })
    .filter((label, idx, labels) => !labels.includes(label, idx + 1))

  return {
    label: (
      <>
        {rest.length > 0 ? 'Mixed' : label} <DropdownChevron />
      </>
    ),
    options: [
      {
        label: (
          <span>
            <TextIcon /> Text
          </span>
        ),
        onClick: () => editor.chain().focus().setParagraph().run(),
      },
      ...([1, 2, 3] as const).map((level) => ({
        label: (
          <span>
            {headingIcons[level]} Heading {level}
          </span>
        ),
        onClick: () => editor.chain().focus().setHeading({ level }).run(),
        disabled: !editor.can().setHeading({ level }),
      })),
      {
        label: (
          <span>
            <CodeIcon /> Code block
          </span>
        ),
        onClick: () => editor.chain().focus().setCodeBlock().run(),
        disabled: !editor.can().setCodeBlock(),
      },
    ],
    hidden: !editor.isEditable,
  }
}

const BulletListIcon = ToolbarIcon.withComponent(BsListUl)
const bulletList: ToolbarAction = (editor) => ({
  label: <BulletListIcon />,
  isActive: editor.isActive('bulletList'),
  onClick: () => editor.chain().focus().toggleBulletList().run(),
  disabled: !editor.can().toggleBulletList(),
  hidden: !editor.isEditable,
})

const OrderedListIcon = ToolbarIcon.withComponent(BsListOl)
const orderedList: ToolbarAction = (editor) => ({
  label: <OrderedListIcon />,
  isActive: editor.isActive('orderedList'),
  onClick: () => editor.chain().focus().toggleOrderedList().run(),
  disabled: !editor.can().toggleOrderedList(),
  hidden: !editor.isEditable,
})

const BlockquoteIcon = ToolbarIcon.withComponent(BsBlockquoteLeft)
const blockquote: ToolbarAction = (editor) => ({
  label: <BlockquoteIcon />,
  isActive: editor.isActive('blockquote'),
  onClick: () => editor.chain().focus().toggleBlockquote().run(),
  disabled: !editor.can().toggleBlockquote(),
  hidden: !editor.isEditable,
})

export const standardActions = {
  bold,
  italic,
  underline,
  link,
  block,
  'bullet-list': bulletList,
  'ordered-list': orderedList,
  blockquote,
}

export type StandardActions = typeof standardActions
