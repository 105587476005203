import * as Sentry from '@sentry/node'

export { default as middleware } from './middleware'

const environment =
  process.env.NEXT_PUBLIC_PREVIEW === 'true'
    ? 'preview'
    : process.env.NODE_ENV === 'production'
    ? 'production'
    : 'development'

export const setup = () => {
  if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    Sentry.init({
      defaultIntegrations: process.env.NODE_ENV === 'production' ? undefined : false,
      // enabled: process.env.NODE_ENV === 'production',
      environment,
      dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    })
  }
}

export { default as Error } from './components/Error'

export const captureException = Sentry.captureException
