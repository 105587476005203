import { MoreOutlined } from '@ant-design/icons'
import React from 'react'
import { Button, Dropdown, Menu, Table as AntTable } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { format } from 'date-fns'
import Link from 'next/link'
import { Text } from './index'

interface ColumnSchemaItem {
  filter: boolean
  sort: boolean
  key: string
  component: string
  title: string
  width?: number
  linkKey?: string
}

interface TableDataItem {
  key: string
  title: string
  [key: string]: any
}

export interface RowAction {
  title: React.ReactNode
  onClick?: (item: TableDataItem) => void
}

interface Props {
  columnSchema: Array<ColumnSchemaItem>
  tableData: Array<TableDataItem>
  pagination?: 20 | 50 | 100
  onChange?: () => void
  actions?: RowAction[]
}

export const Table: React.FC<Props> = ({ columnSchema, tableData, pagination = 20, onChange, actions }) => {
  const getSortFunc = (a: string, b: string): number => {
    if (Date.parse(a) && Date.parse(b)) {
      return new Date(a).getTime() - new Date(b).getTime()
    }

    if (typeof a === 'number' && typeof b === 'number') {
      return a - b
    }

    return ('' + a).localeCompare(b)
  }

  const renderColumnComponent = (column: ColumnSchemaItem, text: string, record: TableDataItem): JSX.Element => {
    const component = column.component

    if (component === 'link') {
      const linkKey = column.linkKey
      const href = linkKey && record[linkKey]
      if (href) {
        return (
          <Link href={href} passHref={true}>
            <a style={{ flex: 1 }}>{text}</a>
          </Link>
        )
      }
    }

    if (component === 'date') {
      return <Text color="light">{format(new Date(text), 'LLL d, h:mm aaaa')}</Text>
    }

    return <Text color="light">{text}</Text>
  }

  const tableColumns: ColumnsType<TableDataItem> = columnSchema.map((column) => {
    const columnKey = column.key
    return {
      ...column,
      dataIndex: columnKey,
      key: columnKey,
      sorter: column.sort ? (a, b) => getSortFunc(a[columnKey], b[columnKey]) : undefined,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => renderColumnComponent(column, text, record),
      filters: column.filter
        ? tableData.map((data) => ({
            text: data[columnKey],
            value: data[columnKey],
          }))
        : undefined,
      onFilter: (value, record): boolean => record[columnKey]?.indexOf(value) === 0,
    }
  })

  if (actions && actions.length !== 0) {
    tableColumns.push({
      title: '',
      key: 'actions',
      // eslint-disable-next-line react/display-name
      render: (_, record) => (
        <Dropdown
          trigger={['click']}
          overlay={
            <Menu>
              {actions.map((action, idx) => (
                <Menu.Item key={idx} onClick={() => action.onClick?.(record)}>
                  {action.title}
                </Menu.Item>
              ))}
            </Menu>
          }
        >
          <Button icon={<MoreOutlined />} shape="circle" type="text" size="large" />
        </Dropdown>
      ),
    })
  }

  return (
    <AntTable
      columns={tableColumns}
      dataSource={tableData}
      pagination={{
        defaultPageSize: pagination,
      }}
      onChange={onChange}
    />
  )
}
