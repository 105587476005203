import React from 'react'
import type { AppProps } from 'next/app'
import '../../public/reset.css'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@emotion/react'
import { ConfiguredApolloProvider } from '../modules/apollo'
import { FeatureFlagsProvider } from '../modules/featureFlags'
import useStore from '../modules/store'
import { theme } from '../modules/interface'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { setup as useSentry } from '../modules/sentry'
import { setup as useMixpanel } from '../modules/mixpanel'
import { hotjar } from 'react-hotjar'
import { QueryClient, QueryClientProvider } from 'react-query'
import 'antd/dist/antd.css'
import Router from 'next/router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import '../modules/datadog'

useSentry()
useMixpanel()

NProgress.configure({ showSpinner: false })

Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

if (process.env.NODE_ENV === 'production' && typeof window !== 'undefined') {
  // Add hotjar
  hotjar.initialize(2245190, 6)
}

const store = useStore()

interface Props extends AppProps {
  err: Error
}

export default function MocharyMethodApp({ Component, pageProps, err }: Props) {
  const queryClientRef = React.useRef(new QueryClient())
  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient()
  }
  return (
    <FeatureFlagsProvider>
      <ConfiguredApolloProvider>
        <Provider store={store}>
          <QueryClientProvider client={queryClientRef.current}>
            <ThemeProvider theme={theme}>
              <Component {...pageProps} err={err} />
            </ThemeProvider>
          </QueryClientProvider>
        </Provider>
      </ConfiguredApolloProvider>
    </FeatureFlagsProvider>
  )
}
