import { Middleware } from 'redux'
import firebase from '../firebase'
import { actions, actionTypes } from './store'
import {
  actionTypes as meetingsActionTypes,
  actions as meetingsActions,
  selectors as meetingsSelectors,
} from '../meetings'
import { actions as integrationActions } from '../integrations'
import { Company } from '@mm/backend/companies/model'
import { ReqBody as UpdateReqBody, ResBody as UpdateResBody } from '../../pages/api/companies/update'
import { actionTypes as accountActionTypes, actions as accountActions } from '../accounts'
import axios from 'axios'
import { notification } from 'antd'

let detachListner: () => void

const middleware: Middleware = ({ dispatch, getState }) => {
  return (next) => async (action: actions | integrationActions | accountActions | meetingsActions) => {
    next(action)

    switch (action.type) {
      case actionTypes.UPDATE_REQUEST: {
        try {
          const res = await axios.post<UpdateResBody, UpdateReqBody>('/api/companies/update', {
            id: action.company.id,
            name: action.company.name,
            bio: action.company.bio,
          })
          const company = res.data
          dispatch(actions.updateSuccess({ company }))
        } catch (error) {
          if (!(error instanceof Error)) return
          console.log(error)
          dispatch(actions.updateFailure({ error }))
          notification.error({
            message: 'There was an error updating this company',
          })
        }
        break
      }
      case accountActionTypes.SET_ACTIVE: {
        if (detachListner) {
          detachListner()
        }
        // Find companies associated with user
        const companies: Array<Company> = []
        detachListner = firebase
          .collection('companies')
          .where('members', 'array-contains', action.account.id)
          .onSnapshot(async (snapshot) => {
            if (!snapshot.empty) {
              snapshot.forEach((doc) => {
                const company = doc.data() as Company
                companies.push(company)
              })
              dispatch(actions.set({ companies }))

              // Get active company
              const activeCompanyId = localStorage.getItem('activeCompanyId')
              // Find active company or defaults to first found
              const activeCompany = companies.find((company) => company.id === activeCompanyId) || companies[0]
              dispatch(actions.setActive({ companyId: activeCompany.id }))
            } else {
              // No company associated with user
              // TODO: Redirect user to onboarding (create new company)
              const error = new Error('[Company Middleware] No company found associated with user.')
              dispatch(actions.updateFailure({ error: error }))
            }
          })
        break
      }
      case meetingsActionTypes.VIEW: {
        try {
          const state = getState()
          const meeting = meetingsSelectors.getMeetingById(action.id)(state)
          dispatch(actions.setActive({ companyId: meeting.companyId }))
        } catch (error) {
          console.warn(error)
        }

        break
      }
      case actionTypes.SET_ACTIVE: {
        localStorage.setItem('activeCompanyId', action.companyId)
        break
      }
      default:
        break
    }
  }
}

export default middleware
