import { useMutation } from 'react-query'
import axios from 'axios'
import {
  CreateReqBody as CreateIssueReqBody,
  CreateReqBodyNew as CreateIssueReqBodyNew,
  CreateResBody as CreateIssueResBody,
} from '../../../pages/api/issues'

const createIssue = async (issue: CreateIssueReqBody | CreateIssueReqBodyNew) => {
  const { data } = await axios.post<CreateIssueResBody, CreateIssueReqBody | CreateIssueReqBodyNew>(
    '/api/issues',
    issue,
  )
  return data
}

const useCreateIssueMutation = () => {
  return useMutation(createIssue)
}

export default useCreateIssueMutation
