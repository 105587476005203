import React from 'react'
import { Modal, Form, Input } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { Account } from '@mm/backend/accounts/model'
import { selectors, actions } from '../'
import { selectors as accountSelectors } from '../index'

interface Props {
  account?: Account
  companyId: string
  visible: boolean
  onComplete: () => void
  onCancel: () => void
}

interface Fields {
  name: string
  email: string
}

export const CreateEditAccountModal: React.FC<Props> = ({ visible, companyId, account, onComplete, onCancel }) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const editExisting = !!account
  const formState = useSelector(selectors.getForm())
  const senderId = useSelector(accountSelectors.getActiveAccountId())

  const handleOk = async () => {
    try {
      const { name, email } = (await form.validateFields()) as Fields

      if (account) {
        dispatch(
          actions.updateRequest({
            account: {
              id: account.id,
              name,
            },
          }),
        )
      } else {
        dispatch(
          actions.addCompanyRequest({
            email,
            name,
            companyId,
            senderId,
          }),
        )
      }
    } catch (error) {
      console.warn(error)
    }
  }

  React.useEffect(() => {
    if (formState.loading === false && formState.success) {
      if (form.isFieldsTouched(['name'])) {
        form.resetFields()
        onComplete()
      }
    }
  }, [formState.success, formState.loading])

  const handleCancel = () => {
    form.resetFields()
    onCancel()
  }

  return (
    <Modal
      title={editExisting ? 'Edit Account' : 'Create Account'}
      okText={editExisting ? 'Save' : 'Create'}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      confirmLoading={formState.loading}
    >
      <Form form={form} name="basic" layout="vertical" initialValues={{ name: account?.name, email: account?.email }}>
        <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Name is required' }]}>
          <Input autoComplete="name" />
        </Form.Item>

        <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Email is required' }]}>
          <Input disabled={editExisting} type="email" autoComplete="email" />
        </Form.Item>

        {formState.error?.message}
      </Form>
    </Modal>
  )
}

export default CreateEditAccountModal
