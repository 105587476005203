import { Theme } from '@emotion/react'
import { Interpolation } from '@emotion/serialize'
import _ from 'lodash'

type theme = typeof theme
declare module '@emotion/react' {
  export interface Theme extends theme {}
}

export type SpacingInterval =
  | 0
  | 0.5
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 8
  | 10
  | 12
  | -0.5
  | -1
  | -2
  | -3
  | -4
  | -5
  | -6
  | -8
  | -10
  | -12

// TODO use theme from props, not the hard-coded one
export const spacing = (multiple?: SpacingInterval) => multiple && multiple * theme.space

export type ThemeProps = {
  theme: Theme
}

type Colors = Theme['color']

type PlainColors = { [K in keyof Colors]: Colors[K] extends string ? K : never }[keyof Colors]
type ColorFamilies = Exclude<keyof Colors, PlainColors>
type ColorFamiliesWithDefault = { [K in ColorFamilies]: 'default' extends keyof Colors[K] ? K : never }[ColorFamilies]

type ColorFamiliesVariants = {
  [F in ColorFamilies]: {
    [K in keyof Colors[F] & (string | number)]: `${F}.${K}`
  }[keyof Colors[F] & (string | number)]
}[ColorFamilies]

export type ColorName = PlainColors | ColorFamiliesWithDefault | ColorFamiliesVariants

export function color(colorName: ColorName): (props: ThemeProps) => string {
  return ({ theme }) => _.get(theme.color, colorName)
}

export type FontMixinProps = {
  size?: keyof Theme['font']['size']
  color?: keyof Theme['font']['color']
  weight?: keyof Theme['font']['weight']
  lineHeight?: number | string
}

export function font({ size, color, weight, lineHeight }: FontMixinProps) {
  return ({ theme }: ThemeProps) => ({
    fontSize: size && theme.font.size[size],
    color: color && theme.font.color[color],
    fontWeight: weight && theme.font.weight[weight],
    lineHeight,
  })
}

export function variants<
  Prop extends string, // name of the property containing chosen variant
  Props extends { [K in Prop]?: string }, // props structure where the variant property is optional
  Variants extends Record<NonNullable<Props[Prop]>, Interpolation<Props>>
>(propName: Prop, variants: Variants, defaultVariant?: keyof Variants): Interpolation<Props> {
  return (props) => {
    const variant = props[propName] ?? defaultVariant
    return variant != null ? variants[variant] : undefined
  }
}

export const modifyLuminance = (hex: string, lum: number) => {
  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, '')
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
  }
  lum = lum || 0

  // convert to decimal and change luminosity
  let rgb = '#',
    c,
    i
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16)
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16)
    rgb += ('00' + c).substr(c.length)
  }

  return rgb
}

const theme = {
  space: 12,
  radius: 12,
  color: {
    primary: '#7261f5',
    background: '#f5f6fa',
    backgroundWhite: '#fff',

    blue: '#42a5f5',
    blueBackground: '#e3f2fd',

    success: '#52c41a',

    lightBlue: {
      100: '#ecf3f7',
      200: '#9dd1f4',
      300: '#67b8ee',
      400: '#3aa4ec',
      default: '#1392ea',
    },

    darkBlue: {
      70: '#eaedf1',
      100: '#d2d7dd',
      200: '#a6b0ba',
      300: '#79899a',
      400: '#4d6278',
      default: '#1f3b55',
    },

    grayscale: {
      white: '#ffffff',
      50: '#fafafa',
      100: '#f2f2f2',
      200: '#eeeeee',
      300: '#e0e0e0',
      500: '#9196a1',
      700: '#60646b',
      black: '#31353d',
    },

    transparentBlack: {
      5: 'rgba(0, 0, 0, 0.05)',
      10: 'rgba(0, 0, 0, 0.1)',
      20: 'rgba(0, 0, 0, 0.2)',
      30: 'rgba(0, 0, 0, 0.3)',
      40: 'rgba(0, 0, 0, 0.4)',
      50: 'rgba(0, 0, 0, 0.5)',
    },

    orange: {
      300: '#ffb74d',
      500: '#ff9800',
      700: '#f57c00',
      default: '#ff7043',
    },

    gray: '#999',
    grayBackground: '#f5f5f5',
    lightGray: '#ddd',
    darkGray: '#666',
    offWhite: '#fafafa',
    silver: '#bbb',
    coal: '#333',
    red: '#f44336',
  },
  font: {
    size: {
      small: 14,
      medium: 16,
      large: 20,
      xLarge: 28,
      huge: 38,
    },
    color: {
      dark: 'rgba(0,0,0,0.87)',
      normal: 'rgba(0,0,0,0.6)',
      light: 'rgba(0,0,0,0.38)',
      lightTinted: '#6F6C99',
      blue: '#1890ff',
      red: '#f44336',
    },
    weight: {
      heavy: 500,
      normal: 400,
      light: 300,
    },
  },
  button: {
    size: {
      small: 34,
      medium: 44,
      large: 56,
    },
    radius: 6,
  },
}

export default theme
