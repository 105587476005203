import React from 'react'
import { useDispatch } from 'react-redux'
import { actions } from '../store'
import { Button } from '../../ui'

export default function LogOutButton() {
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(actions.logOut())
  }

  return <Button label="Log Out" onClick={handleClick} />
}
