import { Reducer } from 'redux'
import {
  IntegrationTypes,
  AccountIntegrationTypes,
  CompanyIntegrationTypes,
  IntegrationStatus,
} from '@mm/backend/integrations/model'
import { actionTypes as authActionTypes, actions as authActions } from '../auth'
import { IntegratedTo as IntegratedToAccount } from '@mm/backend/accounts'
import { IntegratedTo as IntegratedToCompany } from '@mm/backend/companies'

export const selector = 'integrations'

export const actionTypes = {
  LOAD_ACCOUNT_INTEGRATIONS: 'integrations/LOAD_ACCOUNT_INTEGRATIONS' as 'integrations/LOAD_ACCOUNT_INTEGRATIONS',
  LOAD_COMPANY_INTEGRATIONS: 'integrations/LOAD_COMPANY_INTEGRATIONS' as 'integrations/LOAD_COMPANY_INTEGRATIONS',
  CONNECT_CALENDAR: 'integrations/CONNECT_CALENDAR' as 'integrations/CONNECT_CALENDAR',
  CREATE_CALENDAR_TOKEN: 'integrations/CREATE_CALENDAR_TOKEN' as 'integrations/CREATE_CALENDAR_TOKEN',
  CONNECT_ASANA: 'integrations/CONNECT_ASANA' as 'integrations/CONNECT_ASANA',
  CREATE_ASANA_TOKEN: 'integrations/CREATE_ASANA_TOKEN' as 'integrations/CREATE_ASANA_TOKEN',
  SETUP_ASANA: 'integrations/SETUP_ASANA' as 'integrations/SETUP_ASANA',
  CONNECT_MONDAY: 'integrations/CONNECT_MONDAY' as 'integrations/CONNECT_MONDAY',
  INTEGRATION_STARTS: 'integrations/INTEGRATION_STARTS' as 'integrations/INTEGRATION_STARTS',
  INTEGRATION_SUCESS: 'integrations/INTEGRATION_SUCESS' as 'integrations/INTEGRATION_SUCESS',
  INTEGRATION_FAIL: 'integrations/INTEGRATION_FAIL' as 'integrations/INTEGRATION_FAIL',
  DISCONNECT: 'integrations/DISCONNECT' as 'integrations/DISCONNECT',
}

interface LoadAccountIntegrationsAction {
  integratedTo: IntegratedToAccount
}
interface LoadCompanyIntegrationsAction {
  integratedTo: IntegratedToCompany
}
interface CreateCalendarTokenAction {
  userId: string
  authCode: string
  redirectTo: string
}
interface CreateAsanaTokenAction {
  userId: string
  authCode: string
  redirectTo: string
}
interface SetupAsanaAction {
  userId: string
  workspaceId: string
  projectCreateType: 'new' | 'old'
  projectId: string
  projectName: string
}
interface ConnectMondayAction {
  apiKey: string
  boardId: string
  boardName: string
  boardCreateType: string
  groupId: string
  groupName: string
  groupCreateType: string
}
interface IntegrationStartsAction {
  name: IntegrationTypes
}
interface IntegrationSuccessAction {
  name: IntegrationTypes
  status: IntegrationStatus
}
interface IntegrationFailAction {
  name: IntegrationTypes
  errorMessage: string
}
interface DisconnectAction {
  name: IntegrationTypes
}

export const actions = {
  loadAccountIntegrations: ({ integratedTo }: LoadAccountIntegrationsAction) => ({
    type: actionTypes.LOAD_ACCOUNT_INTEGRATIONS,
    integratedTo,
  }),
  loadCompanyIntegrations: ({ integratedTo }: LoadCompanyIntegrationsAction) => ({
    type: actionTypes.LOAD_COMPANY_INTEGRATIONS,
    integratedTo,
  }),
  connectCalendar: () => ({
    type: actionTypes.CONNECT_CALENDAR,
  }),
  createCalendarToken: ({ userId, authCode, redirectTo }: CreateCalendarTokenAction) => ({
    type: actionTypes.CREATE_CALENDAR_TOKEN,
    userId,
    authCode,
    redirectTo,
  }),
  connectAsana: () => ({
    type: actionTypes.CONNECT_ASANA,
  }),
  createAsanaToken: ({ userId, authCode, redirectTo }: CreateAsanaTokenAction) => ({
    type: actionTypes.CREATE_ASANA_TOKEN,
    userId,
    authCode,
    redirectTo,
  }),
  connectMonday: ({
    apiKey,
    boardId,
    boardName,
    boardCreateType,
    groupId,
    groupName,
    groupCreateType,
  }: ConnectMondayAction) => ({
    type: actionTypes.CONNECT_MONDAY,
    apiKey,
    boardId,
    boardName,
    boardCreateType,
    groupId,
    groupName,
    groupCreateType,
  }),
  setupAsana: ({ userId, workspaceId, projectCreateType, projectId, projectName }: SetupAsanaAction) => ({
    type: actionTypes.SETUP_ASANA,
    userId,
    workspaceId,
    projectCreateType,
    projectId,
    projectName,
  }),
  integrationStarts: ({ name }: IntegrationStartsAction) => ({
    type: actionTypes.INTEGRATION_STARTS,
    name,
  }),
  integrationSuccess: ({ name, status }: IntegrationSuccessAction) => ({
    type: actionTypes.INTEGRATION_SUCESS,
    name,
    status,
  }),
  integrationFail: ({ name, errorMessage }: IntegrationFailAction) => ({
    type: actionTypes.INTEGRATION_FAIL,
    errorMessage,
    name,
  }),
  disconnect: ({ name }: DisconnectAction) => ({
    type: actionTypes.DISCONNECT,
    name,
  }),
}

export type actions = ReturnType<typeof actions[keyof typeof actions]>

interface State {
  integrations: Record<IntegrationTypes, IntegrationState>
}
interface IntegrationState {
  errorMessage: string
  loading: boolean
  status: IntegrationStatus
}

// Setup account initial state
const accountTypes = Object.values(AccountIntegrationTypes)
const accountState = {} as Record<IntegrationTypes, IntegrationState>
accountTypes.forEach((type) => {
  accountState[type] = {
    errorMessage: '',
    loading: false,
    status: IntegrationStatus.NOT_CONNECTED,
  }
})
// Setup company initial state
const companyTypes = Object.values(CompanyIntegrationTypes)
const companyState = {} as Record<IntegrationTypes, IntegrationState>
companyTypes.forEach((type) => {
  companyState[type] = {
    errorMessage: '',
    loading: false,
    status: IntegrationStatus.NOT_CONNECTED,
  }
})

const initialState: State = {
  integrations: {
    ...accountState,
    ...companyState,
  },
}

export const reducer: Reducer<State, actions | authActions> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_ACCOUNT_INTEGRATIONS: {
      // Get all integration statuses. If integration is not found, set as NOT_CONNECTED
      const integratedToObj = action.integratedTo
      accountTypes.forEach((type) => {
        if (!integratedToObj[type]) {
          integratedToObj[type] = IntegrationStatus.NOT_CONNECTED
        }
      })

      // Create a integrations array[type, status]
      const integrations = Object.entries(integratedToObj) as [AccountIntegrationTypes, IntegrationStatus][]

      // Create an integratedTo object to build the state for each integration
      const integratedTo = {} as Record<IntegrationTypes, IntegrationState>
      integrations.forEach((integration) => {
        integratedTo[integration[0]] = {
          errorMessage: '',
          loading: false,
          status: integration[1],
        }
      })
      return {
        integrations: {
          ...state.integrations,
          ...integratedTo,
        },
      }
    }
    case actionTypes.LOAD_COMPANY_INTEGRATIONS: {
      // Get all integration statuses. If integration is not found, set as NOT_CONNECTED
      const integratedToObj = action.integratedTo
      companyTypes.forEach((type) => {
        if (!integratedToObj[type]) {
          integratedToObj[type] = IntegrationStatus.NOT_CONNECTED
        }
      })

      // Create a integrations array[type, status]
      const integrations = Object.entries(integratedToObj) as [CompanyIntegrationTypes, IntegrationStatus][]

      // Create an integratedTo object to build the state for each integration
      const integratedTo = {} as Record<IntegrationTypes, IntegrationState>
      integrations.forEach((integration) => {
        integratedTo[integration[0]] = {
          errorMessage: '',
          loading: false,
          status: integration[1],
        }
      })
      return {
        integrations: {
          ...state.integrations,
          ...integratedTo,
        },
      }
    }
    case actionTypes.INTEGRATION_STARTS:
      return {
        integrations: {
          ...state.integrations,
          [action.name]: {
            loading: true,
            errorMessage: '',
            status: IntegrationStatus.NOT_CONNECTED,
          },
        },
      }
    case actionTypes.INTEGRATION_SUCESS:
      return {
        integrations: {
          ...state.integrations,
          [action.name]: {
            loading: false,
            errorMessage: '',
            status: action.status,
          },
        },
      }
    case actionTypes.INTEGRATION_FAIL:
      return {
        integrations: {
          ...state.integrations,
          [action.name]: {
            loading: false,
            errorMessage: action.errorMessage,
            status: IntegrationStatus.NOT_CONNECTED,
          },
        },
      }
    case authActionTypes.LOG_OUT:
      return initialState
    default:
      return state
  }
}

export const selectors = {
  isLoading: (type: IntegrationTypes) => (state: Record<typeof selector, State>) =>
    state[selector].integrations[type].loading,
  getStatus: (type: IntegrationTypes) => (state: Record<typeof selector, State>) =>
    state[selector].integrations[type].status,
  getErrorMessage: (type: IntegrationTypes) => (state: Record<typeof selector, State>) =>
    state[selector].integrations[type].errorMessage,
}
