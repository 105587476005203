import { Middleware } from 'redux'
import { auth } from '../firebase'
import { actions, actionTypes } from './store'
import { actions as accountActions } from '../accounts'
import Router from 'next/router'
import axios from 'axios'
import type firebase from 'firebase'

const middleware: Middleware = ({ dispatch }) => {
  auth.onAuthStateChanged(async (user: firebase.User | null) => {
    if (user && user.email) {
      axios.interceptors.request.use(async (config) => {
        if (user) {
          config.headers['Authorization'] = await user.getIdToken()
        }

        return config
      })

      dispatch(
        actions.userChange({
          user: {
            email: user.email,
            emailVerified: user.emailVerified,
            uid: user.uid,
          },
        }),
      )
    } else {
      dispatch(actions.userChange({ user: null }))
    }
  })

  return (next) => async (action: actions | accountActions) => {
    next(action)

    switch (action.type) {
      case actionTypes.SIGN_IN_REQUEST: {
        try {
          const { user } = await auth.signInWithEmailAndPassword(action.email, action.password)
          if (action.redirectTo) {
            Router.push(action.redirectTo)
          } else {
            Router.push('/')
          }
          dispatch(
            actions.signInSuccess({
              user: user
                ? {
                    email: user.email as string,
                    emailVerified: user.emailVerified,
                    uid: user.uid,
                  }
                : null,
            }),
          )
        } catch (error) {
          if (!(error instanceof Error)) return
          dispatch(actions.signInFailure({ error }))
        }
        break
      }
      case actionTypes.UPDATE_PASSWORD_REQUEST: {
        try {
          if (!auth.currentUser) throw new Error('You need to login before updating your password.')
          await auth.currentUser.updatePassword(action.password)
          dispatch(actions.updatePasswordSuccess())
          if (action.redirectTo) {
            Router.push(action.redirectTo)
          } else {
            Router.push('/')
          }
        } catch (error) {
          if (!(error instanceof Error)) return
          dispatch(actions.updatePasswordFailure({ error }))
        }
        break
      }
      case actionTypes.LOG_OUT: {
        Router.push('/login')
        await auth.signOut()
        break
      }
      default:
        break
    }
  }
}

export default middleware
