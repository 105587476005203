import VideoOutlineIcon from '@icons/material/VideoOutlineIcon'
import { Account } from '@mm/backend/accounts/model'
import { Comment } from '@mm/backend/comments/model'
import { Meeting } from '@mm/backend/meetings/model'
import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'
import { actions as actionsActions } from '../../actions'
import useScopedCreateActionLoadingState from '../../actions/hooks/useScopedCreateActionLoadingState'
import { actions as commentsActions } from '../../comments'
import useScopedCreateCommentLoadingState from '../../comments/hooks/useScopedCreateCommentLoadingState'
import { Editable, EditableRef, ToolbarItem } from '../../editor'
import { RecordVideoButton } from '../../interface'
import { buildToolbarItems } from '../../meetings/utils'
import { Block as ActionsBlockItemType } from './ActionsBlockItem'
import BlockAccountLink from './BlockAccountLink'
import BlockIndent from './BlockIndent'
import BlockMenu, {
  AddActionMenuItem,
  AddCommentMenuItem,
  ChangeParticipantsMenuItem,
  DeleteMenuItem,
  MenuItemDivider,
} from './BlockMenu'
import BlockResolver from './BlockResolver'
import { getActionDueDate } from '../utils/meetingInfoHelpers'

export const TYPE = 'comment'

interface Participant {
  name: string
  id: string
}

export interface Block {
  type: typeof TYPE
  id: string
  participants: Array<Participant>
  firstName: string
  comment: Comment
  activeAccount: Account
  meeting: Meeting | null
  blocks: Array<ActionsBlockItemType | Block>
  allowAddComments?: boolean
  allowAddActions?: boolean
  allowAddVideo?: boolean
  autofocus?: boolean
  toolbar?: ToolbarItem[]
}

export const CommentsBlockItem = ({
  participants,
  firstName,
  comment,
  meeting,
  activeAccount,
  blocks,
  allowAddComments = true,
  allowAddActions = true,
  autofocus = false,
  allowAddVideo = false,
  toolbar,
}: Block) => {
  const dispatch = useDispatch()
  const commentsBlockEditableRef = useRef<EditableRef>(null)
  const [creatingAction, setCreatingAction] = useScopedCreateActionLoadingState()
  const [creatingComment, setCreatingComment] = useScopedCreateCommentLoadingState()

  const handleChangeAccount = (accountId?: string) => {
    accountId && dispatch(commentsActions.edit({ id: comment.id, accountId }))
  }

  const handleDelete = () => {
    dispatch(commentsActions.delete({ id: comment.id }))
  }

  const handleAddAction = (description = '') => {
    setCreatingAction(true)
    dispatch(
      actionsActions.createRequest({
        description,
        accountId: activeAccount.id,
        assignedTo: activeAccount.id,
        featureId: comment.id,
        featureType: 'comment',
        featurePreviewText: description || commentsBlockEditableRef.current?.editor?.getHTML() || '',
        meetingIds: meeting ? [meeting.id] : [],
        groupIds: meeting ? [meeting.groupId] : [],
        participantIds: meeting ? meeting.participantIds : [],
        dueAt: getActionDueDate(meeting),
      }),
    )
  }

  const handleAddComment = () => {
    setCreatingComment(true)
    dispatch(
      commentsActions.createRequest({
        description: '',
        meetingId: comment.meetingId,
        parentId: comment.parentId,
        replyTo: comment.id,
      }),
    )
  }

  const toolbarItems = buildToolbarItems({
    addComment: allowAddComments ? handleAddComment : undefined,
    addAction: allowAddActions ? handleAddAction : undefined,
  })

  const handleSaveVideo = (links: { embedUrl: string; shareUrl: string }) => {
    const currentContent = commentsBlockEditableRef.current?.editor?.getHTML()
    const newContent =
      currentContent + ` <a href="${links.shareUrl}" target="_blank" rel="noopener noreferrer">Video</a>`
    commentsBlockEditableRef.current?.editor?.chain().setContent(newContent).run()
  }

  return (
    <>
      <BlockIndent
        menuLoading={creatingAction || creatingComment}
        menu={
          <BlockMenu>
            <ChangeParticipantsMenuItem
              label="Change author"
              participants={participants}
              onClick={handleChangeAccount}
            />
            <DeleteMenuItem onClick={handleDelete} />
            <MenuItemDivider />
            {allowAddActions ? <AddActionMenuItem onClick={() => handleAddAction()} /> : <></>}
            {allowAddComments ? <AddCommentMenuItem onClick={handleAddComment} /> : <></>}
          </BlockMenu>
        }
      >
        <div style={{ color: '#666', display: 'flex', alignItems: 'center' }}>
          <div style={{ flex: 1 }}>
            <Editable
              ref={commentsBlockEditableRef}
              documentId={comment.contentDocId}
              prefix={<BlockAccountLink id={comment.accountId}>{firstName}</BlockAccountLink>}
              selectionToolbar={toolbar || toolbarItems}
              toolbarCallbackParam={comment}
              autofocus={autofocus}
              mode="compact"
            />
          </div>
          {allowAddVideo && activeAccount.id === comment.accountId ? (
            <RecordVideoButton onComplete={handleSaveVideo}>
              <VideoOutlineIcon
                style={{
                  cursor: 'pointer',
                }}
              />
            </RecordVideoButton>
          ) : null}
        </div>
      </BlockIndent>
      <BlockIndent>
        {blocks.map((block) => (
          <BlockResolver key={block.id} block={block} allowAddVideo={allowAddVideo} />
        ))}
      </BlockIndent>
    </>
  )
}

export default CommentsBlockItem
