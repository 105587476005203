import { useMutation } from 'react-query'
import axios from 'axios'
import { ReqBody, ResBody } from '../../../pages/api/meetings/sendActionEmail'

const sendActionEmail = async (meetingId: string) => {
  const { data } = await axios.post<ResBody, ReqBody>(`/api/meetings/sendActionEmail`, { meetingId })
  return data
}

const useSendActionEmailMutation = () => {
  return useMutation(sendActionEmail)
}

export default useSendActionEmailMutation
