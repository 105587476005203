import { Account } from '../accounts'
import { KeysOfType } from '../helpers'
import { Meeting } from '../meetings'
import { Update } from '../update'
import Likeable from '../interfaces/Likeable'
import { HasAttachments } from '../shared'

export interface Feedback extends HasAttachments, Likeable {
  id: string
  likeText: string
  wishThatText: string
  recipientId: Account['id']
  meetingId: Meeting['id']
  accountId: Account['id']
  updateId: Update['id']
  createdAt: Date
  updatedAt: Date
  visibleToRecipient: boolean
}

export const dateKeys: KeysOfType<Feedback, Date>[] = ['updatedAt', 'createdAt']
