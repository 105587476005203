import React from 'react'
import { useSelector } from 'react-redux'
import { selectors } from '../store'

interface Props {
  id: string
  youLabel?: string
  skipYou?: boolean
}

export const AccountName: React.FC<Props> = ({ id, skipYou = false, youLabel = 'and you' }) => {
  const activeAccount = useSelector(selectors.getActiveAccount())
  const account = useSelector(selectors.getAccountById(id)) || {}

  if (!skipYou && activeAccount && activeAccount.id === id) {
    return <>{youLabel}</>
  } else {
    return <>{account.name}</>
  }
}

export default AccountName
