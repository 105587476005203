import React from 'react'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import BlockResolver from './BlockResolver'
import { Topic } from '@mm/backend/topics/model'
import { Meeting } from '@mm/backend/meetings/model'
import { Account } from '@mm/backend/accounts/model'
import { Block as ActionsBlockItemType } from './ActionsBlockItem'
import { Block as CommentsBlockItemType } from './CommentsBlockItem'
import { actions as topicsActions } from '../../topics'
import { actions as actionsActions } from '../../actions'
import { actions as commentsActions } from '../../comments'
import Editable from './Editable'
import BlockIndent from './BlockIndent'
import BlockAccountLink from './BlockAccountLink'
import BlockMenu, {
  DeleteMenuItem,
  MenuItemDivider,
  AddActionMenuItem,
  AddCommentMenuItem,
  CompleteMenuItem,
  ChangeParticipantsMenuItem,
  RecurringMenuItem,
} from './BlockMenu'
import { Modal, Tooltip } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { differenceInSeconds } from 'date-fns'
import ThumbUpOutlineIcon from '@icons/material/ThumbUpOutlineIcon'
import CommentPlusOutlineIcon from '@icons/material/CommentPlusOutlineIcon'
import VideoOutlineIcon from '@icons/material/VideoOutlineIcon'
import PlaylistPlusIcon from '@icons/material/PlaylistPlusIcon'
import CheckBoxOutlineIcon from '@icons/material/CheckBoxOutlineIcon'
import CheckboxBlankOutlineIcon from '@icons/material/CheckboxBlankOutlineIcon'
import UpdateIcon from '@icons/material/UpdateIcon'
import { FlexRow, RecordVideoButton, Text } from '../../interface'
import { AccountNames } from '../../accounts'
import useScopedCreateActionLoadingState from '../../actions/hooks/useScopedCreateActionLoadingState'
import useScopedCreateCommentLoadingState from '../../comments/hooks/useScopedCreateCommentLoadingState'
import TooltipIconButton from './TooltipIconButton'
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import { useState } from 'react'
import { getActionDueDate } from '../utils/meetingInfoHelpers'

let Quill: any // eslint-disable-line
const NOOP = () => {} // eslint-disable-line

export const TYPE = 'topic'

interface Participant {
  name: string
  id: string
}

export interface Block {
  type: typeof TYPE
  id: string
  activeAccount: Account
  participants: Array<Participant>
  firstName: string
  topic: Topic
  meeting: Meeting | null
  reviewView?: boolean
  blocks: Array<ActionsBlockItemType | CommentsBlockItemType>

  dragHandleProps?: DraggableProvidedDragHandleProps
}

export const TopicsBlockItem = ({
  firstName,
  topic,
  activeAccount,
  participants,
  meeting,
  reviewView = false,
  blocks = [],
  dragHandleProps,
}: Block) => {
  const dispatch = useDispatch()
  const [quill, setQuill] = useState<typeof Quill>()
  const [isOpened, setIsOpened] = React.useState<boolean>(false)
  const [actionLoading, setActionLoading] = useScopedCreateActionLoadingState()
  const [commentLoading, setCommentLoading] = useScopedCreateCommentLoadingState()
  const updateView = topic.updateId && !topic.meetingId
  const editUpdateId = updateView ? topic.updateId : ''

  const handleDescriptionChange = (description: string) => {
    dispatch(topicsActions.edit({ id: topic.id, description, updateId: editUpdateId }))
  }

  const handleMarkDiscussed = (discussed: boolean) => {
    if (!reviewView) {
      dispatch(topicsActions.edit({ id: topic.id, discussed, optimistic: true, updateId: editUpdateId }))
    }
  }

  const handleChangeAccount = (accountId?: string) => {
    accountId && dispatch(topicsActions.edit({ id: topic.id, accountId, optimistic: true, updateId: editUpdateId }))
  }

  const handleSetRecurring = () => {
    dispatch(
      topicsActions.edit({
        id: topic.id,
        recurring: !topic.recurring,
        optimistic: true,
        updateId: editUpdateId,
      }),
    )
  }

  const handleLikeUnlike = () => {
    if (topic.likeAccountIds.includes(activeAccount.id)) {
      dispatch(
        topicsActions.edit({
          id: topic.id,
          likeAccountIds: _.remove(topic.likeAccountIds, activeAccount.id),
          optimistic: true,
          updateId: editUpdateId,
        }),
      )
    } else {
      dispatch(
        topicsActions.edit({
          id: topic.id,
          likeAccountIds: [...topic.likeAccountIds, activeAccount.id],
          optimistic: true,
          updateId: editUpdateId,
        }),
      )
    }
  }

  const likerNames = <AccountNames ids={topic.likeAccountIds} />

  const handleDelete = () => {
    if (blocks.length) {
      Modal.confirm({
        title: 'Are you sure delete this topic?',
        icon: <ExclamationCircleOutlined />,
        content: 'It may delete any comments or actions attached to it.',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: () => {
          dispatch(topicsActions.delete({ id: topic.id, updateId: topic.updateId }))
        },
      })
    } else {
      dispatch(topicsActions.delete({ id: topic.id, updateId: topic.updateId }))
    }
  }

  const handleAddAction = (description = '') => {
    if (meeting) {
      setActionLoading(true)
      dispatch(
        actionsActions.createRequest({
          description,
          accountId: activeAccount.id,
          assignedTo: activeAccount.id,
          featureId: topic.id,
          featureType: 'topic',
          featurePreviewText: description || topic.description,
          meetingIds: [meeting.id],
          groupIds: [meeting.groupId],
          participantIds: meeting.participantIds,
          dueAt: getActionDueDate(meeting),
        }),
      )
    }
  }

  const handleAddComment = () => {
    if (meeting) {
      setCommentLoading(true)
      dispatch(
        commentsActions.createRequest({
          description: '',
          meetingId: meeting.id,
          parentId: topic.id,
        }),
      )
    }
  }

  const handleSaveVideo = (links: { embedUrl: string; shareUrl: string }) => {
    const quillLength = quill?.getLength()
    const delta = {
      ops: [{ retain: quillLength }, { insert: 'Video', attributes: { link: links.shareUrl } }],
    }
    quill?.updateContents(delta, 'user')
  }

  const checkboxComponent = {
    NOT_CHECKED: (
      <CheckboxBlankOutlineIcon
        style={{ cursor: 'pointer', color: '#999' }}
        onClick={() => handleMarkDiscussed(true)}
      />
    ),
    CHECKED: (
      <CheckBoxOutlineIcon style={{ cursor: 'pointer', color: '#42a5f5' }} onClick={() => handleMarkDiscussed(false)} />
    ),
  }

  return (
    <>
      <BlockIndent
        controls={topic.discussed ? checkboxComponent.CHECKED : checkboxComponent.NOT_CHECKED}
        menuLoading={actionLoading || commentLoading}
        menu={
          <BlockMenu>
            <CompleteMenuItem value={topic.discussed} onClick={() => handleMarkDiscussed(!topic.discussed)} />
            {!reviewView && (
              <>
                <ChangeParticipantsMenuItem
                  label="Change author"
                  participants={participants}
                  onClick={handleChangeAccount}
                />
                <DeleteMenuItem onClick={handleDelete} />
              </>
            )}
            <MenuItemDivider />
            <AddActionMenuItem onClick={() => handleAddAction()} />
            <AddCommentMenuItem onClick={handleAddComment} />
            <RecurringMenuItem
              label={topic.recurring ? 'Turn off recurring' : 'Set as recurring'}
              onClick={handleSetRecurring}
            />
          </BlockMenu>
        }
        dragHandleProps={dragHandleProps}
      >
        <FlexRow gap={0.5}>
          <Editable
            setParentQuill={setQuill}
            prefix={
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {topic.recurring ? <UpdateIcon style={{ marginRight: '4px' }} /> : null}
                <BlockAccountLink id={topic.accountId}>{firstName}</BlockAccountLink>
              </div>
            }
            editable={!reviewView}
            focusOnNew={topic.accountId === activeAccount.id && differenceInSeconds(new Date(), topic.createdAt) < 15}
            html={topic.description}
            onChange={handleDescriptionChange}
            onAddComment={handleAddComment}
            onAddAction={(description) => handleAddAction(description)}
            onDelete={handleDelete}
            completed={topic.discussed}
            placeholder={updateView ? 'Topic...' : ''}
            isOpened={isOpened}
            setIsOpened={setIsOpened}
            hasChildBlocks={blocks.length > 0}
          />
          <RecordVideoButton onComplete={handleSaveVideo}>
            <TooltipIconButton title="Add Video" loading={actionLoading} Icon={VideoOutlineIcon} onClick={NOOP} />
          </RecordVideoButton>
          <TooltipIconButton
            title="Add Action"
            loading={actionLoading}
            Icon={PlaylistPlusIcon}
            onClick={() => handleAddAction()}
          />
          <TooltipIconButton
            title="Add Comment"
            loading={commentLoading}
            Icon={CommentPlusOutlineIcon}
            onClick={() => handleAddComment()}
          />
          <Tooltip title={likerNames}>
            <FlexRow
              style={{ color: '#999', cursor: 'pointer' }}
              gap={0.5}
              background="background"
              paddingVertical={0.5}
              paddingHorizontal={1}
              rounded={true}
              onClick={handleLikeUnlike}
            >
              {topic.likeAccountIds.length ? <Text>{topic.likeAccountIds.length}</Text> : null}

              <ThumbUpOutlineIcon width={18} height={18} />
            </FlexRow>
          </Tooltip>
        </FlexRow>
      </BlockIndent>
      {(!topic.discussed || isOpened) && (
        <BlockIndent>
          {blocks.map((block) => {
            if (block.type === 'comment') {
              return (
                <BlockIndent key={block.id}>
                  <BlockResolver block={block} allowAddVideo />
                </BlockIndent>
              )
            }

            return <BlockResolver key={block.id} block={block} />
          })}
        </BlockIndent>
      )}
    </>
  )
}

export default TopicsBlockItem
