import { Account } from '@mm/backend/accounts/model'
import { Issue, IssueDecision, TimerState } from '@mm/backend/issues/model'
import { defaultItems, Editable, EditableRef, getSelectionHtml, ToolbarItem } from '../../editor'
import { addSeconds, differenceInMilliseconds, differenceInSeconds, format } from 'date-fns'
import _ from 'lodash'
import Link from 'next/link'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AccountName, selectors as accountsSelectors } from '../../accounts'
import { BlockAdd, selectors as meetingsSelector } from '../../meetings'
import { actions as actionsActions } from '../../actions'
import useRealtimeActions from '../../actions/hooks/useRealtimeActions'
import useScopedCreateActionLoadingState from '../../actions/hooks/useScopedCreateActionLoadingState'
import { Comment } from '@mm/backend/comments'
import { actions as commentsActions } from '../../comments'
import useRealtimeComments from '../../comments/hooks/useRealtimeComments'
import useScopedCreateCommentLoadingState from '../../comments/hooks/useScopedCreateCommentLoadingState'
import { Button, Card, Div, FlexRow, Text, theme } from '../../interface'
import ActionsBlockItem from '../../meetings/components/ActionsBlockItem'
import CommentsBlockItem, { Block as CommentBlock } from '../../meetings/components/CommentsBlockItem'
import useEditIssueMutation from '../hooks/useEditIssueMutation'
import IssueVideoBox from './IssueVideoBox'
import PauseIcon from '@icons/material/PauseIcon'
import PlayIcon from '@icons/material/PlayIcon'
import StopIcon from '@icons/material/StopIcon'
import DotsVerticalIcon from '@icons/material/DotsVerticalIcon'
import { Popover, Select } from 'antd'
import { getActionDueDate } from '../../../modules/meetings/utils/meetingInfoHelpers'
import { issueDecisionMap, issueRoleMap } from '@mm/common'
import useSound from 'use-sound'

interface Props {
  issue: Issue
  inline?: boolean
  meetingId?: string
  groupId?: string
}

const getAccountFirstName = (account: Account) => {
  const [firstName] = account && 'name' in account ? account.name.split(' ') : ['']
  return firstName
}

const TimerControls: React.FC<{
  handleTimerState: (state: TimerState) => void
  handleTimerStart: () => void
  handleTimerUnpause: () => void
  issue: Issue
}> = ({ handleTimerState, handleTimerStart, handleTimerUnpause, issue }) => {
  return (
    <>
      <PlayIcon
        style={{
          cursor: 'pointer',
        }}
        onClick={() => {
          if (issue.timer?.state === 'stopped') {
            handleTimerStart()
          }
          if (issue.timer?.state === 'paused') {
            handleTimerUnpause()
          }
        }}
      />
      <PauseIcon
        style={{
          cursor: 'pointer',
        }}
        onClick={() => handleTimerState('paused')}
      />
      <StopIcon
        style={{
          cursor: 'pointer',
        }}
        onClick={() => handleTimerState('stopped')}
      />
    </>
  )
}

export const SingleIssueNew: React.FC<Props> = ({ issue, inline, meetingId = '', groupId }) => {
  const activeAccount = useSelector(accountsSelectors.getActiveAccount()) || {}
  const accounts = useSelector(accountsSelectors.getAccounts())
  const selectedMeeting = useSelector(meetingsSelector.getMeetingById(meetingId))
  const actions = useRealtimeActions(issue.id)
  const comments = useRealtimeComments(issue.id)
  const dispatch = useDispatch()
  const editIssueMutation = useEditIssueMutation()
  const finalDecisionEditableRef = useRef<EditableRef>(null)
  const [decisionIsEmpty, setDecisionIsEmpty] = useState(true)
  const [creatingAction, setCreatingAction] = useScopedCreateActionLoadingState()
  const [creatingComment, setCreatingComment] = useScopedCreateCommentLoadingState()
  const [timerValue, setTimerValue] = useState<number>(5 * 60)
  const [secondsUntil, setSecondsUntil] = useState<number>(0)
  const [showTimer, setShowTimer] = useState<boolean>(false)
  const [playAlert, { stop }] = useSound('/sfx/piano_alert.mp3', { volume: 0.25 })

  const handleCalculateUntil = () => {
    if (issue.timer?.timerEnd) {
      const ms = differenceInMilliseconds(new Date(issue.timer.timerEnd), new Date())
      const seconds = parseInt((ms / 1000).toFixed(0), 10)
      const newSeconds = seconds > 0 ? seconds : 0
      if (newSeconds === 0 && secondsUntil === 0) {
        playAlert()
      }
      setSecondsUntil(newSeconds)
    }
  }

  useEffect(() => {
    if (issue.timer?.state === 'running' && issue.timer?.timerEnd) {
      setTimeout(() => {
        handleCalculateUntil()
      }, 1000)
    }
    if (issue.timer?.state === 'stopped') {
      setShowTimer(false)
    }
    if (issue.timer?.state === 'running' || issue.timer?.state === 'paused') {
      setShowTimer(true)
    }
  }, [secondsUntil, issue.timer?.state])

  const sortedActions = _.orderBy(actions, 'createdAt', 'asc')
  const commentsByParent = _(comments)
    .orderBy('createdAt', 'asc')
    .groupBy((comment) => comment.pathToRoot?.[0] ?? '')
    .value()

  const participants = _(issue.participantIds)
    .map((id) => accounts[id])
    .filter((account) => !!account)
    .orderBy('name')
    .value()

  // TODO: @case use a real state machine
  const issueStatusDecidedOrLater = issue.status !== 'CREATED'

  const handleAddAction = (description = '') => {
    setCreatingAction(true)
    dispatch(
      actionsActions.createRequest({
        description,
        accountId: activeAccount.id,
        assignedTo: activeAccount.id,
        featureId: issue.id,
        featureType: 'issue',
        featurePreviewText: issue.title || description,
        meetingIds: issue.meetingIds.filter((meetingId) => meetingId.length),
        groupIds: groupId ? [groupId] : [],
        participantIds: issue.participantIds || [],
        dueAt: getActionDueDate(selectedMeeting),
      }),
    )
  }

  const handleAddComment = (comment: Comment | null) => {
    setCreatingComment(true)
    if (comment) {
      return dispatch(
        commentsActions.createRequest({
          description: '',
          meetingId: comment.meetingId,
          parentId: comment.parentId,
          replyTo: comment.id,
        }),
      )
    }
    dispatch(
      commentsActions.createRequest({
        parentId: issue.id,
        meetingId: '',
        description: '',
      }),
    )
  }

  const handleDecisionChange = async (status: IssueDecision) => {
    await editIssueMutation.mutateAsync({
      id: issue.id,
      issue: {
        status,
      },
    })
  }

  const handleSaveVideo = (links: { embedUrl: string; shareUrl: string }) => {
    editIssueMutation.mutate({
      id: issue.id,
      issue: {
        video: {
          embedUrl: links.embedUrl,
          shareUrl: links.shareUrl,
        },
      },
    })
  }

  const handleRemoveVideo = () => {
    editIssueMutation.mutate({
      id: issue.id,
      issue: {
        video: {},
      },
    })
  }

  const handleTimerStart = () => {
    const now = new Date()
    editIssueMutation.mutate({
      id: issue.id,
      issue: {
        timer: {
          state: 'running',
          timerEnd: addSeconds(now, timerValue),
        },
      },
    })
  }

  const handleTimerUnpause = () => {
    const now = new Date()
    editIssueMutation.mutate({
      id: issue.id,
      issue: {
        timer: {
          state: 'running',
          timerEnd: addSeconds(now, secondsUntil),
        },
      },
    })
  }

  const handleTimerState = (state: TimerState) => {
    if (state === 'stopped') {
      stop()
      setSecondsUntil(0)
    }
    editIssueMutation.mutate({
      id: issue.id,
      issue: {
        timer: {
          state,
        },
      },
    })
  }

  const toolbarActions: ToolbarItem[] = [
    ...defaultItems,
    (editor) => ({
      label: 'Create Action',
      onClick: () => handleAddAction(getSelectionHtml(editor)),
    }),
    () => ({
      label: 'Create Comment',
      onClick: (comment) => handleAddComment(comment),
    }),
  ]

  const descriptionToolbarActions: ToolbarItem[] = [
    ...toolbarActions,
    (editor) => ({
      label: 'Add to Decision',
      onClick: () => {
        finalDecisionEditableRef.current?.editor?.chain().insertContent(getSelectionHtml(editor)).run()
      },
    }),
  ]

  const commentBlocksByParent = (replyTo: string): CommentBlock[] => {
    return (commentsByParent[replyTo] ?? []).map((comment) => ({
      type: 'comment',
      id: comment.id,
      participants: participants,
      firstName: getAccountFirstName(accounts[comment.accountId]),
      comment: comment,
      activeAccount: activeAccount,
      blocks: commentBlocksByParent(comment.id),
      allowAddComments: true,
      allowAddActions: false,
      autofocus: comment.accountId === activeAccount.id && differenceInSeconds(new Date(), comment.createdAt) < 15,
      meeting: null,
      toolbar: toolbarActions,
    }))
  }

  const commenterIds: string[] = []
  const observerIds: string[] = []
  issue.participantIds?.forEach((id) => {
    switch (issue.participants?.[id]?.role) {
      case issueRoleMap.observer:
        observerIds.push(id)
        break
      case issueRoleMap.participant:
      default:
        commenterIds.push(id)
        break
    }
  })

  return (
    <Card
      title={issue.title}
      padding={inline ? 0 : 3}
      renderRight={
        <Link href={`/issues/${issue.id}/edit`}>
          <Button size="small" color="gray" data-cy="edit-issue">
            Edit Issue
          </Button>
        </Link>
      }
    >
      <FlexRow alignItems="flex-start">
        <Div style={{ flex: 1 }} right={4}>
          <Editable documentId={issue.descriptionDocId} selectionToolbar={descriptionToolbarActions} />

          <IssueVideoBox
            shareUrl={issue.video?.shareUrl}
            embedUrl={issue.video?.embedUrl}
            canDelete={issue.accountId === activeAccount.id}
            onSave={handleSaveVideo}
            onRemove={handleRemoveVideo}
          />

          <Div bottom={2}>
            <Text weight="heavy" color="dark">
              Timer
            </Text>
            {showTimer ? (
              <Div
                style={{
                  position: 'fixed',
                  top: 90,
                  left: 12,
                  fontSize: 42,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  background: 'white',
                  padding: '16px 32px',
                  border: `1px solid ${theme.color.lightGray}`,
                  borderRadius: '4px',
                }}
              >
                <Div
                  style={{
                    fontSize: 42,
                  }}
                >
                  {`${Math.floor(secondsUntil / 60)}:${secondsUntil % 60 < 10 ? '0' : ''}${secondsUntil % 60}`}
                </Div>
                <Div style={{ display: 'flex' }}>
                  <TimerControls
                    {...{
                      issue,
                      handleTimerStart,
                      handleTimerState,
                      handleTimerUnpause,
                    }}
                  />
                </Div>
              </Div>
            ) : null}
            <Div top={1} style={{ display: 'flex', alignItems: 'center' }}>
              <Select
                disabled={issue.timer?.state === 'running'}
                value={timerValue}
                onChange={(value) => setTimerValue(value)}
                style={{
                  marginRight: 8,
                }}
              >
                {[2, 5, 10, 15, 20, 30, 50].map((value) => (
                  <Select.Option value={value * 60} key={value}>{`${value} Minute${
                    value > 1 ? 's' : ''
                  }`}</Select.Option>
                ))}
              </Select>
              <Button color={'success'} size={'small'} onClick={handleTimerStart}>
                Start Timer
              </Button>
            </Div>
          </Div>

          <Div top={2} bottom={1}>
            <Text weight="heavy" color="dark">
              Comments
            </Text>
          </Div>
          {commentBlocksByParent('').map((block) => (
            <Div key={block.id} marginVertical={0.5}>
              <CommentsBlockItem {...block} allowAddVideo={true} toolbar={block.toolbar} />
            </Div>
          ))}
          <Div data-cy="issue-add-comment">
            <BlockAdd
              indent={false}
              label="Add comment"
              loading={creatingComment}
              onClick={() => handleAddComment(null)}
              color={issueStatusDecidedOrLater ? '#999' : '#ff7043'}
            />
          </Div>
          <Div top={2} bottom={1}>
            <FlexRow alignItems="center" justifyContent="space-between">
              <Text weight="heavy" color="dark">
                {issueStatusDecidedOrLater && issue.decisionMaker ? (
                  <>
                    Decision by <AccountName id={issue.decisionMaker} skipYou={true} />
                  </>
                ) : (
                  'Draft Decision'
                )}
              </Text>
              {issueStatusDecidedOrLater && (
                <Div style={{ width: 24 }}>
                  <Popover
                    placement="leftTop"
                    content={
                      <Div style={{ cursor: 'pointer' }} onClick={() => handleDecisionChange(issueDecisionMap.created)}>
                        Unsubmit Decision
                      </Div>
                    }
                    trigger="click"
                  >
                    <DotsVerticalIcon style={{ color: '#999', display: 'block', cursor: 'pointer' }} />
                  </Popover>
                </Div>
              )}
            </FlexRow>
          </Div>
          <Div
            style={{
              color: issueStatusDecidedOrLater ? '#333' : 'inherit',
            }}
          >
            <Editable
              ref={finalDecisionEditableRef}
              documentId={issue.finalDecisionDocId}
              selectionToolbar={toolbarActions}
              placeholder="No decision has been made yet"
              background={issueStatusDecidedOrLater ? 'background' : 'backgroundWhite'}
              onChange={(editor) => setDecisionIsEmpty(editor.isEmpty)}
            />
          </Div>
          {!decisionIsEmpty && issue.status === 'CREATED' && issue.decisionMaker === activeAccount.id ? (
            <Div top={1}>
              <Button
                data-cy="issue-submit-decision"
                color="primary"
                loading={editIssueMutation.isLoading}
                onClick={() => handleDecisionChange(issueDecisionMap.decided)}
              >
                Submit Decision
              </Button>
            </Div>
          ) : null}
          <Div top={2} bottom={1}>
            <Text weight="heavy" color="dark">
              Actions to implement decision
            </Text>
          </Div>
          {sortedActions.length ? (
            sortedActions.map((action) => (
              <Div left={-3} key={action.id}>
                <ActionsBlockItem
                  type="action"
                  id={action.id}
                  firstName={getAccountFirstName(accounts[action.assignedTo])}
                  activeAccount={activeAccount}
                  action={action}
                  participants={participants}
                  blocks={[]}
                  allowAddComments={false}
                  allowAddActions={false}
                  meeting={null}
                />
              </Div>
            ))
          ) : (
            <FlexRow
              alignItems="center"
              justifyContent="center"
              background="background"
              paddingVertical={3}
              rounded={true}
              bottom={1}
            >
              <Text color="light">Create actions to track progress implementing the decision.</Text>
            </FlexRow>
          )}
          <BlockAdd
            indent={false}
            label="Add action"
            loading={creatingAction}
            onClick={() => handleAddAction()}
            color={issueStatusDecidedOrLater ? '#ff7043' : '#999'}
          />
        </Div>
        <Div style={{ width: 220 }}>
          <Div top={2} bottom={1}>
            <Text weight="heavy" color="light">
              Status
            </Text>
            <Div>
              <Text>{issue.status}</Text>
            </Div>
          </Div>

          <Div top={2} bottom={1}>
            <Text weight="heavy" color="light">
              Proposer
            </Text>

            <Div>
              <AccountName id={issue.accountId} skipYou={true} />
            </Div>
          </Div>

          <Div top={2} bottom={1}>
            <Text weight="heavy" color="light">
              Proposed Date
            </Text>

            <Div>{format(issue.createdAt, 'MMMM do yyyy')}</Div>
          </Div>

          <Div top={2} bottom={1}>
            <Text weight="heavy" color="light">
              Privacy
            </Text>
            <Div>
              <Text>{issue.isPrivate ? 'Private' : 'Public'}</Text>
            </Div>
          </Div>

          <Div top={2} bottom={1}>
            <Text weight="heavy" color="light">
              Commenters Requested
            </Text>

            {commenterIds.map((id) => (
              <Div key={id}>
                <AccountName id={id} skipYou={true} />
              </Div>
            ))}
          </Div>

          <Div top={2} bottom={1}>
            <Text weight="heavy" color="light">
              Observers
            </Text>

            {observerIds.map((id) => (
              <Div key={id}>
                <AccountName id={id} skipYou={true} />
              </Div>
            ))}
          </Div>

          {issue.commentsDueDate ? (
            <Div top={2} bottom={1}>
              <Text weight="heavy" color="light">
                Comments Due
              </Text>

              <Div>{format(issue.commentsDueDate, 'MMMM do yyyy')}</Div>
            </Div>
          ) : null}

          {issue.decisionMaker ? (
            <Div top={2} bottom={1}>
              <Text weight="heavy" color="light">
                Decision Maker
              </Text>

              <Div>
                <AccountName id={issue.decisionMaker} skipYou={true} />
              </Div>
            </Div>
          ) : null}

          {issue.decisionDueDate ? (
            <Div top={2} bottom={1}>
              <Text weight="heavy" color="light">
                Decision Due
              </Text>

              <Div>{format(issue.decisionDueDate, 'MMMM do yyyy')}</Div>
            </Div>
          ) : null}
        </Div>
      </FlexRow>
    </Card>
  )
}
