import { GetResBody as GetIssuesResBody } from '../../../pages/api/issues'
import axios from 'axios'
import { QueryFunctionContext, QueryKey, useInfiniteQuery } from 'react-query'

export const inflateDates = <T>(data: T, datesToTransform: Array<keyof T>) => {
  datesToTransform &&
    datesToTransform.map((key) => {
      if (data && data[key]) {
        data[key] = (data[key] as any).toDate ? (data[key] as any).toDate() : new Date(data[key] as any)
      }
    })

  return data as T
}

const getIssues = (companyId?: string) => async ({ pageParam }: QueryFunctionContext<QueryKey, string>) => {
  const { data } = await axios.get<GetIssuesResBody>('/api/issues', {
    params: {
      after: pageParam,
      companyId,
    },
  })
  return {
    ...data,
    edges: data.edges.map(({ node }) => ({
      node: inflateDates(node, ['createdAt', 'decisionDueDate', 'commentsDueDate']),
    })),
  }
}

const useIssues = (companyId?: string) =>
  useInfiniteQuery(['issues', companyId], getIssues(companyId), {
    getNextPageParam: (lastPage) => {
      return lastPage.pageInfo.hasNextPage ? lastPage.pageInfo.endCursor : undefined
    },
  })

export default useIssues
