import { useMutation } from 'react-query'
import axios from 'axios'
import { ReqBody, ResBody } from '../../../pages/api/meetings/close'

const completeMeeting = async (meetingId: string) => {
  const { data } = await axios.post<ResBody, ReqBody>(`/api/meetings/close`, { meetingId })
  return data
}

const useCompleteMeetingMutation = () => {
  return useMutation(completeMeeting)
}

export default useCompleteMeetingMutation
