import { Account } from '@mm/backend/accounts/model'
import { GoodThing } from '@mm/backend/goodThings/model'
import { Update } from '@mm/backend/update/model'
import { Reducer } from 'redux'
import { actions as authActions, actionTypes as authActionTypes } from '../auth'
import {
  clear,
  optimisticChange,
  OptimisticChange,
  optimisticCommit,
  OptimisticCommit,
  optimisticRollback,
  OptimisticRollback,
  remove,
  removeById,
  set,
  State,
  upsert,
} from '../optimistic-redux'

export const selector = 'goodThings'

export const actionTypes = {
  CREATE_REQUEST: 'goodThings/CREATE_REQUEST',
  CREATE_SUCCESS: 'goodThings/CREATE_SUCCESS',
  CREATE_FAILURE: 'goodThings/CREATE_FAILURE',
  EDIT: 'goodThings/EDIT',
  DELETE: 'goodThings/DELETE',
  CLEAR: 'goodThings/CLEAR',
  SET: 'goodThings/SET',
  ADD: 'goodThings/ADD',
  CHANGE: 'goodThings/CHANGE',
  REMOVE: 'goodThings/REMOVE',
  LIKE: 'goodThings/LIKE',
  UNLIKE: 'goodThings/UNLIKE',

  OPTIMISTIC_CHANGE: 'goodThings/OPTIMISTIC_CHANGE',
  OPTIMISTIC_ROLLBACK: 'goodThings/OPTIMISTIC_ROLLBACK',
  OPTIMISTIC_COMMIT: 'goodThings/OPTIMISTIC_COMMIT',
} as const

type CreateRequestAction = Pick<GoodThing, 'description' | 'type' | 'meetingId' | 'attachments'> & {
  updateId?: string
}

interface CreateSuccessAction {
  goodThing: GoodThing
}

interface CreateFailureAction {
  error: Error
}

type EditAction = Partial<
  Pick<GoodThing, 'accountId' | 'description' | 'commentIds' | 'type' | 'attachments' | 'updateId'>
> &
  Pick<GoodThing, 'id'> & { optimistic?: boolean }

type DeleteAction = Pick<GoodThing, 'id'> & {
  updateId?: Update['id']
}

interface SetAction {
  goodThings: Record<string, GoodThing>
}

interface AddAction {
  goodThing: GoodThing
}

interface ChangeAction {
  goodThing: GoodThing
}

interface RemoveAction {
  goodThing: GoodThing
}

interface LikeAction {
  goodThing: GoodThing
  accountId: Account['id']
}

interface UnlikeAction {
  goodThing: GoodThing
  accountId: Account['id']
}

export const actions = {
  createRequest: ({ description, type: thingType, meetingId, updateId, attachments }: CreateRequestAction) => ({
    type: actionTypes.CREATE_REQUEST,
    description,
    thingType: thingType,
    meetingId,
    updateId,
    attachments,
  }),
  createSuccess: ({ goodThing }: CreateSuccessAction) => ({
    type: actionTypes.CREATE_SUCCESS,
    goodThing,
  }),
  createFailure: ({ error }: CreateFailureAction) => ({
    type: actionTypes.CREATE_FAILURE,
    error,
  }),
  edit: ({ id, accountId, description, type: thingType, attachments, optimistic, updateId }: EditAction) => ({
    type: actionTypes.EDIT,
    accountId,
    id,
    description,
    thingType: thingType,
    attachments,
    optimistic,
    updateId,
  }),
  delete: ({ id, updateId }: DeleteAction) => ({
    type: actionTypes.DELETE,
    id,
    updateId,
  }),
  clear: () => ({
    type: actionTypes.CLEAR,
  }),
  add: ({ goodThing }: AddAction) => ({
    type: actionTypes.ADD,
    goodThing,
  }),
  set: ({ goodThings }: SetAction) => ({
    type: actionTypes.SET,
    goodThings,
  }),
  change: ({ goodThing }: ChangeAction) => ({
    type: actionTypes.CHANGE,
    goodThing,
  }),
  remove: ({ goodThing }: RemoveAction) => ({
    type: actionTypes.REMOVE,
    goodThing,
  }),
  like: ({ goodThing, accountId }: LikeAction) => ({
    type: actionTypes.LIKE,
    goodThing,
    accountId,
  }),
  unlike: ({ goodThing, accountId }: UnlikeAction) => ({
    type: actionTypes.UNLIKE,
    goodThing,
    accountId,
  }),
  optimisticChange: (action: OptimisticChange<GoodThing>) => ({
    ...action,
    type: actionTypes.OPTIMISTIC_CHANGE,
  }),
  optimisticRollback: (action: OptimisticRollback) => ({
    ...action,
    type: actionTypes.OPTIMISTIC_ROLLBACK,
  }),
  optimisticCommit: (action: OptimisticCommit<GoodThing>) => ({
    ...action,
    type: actionTypes.OPTIMISTIC_COMMIT,
  }),
}

export type actions = ReturnType<typeof actions[keyof typeof actions]>

interface GoodThingsState extends State<GoodThing> {
  form: {
    loading: boolean
    error?: Error
  }
}

const initialState: GoodThingsState = {
  byId: {},
  optimisticStorage: {},
  form: {
    loading: false,
  },
}

export const reducer: Reducer<GoodThingsState, actions | authActions> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET: {
      return set(state, action.goodThings)
    }
    case actionTypes.ADD:
    case actionTypes.CHANGE: {
      return upsert(state, action.goodThing)
    }
    case actionTypes.REMOVE: {
      return remove(state, action.goodThing)
    }
    case actionTypes.DELETE: {
      return removeById(state, action.id)
    }
    case actionTypes.CLEAR: {
      return clear(state)
    }
    case actionTypes.CREATE_REQUEST: {
      return {
        ...state,
        form: {
          loading: true,
        },
      }
    }
    case actionTypes.CREATE_SUCCESS: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.goodThing.id]: action.goodThing,
        },
        form: {
          loading: false,
        },
      }
    }
    case actionTypes.CREATE_FAILURE: {
      return {
        ...state,
        form: {
          loading: false,
          error: action.error,
        },
      }
    }
    case actionTypes.OPTIMISTIC_CHANGE: {
      return optimisticChange(state, action)
    }
    case actionTypes.OPTIMISTIC_ROLLBACK: {
      return optimisticRollback(state, action)
    }
    case actionTypes.OPTIMISTIC_COMMIT: {
      return optimisticCommit(state, action)
    }
    case authActionTypes.LOG_OUT:
      return initialState
    default:
      return state
  }
}

export const selectors = {
  get: () => (state: Record<typeof selector, GoodThingsState>) => state[selector].byId,
  getForm: () => (state: Record<typeof selector, GoodThingsState>) => state[selector].form,
  getById: (id: string) => (state: Record<typeof selector, GoodThingsState>) => state[selector].byId[id],
}
