import { useMutation, UseMutationOptions } from 'react-query'
import axios from 'axios'
import { DeleteReqBody, DeleteResBody } from '../../../pages/api/issues/[id]/removeFromMeeting'

interface AddIssueToMeeting {
  meetingId: string
  issueId: string
}

const addIssueToMeeting = async ({ meetingId, issueId }: AddIssueToMeeting) => {
  const { data } = await axios.post<DeleteResBody, DeleteReqBody>(`/api/issues/${issueId}/removeFromMeeting`, {
    meetingId,
  })
  return data
}

const useAddIssueToMeetingMutation = (
  options?: UseMutationOptions<DeleteResBody, unknown, AddIssueToMeeting, unknown>,
) => {
  return useMutation(addIssueToMeeting, options)
}

export default useAddIssueToMeetingMutation
