import React from 'react'
import { Popover } from 'antd'
import styled from '@emotion/styled'
import { useBlockIndentContext } from '../context/BlockIndentContext'
import AccountConvertIcon from '@icons/material/AccountConvertIcon'
import AccountPlusOutlineIcon from '@icons/material/AccountPlusOutlineIcon'
import CheckboxMarkedCircleOutlineIcon from '@icons/material/CheckboxMarkedCircleOutlineIcon'
import CommentPlusOutlineIcon from '@icons/material/CommentPlusOutlineIcon'
import DeleteOutlineIcon from '@icons/material/DeleteOutlineIcon'
import PlusCircleOutlineIcon from '@icons/material/PlusCircleOutlineIcon'
import SquareEditOutlineIcon from '@icons/material/SquareEditOutlineIcon'
import UpdateIcon from '@icons/material/UpdateIcon'

const Menu = styled.div({
  background: '#fff',
  borderRadius: 4,
  border: '1px solid #ddd',
  boxShadow: '0 2px 2px rgba(0,0,0,0.05), 0 4px 8px rgba(0,0,0,0.1)',
  minWidth: 140,
  marginLeft: -10,
})

export const MenuItem = styled.div({
  padding: 5,
  display: 'flex',
  fontSize: 16,
  cursor: 'pointer',
  transition: 'color 200ms',
  color: '#666',
  marginRight: 3,

  '&:hover': {
    color: '#333',
  },

  '&:active': {
    color: '#999',
  },

  '& > *': {
    marginRight: 4,
  },
})

interface BlockMenuProps {
  children?: React.ReactNode
}

interface GenericMenuItem<Param = unknown> {
  label?: string
  onClick: (param?: Param) => void
}

const handleClick = (onClick: () => void, setPopoverVisible?: (param: boolean) => void) => {
  onClick()

  if (setPopoverVisible) {
    setPopoverVisible(false)
  }
}

export const BlockMenu: React.FC<BlockMenuProps> = ({ children }) => <Menu>{children}</Menu>

export default BlockMenu

export const MenuItemDivider = styled.div({
  borderBottom: '1px solid #ddd',
  margin: '2px 0',
})

export const EditMenuItem: React.FC<GenericMenuItem> = ({ label = 'Edit', onClick }) => {
  const { setPopoverVisible } = useBlockIndentContext()

  return (
    <MenuItem data-cy="edit-menu-item" onClick={() => handleClick(onClick, setPopoverVisible)}>
      <SquareEditOutlineIcon />
      {label}
    </MenuItem>
  )
}

export const DeleteMenuItem: React.FC<GenericMenuItem> = ({ label = 'Delete', onClick }) => {
  const { setPopoverVisible } = useBlockIndentContext()

  return (
    <MenuItem data-cy="delete-menu-item" onClick={() => handleClick(onClick, setPopoverVisible)}>
      <DeleteOutlineIcon />
      {label}
    </MenuItem>
  )
}

export const AddActionMenuItem: React.FC<GenericMenuItem> = ({ label = 'Add Action', onClick }) => {
  const { setPopoverVisible } = useBlockIndentContext()

  return (
    <MenuItem data-cy="add-action-menu-item" onClick={() => handleClick(onClick, setPopoverVisible)}>
      <PlusCircleOutlineIcon />
      {label}
    </MenuItem>
  )
}

export const AddCommentMenuItem: React.FC<GenericMenuItem> = ({ label = 'Add Comment', onClick }) => {
  const { setPopoverVisible } = useBlockIndentContext()

  return (
    <MenuItem data-cy="add-comment-menu-item" onClick={() => handleClick(onClick, setPopoverVisible)}>
      <CommentPlusOutlineIcon />
      {label}
    </MenuItem>
  )
}

export const RecurringMenuItem: React.FC<GenericMenuItem> = ({ label = 'Set as recurring', onClick }) => {
  const { setPopoverVisible } = useBlockIndentContext()

  return (
    <MenuItem data-cy="recurring-menu-item" onClick={() => handleClick(onClick, setPopoverVisible)}>
      <UpdateIcon />
      {label}
    </MenuItem>
  )
}

interface Participant {
  name: string
  id: string
}
export const ChangeParticipantsMenuItem: React.FC<GenericMenuItem<string> & { participants: Array<Participant> }> = ({
  label = 'Assign to',
  participants = [],
  onClick,
}) => {
  const [visible, setVisible] = React.useState(false)
  const { setPopoverVisible } = useBlockIndentContext()
  const handleClick = (id: string) => () => {
    onClick(id)
    setVisible(false)

    if (setPopoverVisible) {
      setPopoverVisible(false)
    }
  }
  return (
    <Popover
      placement="rightTop"
      onVisibleChange={setVisible}
      visible={visible}
      content={
        <BlockMenu>
          {participants.map(({ name, id }, i) => (
            <MenuItem data-cy="assignee-block" key={id || i} onClick={handleClick(id)}>
              {name}
            </MenuItem>
          ))}
        </BlockMenu>
      }
    >
      <MenuItem data-cy="assign-to-menu-item">
        <AccountPlusOutlineIcon />
        {label}
      </MenuItem>
    </Popover>
  )
}

export const CompleteMenuItem: React.FC<GenericMenuItem & { value: boolean }> = ({ value, onClick }) => {
  const { setPopoverVisible } = useBlockIndentContext()

  return (
    <MenuItem data-cy="discussed-menu-item" onClick={() => handleClick(onClick, setPopoverVisible)}>
      <CheckboxMarkedCircleOutlineIcon />
      {value ? 'Undo discussed' : 'Mark as discussed'}
    </MenuItem>
  )
}

export const SwapParticipantsMenuItem: React.FC<GenericMenuItem> = ({ label = 'Swap Participants', onClick }) => {
  const { setPopoverVisible } = useBlockIndentContext()
  return (
    <MenuItem data-cy="swap-participants-menu-item" onClick={() => handleClick(onClick, setPopoverVisible)}>
      <AccountConvertIcon />
      {label}
    </MenuItem>
  )
}
