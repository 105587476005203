import ChevronDownIcon from '@icons/material/ChevronDownIcon'
import { FlexRow } from '../../ui'
import React from 'react'
import BlockIndent from './BlockIndent'

export const TYPE = 'participant'

export interface Block {
  type: typeof TYPE
  id: string
  firstName: string
  showIfEmpty?: boolean
  collapsed?: boolean
  children?: React.ReactNode
}

export const ParticipantsBlockItem = ({
  firstName,
  collapsed = false,
  showIfEmpty = true,
  children,
}: Omit<Block, 'type'>) => {
  const [blocksVisible, setBlocksVisible] = React.useState(!collapsed)
  const show = showIfEmpty || React.Children.count(children) > 0

  return show ? (
    <div style={{ marginBottom: 10 }}>
      <BlockIndent>
        <div style={{ border: '1px solid #eee', borderRadius: 4, padding: 10 }}>
          <FlexRow
            style={{ cursor: 'pointer', paddingLeft: 5, color: '#666' }}
            onClick={() => setBlocksVisible(!blocksVisible)}
          >
            {firstName || 'Deleted Account'}
            <div>
              <div
                style={{
                  transition: 'transform 200ms',
                  transform: `rotate(${blocksVisible ? '0' : '-180deg'})`,
                }}
              >
                <ChevronDownIcon style={{ display: 'block' }} />
              </div>
            </div>
          </FlexRow>

          {blocksVisible ? <div style={{ marginTop: 5 }}>{children}</div> : null}
        </div>
      </BlockIndent>
    </div>
  ) : null
}

export default ParticipantsBlockItem
