import { firestore, ToClientModel, unsafeFirestoreConverter } from '../../firebase'
import { Document as BackendDocument } from '@mm/backend/editor/model'

type Document = ToClientModel<BackendDocument>

const documents = firestore.collection('documents').withConverter(unsafeFirestoreConverter<Document>())

export type Unsubscribe = () => void

const watchDocumentContent = (documentId: string, callback: (content: Uint8Array) => void): Unsubscribe => {
  const ref = documents.doc(documentId)
  return ref.onSnapshot((snapshot) => {
    const document = snapshot.data()
    if (document != null) {
      callback(document.content.toUint8Array())
    }
  })
}

export { watchDocumentContent }
