import { Account } from '@mm/backend/accounts/model'

import { Goal } from '@mm/backend/goals/model'
import { Meeting } from '@mm/backend/meetings/model'
import { actions as actionsActions } from '../../actions'
import useScopedCreateActionLoadingState from '../../actions/hooks/useScopedCreateActionLoadingState'
import { actions as goalsActions, CreateGoal, EditGoal } from '../../goals'
import { FlexRow } from '../../interface'
import { format, isPast } from 'date-fns'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Block as ActionsBlockItemType } from './ActionsBlockItem'
import BlockAdd from './BlockAdd'
import BlockIndent from './BlockIndent'
import BlockMenu, { DeleteMenuItem, EditMenuItem } from './BlockMenu'
import BlockResolver from './BlockResolver'
import { getActionDueDate } from '../utils/meetingInfoHelpers'

export const TYPE = 'goal'

export interface Block {
  type: typeof TYPE
  id: string
  goal: Goal
  activeAccount: Account
  meeting: Meeting | null
  reviewView?: boolean
  blocks: Array<Block | ActionsBlockItemType>
}

export const GoalsBlockItem = ({ goal, activeAccount, meeting, reviewView = false, blocks = [] }: Block) => {
  const dispatch = useDispatch()

  const [showNewForm, setShowNewForm] = useState(false)
  const [showEditForm, setShowEditForm] = useState(false)
  const [creatingAction, setCreatingAction] = useScopedCreateActionLoadingState()
  const isAuthor = activeAccount.id === goal.accountId

  const handleDelete = () => {
    dispatch(goalsActions.delete({ id: goal.id }))
  }

  const handleAddAction = () => {
    setCreatingAction(true)
    dispatch(
      actionsActions.createRequest({
        description: '',
        accountId: activeAccount.id,
        assignedTo: activeAccount.id,
        featureId: goal.id,
        featureType: 'goal',
        featurePreviewText: goal.description,
        meetingIds: meeting ? [meeting.id] : [],
        groupIds: meeting ? [meeting.groupId] : [],
        participantIds: meeting?.participantIds || [],
        dueAt: getActionDueDate(meeting),
      }),
    )
  }

  return (
    <div>
      <div
        style={{
          color: '#666',
          fontWeight: 'bold',
          fontSize: 14,
          textDecoration: isPast(goal.endAt) ? 'line-through' : undefined,
        }}
      >
        <BlockIndent
          menu={
            <BlockMenu>
              <EditMenuItem onClick={() => setShowEditForm(true)} />
              {isAuthor && <DeleteMenuItem onClick={handleDelete} />}
            </BlockMenu>
          }
        >
          {showEditForm ? (
            <EditGoal goal={goal} onEdit={() => setShowEditForm(false)} onCancel={() => setShowEditForm(false)} />
          ) : (
            <div style={{ padding: '8px 0' }}>
              {goal.description}
              {goal.endAtLabel ? ` (${goal.endAtLabel})` : ''}

              <span style={{ float: 'right' }}>{goal.endAt ? format(goal.endAt, 'LLL dd yyyy') : ''}</span>
            </div>
          )}
        </BlockIndent>
      </div>

      <BlockIndent>
        {blocks.map((block) => (
          <BlockResolver key={block.id} block={block} />
        ))}

        <FlexRow>
          {!showNewForm && !reviewView && (
            <>
              <BlockAdd
                label="Add action"
                loading={creatingAction}
                onClick={handleAddAction}
                disabled={!isAuthor}
                color={activeAccount.id === goal.accountId ? '#42a5f5' : '#999'}
                indent={false}
              />
              {!goal.parentId && (
                <BlockAdd
                  label={'Add Sub-goal'}
                  disabled={!isAuthor}
                  color={activeAccount.id === goal.accountId ? '#42a5f5' : '#999'}
                  onClick={() => setShowNewForm(true)}
                  indent={false}
                />
              )}
            </>
          )}
        </FlexRow>

        {showNewForm && !reviewView && (
          <CreateGoal
            accountId={goal.accountId}
            parentId={goal.id}
            endAt={goal.endAt}
            endAtLabel={goal.endAtLabel}
            onCreate={() => setShowNewForm(false)}
            onCancel={() => setShowNewForm(false)}
          />
        )}
      </BlockIndent>
    </div>
  )
}

export default GoalsBlockItem
