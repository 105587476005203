import React, { useState, useEffect } from 'react'
import * as Sentry from '@sentry/browser'
import { FallbackProps } from 'react-error-boundary'
import { Space, Typography, Alert, Button } from 'antd'
import { Div, FlexColumn } from './'
import { spacing } from './theme'

export const ErrorFallback: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  const CHARACTER_COUNT = 60
  const [showDetail, setShowDetail] = useState(false)

  useEffect(() => {
    Sentry.captureException(error)
  }, [error])

  const getErrorDescription = () => {
    if (showDetail || error.message.length <= CHARACTER_COUNT) {
      return error.message
    }

    if (error.message.length >= CHARACTER_COUNT) {
      return `${error.message.slice(0, CHARACTER_COUNT)}...`
    }

    return error.message
  }

  return (
    <Div>
      <FlexColumn
        alignItems="center"
        justifyContent="center"
        style={{ height: 'auto', minHeight: '92vh', maxWidth: '500px', margin: '0 auto' }}
      >
        <Typography.Title>Oops...</Typography.Title>
        <Typography.Title style={{ marginTop: 0, marginBottom: `${spacing(2)}px` }} level={3}>
          There was a problem loading the page.
        </Typography.Title>
        {error?.message && (
          <Alert
            style={{ width: '100%' }}
            message="Error"
            showIcon
            description={getErrorDescription()}
            type="error"
            action={
              <Space direction="vertical">
                {error.message.length >= CHARACTER_COUNT && (
                  <Button size="small" danger onClick={() => setShowDetail(!showDetail)}>
                    {showDetail ? 'Hide Detail' : 'Detail'}
                  </Button>
                )}
                <Button size="small" type="ghost" onClick={() => resetErrorBoundary()}>
                  Reset
                </Button>
              </Space>
            }
          />
        )}
      </FlexColumn>
    </Div>
  )
}
