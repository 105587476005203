import { useMutation, useQuery, useSubscription } from '@apollo/client'
import PencilCircleOutlineIcon from '@icons/material/PencilCircleOutlineIcon'
import { Meeting } from '@mm/backend/meetings'
import { Alert, Button, notification, Spin } from 'antd'
import arrayMove from 'array-move'
import _ from 'lodash'
import React, { useCallback } from 'react'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { useDispatch, useSelector } from 'react-redux'
import { useFeatureFlags } from '../../featureFlags'
import { FlexColumn } from '../../interface'
import { actions as topicsActions, selectors as topicsSelectors } from '../../topics'
import { FocusColorWrap } from '../../ui'
import { actions as meetingActions } from '../store'
import BlockAdd from './BlockAdd'
import BlockEmpty from './BlockEmpty'
import BlockResolver from './BlockResolver'
import BlockTitle from './BlockTitle'
import {
  TopicBlockSubscriptionDocument,
  TopicsBlockMoveTopicDocument,
  TopicsBlockQueryDocument,
} from '../../../gen/graphql/documents'
import { Block as TopicsBlockItemType } from './TopicsBlockItem'
import { Div } from '../../interface'

export const TYPE = 'topics'
const color = '#66bb6a'

export interface Block {
  type: typeof TYPE
  id: typeof TYPE
  title: string
  subtitle: string
  meeting?: Meeting | null
  blocks: Array<TopicsBlockItemType>
  updateId?: string
  reviewView?: boolean
}

const TopicsBlockWrapper: React.FC<Block> = ({ id, updateId, meeting, children }) => {
  const modal = {
    title: 'About the Topics section',
    youtubeId: 'U0yllw4_Usc',
    content: (
      <div>
        <p>List any topics you would like to discuss during the meeting.</p>
      </div>
    ),
  }
  return (
    <FocusColorWrap color={color}>
      <BlockTitle
        id={`${id}-${updateId || meeting?.id}`}
        title="Topics"
        subtitle="List any topics you would like to discuss during the meeting."
        color={color}
        icon={<PencilCircleOutlineIcon />}
        modal={modal}
        content={children}
      />
    </FocusColorWrap>
  )
}

const TopicsBlockNew = (block: Block) => {
  const { blocks = [], updateId, reviewView = false } = block
  const meetingId = block.meeting?.id
  const dispatch = useDispatch()
  const form = useSelector(topicsSelectors.getForm())
  const { data, loading, refetch } = useQuery(TopicsBlockQueryDocument, {
    skip: !meetingId,
    variables: { meetingId },
    notifyOnNetworkStatusChange: true,
  })
  useSubscription(TopicBlockSubscriptionDocument, {
    skip: !meetingId,
    variables: { meetingId },
  })
  const [moveTopic] = useMutation(TopicsBlockMoveTopicDocument, {
    onCompleted: (data) => {
      if (data.moveMeetingTopic.__typename !== 'Meeting') {
        console.error('Failed to move meeting topic:', data.moveMeetingTopic.message)
        notification.error({
          message: 'Failed to move topic',
        })
      }
    },
    onError: () => {
      notification.error({
        message: 'Failed to move topic',
      })
    },
  })

  const meeting = data?.meeting

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination || !meeting) return
      const startIndex = result.source.index
      const endIndex = result.destination.index
      if (startIndex !== endIndex) {
        moveTopic({
          variables: {
            meetingId: meeting.id,
            topicId: meeting.topics[startIndex].id,
            targetIndex: endIndex,
          },
          optimisticResponse: {
            moveMeetingTopic: {
              __typename: 'Meeting',
              id: meeting.id,
              topics: arrayMove(meeting.topics, startIndex, endIndex),
            },
          },
        }).catch()
      }
    },
    [meeting, moveTopic],
  )

  const handleAdd = useCallback(() => {
    const effectiveMeetingId = updateId ? '' : meetingId || null
    if (effectiveMeetingId != null) {
      dispatch(
        topicsActions.createRequest({
          description: '',
          meetingId: effectiveMeetingId,
          attachments: [],
          updateId: updateId,
        }),
      )
    }
  }, [updateId, meetingId])

  if (loading || (meetingId && meeting == null)) {
    return (
      <TopicsBlockWrapper {...block}>
        {loading ? (
          <FlexColumn paddingVertical={2} alignItems="center">
            <Spin size="large" />
          </FlexColumn>
        ) : (
          <Alert
            message="Connection error."
            type="error"
            showIcon
            description="For some reason, topics failed to load. It's not your fault. Wait a moment, and try again."
            action={
              <Button
                size="small"
                danger
                onClick={() => {
                  refetch().catch()
                }}
              >
                Retry
              </Button>
            }
          />
        )}
      </TopicsBlockWrapper>
    )
  }

  const blockById = Object.fromEntries(blocks.map((block) => [block.id, block]))

  const effectiveBlocks = meeting?.topics.flatMap((topic) => _.compact([blockById[topic.id]])) ?? blocks

  return (
    <TopicsBlockWrapper {...block}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {effectiveBlocks.map((block, index) => (
                <Draggable key={block.id} draggableId={block.id} index={index} isDragDisabled={!meeting}>
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.draggableProps}>
                      <BlockResolver block={block} dragHandleProps={!meeting ? undefined : provided.dragHandleProps} />
                    </div>
                  )}
                </Draggable>
              ))}

              {!effectiveBlocks.length && <BlockEmpty label="No topics yet." />}

              {provided.placeholder}
            </div>
          )}
        </Droppable>

        {!reviewView && (
          <Div data-cy="topics-block-add">
            <BlockAdd disabled={form.loading} loading={form.loading} label="Add a topic" onClick={handleAdd} />
          </Div>
        )}
      </DragDropContext>
    </TopicsBlockWrapper>
  )
}

const TopicsBlockOld = ({ id, title, subtitle, meeting, blocks = [], updateId, reviewView = false }: Block) => {
  const dispatch = useDispatch()
  const form = useSelector(topicsSelectors.getForm())
  const modal = {
    title: 'About the Topics section',
    youtubeId: 'U0yllw4_Usc',
    content: (
      <div>
        <p>List any topics you would like to discuss during the meeting.</p>
      </div>
    ),
  }

  const handleAdd = () => {
    const effectiveMeetingId = updateId ? '' : meeting?.id || null
    if (effectiveMeetingId != null) {
      dispatch(
        topicsActions.createRequest({
          description: '',
          meetingId: effectiveMeetingId,
          attachments: [],
          updateId: updateId,
        }),
      )
    }
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination || !meeting) return
    const startIndex = result.source.index
    const endIndex = result.destination.index
    if (startIndex !== endIndex && meeting.orderedTopicIds) {
      const orderedTopicIds = arrayMove(meeting.orderedTopicIds, startIndex, endIndex)
      dispatch(meetingActions.updateRequest({ meeting: { ...meeting, orderedTopicIds } }))
    }
  }

  return (
    <FocusColorWrap color={color}>
      <BlockTitle
        id={`${id}-${updateId || meeting?.id}`}
        title={title}
        subtitle={subtitle}
        color={color}
        icon={<PencilCircleOutlineIcon />}
        modal={modal}
        content={
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {blocks.length ? (
                    blocks.map((block, index) => (
                      <Draggable key={block.id} draggableId={block.id} index={index}>
                        {(provided) => (
                          <div ref={provided.innerRef} {...provided.draggableProps}>
                            <BlockResolver key={block.id} block={block} dragHandleProps={provided.dragHandleProps} />
                          </div>
                        )}
                      </Draggable>
                    ))
                  ) : (
                    <BlockEmpty label="No topics yet." />
                  )}

                  {provided.placeholder}
                </div>
              )}
            </Droppable>

            {!reviewView && (
              <Div data-cy="topics-block-add">
                <BlockAdd disabled={form.loading} loading={form.loading} label="Add a topic" onClick={handleAdd} />
              </Div>
            )}
          </DragDropContext>
        }
      />
    </FocusColorWrap>
  )
}

export const TopicsBlock = (block: Block) => {
  const { topicsGraphqlRearrangement = false } = useFeatureFlags()
  return topicsGraphqlRearrangement ? <TopicsBlockNew {...block} /> : <TopicsBlockOld {...block} />
}
