import React from 'react'
import { actions, selectors } from '../'
import { useDispatch, useSelector } from 'react-redux'
import { Editable } from '../../editor'
import { Controller, useForm } from 'react-hook-form'
import { Button } from '../../interface'

const stripHtml = (string: string) => string.replace(/(<([^>]+)>)|(&.*?;)/gi, '')

export const CompanySettingsProfileTab = () => {
  const dispatch = useDispatch()
  const activeCompanyId = useSelector(selectors.getActiveCompanyId())
  const companies = useSelector(selectors.getCompanies())
  const form = useSelector(selectors.getForm())
  const activeCompany = companies[activeCompanyId] || {}

  const { control, handleSubmit } = useForm({
    defaultValues: {
      name: activeCompany.name,
      bio: activeCompany.bio,
    },
  })

  const onSubmit = handleSubmit((data) => {
    dispatch(
      actions.updateRequest({
        company: {
          id: activeCompanyId,
          name: stripHtml(data.name),
          bio: data.bio,
        },
      }),
    )
  })

  return (
    <form onSubmit={onSubmit}>
      <div>
        <Controller
          control={control}
          name="name"
          render={({ field }) => (
            <Editable
              selectionToolbar={false}
              prefix={<strong style={{ marginLeft: 4 }}>Name:</strong>}
              initialValue={field.value}
              onChangeHtml={field.onChange}
              onBlur={field.onBlur}
            />
          )}
        />

        <div style={{ height: 20 }} />

        <Controller
          control={control}
          name="bio"
          render={({ field }) => (
            <Editable
              selectionToolbar={false}
              prefix={<strong style={{ marginLeft: 4 }}>Company Info:</strong>}
              initialValue={field.value}
              onChangeHtml={field.onChange}
              onBlur={field.onBlur}
            />
          )}
        />

        <div style={{ height: 20 }} />

        <Button type="submit" color="primary" loading={form.loading ?? false}>
          Save
        </Button>
      </div>
    </form>
  )
}

export default CompanySettingsProfileTab
