import { Middleware } from 'redux'
import * as Sentry from '@sentry/node'

import { actions as accountsActions, actionTypes as accountsActionTypes } from '../accounts'
import { actions as companiesActions, actionTypes as companiesActionTypes } from '../companies'

const middleware: Middleware = () => {
  return (next) => async (action: accountsActions | companiesActions) => {
    next(action)

    Sentry.addBreadcrumb({
      category: 'redux',
      message: action.type,
      data: action,
      level: Sentry.Severity.Info,
    })

    switch (action.type) {
      case accountsActionTypes.SET_ACTIVE: {
        Sentry.setTag('accountId', action.account.id)
        Sentry.setUser({ id: action.account.id, email: action.account.email })
        break
      }
      case companiesActionTypes.SET_ACTIVE: {
        Sentry.setTag('companyId', action.companyId)
        break
      }
      default:
        break
    }
  }
}

export default middleware
