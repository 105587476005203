import { Middleware } from 'redux'
import { actions, actionTypes } from './store'
import {
  selectors as accountSelectors,
  actionTypes as accountActionTypes,
  actions as accountActions,
} from '../accounts'
import { actionTypes as authActionTypes, actions as authActions } from '../auth'
import {
  selectors as companySelectors,
  actionTypes as companyActionTypes,
  actions as companyActions,
} from '../companies'
import { CompanyIntegrationTypes, AccountIntegrationTypes, IntegrationStatus } from '@mm/backend/integrations/model'
import {
  ReqBody as SetCalendarTokenReqBody,
  ResBody as SetCalendarTokenResBody,
} from '../../pages/api/integrations/googleCalendar/setRefreshToken'
import {
  ReqBody as GetCalendarRedirectUrlReqBody,
  ResBody as GetCalendarRedirectUrlResBody,
} from '../../pages/api/integrations/googleCalendar/getRedirectUrl'
import {
  ReqBody as DisableAndNotifyReqBody,
  ResBody as DisableAndNotifyResBody,
} from '../../pages/api/integrations/disconnect'
import {
  ReqBody as GetAsanaRedirectUrlReqBody,
  ResBody as GetAsanaRedirectUrlResBody,
} from '../../pages/api/integrations/asana/getRedirectUrl'
import {
  ReqBody as SetAsanaTokenReqBody,
  ResBody as SetAsanaTokenResBody,
} from '../../pages/api/integrations/asana/setRefreshToken'
import { ReqBody as SetupAsanaReqBody, ResBody as SetupAsanaResBody } from '../../pages/api/integrations/asana/setup'
import {
  ReqBody as MondayConnectReqBody,
  ResBody as MondayConnectResBody,
} from '../../pages/api/integrations/monday/connect'
import axios from 'axios'
import { timestampToDate } from '@mm/backend/firebase/helpers'

const middleware: Middleware = ({ dispatch, getState }) => {
  return (next) => async (action: actions | authActions | accountActions | companyActions) => {
    next(action)

    switch (action.type) {
      case accountActionTypes.SET_ACTIVE: {
        const state = getState()
        const account = accountSelectors.getActiveAccount()(state)
        // Set integrations
        if (account && 'integratedTo' in account) {
          dispatch(actions.loadAccountIntegrations({ integratedTo: account.integratedTo }))
        }
        // Set up userflow.com
        if (window && typeof window !== 'undefined') {
          try {
            // eslint-disable-next-line @typescript-eslint/no-var-requires
            const userflow = require('userflow.js').default
            userflow.init(process.env.NEXT_PUBLIC_USERFLOW_KEY)
            userflow.identify(account.id, {
              name: account.name,
              email: account.email,
              signed_up_at: timestampToDate(account.createdAt).toISOString(),
            })
          } catch (error) {
            console.warn(error)
          }
        }
        break
      }
      case authActionTypes.LOG_OUT: {
        // Remove userflow.com
        if (window && typeof window !== 'undefined') {
          try {
            // eslint-disable-next-line @typescript-eslint/no-var-requires
            const userflow = require('userflow.js').default
            userflow.reset()
          } catch (error) {
            console.warn(error)
          }
        }
        break
      }
      case companyActionTypes.SET_ACTIVE: {
        const state = getState()
        const company = companySelectors.getActiveCompany()(state)
        // Set integrations
        if (company && 'integratedTo' in company) {
          dispatch(actions.loadCompanyIntegrations({ integratedTo: company.integratedTo }))
        }
        break
      }
      case actionTypes.INTEGRATION_SUCESS: {
        // Reset local storage
        localStorage.setItem('redirectTo', '/dashboard')
        localStorage.setItem('integrationType', '')
        break
      }
      case actionTypes.DISCONNECT: {
        try {
          dispatch(actions.integrationStarts({ name: action.name }))
          const state = getState()
          const account = accountSelectors.getActiveAccount()(state)
          const company = companySelectors.getActiveCompany()(state)
          const res = await axios.post<DisableAndNotifyResBody, DisableAndNotifyReqBody>(
            `/api/integrations/disconnect`,
            {
              name: action.name,
              userId: account.id,
              companyId: company.id,
            },
          )
          const { errorMessage } = res.data
          if (!errorMessage) {
            dispatch(
              actions.integrationSuccess({
                name: action.name,
                status: IntegrationStatus.NOT_CONNECTED,
              }),
            )
          } else {
            dispatch(actions.integrationFail({ name: action.name, errorMessage }))
          }
        } catch (error) {
          console.log(error)
          dispatch(
            actions.integrationFail({ name: action.name, errorMessage: 'Failure in disconnecting this integration' }),
          )
        }
        break
      }
      case actionTypes.CONNECT_CALENDAR: {
        try {
          dispatch(actions.integrationStarts({ name: AccountIntegrationTypes.GOOGLE_CALENDAR }))
          const state = getState()
          const account = accountSelectors.getActiveAccount()(state)
          if (!account) {
            dispatch(
              actions.integrationFail({
                name: AccountIntegrationTypes.GOOGLE_CALENDAR,
                errorMessage: 'You must be logged in.',
              }),
            )
            return
          }
          // Save integration information on localStorage to use it in integrations/redirect
          localStorage.setItem('userId', account.id)
          localStorage.setItem('redirectTo', '/integrations')
          localStorage.setItem('integrationType', AccountIntegrationTypes.GOOGLE_CALENDAR)

          // Get url for the google auth and redirect
          const res = await axios.post<GetCalendarRedirectUrlResBody, GetCalendarRedirectUrlReqBody>(
            `/api/integrations/googleCalendar/getRedirectUrl`,
          )
          const { redirectUrl } = res.data
          if (typeof redirectUrl === 'string') {
            return window.open(redirectUrl, '_self')
          } else
            dispatch(
              actions.integrationFail({
                name: AccountIntegrationTypes.GOOGLE_CALENDAR,
                errorMessage: 'Invalid redirect url.',
              }),
            )
        } catch (error) {
          console.log(error)
          dispatch(
            actions.integrationFail({ name: AccountIntegrationTypes.GOOGLE_CALENDAR, errorMessage: 'Invalid token' }),
          )
        }
        break
      }
      case actionTypes.CREATE_CALENDAR_TOKEN: {
        try {
          // Get refresh token and save to db
          await axios.post<SetCalendarTokenResBody, SetCalendarTokenReqBody>(
            `/api/integrations/googleCalendar/setRefreshToken`,
            {
              userId: action.userId,
              authCode: action.authCode,
            },
          )
          // Redirect back to
          window.open(`${action.redirectTo}`, '_self')
          dispatch(
            actions.integrationSuccess({
              name: AccountIntegrationTypes.GOOGLE_CALENDAR,
              status: IntegrationStatus.CONNECTED,
            }),
          )
        } catch (error) {
          console.log(error)
          window.open(`${action.redirectTo}`, '_self')
          dispatch(
            actions.integrationFail({ name: AccountIntegrationTypes.GOOGLE_CALENDAR, errorMessage: 'Invalid token.' }),
          )
        }
        break
      }
      case actionTypes.CONNECT_ASANA: {
        try {
          dispatch(actions.integrationStarts({ name: AccountIntegrationTypes.ASANA }))
          const state = getState()
          const account = accountSelectors.getActiveAccount()(state)
          if (!account) {
            dispatch(
              actions.integrationFail({ name: AccountIntegrationTypes.ASANA, errorMessage: 'You must be logged in.' }),
            )
            return
          }
          // Save integration information on localStorage to use it in integrations/redirect
          localStorage.setItem('userId', account.id)
          localStorage.setItem('redirectTo', '/integrations')
          localStorage.setItem('integrationType', AccountIntegrationTypes.ASANA)

          // Get url for the google auth and redirect
          const res = await axios.post<GetAsanaRedirectUrlResBody, GetAsanaRedirectUrlReqBody>(
            `/api/integrations/asana/getRedirectUrl`,
          )
          const { redirectUrl } = res.data

          if (typeof redirectUrl === 'string') {
            return window.open(redirectUrl, '_self')
          } else
            dispatch(
              actions.integrationFail({ name: AccountIntegrationTypes.ASANA, errorMessage: 'Invalid redirect url.' }),
            )
        } catch (error) {
          console.log(error)
          dispatch(actions.integrationFail({ name: AccountIntegrationTypes.ASANA, errorMessage: 'Invalid token' }))
        }
        break
      }
      case actionTypes.CREATE_ASANA_TOKEN: {
        try {
          // Get refresh token and save to db
          await axios.post<SetAsanaTokenResBody, SetAsanaTokenReqBody>(`/api/integrations/asana/setRefreshToken`, {
            userId: action.userId,
            authCode: action.authCode,
          })

          // Redirect back to
          window.open(`${action.redirectTo}?asana=setup-required`, '_self')
          dispatch(
            actions.integrationSuccess({
              name: AccountIntegrationTypes.ASANA,
              status: IntegrationStatus.SETUP_REQUIRED,
            }),
          )
        } catch (error) {
          console.log(error)
          window.open(`${action.redirectTo}?asana=setup-required`, '_self')
          dispatch(actions.integrationFail({ name: AccountIntegrationTypes.ASANA, errorMessage: 'Invalid token.' }))
        }
        break
      }
      case actionTypes.SETUP_ASANA: {
        try {
          dispatch(actions.integrationStarts({ name: AccountIntegrationTypes.ASANA }))

          const res = await axios.post<SetupAsanaResBody, SetupAsanaReqBody>(`/api/integrations/asana/setup`, {
            userId: action.userId,
            workspaceId: action.workspaceId,
            projectCreateType: action.projectCreateType,
            projectName: action.projectName,
            projectId: action.projectId,
          })
          const { errorMessage } = res.data

          if (!errorMessage) {
            dispatch(
              actions.integrationSuccess({ name: AccountIntegrationTypes.ASANA, status: IntegrationStatus.CONNECTED }),
            )
          } else {
            dispatch(actions.integrationFail({ name: AccountIntegrationTypes.ASANA, errorMessage }))
          }
        } catch (error) {
          console.log(error)
          dispatch(actions.integrationFail({ name: AccountIntegrationTypes.ASANA, errorMessage: 'Invalid token.' }))
        }
        break
      }
      case actionTypes.CONNECT_MONDAY: {
        try {
          dispatch(actions.integrationStarts({ name: CompanyIntegrationTypes.MONDAY }))
          const state = getState()
          const company = companySelectors.getActiveCompany()(state)

          const res = await axios.post<MondayConnectResBody, MondayConnectReqBody>(`api/integrations/monday/connect`, {
            apiKey: action.apiKey,
            companyId: company.id,
            boardId: action.boardId,
            boardName: action.boardName,
            boardCreateType: action.boardCreateType,
            groupId: action.groupId,
            groupName: action.groupName,
            groupCreateType: action.groupCreateType,
          })
          const { errorMessage } = res.data

          if (!errorMessage) {
            dispatch(
              actions.integrationSuccess({ name: CompanyIntegrationTypes.MONDAY, status: IntegrationStatus.CONNECTED }),
            )
          } else {
            dispatch(actions.integrationFail({ name: CompanyIntegrationTypes.MONDAY, errorMessage }))
          }
        } catch (error) {
          if (!(error instanceof Error)) return
          console.log(error)
          dispatch(actions.integrationFail({ name: CompanyIntegrationTypes.MONDAY, errorMessage: error.message }))
        }
        break
      }
      default:
        break
    }
  }
}

export default middleware
