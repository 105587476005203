import { Reducer } from 'redux'
import { ReqBody as CreateAccountReq } from '../../pages/api/admin/createAccount'
import { actionTypes as authActionTypes, actions as authActions } from '../auth'
import { Meeting } from '@mm/backend/meetings'

export const selector = 'admin'

export const actionTypes = {
  ADMIN_START: 'admin/ADMIN_START' as 'admin/ADMIN_START',
  ADMIN_SUCCESSFULL: 'admin/ADMIN_SUCCESSFULL' as 'admin/ADMIN_SUCCESSFULL',
  ADMIN_FAIL: 'admin/ADMIN_FAIL' as 'admin/ADMIN_FAIL',
  LOGIN_ADMIN: 'admin/LOGIN_ADMIN' as 'admin/LOGIN_ADMIN',
  CREATE_ACCOUNT: 'admin/CREATE_ACCOUNT' as 'admin/CREATE_ACCOUNT',
  FETCH_MEETINGS: 'admin/FETCH_MEETINGS' as 'admin/FETCH_MEETINGS',
  SET_MEETINGS: 'admin/SET_MEETINGS' as 'admin/SET_MEETINGS',
}

interface LoginAdmin {
  adminKey: string
}
interface AdminSuccessful {
  adminKey: string
  message: string
}
interface AdminFail {
  message: string
}

export const actions = {
  adminStart: () => ({
    type: actionTypes.ADMIN_START,
  }),
  adminSuccessful: ({ adminKey, message }: AdminSuccessful) => ({
    type: actionTypes.ADMIN_SUCCESSFULL,
    adminKey,
    message,
  }),
  adminFail: ({ message }: AdminFail) => ({
    type: actionTypes.ADMIN_FAIL,
    message,
  }),
  loginAdmin: ({ adminKey }: LoginAdmin) => ({
    type: actionTypes.LOGIN_ADMIN,
    adminKey,
  }),
  createAccount: (data: CreateAccountReq) => ({
    type: actionTypes.CREATE_ACCOUNT,
    data,
  }),
  fetchMeetings: () => ({
    type: actionTypes.FETCH_MEETINGS,
  }),
  setMeetings: (data: Meeting[]) => ({
    type: actionTypes.SET_MEETINGS,
    data,
  }),
}

export type actions = ReturnType<typeof actions[keyof typeof actions]>

interface State {
  loading: boolean
  adminKey: string
  error: boolean
  message: string
  meetingsState: {
    initialized: boolean
    loading: boolean
    meetings: Meeting[]
  }
}

const initialState: State = {
  loading: false,
  error: false,
  message: '',
  adminKey: '',
  meetingsState: {
    initialized: false,
    loading: false,
    meetings: [],
  },
}

export const reducer: Reducer<State, actions | authActions> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADMIN_START:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.ADMIN_SUCCESSFULL:
      return {
        ...state,
        loading: false,
        error: false,
        message: action.message,
        adminKey: action.adminKey,
      }
    case actionTypes.ADMIN_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.message,
      }
    case authActionTypes.LOG_OUT:
      return initialState
    case actionTypes.FETCH_MEETINGS:
      return {
        ...state,
        meetingsState: {
          ...state.meetingsState,
          initialized: true,
          loading: true,
        },
      }
    case actionTypes.SET_MEETINGS:
      return {
        ...state,
        meetingsState: {
          initialized: true,
          loading: false,
          meetings: action.data,
        },
      }
    default:
      return state
  }
}

export const selectors = {
  isLoading: () => (state: Record<typeof selector, State>) => state[selector].loading,
  getError: () => (state: Record<typeof selector, State>) => state[selector].error,
  getMessage: () => (state: Record<typeof selector, State>) => state[selector].message,
  getAdminKey: () => (state: Record<typeof selector, State>) => state[selector].adminKey,
  getMeetingsState: () => (state: Record<typeof selector, State>) => state[selector].meetingsState,
}
