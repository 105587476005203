import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import Link from '@tiptap/extension-link'
import Placeholder from '@tiptap/extension-placeholder'
import { Extension } from '@tiptap/core'
import Paragraph from '@tiptap/extension-paragraph'
import { Plugin } from 'prosemirror-state'
import { Decoration, DecorationSet } from 'prosemirror-view'

export type MocharyMethodKitOptions = {
  history: boolean
  placeholder: string
}

const CustomParagraph = Paragraph.extend({
  addAttributes() {
    return {
      placeholder: {
        default: null,
        parseHTML: (element) => ({
          placeholder: element.getAttribute('data-placeholder'),
        }),
        renderHTML: (attributes) => ({
          'data-placeholder': attributes.placeholder,
        }),
        keepOnSplit: false,
      },
    }
  },
  addProseMirrorPlugins() {
    return [
      new Plugin({
        props: {
          decorations: ({ doc }) => {
            const decorations: Decoration[] = []

            doc.descendants((node, pos) => {
              if (node.type.isBlock && node.childCount === 0) {
                decorations.push(
                  Decoration.node(pos, pos + node.nodeSize, {
                    class: 'is-empty',
                  }),
                )
              }
            })

            return DecorationSet.create(doc, decorations)
          },
        },
      }),
    ]
  },
})

export default Extension.create<MocharyMethodKitOptions>({
  name: 'mochary-method',
  defaultOptions: {
    history: true,
    placeholder: '',
  },
  addExtensions() {
    return [
      StarterKit.configure({
        paragraph: false,
        history: this.options.history ? {} : false,
      }),
      CustomParagraph,
      Underline,
      Link,
      Placeholder.configure({
        placeholder: this.options.placeholder,
      }),
    ]
  },
})
