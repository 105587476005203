import React, { Dispatch, SetStateAction } from 'react'
import _ from 'lodash'
import styled from '@emotion/styled'
import { ReactQuill } from '../../ui'
import { theme, Flex } from '../../interface'

let Quill: any // eslint-disable-line

interface Props {
  prefix?: JSX.Element
  editable?: boolean
  placeholder?: string
  html: string
  padding?: number
  focusOnNew?: boolean
  showToolbar?: boolean
  onChange: (html: string) => void
  onAddComment?: () => void
  onAddAction?: (selection?: string) => void
  onDelete?: () => void
  setIsOpened?: (isOpened: boolean) => void
  isOpened?: boolean
  hasChildBlocks?: boolean
  style?: React.CSSProperties
  completed?: boolean
  innerStyle?: React.CSSProperties
  setParentQuill?: Dispatch<SetStateAction<typeof Quill | undefined>>
}

const QuillPadding = styled.div(
  ({ padding = 5, completed, styles = {} }: { padding: number; completed?: boolean; styles?: any }) => ({
    '& .ql-editor': {
      padding,
      fontSize: 16,
      lineHeight: 1.5,
      overflowY: 'visible',
      color: theme.font.color.dark,

      overflowWrap: 'anywhere',
      // fallback to deprecated break-word in case overflow-wrap: anywhere is not supported (eg Safari)
      wordBreak: 'break-word',

      ...(completed
        ? {
            color: theme.font.color.light,
            textDecoration: 'line-through',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 1 /* number of lines to show */,
            WebkitBoxOrient: 'vertical',
          }
        : {}),

      ...styles,
    },
  }),
)

export const Editable: React.FC<Props> = ({
  prefix,
  html,
  padding = 5,
  focusOnNew = false,
  showToolbar = true,
  editable = true,
  isOpened = false,
  hasChildBlocks = false,
  placeholder,
  onChange,
  onAddComment,
  onAddAction,
  onDelete,
  style,
  completed,
  innerStyle,
  setParentQuill,
  setIsOpened,
}) => {
  const debouncedChange = React.useCallback(_.throttle(onChange, 1000), [])
  const [highLightedContent, setHighLightedContent] = React.useState<string>('')
  const toolbarActions = [['bold', 'italic', 'underline', 'link']]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const toolbarHandlers: { comment?: any; action?: any } = {}

  if (onAddComment) {
    toolbarActions.push(['comment'])
    toolbarHandlers.comment = onAddComment
  }

  if (onAddAction) {
    toolbarActions.push(['action'])
    toolbarHandlers.action = () => onAddAction(highLightedContent)
  }

  if (!completed && isOpened && setIsOpened) {
    setIsOpened(false)
  }

  const doesStringOverflow = (html: string) => {
    const cleanedString = html.replace(/(<([^>]+)>)/gi, '')
    const spansMultipleLines = html.includes('</p><p>')

    if (cleanedString.length >= 60 || spansMultipleLines) {
      return true
    }
  }

  return (
    <div className="text-editor">
      <div
        style={{ float: 'left', marginRight: 5, marginTop: padding, marginLeft: 5, position: 'relative', zIndex: 2 }}
      >
        {prefix}
      </div>
      <QuillPadding padding={padding} completed={completed && !isOpened} styles={innerStyle}>
        <Flex alignItems="flex-end" justifyContent="space-between" direction="row">
          <ReactQuill
            value={html}
            className="ant-comment-content-detail"
            placeholder={placeholder}
            editable={editable}
            style={style}
            tabIndex={1}
            toolbarActions={showToolbar ? toolbarActions : undefined}
            toolbarHandlers={toolbarHandlers}
            focusOnNew={focusOnNew}
            onChange={debouncedChange}
            onDeleteWhenEmpty={onDelete}
            setHighLightedContent={setHighLightedContent}
            setParentQuill={setParentQuill}
          />
          {setIsOpened && completed && (doesStringOverflow(html) || hasChildBlocks) && (
            <a
              onClick={() => setIsOpened(!isOpened)}
              className={'link'}
              style={{
                flexShrink: 0,
                flexBasis: '85px',
                position: 'relative',
                paddingBottom: '5px',
                fontSize: theme.font.size.small,
              }}
            >
              {!isOpened ? 'Show More' : 'Show Less'}
            </a>
          )}
        </Flex>
      </QuillPadding>
    </div>
  )
}

export default Editable
