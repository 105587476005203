import { Reducer } from 'redux'
import { Company } from '@mm/backend/companies/model'
import { actionTypes as authActionTypes, actions as authActions } from '../auth'

export const selector = 'companies'

export const actionTypes = {
  CREATE_REQUEST: 'companies/CREATE_REQUEST' as 'companies/CREATE_REQUEST',
  CREATE_SUCCESS: 'companies/CREATE_SUCCESS' as 'companies/CREATE_SUCCESS',
  CREATE_FAILURE: 'companies/CREATE_FAILURE' as 'companies/CREATE_FAILURE',
  UPDATE_REQUEST: 'companies/UPDATE_REQUEST' as 'companies/UPDATE_REQUEST',
  UPDATE_SUCCESS: 'companies/UPDATE_SUCCESS' as 'companies/UPDATE_SUCCESS',
  UPDATE_FAILURE: 'companies/UPDATE_FAILURE' as 'companies/UPDATE_FAILURE',
  SET: 'companies/SET' as 'companies/SET',
  SET_ACTIVE: 'companies/SET_ACTIVE' as 'companies/SET_ACTIVE',
}

interface CreateAction {
  name: Company['name']
  bio: Company['bio']
  userId: string
}

interface CreateSuccessAction {
  company: Company
}

interface CreateFailureAction {
  error: Error
}

interface UpdateRequestAction {
  company: Partial<Pick<Company, 'name' | 'bio' | 'members' | 'integratedTo'>> & Pick<Company, 'id'>
}

interface UpdateSuccessAction {
  company: Company
}

interface UpdateFailureAction {
  error: Error
}

interface SetAction {
  companies: Array<Company>
}

interface SetActiveAction {
  companyId: string
}

export const actions = {
  createRequest: ({ name, bio, userId }: CreateAction) => ({
    type: actionTypes.CREATE_REQUEST,
    name,
    bio,
    userId,
  }),
  createSuccess: ({ company }: CreateSuccessAction) => ({
    type: actionTypes.CREATE_SUCCESS,
    company,
  }),
  createFailure: ({ error }: CreateFailureAction) => ({
    type: actionTypes.CREATE_FAILURE,
    error,
  }),
  updateRequest: ({ company }: UpdateRequestAction) => ({
    type: actionTypes.UPDATE_REQUEST,
    company,
  }),
  updateSuccess: ({ company }: UpdateSuccessAction) => ({
    type: actionTypes.UPDATE_SUCCESS,
    company,
  }),
  updateFailure: ({ error }: UpdateFailureAction) => ({
    type: actionTypes.UPDATE_FAILURE,
    error,
  }),
  set: ({ companies }: SetAction) => ({
    type: actionTypes.SET,
    companies,
  }),
  setActive: ({ companyId }: SetActiveAction) => ({
    type: actionTypes.SET_ACTIVE,
    companyId,
  }),
}

export type actions = ReturnType<typeof actions[keyof typeof actions]>

interface State {
  activeCompanyId: string
  byId: { [id: string]: Company }
  form: {
    loading: boolean
    error?: Error
  }
}

const initialState: State = {
  activeCompanyId: '',
  byId: {},
  form: {
    loading: false,
  },
}

export const reducer: Reducer<State, actions | authActions> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ACTIVE: {
      return {
        ...state,
        activeCompanyId: action.companyId,
        form: {
          loading: false,
        },
      }
    }
    case actionTypes.SET: {
      const byId: { [id: string]: Company } = {}
      action.companies.map((company) => {
        byId[company.id] = company
      })
      return {
        ...state,
        byId,
      }
    }
    case actionTypes.CREATE_REQUEST:
    case actionTypes.UPDATE_REQUEST: {
      return {
        ...state,
        form: {
          loading: true,
        },
      }
    }
    case actionTypes.CREATE_FAILURE:
    case actionTypes.UPDATE_FAILURE: {
      return {
        ...state,
        form: {
          loading: false,
          error: action.error,
        },
      }
    }
    case actionTypes.UPDATE_SUCCESS: {
      const { company } = action
      return {
        ...state,
        form: {
          loading: false,
        },
        byId: {
          ...state.byId,
          [company.id]: { ...company },
        },
      }
    }
    case authActionTypes.LOG_OUT:
      return initialState
    default:
      return state
  }
}

export const selectors = {
  getActiveCompanyId: () => (state: Record<typeof selector, State>) => state[selector].activeCompanyId,
  getActiveCompany: () => (state: Record<typeof selector, State>) => {
    const activeCompanyId = state[selector].activeCompanyId
    const companies = state[selector].byId
    return companies[activeCompanyId] || companies[0]
  },
  getCompanyById: (id: string) => (state: Record<typeof selector, State>) => state[selector].byId[id],
  getCompanies: () => (state: Record<typeof selector, State>) => state[selector].byId,
  getForm: () => (state: Record<typeof selector, State>) => state[selector].form,
}
