import React, { useCallback, useEffect, useImperativeHandle, useMemo, useRef } from 'react'
import { Extensions, HTMLContent, JSONContent } from '@tiptap/react'
import extension from '@mm/tiptap-extension'
import { Content, Editor } from '@tiptap/core'
import { TipTapEditor, TipTapEditorProps, TipTapEditorRef } from './TipTapEditor'
import { useLatest } from 'react-use'
import * as Y from 'yjs'
import { YjsFirestoreProvider } from '../collaboration'
import { Collaboration } from '@tiptap/extension-collaboration'
import { yDocFragment } from '@mm/backend/editor/model'
import { WebrtcProvider } from 'y-webrtc'
import { CollaborationCursor } from '@tiptap/extension-collaboration-cursor'
import { useSelector } from 'react-redux'
import { selectors } from '../../accounts'
import { useLatestCallback } from '../hooks'

export type EditableProps = Pick<
  TipTapEditorProps,
  'background' | 'editable' | 'mode' | 'prefix' | 'selectionToolbar'
> & {
  documentId?: string
  initialValue?: Content
  placeholder?: string
  autofocus?: boolean
  toolbarCallbackParam?: any

  onChange?: (editor: Editor) => void
  onChangeHtml?: (html: HTMLContent) => void
  onChangeJson?: (json: JSONContent) => void

  onBlur?: () => void
  onFocus?: () => void
}

export type EditableRef = {
  editor: Editor | null
}

const userColors = ['#958DF1', '#F98181', '#FBBC88', '#FAF594', '#70CFF8', '#94FADB', '#B9F18D']

export const Editable = React.forwardRef<EditableRef, EditableProps>(function Editable(
  {
    documentId,
    initialValue,
    placeholder,
    autofocus = false,
    onChange,
    onChangeHtml,
    onChangeJson,
    onBlur,
    onFocus,
    ...rest
  },
  ref,
): React.ReactElement {
  if (documentId != null && initialValue != null) {
    console.warn('[Editor] documentId and initialValue should not be set at the same time')
  }

  const editorRef = useRef<TipTapEditorRef>(null)
  useImperativeHandle(ref, () => ({
    get editor() {
      return editorRef.current?.editor ?? null
    },
  }))

  const user = useSelector(selectors.getActiveAccount())

  const yDoc = useMemo(() => new Y.Doc(), [documentId])
  const firestoreProvider = useMemo(() => {
    if (documentId != null) {
      return new YjsFirestoreProvider(yDoc, documentId)
    } else {
      return null
    }
  }, [yDoc, documentId])
  // usage of WebRTC provides low-latency real-time collaboration and cursors with no effort.
  // however it has severe security implications when using public signalling servers without password protection:
  // anyone can pretend to have access to a document just by providing its ID.
  // password protection is a bit tricky though: for better security passwords must be rotated regularly and every time
  // a user loses access to a document, otherwise they will still be able to connect to the document's WebRTC room
  // TODO[MM-238] this must be secured by using private signalling servers with authentication
  // TODO[MM-238] or migrating to a secure websocket provider
  const webRtcProvider = useMemo(() => {
    if (documentId != null) {
      return new WebrtcProvider(documentId, yDoc)
    } else {
      return null
    }
  }, [yDoc, documentId])

  useEffect(() => {
    firestoreProvider?.start()
    return () => firestoreProvider?.destroy()
  }, [firestoreProvider])
  useEffect(() => {
    // already initialized
    return () => {
      webRtcProvider?.disconnect()
      webRtcProvider?.destroy()
    }
  }, [webRtcProvider])

  const onChangeRef = useLatest(onChange)
  const onChangeHtmlRef = useLatest(onChangeHtml)
  const onChangeJsonRef = useLatest(onChangeJson)

  const handleUpdate = useCallback(({ editor }: { editor: Editor }): void => {
    onChangeRef.current?.(editor)
    onChangeHtmlRef.current?.(editor.getHTML())
    onChangeJsonRef.current?.(editor.getJSON() as JSONContent)
  }, [])

  const handleBlur = useLatestCallback(onBlur)
  const handleFocus = useLatestCallback(onFocus)

  if (user == null) {
    return <></>
  }

  const extensions: Extensions = [
    extension.configure({
      history: false,
      placeholder,
    }),
    Collaboration.configure({
      document: yDoc,
      field: yDocFragment,
    }),
  ]

  if (webRtcProvider != null) {
    extensions.push(
      CollaborationCursor.configure({
        provider: webRtcProvider,
        user: {
          name: user.name,
          color: userColors[Math.trunc(userColors.length * Math.random())],
        },
      }),
    )
  }

  return (
    <TipTapEditor
      {...rest}
      key={documentId}
      ref={editorRef}
      editorOptions={{
        content: initialValue,
        extensions,
        onUpdate: handleUpdate,
        onBlur: handleBlur,
        onFocus: handleFocus,
        autofocus,
      }}
    />
  )
})
