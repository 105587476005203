import * as Y from 'yjs'
import { Unsubscribe, watchDocumentContent } from './model'
import _ from 'lodash'
import { updateContent } from './api'

export class YjsFirestoreProvider {
  private readonly yDoc: Y.Doc
  private readonly documentId: string
  private documentSubscription?: Unsubscribe

  constructor(yDoc: Y.Doc, documentId: string) {
    this.yDoc = yDoc
    this.documentId = documentId
  }

  readonly start = (): void => {
    this.yDoc.on('update', this.updateHandler)
    this.documentSubscription = watchDocumentContent(this.documentId, (content) => {
      Y.applyUpdate(this.yDoc, content)
    })
  }

  readonly destroy = (): void => {
    this.yDoc.off('update', this.updateHandler)
    this.documentSubscription?.()
  }

  // this function actually has following signature: (update: Uint8Array) => void
  private readonly updateHandler = _.throttle((): void => {
    // here we take snapshot of the whole document instead of applying granular updates
    // since we store the document snapshot in the firestore anyway, and doing it other way would force us
    // to handle situations where only part of the updates are applied in order to guarantee consistency between clients
    // WARN: this is probably a performance bottleneck for large documents
    const snapshot = Y.encodeStateAsUpdate(this.yDoc)
    updateContent(this.documentId, snapshot).catch((err) => {
      console.error('Failed to store yjs update', err)
    })
  }, 1000)
}
