/* eslint-disable react/display-name */
import { Theme } from '@emotion/react'
import React from 'react'
import styled from '@emotion/styled'
import { spacing, modifyLuminance, ColorName, color as themeColor } from './theme'

interface Props {
  children?: React.ReactElement | string
  loading?: boolean
  disabled?: boolean
  type?: 'button' | 'submit' | 'reset'
  size?: keyof Theme['button']['size']
  color?: ColorName
  onClick?: () => void
}

const sizeToButtonConfig = (type: Props['size'], theme: Theme): Record<string, unknown> => {
  switch (type) {
    case 'small':
      return {
        height: theme.button.size.small,
        paddingLeft: spacing(1),
        paddingRight: spacing(1),
        fontSize: theme.font.size.small,
      }
    case 'medium':
      return {
        height: theme.button.size.medium,
        paddingLeft: spacing(1),
        paddingRight: spacing(1),
        fontSize: theme.font.size.medium,
      }
    case 'large':
      return {
        height: theme.button.size.large,
        paddingLeft: spacing(2),
        paddingRight: spacing(2),
        fontSize: theme.font.size.large,
      }
    default:
      return {}
  }
}

// https://stackoverflow.com/a/41491220
const getContrastingColor = (bgColor: string) => {
  return parseInt(bgColor.replace('#', ''), 16) > 0xffffff / 2 ? 'rgba(0,0,0,0.8)' : 'rgba(255,255,255,0.9)'
}

const BaseButton = styled.button<Props>(({ size = 'medium', color = 'gray', theme }) => ({
  ...sizeToButtonConfig(size, theme),
  color: getContrastingColor(themeColor(color)({ theme })),
  backgroundColor: themeColor(color)({ theme }),
  borderRadius: theme.button.radius,
  border: 'none',
  cursor: 'pointer',
  outline: 'none',
  lineHeight: 1,
  transition: '100ms background-color, 100ms box-shadow',

  '&:hover': {
    backgroundColor: modifyLuminance(themeColor(color)({ theme }), 0.1),
  },

  '&:disabled': {
    cursor: 'not-allowed',
    backgroundColor: theme.color.lightGray,
  },

  '&:active, &:focus': {
    backgroundColor: modifyLuminance(themeColor(color)({ theme }), -0.2),
    boxShadow: `0 0 0 1px #fff, 0 0 0 3px ${themeColor(color)({ theme })}`,
  },
}))

export const Button = React.forwardRef<HTMLButtonElement, Props>(({ loading, ...props }, ref) => {
  return (
    <BaseButton {...props} ref={ref}>
      {loading ? 'Loading...' : props.children}
    </BaseButton>
  )
})
