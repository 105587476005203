export const roleMap = {
  participant: 'PARTICIPANT',
  observer: 'OBSERVER',
  owner: 'MEETING_OWNER',
} as const

export const roles = ['MEETING_OWNER', 'PARTICIPANT', 'OBSERVER'] as const

export type MeetingParticipantRole = typeof roles[number]

export interface IssueParticipantInput {
  id: string
  role: IssueParticipantRole
}

export const issueRoleMap = {
  participant: 'PARTICIPANT',
  observer: 'OBSERVER',
} as const

export const issueRoles = ['PARTICIPANT', 'OBSERVER'] as const

export type IssueParticipantRole = typeof issueRoles[number]

export const sharedBlockProperties = {
  description: '',
  likes: 0,
  meetingId: '',
  likeAccountIds: [],
  attachments: [],
}

export const sharedGoodThingConfig = {
  type: 'goodThings',
  id: 'goodThings',
  title: 'Good Things',
  subtitle: "Celebrate the good things that have recently happened in the company and in each other's personal lives.",
  color: '#7e57c2',
} as const

export const sharedTopicsConfig = {
  type: 'topics',
  id: 'topics',
  title: 'Topics',
  subtitle: 'List any topics you would like to discuss during the meeting.',
  color: '#66bb6a',
} as const

export const sharedIssuesConfig = {
  type: 'issues',
  id: 'issues',
  title: 'Issues',
  subtitle: 'Share the challenges you are facing in your work and personal life right now.',
  color: '#ff7043',
} as const

export const sharedActionsPriorConfig = {
  type: 'actionsPrior',
  id: 'actionsPrior',
  title: 'Review Actions',
  subtitle: 'Mark actions from previous meetings as either Done or Not Done.',
  color: '#42a5f5',
} as const

export const sharedActionsNextConfig = {
  type: 'actionsNext',
  id: 'actionsNext',
  title: 'Actions Towards Goals',
  subtitle:
    'For each goal, enter the next action that you can take in order to significantly advance toward completion of that goal.',
  color: '#42a5f5',
} as const

export const sharedFeedbackConfig = {
  type: 'feedback',
  id: 'feedback',
  title: 'Feedback',
  subtitle: 'Share feedback with each other, both positive and negative, so that everyone can improve.',
  color: '#78909c',
} as const

export const recurringMeetingMap = {
  monthly: 4,
  'bi-weekly': 2,
  weekly: 1,
  daily: 1,
} as const

export const issueDecisionMap = {
  decided: 'DECIDED',
  created: 'CREATED',
  resolved: 'RESOLVED',
} as const

export const meetingPrepTimeLine = [
  {
    key: 1,
    subDays: 2,
    dateFormat: 'MM/dd',
  },
  {
    key: 2,
    subDays: 1,
    dateFormat: 'MM/dd',
  },
  {
    key: 3,
    subDays: 0,
    dateFormat: 'MM/dd',
  },
] as const
