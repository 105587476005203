export interface Document {
  parent?: DocumentParent
  content: Uint8Array
}

export interface DocumentParent {
  type: 'group' | 'comment' | 'issue'
  id: string
}

export const yDocFragment = 'default'
