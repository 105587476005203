import { useMutation } from 'react-query'
import axios from 'axios'
import { EditReqBody, EditResBody } from '../../../pages/api/issues/[id]'

interface EditIssue {
  id: string
  issue: EditReqBody
}

const editIssue = async ({ id, issue }: EditIssue) => {
  const { data } = await axios.post<EditResBody, EditReqBody>(`/api/issues/${id}`, issue)
  return data
}

const useEditIssueMutation = () => {
  return useMutation(editIssue)
}

export default useEditIssueMutation
