import styled from '@emotion/styled'

export const Card = styled.div({
  borderRadius: 4,
  backgroundColor: '#fff',
  border: '1px solid #ddd',
  padding: 10,
})

export default Card
