import React from 'react'
import styled from '@emotion/styled'
import { Modal, Typography, Button } from 'antd'
import { FlexRow } from '../../ui'
import ChevronDownIcon from '@icons/material/ChevronDownIcon'

const BlockTitleWrap = styled.div({
  padding: '0 30px',
  position: 'relative',
})

interface Props {
  id: string
  title: string
  subtitle: string | JSX.Element
  modal?: LearnMoreModal
  color: string
  icon: JSX.Element
  content: JSX.Element[] | JSX.Element | React.ReactNode
  right?: JSX.Element
}
interface LearnMoreModal {
  title: string
  content: JSX.Element
  youtubeId?: string
}

export const BlockTitle = ({ id, title, subtitle, modal, color, icon, content, right }: Props) => {
  const [blocksVisible, setBlocksVisible] = React.useState(true)
  const [isModalVisible, setIsModalVisible] = React.useState(false)

  const handleShowModal = () => setIsModalVisible(true)
  const handleCloseModal = () => setIsModalVisible(false)

  return (
    <>
      {modal && isModalVisible && (
        <Modal
          title={modal.title}
          visible={isModalVisible}
          onOk={handleCloseModal}
          onCancel={handleCloseModal}
          footer={null}
        >
          {modal.content}
          {modal.youtubeId && (
            <iframe
              src={`https://www.youtube.com/embed/${modal.youtubeId}`}
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
              title={title}
              style={{ width: '100%', height: 300, borderRadius: 8, marginTop: 12, display: 'block' }}
            />
          )}
        </Modal>
      )}
      <BlockTitleWrap>
        <div
          style={{
            transition: 'transform 200ms',
            transform: `rotate(${blocksVisible ? '0' : '-180deg'})`,
            position: 'absolute',
            left: 0,
            top: 3,
            color: '#999',
            cursor: 'pointer',
          }}
          onClick={() => setBlocksVisible(!blocksVisible)}
        >
          <ChevronDownIcon style={{ display: 'block' }} />
        </div>

        <FlexRow style={{ color }}>
          {icon}
          <Typography.Title level={3} id={id} style={{ marginBottom: 0, color, flex: 1 }}>
            {title}
          </Typography.Title>
          {right}
        </FlexRow>

        <Typography.Text style={{ display: 'block' }} type="secondary">
          {subtitle}

          {modal ? (
            <Button type="link" style={{ color, padding: 0, marginLeft: 7 }} onClick={handleShowModal}>
              Learn more
            </Button>
          ) : null}
        </Typography.Text>
      </BlockTitleWrap>
      {blocksVisible ? <div style={{ paddingTop: 15 }}>{content}</div> : null}
    </>
  )
}

export default BlockTitle
